import React, { useEffect, useState } from "react";
import PostSidebar from "../../../shared/PostSidebar";
import { useParams } from "react-router-dom/dist/umd/react-router-dom.development";
import noData from "../../../assets/images/no-data-found.png";
import { loadFundraiserDetails } from "../../../services/FundrisersService";
import Loader from "../../elements/loader/Loader";
import Comments from "../fundraiser/Comments";
import dayjs from "dayjs";
import { classNames } from "../../../helpers/classNames";
import Image from "../../elements/Image";
import DetailsImage from "../fundraiser/DetailsImage";
import Tag from "../../elements/Tag";
import Share from "../Share";
import Tabs from "../../elements/Tabs";
import About from "../fundraiser/About";
import Documents from "../fundraiser/Documents";
import Updates from "../fundraiser/Updates";
import Donation from "../fundraiser/Donation";
import avater from "../../../assets/images/no-profile-picture.jpg";
import Visa from "../../../assets/images/visa.svg";
import MasterCard from "../../../assets/images/mastercard.svg";
import Amex from "../../../assets/images/amex.svg";
import UPI from "../../../assets/images/upi.svg";
import QRCode from "../../../assets/images/qrcode.png";
import GPay from "../../../assets/images/gpay.png";
import Paytm from "../../../assets/images/paytm.png";
import PhonePay from "../../../assets/images/phonepay.png";
import MetaInfo from "../fundraiser/MetaInfo";
import { loadSupporterList } from "../../../services/SupportersService";
import Suppoters from "../../../components/partials/fundraiser/Suppoters";
import ImageGallery from "react-image-gallery";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Button from "../../form/Button";
import ImageSkeletonLoader from "../../elements/loader/ImageSkeletonLoader";

const FundraisedDashboard = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [currentImage, setCurrentImage] = useState("");
  const [loaded, setLoaded] = useState(true);
  const [supportersList, setsupportersList] = useState([]);
  const [supporterListLoaded, setSupporterListLoaded] = useState(true);

  const aboutData = {
    title: "About",
    content: data && data?.description,
  };

  const updateData = {
    title: "Updates",
    content:
      "<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p> <p>Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like). It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p><p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p> <p>Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like). It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>",
  };

  const commentData = {
    title: "Comments",
    count: 500,
    id: data && data?._id,
    subdata:
      data &&
      Array.isArray(data?.comments) &&
      data?.comments?.length > 0 &&
      data?.comments?.map((elm) => ({
        _id: elm?._id || crypto.randomUUID(),
        name: elm?.user?.name,
        image: elm?.user?.image?.url,
        comments: elm?.comment,
        replies: elm?.replies,
        user: elm?.user,
        time: dayjs(elm?.createdAt).format("MMM D,h:mm A"),
      })),
  };

  const tabData = [
    {
      _id: 1,
      name: "About",
      component: data && data.description && <About data={aboutData} />,
    },
    {
      _id: 2,
      name: "Documents",
      component: (
        <>
          <Documents data={data && data?.documents} />
          <div className="relative mt-4">
            <Button
              buttonClasses={"!px-8 !h-10  !w-52 ml-auto"}
              buttonHasLink={true}
              buttonLink={`/fundraised-edit/${data?.slug}`}
              buttonLabel={"Add New Documents"}
              buttonLabelClasses={"text-sm"}
              buttonEffect={"filled"}
              buttonIcon={"fa-sharp fa-solid fa-plus"}
            />
          </div>
        </>
      ),
    },
    {
      _id: 3,
      name: "Updates",
      component:
        Array.isArray(data?.updates) && data?.updates?.length > 0 ? (
          <>
            {data?.updates?.map((item, index) => (
              <div className="relative  gap-3 xl:gap-5 py-5" key={index}>
                <Updates
                  data={{
                    title: item?.title,
                    content: item?.description,
                    _id: item?._id,
                    fundraiserId: data?._id,
                  }}
                  link={`/fundraised-update/${id}`}
                  setData={setData}
                  item={item}
                />
              </div>
            ))}
            <div className="relative mt-4">
              <Button
                buttonClasses={"!px-8 !h-10  !w-48 ml-auto"}
                buttonHasLink={true}
                buttonLink={`/fundraised-update/${data?.slug}`}
                buttonLabel={"Add New Updates"}
                buttonLabelClasses={"text-sm"}
                buttonEffect={"filled"}
                buttonIcon={"fa-sharp fa-solid fa-plus"}
              />
            </div>
          </>
        ) : (
          <>
            <Updates />
            <div className="relative mt-4">
              <Button
                buttonClasses={"!px-8 !h-10  !w-48 ml-auto"}
                buttonHasLink={true}
                buttonLink={`/fundraised-update/${data?.slug}`}
                buttonLabel={"Add New Updates"}
                buttonLabelClasses={"text-sm"}
                buttonEffect={"filled"}
                buttonIcon={"fa-sharp fa-solid fa-plus"}
              />
            </div>
          </>
        ),
    },
    {
      _id: 4,
      name: "Comments",
      component: <Comments id={data?._id} userId={data && data?.userId} />,
    },
  ];

  const donationData = {
    title: "Donation Details",
    raised: data?.raised,
    goal: data?.amount,
    paymentModes: [
      { name: "Visa", image: Visa },
      { name: "Master Card", image: MasterCard },
      { name: "American Express", image: Amex },
      { name: "UPI (Unified Payments Interface)", image: UPI },
    ],
    button: "Donate Now",
    instantPay: {
      image: QRCode,
      title: "Scan with any app",
      mode: [
        { name: "Google P{ay", image: GPay },
        { name: "PAYTM", image: Paytm },
        { name: "Phone Pay", image: PhonePay },
      ],
    },
  };

  const metaInfoData = {
    createdFor: {
      image:
        data && data?.beneficiary?.image[0]?.url
          ? data?.beneficiary?.image[0]?.url
          : avater,
      location: data && data?.beneficiary?.address,
      name: data && data?.beneficiary?.name,
      user: data && data?.user,
    },
  };

  const supportersData = {
    title: "Supporters",
    count:
      supportersList && Array.isArray(supportersList) && supportersList.length,
  };

  useEffect(() => {
    if (data?._id) {
      loadSupporterList(data?._id, () => {}, setSupporterListLoaded).then(
        (res) => {
          let data = res?.data?.docs;
          const list =
            data &&
            data.map((elm) => ({
              _id: elm?.supporter?._id,
              name: elm?.supporter?.name,
              image: elm?.supporter?.image?.url,
              donation: elm?.totalDonation,
              currency: "INR",
              currencyClass: "fa-indian-rupee-sign",
            }));
          setsupportersList(list);
        }
      );
    }
  }, [data]);

  useEffect(() => {
    if (data && data?.gallery) {
      setCurrentImage(data?.gallery[0]?.url);
    }
  }, [data]);

  useEffect(() => {
    loadFundraiserDetails(id, setData, setLoaded);
  }, [id]);

  const images =
    data &&
    data?.gallery &&
    data?.gallery.length > 0 &&
    data?.gallery.map((elm) => ({
      original: elm?.url,
      thumbnail: elm?.url,
    }));

  // const renderFullscreenButton = (onClick, isFullscreen) => {
  //   return (
  //     <button
  //       type="button"
  //       className={`image-gallery-fullscreen-button ${
  //         isFullscreen ? "active" : ""
  //       }`}
  //       onClick={onClick}
  //     />
  //   );
  // };

  // console.log(data, "lkjhgfjpjh");

  return (
    <section className="relative  overflow-hidden w-full py-5 bg-gray-100">
      <div className="relative z-[1] w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
        <div className="w-full flex md:flex-nowrap flex-wrap gap-4 md:gap-0">
          <div className="w-full md:w-80 min-w-[320px] ">
            <PostSidebar id={id} />
          </div>

          {/* {!loaded && <Skeleton count={50} />} */}
          <div className="relative bg-white rounded-r-lg w-full max-w-[calc(100%-320px)])] overflow-hidden">
            <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
              <div className="relative lg:col-span-2 space-y-6 xl:space-y-8 px-5">
                {loaded ? (
                  <div className="relative bg-white rounded-3xl">
                    <div className="py-8 px-6">
                      <h1 className="font-Lexend font-semibold text-black text-lg leading-tight sm:leading-normal sm:text-xl xl:text-2xl 2xl:text-3xl">
                        {data?.title}
                      </h1>
                    </div>
                    {/* <div className="relative z-0">
                    <div
                      className={classNames(
                        "relative z-[2] w-full h-80 sm:h-[420px] xl:h-[480px] 2xl:h-[600px] overflow-hidden rounded-3xl",
                        "after:content-[''] after:absolute after:w-full after:h-40 after:bg-gradient-to-t after:from-black/60 after:bottom-0 after:left-0 after:z-[1]"
                      )}
                    >
                      <Image
                        src={currentImage}
                        alt={""}
                        width={"100%"}
                        height={"100%"}
                        effect={"blur"}
                        classes={"object-cover"}
                      />
                    </div>
                    <Tag label={"Urgent need of funds"} />
                  </div> */}
                    {/* <div className="flex flex-col sm:flex-row items-center justify-between px-4 xl:px-10">
                    <div className="relative w-full sm:max-w-[calc(100%-200px)] pb-5 sm:pb-0">
                      <DetailsImage
                        data={data && data?.gallery}
                        setCurrentImage={setCurrentImage}
                      />
                    </div>
                    <Share />
                  </div> */}

                    {images && images.length > 0 && (
                      <ImageGallery
                        items={images}
                        renderItem={(item) => (
                          <div className="image-gallery-image">
                            <img
                              src={item.original}
                              alt={item.description}
                              // className="image-gallery-image"
                            />
                          </div>
                        )}
                        showThumbnails={true}
                        // renderFullscreenButton={true}
                        showFullscreenButton={false}
                        renderThumbInner={(item) => (
                          <div className="image-gallery-thumbnail-inner">
                            <img src={item?.thumbnail} />
                          </div>
                        )}
                      />
                    )}
                  </div>
                ) : (
                  <ImageSkeletonLoader />
                )}

                <div className="pb-5 bg-carnation-50 rounded-md p-5">
                  <Tabs tabs={tabData} />
                </div>
                <div className="flex py-3">
                  <div className="w-2/3 border-r border-slate-200 px-5">
                    <MetaInfo data={metaInfoData} />
                    <Suppoters
                      data={supportersData}
                      loaded={supporterListLoaded}
                    />
                  </div>
                  <div className="w-1/3">
                    <Donation data={donationData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FundraisedDashboard;
