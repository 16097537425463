
import React from 'react';
import { Link } from "react-router-dom";
import Image from "../elements/Image";
import Button from "../form/Button";
import dayjs from 'dayjs'


const SingleArticle = ({ data }) => {
  const dateObject = dayjs(data?.createdAt).format("D MMMM, YYYY");

  return (
    <>
      <div className={data.boxRow ? "w-full flex flex-row bg-gray-100 rounded-4xl rounded-tl-none overflow-hidden transition-all duration-200 hover:shadow-lg" : "w-full flex flex-col bg-white rounded-4xl rounded-tl-none overflow-hidden transition-all duration-200 hover:shadow-lg"}>
        <div className={data.smallImage ? "w-[280px] data.smallImage?'70%': h-52 xl:h-80 3xl:h-52 overflow-hidden rounded-4xl rounded-tl-none" : "h-52 xl:h-80 3xl:h-96 overflow-hidden rounded-4xl rounded-tl-none"}>
          <Link to={data?.postType && data?.postType === "news" ? `/news-details/${data?._id}` : `/blogs-details/${data?._id}`}>
            <></>
            <Image src={data?.image?.url} alt={''} width={'100%'} height={'100%'} effect={'blur'} classes={'object-cover'} />
          </Link>
        </div>
        <div className={data.fontSize ? "py-6 px-4 w-[calc(100%-280px)]" : "py-6 px-4 "}>
          <Link className='w-full flex flex-col h-full' to={data?.postType && data?.postType === "news" ? `/news-details/${data?._id}` : `/blogs-details/${data?._id}`}>
            <div className='relative mt-auto mb-1'>
              <div className={data.type === "date" ? "relative hidden " : "relative flex"}>
                <div className='text-slate-900'>{dateObject}</div>
              </div>
            </div>
            <h4 className="text-xl font-Lexend text-black font-medium leading-tight">{data?.title}</h4>
            <div className="text-xs xl:text-sm text-slate-600 my-2">
              <div className="text-xs xl:text-sm text-slate-600 my-2 text-ellips-desc" dangerouslySetInnerHTML={{ __html:data?.description }} />
            </div>
            <div className={data.type === "date" ? "relative flex" : "relative hidden "}>
              <div className='text-slate-900 mt-2'>{data?.dateText}</div>
            </div>
            <div style={{ marginBottom: "0px" }}></div>

            {data.raised !== undefined && <div className="relative bg-gray-100 pt-10 pb-4 px-6 my-4 rounded-lg">
              <div className="relative flex bg-gray-200 rounded-full w-full h-2">
                <div className="relative bg-carnation-500 rounded-full" style={{ width: (data?.raised / data?.goal) * 100 + "%" }}>
                  <div className="absolute w-4 h-4 rounded-full top-1/2 -translate-y-1/2 -right-2 bg-carnation-500"></div>
                  <div className="absolute w-8 h-5 rounded-full -top-6 -right-4 text-xs text-center font-medium text-slate-600">{(data?.raised / data?.goal) * 100 + "%"}</div>
                </div>
              </div>
              <div className="flex items-center justify-between mt-2">
                {data.raised !== undefined && <div className="text-xs font-medium text-slate-600"></div>}
                {data.goal !== undefined && <div className="text-xs font-medium text-slate-600"></div>}
              </div>
            </div>}

          </Link>
          <div className="flex items-center justify-between mt-auto">
            {data.shareText !== undefined && <Button
              buttonClasses={'!px-8 !h-10 !mr-auto !bg-transparent !text-carnation-500 border border-carnation-500'}
              buttonHasLink={true}
              buttonLink={""}
              buttonLabel={'Share'}
              buttonLabelClasses={'text-sm'}
              buttonEffect={"hollow"}
            />}
            {data.donateText !== undefined && <Button
              buttonClasses={'!px-8 !h-10 !ml-auto'}
              buttonHasLink={true}
              buttonLink={""}
              buttonLabel={'Donate'}
              buttonLabelClasses={'text-sm'}
              buttonEffect={"filled"}
            />}
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleArticle;