import React, { useState } from "react";

const StorageItemLoader = ({ count = 1 }) => {
  const loaderItem = [];

  for (let i = 1; i <= count; i++) {
    loaderItem.push(`item${i}`);
  }

  return (
    <>
      {Array.isArray(loaderItem) &&
        loaderItem?.length > 0 &&
        loaderItem?.map((item) => (
          <div key={item} className="w-28 shadow-sm flex flex-col justify-center items-center bg-slate-50 rounded-md overflow-hidden h-32 min-h-32 max-h-32">
            <div className="w-full h-full relative before bg-gradient-to-r from-transparent via-gray-100/90 to-transparent -translate-x-full animate-[shimmer_2s_infinite]"></div>
          </div>
        ))}
    </>
  );
};

export default StorageItemLoader;
