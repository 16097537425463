import React from "react";
import SingleSupporter from "../../items/SingleSupporter";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SupporterList = ({ data, loaded }) => {
  return (
    <>
      {loaded ? (
        <>
          <div className="relative space-y-5">
            {data.length > 0 &&
              data.map((item) => (
                <SingleSupporter key={item._id} data={item} />
              ))}
          </div>
        </>
      ) : (
        <Skeleton />
      )}
    </>
  );
};

export default SupporterList;
