import React, { useEffect,useState } from "react";
import PostSidebar from "../shared/PostSidebar";
import { useParams } from "react-router-dom/dist/umd/react-router-dom.development";
import SupporterList from "../components/partials/supporter/SupporterList";
import { loadSupporterList } from "../services/SupportersService";
import Loader from "../components/elements/loader/Loader";
import dayjs from "dayjs";
import noData from "../assets/images/no-data-found.png"

const FundraiserSupporters = () => {
  const { id } = useParams();
  const [loaded, setloaded] = useState(true);
  const [supporters, setsupporters] = useState([]);

  useEffect(() => {
    loadSupporterList({}, setsupporters, setloaded).then((res) => {});
  }, []);

  const supporterListDataa =
  Array.isArray(supporters) &&
  supporters.length > 0 &&
  supporters.map((elm, index) => ({
    _id: elm?._id,
    name: elm?.supporter?.name,
    image: elm?.supporter?.image?.url,
    donation: elm?.totalDonation,
    donationList:
      Array.isArray(elm.donations) &&
      elm.donations.length > 0 &&
      elm.donations.map((ele, index) => ({
        _id: index,
        title: ele?.transactionInfo,
        donation: ele?.donation,
        date: dayjs(ele?.createdAt).format("MMM D, YYYY"),
        transactionId: ele?.transactionId,
        method: ele?.paymentMethod,
        status: ele?.paymentStatus,
      })),
  }));

  const dropdownData = [
    // {_id:1, label: 'Dashboard', icon:'fa-gauge', link:'/user/dashboard', standout:false},
    // {_id:2, label: 'Fundraise', icon:'fa-hands-holding-dollar', link:'/user/fundraised', standout:false},
    // {_id:3, label: 'Completed', icon:'fa-heart-circle-check', link:'/user/completed-fundraised', standout:false},
    {
      _id: 3,
      label: "Edit Post",
      icon: "fa-solid fa-file-pen",
      link: `/fundraiser-controll/${id}`,
      standout: false,
    },
    {
      _id: 4,
      label: "Supporters",
      icon: "fa-hand-holding-dollar",
      link: `/fundraiser-supporters/${id}`,
      standout: false,
    },
    {
      _id: 5,
      label: "Transactions",
      icon: "fa-circle-dollar-to-slot",
      link: "/user/transactions",
      standout: false,
    },
    {
      _id: 6,
      label: "Withdraw",
      icon: "fa-money-bill-transfer",
      link: "/user/transactions",
      standout: false,
    },
    // {_id:6, label: 'Supporters', icon:'fa-people-group', link:'/user/supporters', standout:false},
    // {_id:7, label: 'Profile', icon:'fa-circle-user', link:'/user/profile', standout:false},
    // {_id:8, label: 'Settings', icon:'fa-sliders', link:'/user/settings', standout:false},
    { _id: 9, separator: true },
    // {_id:10, label: 'Logout', icon:'fa-arrow-right-from-arc', onclick:() => {
    //   setOpen(true)
    // }, standout:true},
  ];
  return (
    <section className="relative  overflow-hidden w-full py-5 bg-gray-100">
      <div className="relative z-[1] w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
        <div className="w-full flex md:flex-nowrap flex-wrap gap-4 md:gap-0">
          <div className="w-full md:w-80 min-w-[320px] ">
            <PostSidebar data={dropdownData} />
          </div>
          <div className="ml-5">
            {Array.isArray(supporters) && supporters.length > 0 ? (
              loaded ? (
                <>
                  {" "}
                  <SupporterList data={supporterListDataa} />
                </>
              ) : (
                <Loader />
              )
            ) : (
              <img src={noData} />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FundraiserSupporters;
