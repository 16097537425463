// import React from 'react';

// const SingleSuccessStory = () => {
//     return (
//         <div>

//         </div>
//     );
// };

// export default SingleSuccessStory;

import React from "react";
import Image from "../elements/Image";
import { Link } from "react-router-dom";
import Button from "../form/Button";

const SingleSuccessStory = ({ data }) => {
    console.log(data,"singleData")
  return (
    <>
      <div className="w-full flex flex-col bg-white rounded-2xl  overflow-hidden transition-all duration-200 hover:shadow-lg">
        <div className="flex flex-col items-center gap-5">
          <div className="w-full h-16 xl:w-full xl:h-60 flex-grow-0 flex-shrink-0 overflow-hidden">
            <Image
              src={data?.image?.url}
              alt={""}
              width={"100%"}
              height={"100%"}
              effect={"blur"}
              classes={"object-cover"}
            />
          </div>
          <div className="w-full flex-grow flex-shrink px-4">
            <h4 className="text-base xl:text-lg font-Lexend text-black font-semibold leading-tight text-ellips-title">
              {data?.title}
            </h4>
            {/* <div
              className="text-xs xl:text-sm text-slate-600"
              dangerouslySetInnerHTML={{ __html: data?.description }}
            /> */}
          </div>
        </div>
        {/* <div className="text-xs xl:text-sm text-slate-600 mt-4">{data?.description}</div> */}
        <div
          className="text-xs xl:text-sm text-slate-600 mt-2 px-4 pb  pb-4 text-ellips-desc"
          dangerouslySetInnerHTML={{ __html: data?.description }}
        />
        <div className="text-sm px-4 pb-4 text-slate-500 hover:text-carnation-500 transition-all">
          <Link to={`/success-story-details/${data?._id}`}>Read More</Link>
        </div>
      </div>
    </>
  );
};

export default SingleSuccessStory;
