import { input } from "@material-tailwind/react";
import React, { useState } from "react";

const ReplyEditModal = ({
  isOpen,
  onClose,
  onSubmit,
  inputValue,
  setInputValue,
}) => {
  //   const [inputValue, setInputValue] = useState('');

  const handleSubmit = () => {
    onSubmit(inputValue);
    onClose();
  };

  return (
    <div
      className={`fixed inset-0 ${
        isOpen ? "" : "hidden"
      } flex items-center justify-center z-50`}
    >
      <div className="modal-overlay" onClick={onClose}></div>
      <div className="modal-container bg-white w-1/2 mx-auto rounded shadow-lg z-50">
        <div className="modal-content py-4 text-left px-6">
          <h1 className="text-2xl font-semibold mb-4">Edit Reply</h1>
          <input
            type="text"
            className="border rounded w-full py-2 px-3"
            placeholder="Enter something..."
            value={inputValue?.reply}
            onChange={(e) =>
              setInputValue((pre) => ({ ...pre, reply: e.target.value }))
            }
          />
          <div className="mt-4">
            <button
              className="btn-primary mr-2 rounded-lg bg-carnation-500 text-white cursor-pointer p-1"
              onClick={handleSubmit}
            >
              Submit
            </button>
            <button
              className="btn-secondary rounded-lg bg-carnation-500 text-white cursor-pointer p-1"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReplyEditModal;
