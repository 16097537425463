import React from 'react';
import Image from "../elements/Image";

const DownloadApp = ({data}) => {
  return (
    <>
      <section className="relative overflow-hidden w-full py-28 bg-white">
        <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
          <div className="space-y-12">
            <div className="space-y-4 text-center">
              <h2 className="font-Lexend text-3xl sm:text-4xl xl:text-5xl font-bold text-black">{data.title}</h2>
              <div className="text-sm xl:text-base text-slate-600 max-w-2xl mx-auto">{data.subtitle}</div>
            </div>
            {data?.subdata?.length > 0 &&
              <div className="relative flex items-center justify-center gap-4 sm:gap-10">
                {data?.subdata?.map((item) => (
                  <a key={item._id} href={item.link} target="_blank" className="inline-flex h-12 xl:h-14">
                    <Image src={item.image} alt={''} width={'100%'} height={'100%'} effect={'blur'} classes={'object-contain'} />
                  </a>
                ))}
              </div>
            }
          </div>
        </div>
      </section>
    </>
  );
};

export default DownloadApp;