import React, { useEffect, useState } from "react";
import SingleComment from "../../items/SingleComment";
import Button from "../../form/Button";
import CommentInput from "./CommentInput";
import {
  AddCommentsService,
  loadCommentDetails,
} from "../../../services/FundrisersService";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import Loader from "../../elements/loader/Loader";
import MiniLoader from "../../elements/loader/MiniLoader";
import CommentSkeletonLoader from "../../elements/loader/CommentSkeletonLoader";

const Comments = ({ id, userId }) => {
  const [limit, setLimit] = useState(2);
  const [offset, setOffset] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);
  const [comment, setcomment] = useState(null);
  const [loaded, setloaded] = useState(true);
  const [commentRes, setcommentRes] = useState("");
  const [commentList, setCommentList] = useState([]);
  const [allComments, setallComments] = useState([]);

  // useEffect(() => {
  //   data &&
  //     data.subdata &&
  //     data.subdata[0]._id && data.subdata[0].name  &&
  //     setallComments(data?.subdata);
  // }, [data]);

  // useEffect(() => {
  //   let singleComment = {
  //     _id: commentRes && commentRes?._id,
  //     name: commentRes && commentRes?.user?.name,
  //     image: commentRes && commentRes?.user?.image?.url,
  //     comments: commentRes && commentRes?.comment,
  //     time: dayjs(commentRes && commentRes?.createdAt).format("MMM D,h:mm A"),
  //   };
  //   commentRes && setallComments((pre) => [...pre, singleComment]);
  // }, [commentRes]);

  useEffect(() => {
    setallComments((pre) => [...pre, ...commentList]);
  }, [commentList]);

  useEffect(() => {
    loadCommentDetails(
      { fundraiserId: id, limit, offset },
      setCommentList,
      setloaded
    ).then((res) => setTotalDocs(res?.data?.totalDocs));
  }, [id, offset]);

  const onChange = (e) => {
    setcomment(e.target.value);
  };

  const handleSubmit = () => {
    id && comment
      ? AddCommentsService({ comment, _id: id }, setcommentRes, setloaded).then(
          (res) => {
            if (res?.data?.status === 200) {
              toast.success(res?.data?.message);
              let singleComment = {
                _id: res.data.data && res?.data?.data?._id,
                name: res.data.data && res?.data?.data?.user?.name,
                image: res?.data?.data && res?.data?.data?.user?.image?.url,
                replies: res?.data?.data && res?.data?.data?.replies,
                comment: res?.data?.data && res?.data?.data?.comment,
                time: dayjs(
                  res?.data?.data && res?.data?.data?.createdAt
                ).format("MMM D,h:mm A"),
                user: res?.data?.data && res?.data?.data?.user,
              };
              setallComments((pre) => [...pre, singleComment]);
              setcomment("");
            } else {
              toast.error(res?.data?.message);
            }
          }
        )
      : toast.error("Comment box is empty!");
  };

  return (
    <>
      <div className="relative space-y-2 xl:space-y-5">
        {loaded ? (
          allComments.length > 0 && (
            <>
              <div className="relative divide-y divide-slate-100">
                {allComments.map((item) => (
                  <SingleComment
                    key={item._id}
                    data={item}
                    setallComments={setallComments}
                    userId={userId}
                  />
                ))}
              </div>
              {totalDocs > offset + limit && (
                <div className="flex items-center justify-center border-y border-slate-100 -mx-8 py-4">
                  {!loaded ? (
                    <MiniLoader />
                  ) : (
                    <Button
                      buttonClasses={
                        "!px-0 !h-auto !bg-transparent !rounded-none"
                      }
                      buttonHasLink={false}
                      buttonFunction={() => setOffset((pre) => pre + limit)}
                      buttonLabel={"View More"}
                      buttonLabelClasses={
                        "font-semibold text-carnation-500 !text-sm hover:!underline"
                      }
                    />
                  )}
                </div>
              )}
            </>
          )
        ) : (
          <>
            <CommentSkeletonLoader />
            <CommentSkeletonLoader />
            <CommentSkeletonLoader />
            <CommentSkeletonLoader />
          </>
        )}

        <CommentInput
          onChange={onChange}
          handleSubmit={handleSubmit}
          loaded={loaded}
          inputValue={comment}
        />
      </div>
    </>
  );
};

export default Comments;
