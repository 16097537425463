import { apiRequest } from "../utils/async/apiUtils";


export const loadWhyUsList = async (
    setWhyUs = () => {},
    setLoaded = () => {},
  
  ) => {
    setLoaded(false);
  
    try {
      const res = await apiRequest("get", "topics", {
        queries: {
          selectValue: "name,icon,image,type,description,status,ordering,createdAt",
          type:1
        },
      });
      const data = res.data;
      setWhyUs(data.docs);
      setLoaded(true);
      return res
    } catch (err) {
      setLoaded(true);
    }
  };