import React, { useState } from "react";
import SingleFundRaiser from "../items/SingleFundRaiser";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../elements/loader/Loader";
import Pagination from "../elements/Pagination/Pagination";
import { useEffect } from "react";
import CardSkeletonLoader from "../elements/loader/CardSkeletonLoader";

const ListFundraiser = ({ data, loaded }) => {
  return (
    <>
      <section className="relative overflow-hidden w-full py-20 bg-gray-100">
        <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
          <div className="space-y-10">
            <div className="space-y-4 text-center">
              <h2 className="font-Lexend text-3xl sm:text-4xl xl:text-5xl font-bold text-black">
                Our Top Fundraisers
              </h2>
              <div className="text-sm xl:text-base text-slate-600 max-w-xl mx-auto">
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form
              </div>
            </div>
            {/* <InfiniteScroll
              dataLength={Array.isArray(data) && data.length > 0 && data.length}
              next={fetchData}
              hasMore={page < totalPages}
              loader={!data?.loaded && <Loader />}
            > */}

            <div className="{relative grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-5 2xl:gap-x-10 gap-y-8 sm:gap-y-14}">
              {loaded ? (
                data?.subdata?.length > 0 &&
                data?.subdata?.map((item) => (
                  <SingleFundRaiser key={item._id} data={item} />
                ))
              ) : (
                <>
                  <CardSkeletonLoader /> <CardSkeletonLoader />{" "}
                  <CardSkeletonLoader />
                </>
              )}
            </div>

            {/* </InfiniteScroll> */}
            {/* <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            /> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default ListFundraiser;
