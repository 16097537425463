import React, { useState } from "react";
import Image from "../elements/Image";
import { classNames } from "../../helpers/classNames";
import { Link } from "react-router-dom";
import Ribbon from "../../assets/images/ribbon.svg";
import "rc-tooltip/assets/bootstrap.css";
import Tooltip from "rc-tooltip";
import RepostFundraiserModal from "../elements/modal/RepostFundraiserModal";
import InactivateModal from "../elements/modal/InactivateModal";
import {
  inactiveService,
  repostService,
} from "../../services/FundrisersService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";

const SingleFundraised = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInactivateModalOpen, setisInactivateModalOpen] = useState(false);
  const [inactiveLoaded, setInactiveLoaded] = useState(true);
  const [status, setstatus] = useState(data && data?.status);
  const [selectedDate, setSelectedDate] = useState(null);
  const [repostLoader, setrepostLoader] = useState(true);

  const navigate = useNavigate();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleInactivateModalClose = () => {
    setisInactivateModalOpen(false);
  };
  const handleInactivateModalOpen = () => {
    data && data.status === 1 && setisInactivateModalOpen(true);
  };
  const handleInactivate = () => {
    inactiveService(
      { id: data && [data?._id], status: 2 },
      setInactiveLoaded
    ).then((res) => {
      if (res?.data?.status === 200) {
        toast.success(res.data.message);
        setstatus(2);
      } else toast.error(res.data?.message);
    });
  };
  const handleSubmit = () => {
    // Handle the submitted date here
    repostService(
      { fundraiserId: data && data?._id, endDate: selectedDate },
      setrepostLoader
    ).then((res) => {
      closeModal();
      navigate("/user/fundraised");
      setstatus(4);
    });

    // Close the modal
  };
  return (
    <>
      <div
        // to={data && data?.link}
        // target="_blank"
        className="relative flex flex-col lg:flex-row shadow rounded-lg overflow-hidden hover:shadow-lg transition-all duration-200 bg-white"
      >
        <div className="w-full lg:w-60 h-60 lg:h-full relative lg:absolute top-0 left-0">
          <div
            className={classNames(
              `${
                !data?.image ? "flex items-center justify-center" : ""
              } w-full h-full overflow-hidden relative z-0`,
              data?.status === "completed"
                ? "before:content-[''] before:absolute before:w-full before:h-full before:bg-black/25 before:z-[1]"
                : ""
            )}
          >
            {data.status === "completed" && (
              <div className="absolute z-[2] w-24 h-24 bottom-2 left-1/2 -translate-x-1/2 before:content-[''] before:absolute before:w-96 before:h-2 before:bg-carnation-400 before:z-[-1] before:left-1/2 before:-translate-x-1/2 before:top-5">
                <Image
                  src={Ribbon}
                  alt={""}
                  width={"100%"}
                  height={"100%"}
                  effect={"blur"}
                  classes={"object-contain"}
                />
              </div>
            )}
            <Image
              src={data?.image}
              alt={"Image"}
              width={`${data?.image ? "100%" : "70%"}`}
              height={`${data?.image ? "100%" : "70%"}`}
              effect={"blur"}
              classes={`${
                !data?.image ? "object-contain object-center" : "object-cover"
              }`}
            />
          </div>
        </div>
        <div className="w-full pl-0 lg:pl-60">
          <div className="flex sm:py-4 py-2 sm:px-5 px-3">
            <div className="w-full flex-grow flex-shrink space-y-2">
              {data && data?.title && (
                <Link to={data && data?.dashboardLink} target="_blank">
                  <h2 className="font-Lexend text-sm sm:font-semibold leading-tight line-clamp-2 text-slate-900">
                    {data.title}
                  </h2>
                </Link>
              )}
              {data && data?.createdby && (
                <div className="flex items-center">
                  <div className="w-6 h-6 rounded-full overflow-hidden">
                    <Image
                      src={data && data?.createdby?.image}
                      alt={""}
                      width={"100%"}
                      height={"100%"}
                      effect={"blur"}
                      classes={"object-cover"}
                    />
                  </div>
                  <div className="text-xs font-semibold text-slate-700 ml-2">
                    {data && data?.createdby?.name}
                  </div>
                  <div className="text-xs ml-1 text-slate-500">
                    <em>on</em> {data && data?.createdby?.date}
                  </div>
                </div>
              )}
              {/* {data && data?.description && (
                <div
                  className="text-xs lg:text-sm text-slate-500 line-clamp-2"
                  dangerouslySetInnerHTML={{ __html: data?.description }}
                />
              )} */}
              {/* {data && data?.raised && data?.goal && (
                <div className="w-full max-w-xs space-y-2 !mt-4">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center text-base leading-none mr-auto">
                      <i className="text-sm fa-solid fa-fw fa-indian-rupee-sign"></i>
                      <span className="text-slate-900 font-semibold">
                        {data.raised.toLocaleString()}
                      </span>
                      <span className="text-xs text-slate-500 ml-2">
                        Raised
                      </span>
                    </div>
                    <div className="flex items-center leading-none ml-auto text-xs text-slate-500">
                      Goal:{" "}
                      <i className="text-[10px] fa-regular fa-fw fa-indian-rupee-sign"></i>
                      {data.goal.toLocaleString()}
                    </div>
                  </div>
                  <div className="relative flex bg-gray-200 rounded-full w-full h-2">
                    <div
                      className="relative bg-carnation-500 rounded-full"
                      style={{ width: (data.raised / data.goal) * 100 + "%" }}
                    ></div>
                  </div>
                  <div className="text-xs lg:text-sm text-slate-500 line-clamp-2">
                    Admin Amount :{" "}
                    <span>
                      <i className="text-[10px] fa-regular fa-fw fa-indian-rupee-sign"></i>
                    </span>
                    <span>{(data?.commission * data.goal) / 100}</span>
                  </div>
                </div>
              )} */}
              <div className="w-full max-w-xs space-y-2 !mt-4">
                <div className="flex items-center justify-between">
                  <div className="flex items-center text-base leading-none mr-auto">
                    <i className="text-sm fa-solid fa-fw fa-indian-rupee-sign"></i>
                    <span className="text-slate-900 font-semibold">
                      {data && data?.raised?.toLocaleString()}
                    </span>
                    <span className="text-xs text-slate-500 ml-2">Raised</span>
                  </div>
                  <div className="flex items-center leading-none ml-auto text-xs text-slate-500">
                    Goal:{" "}
                    <i className="text-[10px] fa-regular fa-fw fa-indian-rupee-sign"></i>
                    {data?.goal.toLocaleString()}
                  </div>
                </div>
                <div className="relative flex bg-gray-200 rounded-full w-full h-2">
                  <div
                    className="relative bg-carnation-500 rounded-full"
                    style={{ width: (data?.raised / data?.goal) * 100 + "%" }}
                  ></div>
                </div>

                {status && (status === 1 || status === 3 || status === 5) && (
                  <div className="text-xs lg:text-sm text-slate-500 line-clamp-2">
                    <Tooltip
                      placement="top"
                      overlay={
                        <span>Payment getway charge & Service Taxes Extra</span>
                      }
                    >
                      <span>Platform Fees :</span>
                    </Tooltip>
                    <span>
                      <i className="text-[10px] fa-regular fa-fw fa-indian-rupee-sign"></i>
                    </span>
                    <span>{(data?.commission * data?.raised) / 100}</span>
                    <span className="ml-2">{`(${
                      data?.commission + "%"
                    })`}</span>
                  </div>
                )}
              </div>
            </div>
            <div className="w-20 lg:w-40 flex-grow-0 flex-shrink-0 flex flex-col justify-between items-end">
              <div>
                {status && (
                  <div className="w-full flex justify-end">
                    <div
                      className={classNames(
                        "text-xs px-2 py-1 rounded capitalize font-medium border cursor-pointer",
                        status === 1
                          ? "text-green-500 bg-green-50 border-green-500"
                          : status === 2
                          ? "text-rose-500 bg-rose-50 border-rose-500"
                          : status === 4
                          ? "text-amber-500 bg-amber-50 border-amber-500"
                          : status === 5
                          ? "text-teal-500 bg-teal-50 border-teal-500"
                          : "text-slate-500 bg-slate-50 border-slate-500"
                      )}
                      onClick={handleInactivateModalOpen}
                    >
                      {status === 1
                        ? "Active"
                        : status === 2
                        ? "Inactive"
                        : status === 4
                        ? "On-hold"
                        : status === 5
                        ? "Completed"
                        : "Status"}
                    </div>
                  </div>
                )}
                {/* <div className="mt-2 text-xs px-2 py-1 rounded capitalize font-medium border text-slate-500 bg-slate-50 border-slate-500">
                  View Details
                </div> */}
              </div>

              {data && data?.donation && (
                <div className="relative mt-auto">
                  <div className="text-xs text-slate-500 text-right">
                    My Donation
                  </div>
                  <div className="flex items-center justify-end text-base leading-none">
                    <i className="text-sm fa-solid fa-fw fa-indian-rupee-sign"></i>
                    <span className="text-slate-900 font-semibold">
                      {data.donation.toLocaleString()}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex py-2 px-5 border-t border-bg-slate-200">
            {/* {data && data?.supporters?.length > 0 &&
              <div className="flex items-center space-x-2">
                <div className="text-xs text-slate-500">Supporters:</div>
                <div className="flex items-center -space-x-2">
                  {data && data?.supporters?.slice(0, 5).map((item) => (
                    <div key={item._id} className="w-6 h-6 rounded-full overflow-hidden border border-slate-300" title={item.name}>
                      <Image src={item.image} alt={item.name} width={'100%'} height={'100%'} effect={'blur'} classes={'object-cover'} />
                    </div>
                  ))}
                  {data && data?.supporters?.length > 5 &&
                    <div className="!ml-1 text-xs font-semibold text-slate-400">+{data.supporters?.length - 5}</div>
                  }
                </div>
              </div>
            } */}
            <div>
              <span className="text-xs text-blue-500 px-2">
                <Link to={data?.dashboardLink}>
                  <span className="border border-carnation-500 px-2 py-1 rounded-[3px] mr-1 text-carnation-500 hover:bg-carnation-500 hover:text-white">
                    Dashboard
                  </span>
                </Link>
                <Link to={data?.editLink}>
                  <span className="border border-carnation-500 px-2 py-1 rounded-[3px] mr-1 text-carnation-500 hover:bg-carnation-500 hover:text-white">
                    Edit Post
                  </span>
                </Link>
                <Link to={data?.withdrawLink}>
                  <span className="border border-carnation-500 px-2 py-1 rounded-[3px] mr-1 text-carnation-500 hover:bg-carnation-500 hover:text-white">
                    Fund Withdrawn
                  </span>
                </Link>
                {status === 1 && (
                  <Link to={data?.viewLink}>
                    <span className="border border-carnation-500 px-2 py-1 rounded-[3px] mr-1 text-carnation-500 hover:bg-carnation-500 hover:text-white">
                      View
                    </span>
                  </Link>
                )}

                {/* <Link to={data?.promotionLink}>
                  <span className="border border-carnation-500 px-2 py-1 rounded-[3px] mr-1 text-carnation-500 hover:bg-carnation-500 hover:text-white">
                    Promotion
                  </span>
                </Link>
                */}
              </span>
            </div>
            {status && (status === 1 || status === 4) && (
              <div className="flex items-center sm:space-x-1 space-x-2 leading-none ml-2 sm:ml-auto">
                <span className="text-sm sm:text-sm font-bold text-slate-800">
                  {data.remainingDays < 0
                    ? "Date over"
                    : data.remainingDays == 0
                    ? "Last day"
                    : data.remainingDays}
                </span>
                <span className="text-xs sm:text-sm text-slate-500">
                  {data.remainingDays > 1
                    ? "Days Remaining"
                    : data.remainingDays == 1
                    ? "Day Remaining"
                    : ""}
                </span>
              </div>
            )}
            {data && (status === 5 || status === 2) && (
              <div className="flex items-center sm:space-x-1 space-x-2 leading-none ml-2 sm:ml-auto">
                <span className="text-sm sm:text-sm font-bold text-slate-800">
                  <div onClick={openModal}>
                    <span className="border cursor-pointer border-carnation-500 px-2 py-1 rounded-[3px] mr-1 text-carnation-500 hover:bg-carnation-500 hover:text-white">
                      Repost
                    </span>
                  </div>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <RepostFundraiserModal
        isOpen={isModalOpen}
        onClose={closeModal}
        handleSubmit={handleSubmit}
        setSelectedDate={setSelectedDate}
        loaded={repostLoader}
      />
      <InactivateModal
        isOpen={isInactivateModalOpen}
        onConfirm={handleInactivate}
        onCancel={handleInactivateModalClose}
        loaded={inactiveLoaded}
      />
    </>
  );
};

export default SingleFundraised;
