import React from "react";
import PostSidebar from "../../../shared/PostSidebar";
import { useParams } from "react-router-dom/dist/umd/react-router-dom.development";
import NoDataFound from "../../elements/extra/NoDataFound";
import SocialShare from "../../elements/extra/SocialShere";

const FundraisedPromotion = () => {
  const { id } = useParams();
  const articleUrl = `https://crowdfundingfront.iosx.in/fund-raiser/${id}`;
  const articleTitle = {id};
  const articleDescription = {id};

  return (
    <section className="relative  overflow-hidden w-full py-5 bg-gray-100">
      <div className="relative z-[1] w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
        <div className="w-full flex md:flex-nowrap flex-wrap gap-4 md:gap-0">
          <div className="w-full md:w-80 min-w-[320px] ">
            <PostSidebar id={id} />
          </div>

          <SocialShare
              url={articleUrl}
              title={articleTitle}
              description={articleDescription}
            />
          {/* <div className="p-3 relative bg-white rounded-r-lg w-full max-w-[calc(100%-320px)])] overflow-hidden"> */}
            {/* <NoDataFound /> */}
            
          {/* </div> */}
        </div>
      </div>
    </section>
  );
};

export default FundraisedPromotion;
