import React, { useEffect, useRef, useState } from "react";
import people from "../../assets/images/team/team-01.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import { loadTransectionsList } from "../../services/TransectionService";
import dayjs from "dayjs";
import Loader from "../../components/elements/loader/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import NoData from "../../assets/images/no-data-found.png"

// const Transactions = () => {

const Transactions = () => {
  const [data, setdata] = useState([]);
  const [loaded, setloaded] = useState(true);
  const [limit, setlimit] = useState(2);
  const [offset, setoffset] = useState(0);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(null);
  const [totalFiles, setTotalFiles] = useState([]);
  const [flag, setFlag] = useState(true);
  const [totalDocs, setTotalDocs] = useState(null);

  useEffect(() => {
    setTotalFiles([]);
    setPage(0)
    setTotalPages(null)
  }, []);

  useEffect(() => {
    loadTransectionsList({ limit, offset }, setdata, setloaded).then((res) => {
      setTotalPages(res?.data?.totalPages);
      if (flag === true && res?.data?.status === 200) {
        setFlag(false);
        setTotalDocs(res?.data?.totalDocs);
      }
    });
  }, [offset, flag]);

  function removeDuplicatesFromArray(array) {
    const uniqueObjects = new Map();
  
    // Filter out duplicates and keep only the first occurrence
    const filteredArray =array && array.filter((obj) => {
      const objString = JSON.stringify(obj);
      if (!uniqueObjects.has(objString)) {
        uniqueObjects.set(objString, true);
        return true;
      }
      return false;
    });
  
    return filteredArray;
  }

  const finalData=removeDuplicatesFromArray(totalFiles)
 

  const fetchData = () => {
    setPage((pre) => pre + 1);
    setoffset((pre) => pre + limit);
  };

  useEffect(() => {
      setTotalFiles((prevTotalFiles) => [...prevTotalFiles, ...data]);
  }, [data]);

  return flag === false ? (
    <>
      <nav class="flex justify-between items-center overflow-auto px-4 xl:px-8 gap-5 xl:gap-6">
        <div class="relative py-3 xl:py-4 rounded-t-2xl transition-all duration-200 border-b-2 border-transparent px-0">
          <div class="whitespace-nowrap text-sm font-semibold text-black">
            Transactions His
          </div>
        </div>
        <div class="relative py-3 xl:py-4 rounded-t-2xl transition-all duration-200 border-b-2 border-transparent px-0 bg-white">
          <div className="flex items-center gap-3">
            <div className="text-black">SHORT BY: </div>
            <div className="flex items-center gap-2 text-sm text-slate-500">
              <div className="relative">Today</div>
              <div className="relative">
                <i class="fa-light fa-angle-down"></i>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {Array.isArray(finalData) && finalData.length>0 ?  <InfiniteScroll
        dataLength={totalFiles.length}
        next={fetchData}
        hasMore={page < totalPages}
        loader={!loaded && <Loader />}
        className="px-4 xl:px-8"
      >
        {Array.isArray(finalData) &&
          finalData.length > 0 &&
          finalData.map((elm, index) => (
            <div
              className="relative border border-slate-200 rounded-md mb-4"
              key={index}
            >
              <div className="border-b border-solid border-slate-200">
                <div className="flex flex-col gap-2.5 px-4 py-4">
                  <div className="flex items-center justify-between">
                    <div className="flex gap-3 items-center">
                      <div className="flex w-10 h-10 overflow-hidden rounded-full">
                        <img
                          src={people}
                          className="w-full h-full object-fit"
                        />
                      </div>
                      <div className="relative">{elm?.user?.name}</div>
                    </div>
                    <div className="flex items-center justify-center gap-1 bg-[#D6FFF0] border border-solid border-[#00D387] text-[#00D387] lg:w-36 w-28 text-sm xl:text-base lg:h-10 h-8 rounded-full">
                      <i class="fa-light fa-arrow-down-long"></i>{" "}
                      {elm?.paymentStatus}
                    </div>
                  </div>
                  <div className="flex justify-between items-center w-[calc(100%_-_45px)] ]">
                    <div className="flex items-center gap-1 text-xs xl:text-xs text-slate-500 ">
                      <div className="uppercase">id</div>
                      <div className="flex items-center">
                        <div className="">{elm?.transactionId}</div>
                        <div className="mx-2 w-[2px] h-[15px] bg-slate-300"></div>
                        <div className="">
                          {dayjs(elm?.createdAt).format("DD/MM/YYYY")}
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-1 items-center justify-center w-[45px] text-slate-500 text-sm lg:text-xl font-medium">
                      <i class="fa-solid fa-fw fa-indian-rupee-sign text-sm lg:text-xl"></i>
                      {elm?.amount}
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative md:flex  items-center justify-between px-4 py-3">
                <div className=" text-slate-500 w-full md:w-2/5 leading-6">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry...
                </div>
                <div className="flex items-center justify-center gap-1 bg-slate-100 text-slate-500 lg:w-36 w-28 text-sm xl:text-base lg:h-10 h-8  rounded-full mt-3 md:mt-0">
                  Raised
                </div>
              </div>
            </div>
          ))}
      </InfiniteScroll> : <img src={NoData}/>}

     
    </>
  ) : (
    <Loader />
  );
};

export default Transactions;
