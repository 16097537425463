import { toast } from "react-toastify";

export const hasArrayData = (data, checkedArr = []) => {
  if (Array.isArray(data) && data.length > 0) {
    for (let i = 0; i < checkedArr.length; i++) {
      if (!data.includes(checkedArr[i])) {
        return false;
      }
    }
    return true;
  } else {
    if (typeof data == "object") {
      for (let i = 0; i < checkedArr.length; i++) {
        if (!Object.keys(data).includes(checkedArr[i])) {
          return false;
        } else {
          if (
            Array.isArray(data[checkedArr[i]]) &&
            data[checkedArr[i]]?.length == 0
          ) {
            return false;
          }
          if (
            typeof data[checkedArr[i]] == "object" &&
            Object.keys(data[checkedArr[i]])?.length == 0
          ) {
            return false;
          }
        }
      }
      return true;
    } else {
      toast.error("Developer error : aurgument should be an array or object");
    }
  }
};

export const nameHasArrayData = (data, array2 = [], replaceWithArr = []) => {
  const replaceWith = [];
  if (Array.isArray(data) && data.length > 0) {
    for (let i = 0; i < array2.length; i++) {
      if (!data.includes(array2[i])) {
        replaceWith.push(replaceWithArr[i]);
      }
    }
    return replaceWith[0];
  } else {
    if (typeof data == "object") {
      for (let i = 0; i < array2.length; i++) {
        if (!Object.keys(data).includes(array2[i])) {
          replaceWith.push(replaceWithArr[i]);
        } else {
          if (Array.isArray(data[array2[i]]) && data[array2[i]]?.length == 0) {
            replaceWith.push(replaceWithArr[i]);
          }
        }
      }
      return replaceWith[0];
    } else {
      toast.error("Developer error : aurgument should be an array or object");
    }
  }
};

export const findErrorField = (
  formFieldRef,
  requiredArr = [],
  message = ""
) => {
  const div = document.createElement("div");
  const errorText = ["error-field-text"];
  if (div) {
    div.className = "error-field-text text-carnation-600 text-sm";
    div.innerHTML = message;
  }
  if (Array.isArray(formFieldRef) && formFieldRef?.length > 0) {
    formFieldRef.forEach((fieldRef) => {
      if (fieldRef.current && fieldRef.current !== null) {
        console.log(fieldRef.current.childNodes);
        // for (let i = 0; i < fieldRef.current.childNodes.length; i++) {
        //   const childNode = Array.from(fieldRef.current.childNodes).filter(
        //     (node) =>
        //       node.className
        //         .split(" ")
        //         .find((item) => requiredArr.includes(item)) && node
        //   )[0];
        //   const hasField = Array.from(fieldRef.current.childNodes).filter(
        //     (node) =>
        //       node.className
        //         .split(" ")
        //         .find((item) => errorText.includes(item) && node)
        //   );
        //   if (Array.isArray(hasField) && hasField?.length == 0) {
        //     if (childNode) {
        //       childNode.className = childNode.className + " field-error";
        //       fieldRef.current.appendChild(div);
        //     }
        //   }
        // }
      }
    });
  } else {
    if (formFieldRef.current && formFieldRef.current !== null) {
      for (let i = 0; i < formFieldRef.current.childNodes.length; i++) {
        const childNode = Array.from(formFieldRef.current.childNodes).filter(
          (node) =>
            node.className
              .split(" ")
              .find((item) => requiredArr.includes(item)) && node
        )[0];
        const hasField = Array.from(formFieldRef.current.childNodes).filter(
          (node) =>
            node.className
              .split(" ")
              .find((item) => errorText.includes(item) && node)
        );
        if (Array.isArray(hasField) && hasField?.length == 0) {
          if (childNode) {
            childNode.className = childNode.className + " field-error";
            formFieldRef.current.appendChild(div);
          }
        }
      }
    }
  }
};

export const numReg = /^[0-9]*$/;

export const calculateRemainingDays = (targetDateString) => {
  // Convert the target date string to a Date object
  const targetDate = new Date(targetDateString);

  // Get the current date
  const currentDate = new Date();

  // Calculate the time difference in milliseconds
  const timeDifferenceMillis = targetDate - currentDate;

  // Calculate the number of days remaining
  const daysRemaining = Math.ceil(timeDifferenceMillis / (1000 * 60 * 60 * 24));

  return daysRemaining;
};

export const  removeHtmlTags = (input) => {
  // Use a regular expression to match and remove HTML tags
  return input.replace(/<[^>]*>/g, '');
}

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};