import React from 'react';
import SingleMetaInfo from "../../items/SingleMetaInfo";

const MetaInfo = ({ data }) => {

  return (
    <>
      <div className="relative bg-white rounded-3xl">
        <div className="divide-y divide-slate-100">
          {/* {data.createdby &&
            <SingleMetaInfo data={data?.createdby}/>
          } */}
          {data?.createdFor &&
            <SingleMetaInfo data={data?.createdFor}/>
          }
        </div>
      </div>
    </>
  );
};

export default MetaInfo;