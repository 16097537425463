import React, { useState } from "react";

const PreviewModal = ({ url, extension, onClose }) => {
  const isPdf = extension === "pdf";

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="modal-overlay" onClick={onClose}></div>
        <div className="modal-container bg-white p-4 mx-auto rounded shadow-lg z-50">
          <div className="modal-content text-center">
            {isPdf ? (
              <iframe
                title="PDF Preview"
                src={url}
                width="800px"
                height="500px"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            ) : (
              <img
                src={url}
                alt="Image Preview"
                // className="max-w-full max-h-full"
                style={{ width: "600px", height: "400px" }}
              />
            )}
            <button
              className="btn-primary mr-2 w-[80px] bg-carnation-500 text-white p-1 rounded-md mt-4 p-2 bg-blue-500 text-white"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviewModal;

