import React, { useEffect, useState } from "react";
import { setTitle } from "../helpers/MetaTag";
import MainBanner from "../components/sections/MainBanner";
import TopFundRaiser from "../components/sections/TopFundRaiser";
import Cause from "../components/sections/Cause";
import MobileApp from "../components/sections/MobileApp";
import DownloadApp from "../components/sections/DownloadApp";
import WhyUs from "../components/sections/WhyUs";
import CallToAction from "../components/sections/CallToAction";
import Stories from "../components/sections/Stories";

import Banner from "../assets/images/banner.jpg";
import Patient01 from "../assets/images/patient-01.png";
import Patient02 from "../assets/images/patient-02.png";
import Patient03 from "../assets/images/patient-03.png";
import MobileImage from "../assets/images/home.jpg";
import AppStore from "../assets/images/app-store.png";
import PlayStore from "../assets/images/play-store.png";
import CTAImage from "../assets/images/bg-cta.jpg";
import UserImage from "../assets/images/user.jpg";
import { loadFundsForList } from "../services/fundsForService";
import { loadWhyUsList } from "../services/whyUsService";
import { LoadSuccessStoriesList } from "../services/SuccessStoriesService";
import { loadHowItWorksList } from "../services/HowItWorksService";
import { loadStoryList } from "../services/StoriesService";
import Loader from "../components/elements/loader/Loader";
import { loadFundraiserList } from "../services/FundrisersService";
import Categories from "../components/sections/Categories";
import { loadCategoryList } from "../services/CategoryService";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Home = () => {
  setTitle("Crowd Funding | Home");

  const [fundsFor, setFundsFor] = useState([]);
  const [whyUs, setWhyUs] = useState([]);
  const [stories, setStories] = useState([]);
  const [howItWorks, setHowItWorks] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [storyLoader, setStoryLoader] = useState(true);
  const [fundraires, setfundraires] = useState([]);
  const [categories, setcategories] = useState([]);
  const [categoryLoader, setCategoryLoader] = useState(true);

  useEffect(() => {
    loadFundraiserList(
      { isFeatured: true, status: 1 },
      setfundraires,
      setLoaded
    );
  }, []);

  useEffect(() => {
    loadCategoryList(setcategories, setCategoryLoader);
  }, []);

  const fundraisersFinalData =
    Array.isArray(fundraires) &&
    fundraires.length > 0 &&
    fundraires.map((elm) => ({
      _id: elm?._id,
      slug: elm?.slug,
      image: elm?.gallery.length > 0 && elm?.gallery,
      name: elm?.title,
      description: elm?.description,
      raised: elm?.raised,
      goal: elm?.amount,
      shareLink: "",
      shareText: "Share",
      donateLink: "",
      donateText: "Donate",
    }));

  const TopFundraiserData = {
    title: "Our Top Fundraisers",
    loaded: loaded,
    subtitle:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form",
    subdata:
      Array.isArray(fundraisersFinalData) &&
      fundraisersFinalData.length > 0 &&
      fundraisersFinalData,
  };

  const bannerData = {
    image: Banner,
    title:
      // "Many desktop <span class='text-carnation-500'>publishing</span> packages",
      "Need Funds For <span class='text-carnation-500'> Medical Treatment </span> or Social Cause ?",
    subtitle: "Donate to contribution",
    description:
      "KYNREM, your home for help. ​​Create an online fundraising campaign by providing the necessary details.",
    actionLink: "/new-fundraising",
    actionLabel: "Start a Fundraiser",
    loaded: loaded,
  };

  const fundRaiserData = {
    title: "Our Top Fundraisers",
    subtitle:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form",
    subdata: [
      {
        _id: 1,
        image: Patient01,
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        raised: 70000,
        goal: 100000,
        shareLink: "",
        shareText: "Share",
        donateLink: "",
        donateText: "Donate",
      },
      {
        _id: 2,
        image: Patient02,
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        raised: 70000,
        goal: 100000,
        shareLink: "",
        shareText: "Share",
        donateLink: "",
        donateText: "Donate",
      },
      {
        _id: 3,
        image: Patient03,
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        raised: 70000,
        goal: 100000,
        shareLink: "",
        shareText: "Share",
        donateLink: "",
        donateText: "Donate",
      },
      {
        _id: 4,
        image: Patient01,
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        raised: 70000,
        goal: 100000,
        shareLink: "",
        shareText: "Share",
        donateLink: "",
        donateText: "Donate",
      },
      {
        _id: 5,
        image: Patient02,
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        raised: 70000,
        goal: 100000,
        shareLink: "",
        shareText: "Share",
        donateLink: "",
        donateText: "Donate",
      },
      {
        _id: 6,
        image: Patient03,
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        raised: 70000,
        goal: 100000,
        shareLink: "",
        shareText: "Share",
        donateLink: "",
        donateText: "Donate",
      },
    ],
    // subdata:fundraisersFinalData && fundraisersFinalData
  };

  const causeData = {
    title: "Causes you can raise funds for",
    subtitle:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
    subdata: [
      {
        _id: 1,
        name: "Medical",
        description:
          "It is a long established fact that a reader will be distracted.",
        icon: "fa-hands-holding-heart",
      },
      {
        _id: 2,
        name: "Memorial",
        description:
          "It is a long established fact that a reader will be distracted.",
        icon: "fa-tombstone",
      },
      {
        _id: 3,
        name: "Children",
        description:
          "It is a long established fact that a reader will be distracted.",
        icon: "fa-child-reaching",
      },
      {
        _id: 4,
        name: "Education",
        description:
          "It is a long established fact that a reader will be distracted.",
        icon: "fa-graduation-cap",
      },
    ],
  };

  const mobileAppData = {
    image: MobileImage,
    subdata: [
      {
        _id: 1,
        name: "Lorem ipsum",
        description:
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
        classess: "lg:items-end lg:text-right",
      },
      {
        _id: 2,
        name: "Lorem ipsum",
        description:
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
        classess: "lg:items-end lg:text-right",
      },
      {
        _id: 3,
        name: "Lorem ipsum",
        description:
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
        classess: "",
      },
      {
        _id: 4,
        name: "Lorem ipsum",
        description:
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
        classess: "",
      },
    ],
  };

  const downloadData = {
    title: "Download app raise your fund",
    subtitle:
      "Set up and manage your fundraiser or donate to different causes from your mobile at anytime and from anywhere",
    subdata: [
      {
        _id: 1,
        image: PlayStore,
        link: "",
      },
      {
        _id: 2,
        image: AppStore,
        link: "",
      },
    ],
  };

  const whyusData = {
    title: "Why Us?",
    subtitle:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
    subdata: [
      {
        _id: 1,
        name: "Industry’s best fundraising success rate",
        description:
          "It is a long established fact that a reader will be distracted by the readable content.",
        icon: "fa-badge-check",
      },
      {
        _id: 2,
        name: "Receive donations via all popular payment modes",
        description:
          "It is a long established fact that a reader will be distracted by the readable content.",
        icon: "fa-credit-card-front",
      },
      {
        _id: 3,
        name: "Withdraw Funds Without Hassle",
        description:
          "It is a long established fact that a reader will be distracted by the readable content.",
        icon: "fa-money-from-bracket",
      },
    ],
  };

  let categoryData =
    categories &&
    Array.isArray(categories) &&
    categories.length > 0 &&
    categories.map((elm) => ({
      category: elm?.name,
      icon: elm?.icon,
    }));
  // const categoryData = [
  //   {
  //     category: "Health",
  //     icon: "fa-suitcase-medical",
  //   },
  //   {
  //     category: "NGO/Charity",
  //     icon: "fa-hand-holding-heart",
  //   },
  //   {
  //     category: "Others",
  //     icon: "fa-handshake-simple",
  //   },
  // ];
  const callToActionData = {
    title: "Need help to setup your free fundraiser?",
    image: CTAImage,
    actionLabel: "Start a Fundraiser",
    actionLink: "/new-fundraising",
  };

  const storiesData = {
    title: "Success",
    subtitle: "Stories",
    subdata: [
      {
        _id: 1,
        image: UserImage,
        name: "John Doe",
        meta: "Lorem ipsum",
        description:
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that",
      },
      {
        _id: 2,
        image: UserImage,
        name: "John Doe",
        meta: "Lorem ipsum",
        description:
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that",
      },
      {
        _id: 3,
        image: UserImage,
        name: "John Doe",
        meta: "Lorem ipsum",
        description:
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that",
      },
      {
        _id: 4,
        image: UserImage,
        name: "John Doe",
        meta: "Lorem ipsum",
        description:
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that",
      },
      {
        _id: 5,
        image: UserImage,
        name: "John Doe",
        meta: "Lorem ipsum",
        description:
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that",
      },
      {
        _id: 6,
        image: UserImage,
        name: "John Doe",
        meta: "Lorem ipsum",
        description:
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that",
      },
    ],
  };

  useEffect(() => {
    loadFundsForList(setFundsFor, setLoaded);
    loadWhyUsList(setWhyUs, setLoaded);
    loadHowItWorksList(setHowItWorks, setLoaded);
    loadStoryList(() => {}, setStories, setStoryLoader);
  }, []);

  return (
    <>
      <MainBanner data={bannerData} />
      <TopFundRaiser data={TopFundraiserData} />
      <Categories data={categoryData} loaded={categoryLoader} />
      <Cause data={fundsFor} />
      <MobileApp
        data={{ data: howItWorks, image: MobileImage, loaded: loaded }}
      />
      <DownloadApp data={downloadData} />
      {/* <WhyUs
        data={
          Array.isArray(whyUs) &&
          whyUs?.length > 0 &&
          whyUs?.filter((why) => why.status == 1)
        }
      /> */}
      <CallToAction data={callToActionData} />

      <Stories data={stories} loaded={storyLoader} />
    </>
  );
};

export default Home;
