import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { classNames } from "../../../helpers/classNames";

const QuilEditor = ({
  label = "",
  labelClasses,
  value = "",
  onChange = () => {},
  name = "editor",
  placeholder = "Write something awesome...",
  errorText,
  errorType,
}) => {
  const [textValue, setTextValue] = useState(value);

  const Quill = ReactQuill.Quill;
  var Font = Quill.import("formats/font");
  var Size = Quill.import("attributors/style/size");
  const fontArr = ["Lexend"];
  const fontSizeArr = [
    "8px",
    "9px",
    "10px",
    "12px",
    "14px",
    "16px",
    "20px",
    "24px",
    "32px",
    "42px",
    "54px",
    "68px",
    "84px",
  ];

  Font.whitelist = fontArr;
  Size.whitelist = fontSizeArr;
  Quill.register(Font, true);
  Quill.register(Size, true);

  useEffect(() => {
    setTextValue(value);
  }, [value]);

  const onChangeHandler = (e) => {
    setTextValue(e);
    onChange({ target: { name: name, value: e } });
  };

  const colors = [
    "#f0fdf9",
    "#cdfaec",
    "#9bf4db",
    "#61e7c7",
    "#31d0ae",
    "#17b193",
    "#10917b",
    "#117464",
    "#135c52",
    "#144d44",
    "#D1FBFF",
    "#9EF7FF",
    "#00E0F5",
    "#00C0D1",
    "#00919E",
    "#003D42",
    "#00383D",
    "#002A2E",
    "#001C1F",
    "#001C1F",

    "#F2FDF7",
    "#E0FAED",
    "#C6F6DD",
    "#9EF0C6",
    "#72E9AB",
    "#3DE18B",
    "#1FCB72",
    "#1CB566",
    "#179655",
    "#106A3C",

    "#F3FFD1",
    "#E2FF94",
    "#B7FA00",
    "#B0F000",
    "#A5E000",
    "#97CE00",
    "#8ABD00",
    "#78A300",
    "#618500",
    "#476100",
  ];

  const modules = {
    toolbar: [
      // [{ font: Font.whitelist }],
      // [{ size: Size.whitelist }],
      // [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [
        "bold",
        "italic",
        "underline",
        //  "strike"
      ],
      // [{ color: colors }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      // ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [
        // { indent: "-1" }, { indent: "+1" },
        { align: [] },
      ],
      ["link", "image", "video"],
      // ["clean"],
    ],
  };

  return (
    <>
      {label && (
        <div
          className={classNames(
            "text-sm font-medium text-slate-500 mb-1",
            labelClasses
          )}
        >
          {label}
        </div>
      )}
      <div className="quil-editor-component">
        <ReactQuill
          theme="snow"
          value={textValue}
          onChange={(e) => onChangeHandler(e)}
          placeholder={placeholder}
          modules={modules}
        />
      </div>
      {errorType && errorType === "danger" && (
        <div className="text-xs text-red-600 mt-1">
          <i className="fa-regular fa-fw fa-square-exclamation text-sm mr-1"></i>
          {errorText}
        </div>
      )}
      {errorType && errorType === "warning" && (
        <div className="text-xs text-amber-600 mt-1">
          <i className="fa-regular fa-fw fa-triangle-exclamation text-sm mr-1"></i>
          {errorText}
        </div>
      )}
      {errorType && errorType === "success" && (
        <div className="text-xs text-green-600 mt-1">
          <i className="fa-regular fa-fw fa-circle-check text-sm mr-1"></i>
          {errorText}
        </div>
      )}
      {errorType && errorType === "info" && (
        <div className="text-xs text-sky-600 mt-1">
          <i className="fa-regular fa-fw fa-circle-info text-sm mr-1"></i>
          {errorText}
        </div>
      )}
    </>
  );
};

export default QuilEditor;
