import { useForm } from "react-hook-form";
import Button from "../components/form/Button";
import Input from "../components/form/hooks-form/Input";
import Select from "../components/form/hooks-form/Select";
import { useState } from "react";
import TextArea from "../components/form/hooks-form/TextArea";
import { addBankDetails } from "../services/BankService";
import TailwindModal from "../components/elements/modal/TailwindModal";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  loadFundraiserDetails,
  loadFundraiserRelationList,
} from "../services/FundrisersService";
import { useEffect } from "react";

const BankDetailsForm = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [data, setData] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [country, setCountry] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [relationList, setrelationList] = useState({
    loaded: true,
    data: [],
  });

  useEffect(() => {
    loadFundraiserDetails(id, setData);
  }, [id]);
  const fundraiserId = data && data?._id;

  console.log(data, "datattata");
  console.log(fundraiserId, "fundraiserId");

  const [relationListData, setrelationListData] = useState([]);

  const account_options = [
    {
      _id: 1,
      name: "(Beneficiary)",
      info: "(supports both INR and USD transfers)",
    },
    {
      _id: 2,
      name: "Family Member",
      info: "(supports both INR and USD transfers)",
    },
    {
      _id: 3,
      name: "Treating Hospital",
      info: "(supports both INR and USD transfers)",
    },
    {
      _id: 4,
      name: "Vendor",
      info: "(supports both INR and USD transfers)",
    },
    {
      _id: 5,
      name: "Educational Institute",
      info: "(supports both INR and USD transfers)",
    },
    {
      _id: 6,
      name: "NGO",
      info: "(supports both INR and USD transfers)",
    },
  ];

  const country_options = [
    {
      _id: 1,
      name: "India",
      value: "india",
    },
    {
      _id: 2,
      name: "USA",
      value: "usa",
    },
    {
      _id: 3,
      name: "Others",
      value: "others",
    },
  ];

  useEffect(() => {
    setrelationList({
      loaded: false,
      data: [],
    });
    loadFundraiserRelationList().then((res) => {
      if (res?.data?.status === 200) {
        setrelationList({
          loaded: true,
          data: res.data?.docs,
        });
      } else {
        setrelationList({
          loaded: true,
          data: [],
        });
      }
    });
  }, []);

  useEffect(() => {
    setrelationListData(
      (Array.isArray(relationList?.data) &&
        relationList?.data.length > 0 &&
        relationList?.data
          .map(
            (elm) =>
              Array.isArray(elm?.childs) &&
              elm?.childs.length > 0 &&
              elm?.childs?.filter((item) => item?.status == 1)
          )
          .flat(Infinity)) ||
        []
    );
  }, [relationList?.data]);

  const onSubmit = (data) => {
    if (data?.accountNo !== data?.reEnterAccountNumber) {
      toast.info(
        "Please check re enter account number. Re enter account number does'nt match with account number"
      );
      return;
    }
    Object.keys(data)?.forEach(
      (item) => data[item] == "" || (data[item] == "null" && delete data[item])
    );

    const form = {
      ...data,
      address: {
        address1: data?.address1,
        city: data?.city,
        state: data?.state,
        zipcode: data?.zipcode,
      },
    };
    if (data?.country === "india") {
      // delete form.address;
      // delete form.bankName;
      // delete form.branchName;
      delete form.routingNo;
      // delete form.swiftCode;
    }
    if (data?.country === "usa") {
      delete form?.swiftCode;
    }
    if (data?.country === "others") {
      delete form?.routingNo;
    }
    delete form?.address1;
    delete form?.city;
    delete form?.state;
    delete form?.zipcode;
    delete form?.reEnterAccountNumber;

    fundraiserId &&
      addBankDetails({ bankDetails: { ...form }, id: fundraiserId }).then(
        (res) => {
          if (res.data.status === 200) {
            toast.success(res.data.message);
            navigate("/user/fundraised");
          } else {
            toast.info(res.data.message);
          }
        }
      );

    // console.log(form, "formmmm");
  };

  return (
    <section>
      <div className="w-2/3 mx-auto py-5 my-5">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="bg-slate-50 rounded-md shadow p-5 hover:shadow-md mb-3">
            <div className="my-3 text-center font-bold text-slate-500">
              Add new recipient account
            </div>
            <div className="mt-5">
              <div className="flex gap-3 w-full mb-3">
                <Select
                  register={register}
                  label="Transfer funds to"
                  name="relationWithFundraiser"
                  selectClasses="!bg-white"
                  placeholder="Select transfer funds to"
                  loading={!relationList?.loaded}
                  options={relationListData?.map((item) => ({
                    value: item?._id,
                    label: (
                      <span className="font-semibold text-carnation-500">
                        {item.name}
                      </span>
                    ),
                    subLevel: "(supports both INR and USD transfers)",
                  }))}
                  errors={errors}
                  setValue={setValue}
                  rules={{
                    required: true,
                    message: "Please select transfer funds to",
                  }}
                />
                <Input
                  label="Full name"
                  register={register}
                  name="name"
                  inputClasses="!bg-white"
                  placeholder="Full Name"
                  errors={errors}
                  rules={{
                    required: true,
                    message: "Please enter full name",
                  }}
                />
              </div>
              <div className="flex gap-3 mb-3">
                <Input
                  register={register}
                  name="accountNo"
                  label="Account number"
                  placeholder="Account Number"
                  inputClasses="!bg-white"
                  errors={errors}
                  rules={{
                    required: true,
                    message: "Please enter account number",
                  }}
                />
                <Input
                  register={register}
                  name="reEnterAccountNumber"
                  label="Re enter account number"
                  placeholder="Re Enter Account Number"
                  inputClasses="!bg-white"
                  errors={errors}
                  rules={{
                    required: true,
                    message: "Please enter re-type account number",
                  }}
                />
              </div>
              <div className="flex gap-3 mb-3">
                <Select
                  register={register}
                  placeholder="Select country"
                  label="Country"
                  name="country"
                  inputClasses="!bg-white"
                  onChange={(value) => setCountry(value)}
                  options={country_options?.map((item) => ({
                    value: item.value,
                    label: item.name,
                  }))}
                  setValue={setValue}
                  errors={errors}
                  rules={{
                    required: true,
                    message: "Please select country",
                  }}
                  selectClasses="!bg-white"
                />
              </div>
            </div>
          </div>
          {country && country === "india" && (
            <div className="bg-slate-50 rounded-md shadow p-5 hover:shadow-md mb-3">
              <div className="w-full">
                <Input
                  register={register}
                  name="ifscCode"
                  label="IFSC code"
                  placeholder="IFSC Code"
                  inputClasses="!bg-white"
                  errors={errors}
                  rules={{
                    required: true,
                    message: "Please enter ifsc code",
                  }}
                />
                <div className="text-xs text-slate-400">
                  Enter your IFSC code to fetch and verify the rest of your bank
                  details.
                </div>
              </div>
            </div>
          )}

          {/* {country && country !== "india" && ( */}
          <div className="relative bg-slate-50 rounded-md shadow p-5 hover:shadow-md mb-3">
            <div className="flex gap-3 items-center">
              <span className="icon w-12 h-12 min-w-[3rem] min-h-[3rem] flex items-center justify-center text-2xl bg-carnation-100 rounded-full overflow-hidden">
                <img
                  src={require("../assets/images/accoun-details.png")}
                  alt="icon"
                  className="w-2/3 h-2/3 object-contain object-center"
                />
              </span>
              <span className="text-sm font-bold text-slate-500">
                BANK INFORMATION
              </span>
            </div>

            <div className="form-sec mt-5">
              <div className="mb-3">
                <Input
                  register={register}
                  name="bankName"
                  label="Bank name"
                  inputClasses="!bg-white"
                  placeholder="Bank Name"
                  errors={errors}
                  rules={{
                    required: true,
                    message: "Please enter bank name",
                  }}
                />
              </div>
              <div className="mb-3">
                <Input
                  register={register}
                  label="Branch name"
                  name="branchName"
                  placeholder="Bank Brunch Name"
                  inputClasses="!bg-white"
                  errors={errors}
                  rules={{
                    required: true,
                    message: "Please enter bank brunch name",
                  }}
                />
              </div>
              <div className="mb-3">
                <TextArea
                  label="Address"
                  register={register}
                  name="address1"
                  placeholder="Bank Address"
                  textareaClasses="!bg-white"
                  inputClasses="!bg-white"
                  errors={errors}
                  rules={{
                    required: true,
                    message: "Please enter bank address",
                  }}
                />
              </div>
              <div className="mb-3">
                <Input
                  register={register}
                  label="Zipcode"
                  name="zipcode"
                  placeholder="Pin Code"
                  inputClasses="!bg-white"
                  errors={errors}
                  rules={{
                    required: true,
                    message: "Please enter pin code",
                  }}
                />
              </div>
              <div className="flex gap-3">
                <Input
                  register={register}
                  label="City"
                  name="city"
                  placeholder="City"
                  inputClasses="!bg-white"
                  errors={errors}
                  rules={{
                    required: true,
                    message: "Please enter city",
                  }}
                />
                <Input
                  register={register}
                  label="State"
                  name="state"
                  placeholder="State"
                  inputClasses="!bg-white"
                  errors={errors}
                  rules={{
                    required: true,
                    message: "Please enter state",
                  }}
                />
              </div>
            </div>
          </div>
          {/* )} */}

          {/* {country && (country === "usa" || country === "others") && ( */}
          <div className="relative bg-slate-50 rounded-md shadow p-5 hover:shadow-md mb-3">
            <div className="text-sm text-slate-500">
              {country === "usa"
                ? "You will be able to transfer only foreign(USD) contributions to this recipient account"
                : "If you prefer to transfer international (USD) funds to this account, SWIFT code is mandatory"}
            </div>
            <div className="mt-5">
              {country === "usa" ? (
                <Input
                  register={register}
                  label="Routing number"
                  name="routingNo"
                  inputClasses="!bg-white"
                  placeholder="Routing Number"
                  errors={errors}
                  rules={{
                    required: true,
                    message: "Please enter routing number",
                  }}
                />
              ) : (
                <Input
                  register={register}
                  label="Swift code"
                  name="swiftCode"
                  inputClasses="!bg-white"
                  placeholder="SWIFT Code"
                  errors={errors}
                  rules={{
                    required: true,
                    message: "Please enter SWIFT Code",
                  }}
                />
              )}
            </div>
            <div className="mt-5">
              <div
                onClick={() => setModalOpen(true)}
                className="font-semibold bg-carnation-500 p-1 px-3 rounded-md text-white text-[12px] cursor-pointer flex gap-2 items-center w-fit"
              >
                <span>
                  <i className="fa-regular fa-circle-info"></i>
                </span>
                <span>
                  {country === "usa"
                    ? "What is Routing number?"
                    : "What is SWIFT Code?"}
                </span>
              </div>
            </div>
          </div>
          {/* )} */}

          <Button
            buttonType={"submit"}
            buttonLabel={"Submit"}
            buttonClasses={"w-full mt-4"}
          />
        </form>
      </div>

      <TailwindModal isOpen={modalOpen} setOpen={() => setModalOpen(false)}>
        <div className="text-md text-carnation-500 font-semibold">
          {country && country === "usa"
            ? "Routing Number (Applicable for only US Bank Accounts)"
            : "Swift code"}
        </div>
        <div className="mt-4 text-slate-500 text-[14px]">
          {country && country === "usa"
            ? "Your bank routing number is a nine-digit code that’s based on the U.S. Bank location where your account was opened. It’s the first set of numbers printed on the bottom of your checks, on the left side."
            : "It may be eight to eleven digits code to identify every bank uniquely SWIFT Code is mandatory to transfer USD donations to any bank account located outside USA."}
        </div>
      </TailwindModal>
    </section>
  );
};

export default BankDetailsForm;
