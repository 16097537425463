import React from 'react';
import {setTitle} from '../helpers/MetaTag';

import MainBannerAlt from "../components/sections/MainBannerAlt";
import MobileApp from "../components/sections/MobileApp";
import DownloadApp from "../components/sections/DownloadApp";
import CallToAction from "../components/sections/CallToAction";
import Stories from "../components/sections/Stories";

import Banner from "../assets/images/banner-alt.jpg";
import MobileImage from "../assets/images/home.jpg";
import AppStore from "../assets/images/app-store.png";
import PlayStore from "../assets/images/play-store.png";
import CTAImage from "../assets/images/bg-cta.jpg";
import UserImage from "../assets/images/user.jpg";
import CrowdFundingImage from "../assets/images/image-crowd-funding.jpg";
import DescriptiveWithImage from "../components/sections/DescriptiveWithImage";
import Provided from "../components/sections/Provided";
import { useState } from 'react';
import { useEffect } from 'react';
import { loadHowItWorksList } from '../services/HowItWorksService';


const HowItWorks = () => {
  const [howItWorks,setHowItWorks]=useState([])
  const [loaded,setLoaded]=useState(true)


  setTitle('Crowd Funding | How It Works');



  const bannerData = {
    image: Banner,
    title: "Many desktop <span class='text-carnation-500'>publishing</span> packages",
    statistics:[
      {
        _id:1,
        label:"Donors",
        data:"3.2 Lakh+"
      },
      {
        _id:2,
        label:"Fundraisers",
        data:"3.2 Lakh+"
      },
      {
        _id:3,
        label:"Platform fee",
        data:"0%"
      },
    ]
  }

  const descriptiveData = {
    title:"What is Crowdfunding?",
    image:CrowdFundingImage,
    description:"<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search.</p> <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search.</p>"
  } 

  const mobileAppData = {
    image:MobileImage,
    subdata: [
      {
        _id:1,
        name:"Lorem ipsum",
        description:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
        classess:"lg:items-end lg:text-right"
      },
      {
        _id:2,
        name:"Lorem ipsum",
        description:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
        classess:"lg:items-end lg:text-right"
      },
      {
        _id:3,
        name:"Lorem ipsum",
        description:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
        classess:""
      },
      {
        _id:4,
        name:"Lorem ipsum",
        description:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
        classess:""
      },
    ]
  }

  const downloadData = {
    title:"Download app raise your fund",
    subtitle:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that",
    subdata:[
      {
        _id:1,
        image:PlayStore,
        link:""
      },
      {
        _id:2,
        image:AppStore,
        link:""
      },
    ]
  }

  const providedData = {
    title:"We provide best thing for your needs",
    subtitle:"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium",
    subdata: [
      {
        _id:1,
        name:"International payment support",
        description:"It is a long established fact that a reader will be distracted by the readable content.",
        icon:"fa-hands-holding-dollar",
      },
      {
        _id:2,
        name:"Multiple people - Same Fundraiser",
        description:"It is a long established fact that a reader will be distracted by the readable content.",
        icon:"fa-users",
      },
      {
        _id:3,
        name:"Dedicated Fundraiser expert",
        description:"It is a long established fact that a reader will be distracted by the readable content.",
        icon:"fa-user-crown",
      },
      {
        _id:4,
        name:"24/7 support",
        description:"It is a long established fact that a reader will be distracted by the readable content.",
        icon:"fa-headset",
      },
      {
        _id:5,
        name:"Payout",
        description:"It is a long established fact that a reader will be distracted by the readable content.",
        icon:"fa-money-from-bracket",
      },
      {
        _id:6,
        name:"Advertising support",
        description:"It is a long established fact that a reader will be distracted by the readable content.",
        icon:"fa-billboard",
      },
    ]
  }

  const callToActionData = {
    title:"Need help to setup your free fundraiser?",
    image:CTAImage,
    actionLabel:"Start a Fundraiser",
    actionLink:"/new-fundraising"
  }

  const storiesData = {
    title:"Success",
    subtitle:"Stories",
    subdata: [
      {
        _id:1,
        image:UserImage,
        name:"John Doe",
        meta:"Lorem ipsum",
        description:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that",
      },
      {
        _id:2,
        image:UserImage,
        name:"John Doe",
        meta:"Lorem ipsum",
        description:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that",
      },
      {
        _id:3,
        image:UserImage,
        name:"John Doe",
        meta:"Lorem ipsum",
        description:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that",
      },
      {
        _id:4,
        image:UserImage,
        name:"John Doe",
        meta:"Lorem ipsum",
        description:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that",
      },
      {
        _id:5,
        image:UserImage,
        name:"John Doe",
        meta:"Lorem ipsum",
        description:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that",
      },
      {
        _id:6,
        image:UserImage,
        name:"John Doe",
        meta:"Lorem ipsum",
        description:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that",
      },
    ]
  }

  useEffect(()=>{
    loadHowItWorksList(setHowItWorks,setLoaded)
  },[])

  return (
    <>
      <MainBannerAlt data={bannerData}/>
      <DescriptiveWithImage data={descriptiveData} imagePosition={"left"}/>
      <MobileApp data={{data:howItWorks,image:MobileImage}}/>
      <DownloadApp data={downloadData}/>
      <Provided data={howItWorks}/>
      <CallToAction data={callToActionData}/>
      <Stories data={storiesData}/>
    </>
  );
};

export default HowItWorks;