import React from "react";
import Image from "../elements/Image";
import { classNames } from "../../helpers/classNames";
import { Link } from "react-router-dom";
import Ribbon from "../../assets/images/ribbon.svg";
import Logo from "../../assets/images/logo.png";
import "rc-tooltip/assets/bootstrap.css";
import Tooltip from "rc-tooltip";

const SingleDonation = ({ data = {} }) => {
  return (
    <div className="relative flex flex-col lg:flex-row shadow rounded-lg overflow-hidden hover:shadow-lg transition-all duration-200 bg-white">
      <div className="flex w-full">
        <div className="w-32 min-w-[128px] h-32 m-3 rounded-md overflow-hidden">
          <div
            className={classNames(
              `${
                !data?.image ? "flex items-center justify-center" : ""
              } w-full h-full overflow-hidden relative z-0`,
              data?.status === "completed"
                ? "before:content-[''] before:absolute before:w-full before:h-full before:bg-black/25 before:z-[1]"
                : ""
            )}
          >
            <Image
              src={data?.image || Logo}
              alt={""}
              width={`${data?.image ? "100%" : "70%"}`}
              height={`${data?.image ? "100%" : "70%"}`}
              effect={"blur"}
              classes={`${
                !data?.image ? "object-contain object-center" : "object-cover"
              }`}
            />
          </div>
        </div>
        <div className="w-full">
          <div className="flex sm:py-4 py-2 sm:px-5 px-3">
            <div className="w-full flex-grow flex-shrink space-y-2">
              {data && data?.title && (
                <Link to={data?.editLink}>
                  <h2 className="font-Lexend text-sm sm:font-semibold leading-tight line-clamp-2 text-slate-900">
                    {data.title}
                  </h2>
                </Link>
              )}
              {data && data?.description && (
                <div
                  className="text-xs lg:text-sm text-slate-500 line-clamp-3"
                  dangerouslySetInnerHTML={{ __html: data?.description }}
                />
              )}
            </div>
          </div>
          <div className="flex items-center gap-2 sm:px-5 px-3 border-t border-bg-slate-200">
            <div className="text-xs text-carnation-500 font-semibold">My Donation</div>
            <span className="text-slate-900 font-semibold">
              <i className="text-sm fa-solid fa-fw fa-indian-rupee-sign"></i>{data.donation.toLocaleString()}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleDonation;
