import React, { useEffect, useRef } from "react";
import { register } from "swiper/element/bundle";
import SingleStory from "../items/SingleStory";
import Button from "../form/Button";
import TestimonialSkeletonLoader from "../elements/loader/TestimonialSkeletonLoader";
register();

const Stories = ({ data, loaded }) => {
  const swiperElRef = useRef(null);
  useEffect(() => {
    const swiperEl = document.querySelector("swiper-container.stories");
    const swiperParams = {
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        0: {
          slidesPerView: 1.2,
          spaceBetween: 20,
          centeredSlides: true,
          loop: true,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
          centeredSlides: true,
          loop: true,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 20,
          centeredSlides: false,
          loop: false,
        },
      },
      on: {
        init() {},
      },
    };

    if (data && data.length > 0) {
      Object.assign(swiperEl, swiperParams);
      swiperEl.initialize();
    }
  }, [data]);

  return (
    <>
      <section className="relative overflow-hidden w-full bg-carnation-500 lg:bg-gray-100 py-10 lg:py-0">
        <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
          {loaded ? (
            <div className="relative flex flex-col lg:flex-row items-center gap-10 z-[1]">
              <div className="w-full lg:w-1/3 relative z-[1] text-center lg:text-left">
                <div className="hidden lg:block absolute top-1/2 -translate-y-1/2 right-0 w-[1000%] h-[1000%] bg-gray-100 z-[-1]"></div>
                <h2 className="font-Lexend text-3xl sm:text-4xl lg:text-5xl xl:text-6xl font-bold text-white lg:text-black">
                  Success
                </h2>
                <div className="font-Lexend text-base sm:text-xl lg:text-2xl xl:text-3xl text-white lg:text-slate-600">
                  Stories
                </div>
              </div>
              {data && data?.length > 0 && (
                <div className="relative w-full lg:w-2/3 z-0 lg:py-24">
                  <div className="hidden lg:block absolute top-0 left-40 w-[1000%] h-[1000%] bg-carnation-500 z-[-1]"></div>
                  <div className="relative">
                    <swiper-container
                      ref={swiperElRef}
                      class="overflow-visible stories"
                      init="false"
                    >
                      {data &&
                        data?.map((item) => (
                          <swiper-slide
                            key={item?._id}
                            className="!h-auto flex"
                          >
                            <SingleStory data={item} />
                          </swiper-slide>
                        ))}
                    </swiper-container>
                    <div className="flex relative justify-center lg:justify-start lg:left-48 mt-8 !gap-x-2">
                      <Button
                        buttonClasses={
                          '!relative !top-auto !bottom-auto !left-auto !right-auto !m-0 !w-auto bg-white !text-slate-800 !h-8 !px-5 !rounded-lg swiper-button-prev after:content-[""] !gap-0'
                        }
                        buttonType={"submit"}
                        buttonLabel={""}
                        buttonLabelClasses={""}
                        buttonIcon={"fa-regular fa-arrow-left-long"}
                        buttonIconPosition={"left"}
                      />
                      <Button
                        buttonClasses={
                          '!relative !top-auto !bottom-auto !left-auto !right-auto !m-0 !w-auto bg-white !text-slate-800 !h-8 !px-5 !rounded-lg swiper-button-next after:content-[""] !gap-0'
                        }
                        buttonType={"submit"}
                        buttonLabel={""}
                        buttonLabelClasses={""}
                        buttonIcon={"fa-regular fa-arrow-right-long"}
                        buttonIconPosition={"left"}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <TestimonialSkeletonLoader />
          )}
        </div>
      </section>
    </>
  );
};

export default Stories;
