import React from 'react';
import SingleMobileApp from "../items/SingleMobileApp";
import Image from "../elements/Image";
import phone from "../../assets/images/phone.png";
import Button from '../form/Button';
import CallToActionAlt from './CallToActionAlt';


const MobileAppAlt = ({ data }) => {

    const callToActionBtn = {
        actionLabel:"Start a Fundraiser For Free",
        actionLink:"/new-fundraising"
      }

    return (
        <>
            <section className="relative overflow-hidden w-full py-20 bg-gray-100">
                <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
                    <div className='font-Lexend text-3xl sm:text-4xl xl:text-5xl font-bold text-black leading-tight text-center mb-6'>Start a Fundraiser in four simple steps</div>
                    <div className="relative flex flex-col md:flex-row items-center lg:py-40 gap-16 lg:gap-0">
                        <div className="w-[240px] min-w-[240px] sm:w-[300px] sm:min-w-[280px] relative lg:absolute lg:left-1/2 lg:-translate-x-1/2">
                            <div className="absolute bottom-5 left-1/2 -translate-x-1/2 w-80 h-80 sm:w-[400px] sm:h-[400px] bg-gradient-to-t from-carnation-200 rounded-full"></div>
                            <div className="relative flex flex-col w-full z-0 overflow-hidden rounded-4xl sm:rounded-5xl lg:rounded-6xl">
                                <Image src={phone} alt={'Logo'} width={'100%'} height={'100%'} effect={'blur'} classes={'object-contain'} />
                                <div className="w-full h-full absolute top-0 left-0 z-[-1] p-3 rounded-5xl overflow-hidden">
                                    <Image src={data?.image} alt={'Logo'} width={'100%'} height={'100%'} effect={'blur'} classes={'object-cover object-top'} />
                                </div>
                            </div>
                            <div className="absolute bottom-5 left-1/2 -translate-x-1/2 w-80 h-80 sm:w-[400px] sm:h-[400px] rounded-full custom-shadow"></div>
                        </div>
                        <div className="flex-grow flex-shrink relative grid lg:grid-rows-2 lg:grid-flow-col gap-y-8 lg:gap-y-20 lg:gap-x-[460px] xl:gap-x-[500px] 2xl:gap-x-[640px] xl:px-10">
                            {data?.data?.length > 0 && data?.data?.map((item, index) => (
                                <SingleMobileApp key={item._id} data={item} index={index + 1} />
                            ))}
                        </div>
                    </div>
                    <CallToActionAlt data={callToActionBtn}/>
                </div>
            </section>
        </>
    );
};

export default MobileAppAlt;