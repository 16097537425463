import React from 'react';
import Image from "../elements/Image";
import Button from "../form/Button";

const CallToActionAlt = ({data}) => {
  return (
    <>
      <section className="relative overflow-hidden w-full z-0 flex items-center ">
        <div className="relative z-[1]  w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto flex justify-center items-center">
            <div className="flex items-center !mt-10">
              <Button
                buttonClasses={'!px-5'}
                buttonHasLink={true}
                buttonLink={data.actionLink}
                buttonLabel={data.actionLabel}
                buttonLabelClasses={'text-base'}
                buttonEffect={"filled"}
              />
            </div>

        </div>
      </section>
    </>
  );
};

export default CallToActionAlt;