import React, { useEffect, useState } from "react";
import Input from "../../../form/Input";
import Select from "../../../form/Select";
import SearchWithDropdown from "../../../form/SearchWithDropdown";
import "react-toastify/dist/ReactToastify.css";

import { numReg } from "../../../../helpers";
import {
  getCountryDetails,
  loadCountries,
} from "../../../../services/CountryService";
import {
  loadHospitalList,
  loadMedicalConditionList,
} from "../../../../services/FundrisersService";

const MedicalForm = ({ stepsData, setStepsData = () => {} }) => {
  const [loaded, setloaded] = useState(true);
  const [hospitalListData, sethospitalListData] = useState([]);
  const [medicalConditionData, setmedicalConditionData] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [currentCountry, setCurrentCountry] = useState({});
  const [coords, setCoords] = useState({
    lat: undefined,
    lon: undefined,
  });

  const ageData = [
    { name: "Below 5 years" },
    { name: "6 -10 years" },
    { name: "11 - 17 years" },
    { name: "18 - 30 years" },
    { name: "30 - 40 years" },
    { name: "Above 40 years" },
  ];

  const hospitalStatusData = [
    { name: "Currently hospitalised" },
    { name: "Does not require hospitalisation" },
    { name: "Recently discharged from the hospital" },
    { name: "Will be hospitalised soon" },
  ];

  // get country list ===========>

  useEffect(() => {
    loadCountries().then((res) => {
      if (res?.status === 200) {
        setCountryList(
          res?.docs?.map((country) => ({
            name: "+" + country?.phone_code?.replace("+", ""),
            country_name: country?.name,
            _id: country?._id,
          }))
        );
      }
    });
  }, []);

  // get current country details ==========>

  useEffect(() => {
    if (coords?.lon && coords?.lat && countryList) {
      getCountryDetails({ lat: coords?.lat, lon: coords?.lon }).then((res) => {
        setCurrentCountry({
          name: countryList?.find(
            (item) => item?.country_name === res?.address?.country
          )?.name,
        });
        setStepsData((pre) => ({
          ...pre,
          "beneficiary[phoneCode]": countryList?.find(
            (item) => item?.country_name === res?.address?.country
          )?.name,
        }));
      });
    } else {
      setStepsData((pre) => ({
        ...pre,
        "beneficiary[phoneCode]": "+91",
      }));
    }
  }, [coords, countryList]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setCoords({
        lat: position?.coords?.latitude,
        lon: position?.coords?.longitude,
      });
    });
  }, []);

  useEffect(() => {
    loadMedicalConditionList(setmedicalConditionData, setloaded).then(
      (res) => {}
    );
  }, []);

  useEffect(() => {
    loadHospitalList(sethospitalListData, setloaded);
  }, []);

  const onHandleChange = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    if (e.target.value) {
      setStepsData((preState) => ({ ...preState, [name]: value }));
    } else {
      delete stepsData[name];
      setStepsData({ ...stepsData });
    }
  };
  console.log(stepsData, "stepppppp");
  return (
    <>
      <div className="relative">
        <Input
          isInputGroup={false}
          label={"Patient's full name"}
          errorType={stepsData?.[`beneficiary[name]`] ? "info" : "danger"}
          inputPlaceholder={"Enter full name"}
          inputName={"beneficiary[name]"}
          inputValue={stepsData?.[`beneficiary[name]`]}
          errorText={
            stepsData?.[`beneficiary[name]`]
              ? "Name as mentioned in Patient’s Aadhar card"
              : "Patient's full name required!"
          }
          onChange={(e) => onHandleChange(e)}
          isDisabled={false}
        />
      </div>
      <div className="relative mt-2 w-full">
        <SearchWithDropdown
          label={"Country"}
          placeholder={"Type three letter and find country"}
          data={
            countryList?.find(
              (item) => item?.name === stepsData?.[`beneficiary[phoneCode]`]
            )?.country_name
          }
          dropdownData={[
            { name: "Select country" },
            ...countryList?.map((item) => ({
              name: item?.country_name,
              country_code: item?.name,
              _id: item?._id,
            })),
          ]}
          errorText="Country name is required!"
          isError={stepsData?.[`beneficiary[phoneCode]`] ? false : true}
          setCustomSelecedvalue={(value) => {
            onHandleChange({
              target: {
                name: "beneficiary[phoneCode]",
                value: value?.country_code,
              },
            });
          }}
        />
      </div>
      <div className="grid sm:grid-cols-2 gap-4">
        <div className="relative">
          <Select
            xPlacement={"left"}
            label={"Patient's age"}
            dropdownData={[{ name: "Select patient's age" }, ...ageData]}
            dropdownClass={"max-h-60 w-full"}
            dropdownButtonClass={""}
            selectedValueType={"name"}
            selectedValue={stepsData?.[`beneficiary[age]`]}
            setCustomSelecedvalue={(value) => {
              if (value?.name === "Select patient's age") {
                delete stepsData?.[`beneficiary[age]`];
                setStepsData({ ...stepsData });
              } else {
                onHandleChange({
                  target: { name: "beneficiary[age]", value: value?.name },
                });
              }
            }}
            errorText={"Patient's age is required!"}
            errorType={!stepsData?.[`beneficiary[age]`] ? "error" : ""}
          />
        </div>
        <div>
          <div class="text-sm font-medium text-slate-500 mb-1">
            {stepsData?.[`beneficiary[age]`] === "Below 5 years" ||
            stepsData?.[`beneficiary[age]`] === "6 -10 years" ||
            stepsData?.[`beneficiary[age]`] === "11 - 17 years"
              ? "Gardian Contact No."
              : "Contact No."}
          </div>
          <div className="relative flex">
            <div className="w-20 min-w-[80px]">
              <Select
                xPlacement={"left"}
                dropdownData={countryList}
                dropdownClass={"max-h-40"}
                dropdownButtonClass={"!px-2 !border-r-none rounded-r-none"}
                selectedValue={
                  countryList?.find(
                    (item) =>
                      item?.name === stepsData?.[`beneficiary[phoneCode]`]
                  )?._id
                }
                setCustomSelecedvalue={(val) =>
                  onHandleChange({
                    target: {
                      name: "beneficiary[phoneCode]",
                      value: val?.name,
                    },
                  })
                }
              />
            </div>
            <div className="w-full">
              <Input
                inputPlaceholder={"Enter your phone number"}
                inputClasses={"!border-l-none rounded-l-none -ml-[1px]"}
                inputName={"beneficiary[phone]"}
                inputValue={stepsData?.[`beneficiary[phone]`]}
                onChange={(e) => {
                  if (numReg.test(e.target.value)) {
                    if (e.target.value?.length > 10) {
                      return;
                    } else {
                      onHandleChange(e);
                    }
                  } else {
                    return;
                  }
                }}
                errorText={"Beneficiary phone number is rwquired!"}
                errorType={stepsData?.[`beneficiary[phone]`] ? "" : "danger"}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex gap-2">
        {(stepsData?.[`beneficiary[age]`] === "Below 5 years" ||
          stepsData?.[`beneficiary[age]`] === "6 -10 years" ||
          stepsData?.[`beneficiary[age]`] === "11 - 17 years") && (
          <div className="relative w-full">
            <Input
              label="Gardian name"
              inputPlaceholder={"Enter name"}
              inputValue={stepsData?.[`beneficiary[parentName]`] || ""}
              inputName={"beneficiary[parentName]"}
              onChange={(e) => onHandleChange(e)}
              errorText={"Gardian's name is required!"}
              errorType={stepsData?.[`beneficiary[parentName]`] ? "" : "danger"}
            />
          </div>
        )}
        <div className="relative w-full">
          <Select
            xPlacement={"left"}
            label={"Patient's Gender"}
            dropdownData={[
              { name: "Patient's Gender" },
              { name: "male" },
              { name: "female" },
              { name: "others" },
            ]}
            dropdownClass={"max-h-60 w-full"}
            dropdownButtonClass={""}
            selectedValueType={"name"}
            selectedValue={stepsData?.[`beneficiary[gender]`]}
            setCustomSelecedvalue={(value) => {
              if (value?.name === "Patient's Gender") {
                delete stepsData?.[`beneficiary[gender]`];
                setStepsData({ ...stepsData });
              } else {
                onHandleChange({
                  target: { name: "beneficiary[gender]", value: value?.name },
                });
              }
            }}
            errorText={"Gender selection is required!"}
            errorType={stepsData?.[`beneficiary[gender]`] ? "" : "error"}
          />
        </div>
      </div>

      <div className="relative">
        <Input
          isInputGroup={true}
          inputGroupIcon={"fa-regular fa-city"}
          inputGroupPosition={"right"}
          inputClasses={"!border-r-0 !rounded-r-none"}
          label={"Patient's City"}
          inputPlaceholder={"Enter city"}
          inputValue={stepsData?.[`beneficiary[address][city]`]}
          inputName={"beneficiary[address][city]"}
          onChange={(e) => onHandleChange(e)}
          errorText={"Patient's city name is required!"}
          errorType={stepsData?.[`beneficiary[address][city]`] ? "" : "danger"}
        />
      </div>
      <div className="relative">
        <SearchWithDropdown
          label={"Ailment / Medical Condition"}
          placeholder={"Type your Medical Condition here"}
          data={
            medicalConditionData?.find(
              (item) => item?._id === stepsData?.[`hospitalDetails[ailment]`]
            )?.name
          }
          dropdownData={[
            { name: "Select medical condition" },
            ...medicalConditionData,
          ]}
          setCustomSelecedvalue={(value) => {
            if (value?.name === "Select medical condition") {
              return;
            } else {
              onHandleChange({
                target: { name: "hospitalDetails[ailment]", value: value?._id },
              });
            }
          }}
          errorText={"Medical condition is required!"}
          isError={stepsData?.[`hospitalDetails[ailment]`] ? false : true}
        />
      </div>
      <div className="relative">
        <Select
          xPlacement={"left"}
          label={"Hospitalisation status"}
          dropdownData={[
            { name: "Select hospitalisation status" },
            ...hospitalStatusData,
          ]}
          dropdownClass={"max-h-60 w-full"}
          dropdownButtonClass={""}
          selectedValueType={"name"}
          selectedValue={stepsData?.[`hospitalDetails[hospitalisation]`]}
          setCustomSelecedvalue={(value) => {
            if (value.name === "Select hospitalisation status") {
              delete stepsData?.[`hospitalDetails[hospitalisation]`];
              setStepsData({ ...stepsData });
            } else {
              onHandleChange({
                target: {
                  name: "hospitalDetails[hospitalisation]",
                  value: value?.name,
                },
              });
            }
          }}
          errorText={"Hospitalisation status is required!"}
          errorType={
            stepsData?.[`hospitalDetails[hospitalisation]`] ? "" : "error"
          }
        />
      </div>
      <div className="relative">
        <SearchWithDropdown
          label={"Hospital"}
          data={
            hospitalListData?.find(
              (item) => item?._id === stepsData?.[`hospitalDetails[name]`]
            )?.name
          }
          placeholder={"Type your Hospital Name here"}
          dropdownData={hospitalListData}
          setCustomSelecedvalue={(value) => {
            onHandleChange({
              target: { name: "hospitalDetails[name]", value: value?._id },
            });
          }}
          errorText={"Hospital name is required!"}
          isError={stepsData?.[`hospitalDetails[name]`] ? false : true}
        />
      </div>
    </>
  );
};

export default MedicalForm;
