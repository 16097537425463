import { apiRequest } from "../utils/async/apiUtils";

export const loadSupportedList = async (
    params,
    setSupporters = () => {},
    setLoaded = () => {}
  ) => {
    setLoaded(false);
  
    try {
      const res = await apiRequest("get", "profile/supported", {
        queries: {
          ...params,
          // selectValue: "name,icon,image,type,description,status,,createdAt",
        },
      });
  
      const data = res.data;
      setSupporters(data.docs);
      setLoaded(true);
      return res;
    } catch (err) {
      setLoaded(true);
    }
  };