import React from 'react';
import { classNames } from "../../helpers/classNames";

const SingleProvide = ({data}) => {
  return (
    <>
      <div className="relative w-full flex flex-col bg-white rounded-3xl rounded-tl-none overflow-hidden py-10 px-8 sm:py-6 sm:px-4 lg:py-10 lg:px-8 shadow border border-slate-100 space-y-4 transition-all duration-200 hover:shadow-lg">
        <div className="w-20 h-20 flex items-center justify-center rounded-full border border-carnation-500 bg-carnation-50 text-3xl text-carnation-500 mx-auto">
          {data.icon &&
            <i className={classNames("fa-regular fa-fw", data?.icon)}></i>
          }
        </div>
        <div className="space-y-3 text-center">
          <h4 className="font-semibold text-black text-base xl:text-lg leading-tight">{data.name}</h4>
          {/* <div className="text-xs xl:text-sm text-slate-600">{data.description}</div> */}
          <div className="text-xs xl:text-sm text-slate-600" dangerouslySetInnerHTML={{ __html: data?.description }} />
        </div>
      </div>
    </>
  );
};

export default SingleProvide;