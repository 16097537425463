import React from "react";

const Progress = ({ progress = 0, classes = "" }) => {
  return (
    <div className={"w-full h-1 bg-slate-200 rounded-md overflow-hidden " + classes}>
      {progress > 0 && (
        <div
          className="h-full bg-green-600"
          style={{ width: progress + "%" }}
        ></div>
      )}
    </div>
  );
};

export default Progress;
