import React from 'react';
import { classNames } from "../../helpers/classNames";
import { Link } from "react-router-dom";

const SingleSupporterDonation = ({ data }) => {
  return (
    <>
      <div className="relative block shadow rounded-lg overflow-hidden hover:shadow-lg transition-all duration-200 bg-white">
        <div className="flex py-4 px-5">
          <div className="relative space-y-1 w-full flex-grow flex-shrink">
            {data.transactionId &&
              <div className="text-xs text-slate-400 font-semibold">{data.transactionId}</div>
            }
            {data.title &&
              <h2 className="font-Lexend text-base font-semibold leading-tight line-clamp-2 text-slate-900">{data.title}</h2>
            }
          </div>
          <div className="w-60 flex-grow-0 flex-shrink-0 flex items-start justify-end">
            {data.status &&
              <div className="w-full flex justify-end">
                <div className={classNames(
                  "text-xs px-2 py-1 rounded capitalize font-medium border",
                  data.status === 'Success' ? "text-green-500 bg-green-50 border-green-500" :
                    data.status === 'Cancelled' ? "text-rose-500 bg-rose-50 border-rose-500" :
                      data.status === 'Pending' ? "text-amber-500 bg-amber-50 border-amber-500" :
                        "text-slate-500 bg-slate-50 border-slate-500"
                )}>{data.status}</div>
              </div>
            }
          </div>
        </div>
        <div className="flex py-2 px-5 border-t border-bg-slate-200">
          <div className="flex items-center divide-x divide-slate-200 -mx-2">
            {data.date &&
              <div className="flex items-center space-x-2 px-2">
                <div className="text-xs text-slate-500">Date:</div>
                <div className="text-xs text-slate-500">{data.date}</div>
              </div>
            }
            {data.date &&
              <div className="flex items-center space-x-2 px-2">
                <div className="text-xs text-slate-500">Payment Method:</div>
                <div className="text-xs text-slate-500">{data.method}</div>
              </div>
            }
          </div>
          {data.donation &&
            <div className="relative ml-auto flex items-center space-x-2">
              <div className="text-xs text-slate-500">Donation:</div>
              <div className="flex items-center text-base leading-none">
                <i className="text-sm fa-solid fa-fw fa-indian-rupee-sign"></i>
                <span className="text-slate-900 font-semibold">{data.donation.toLocaleString()}</span>
              </div>
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default SingleSupporterDonation;