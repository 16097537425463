import React from "react";
import { Link } from "react-router-dom";
import Image from "../elements/Image";
import Button from "../form/Button";

const SingleFundRaiser = ({ data }) => {
  return (
    <>
      <div
        className={
          "w-full flex flex-col bg-white rounded-4xl rounded-tl-none overflow-hidden transition-all duration-200 hover:shadow-lg"
        }
      >
        <div
          className={
            data?.smallImage
              ? "w-full data.smallImage?'70%': h-52 xl:h-80 3xl:h-96 overflow-hidden rounded-4xl rounded-tl-none"
              : "h-52 xl:h-80 3xl:h-96 overflow-hidden rounded-4xl rounded-tl-none"
          }
        >
          <Link to={`/fundraiser/${data?.slug}`}>
            <></>
            <Image
              src={data && data?.image[0]?.url}
              alt={""}
              width={"100%"}
              height={"100%"}
              effect={"blur"}
              classes={"object-cover"}
            />
          </Link>
        </div>
        <div className="py-6 px-4">
          <Link to={`/fundraiser/${data?.slug}`}>
            <h4 className="text-base font-Lexend text-black font-medium leading-tight">
              {data?.name}
            </h4>
            {/* <div
              className="text-xs xl:text-sm text-slate-600 my-2 text-ellips-desc"
              dangerouslySetInnerHTML={{ __html: data?.description }}
            /> */}
            <div className="flex items-center">
              <div className="text-slate-900 text-base">{data?.image1}</div>
              <div className="text-slate-900 text-base">{data?.image2}</div>
            </div>
            {data.raised !== undefined && (
              <div className="relative bg-gray-100 pt-10 pb-4 px-6 my-4 rounded-lg">
                <div className="relative flex bg-gray-200 rounded-full w-full h-2">
                  <div
                    className="relative bg-carnation-500 rounded-full"
                    style={{
                      width: ((data.raised / data.goal) * 100).toFixed(2) + "%",
                    }}
                  >
                    <div className="absolute w-4 h-4 rounded-full top-1/2 -translate-y-1/2 -right-2 bg-carnation-500"></div>
                    <div className="absolute w-8 h-5 rounded-full -top-6 -right-4 text-xs text-center font-medium text-slate-600">
                      {((data.raised / data.goal) * 100).toFixed(2) + "%"}
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-between mt-2">
                  {data.raised !== undefined && (
                    <div className="text-xs font-medium text-slate-600">
                      {data.raised.toLocaleString()}
                    </div>
                  )}
                  {data.goal !== undefined && (
                    <div className="text-xs font-medium text-slate-600">
                      {data.goal.toLocaleString()}
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="relative">
              <div className="relative flex">
                <div className="text-slate-900">{data.dateText}</div>
              </div>
              <div className="">
                <div></div>
              </div>
            </div>
          </Link>
          <div className="flex items-center justify-between mt-auto">
            {data.shareText !== undefined && (
              <Button
                buttonClasses={
                  "!px-8 !h-10 !mr-auto !bg-transparent !text-carnation-500 border border-carnation-500"
                }
                buttonHasLink={true}
                buttonLink={""}
                buttonLabel={"Share"}
                buttonLabelClasses={"text-sm"}
                buttonEffect={"hollow"}
              />
            )}
            {data.donateText !== undefined && (
              <Button
                buttonClasses={"!px-8 !h-10 !ml-auto"}
                buttonHasLink={true}
                buttonLink={""}
                buttonLabel={"Donate"}
                buttonLabelClasses={"text-sm"}
                buttonEffect={"filled"}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleFundRaiser;
