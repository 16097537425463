import React from "react";
import TextAreaAutoSize from "../../form/TextareaAutoSize";
import Button from "../../form/Button";
import { useState } from "react";

const CommentInput = ({ onChange, handleSubmit, loaded, inputValue }) => {

  return (
    <>
      <div className="relative space-y-4">
        <h4 className="text-lg font-semibold text-black">Post your comments</h4>
        <TextAreaAutoSize
          inputClasses={"!min-h-[120px]"}
          inputPlaceholder={"Comments"}
          inputValue={inputValue}
          onChange={onChange}
        />
        <div className="flex justify-end">
          <Button
            buttonClasses={"!px-8"}
            buttonType={"submit"}
            buttonLabel={"Submit"}
            buttonLabelClasses={""}
            buttonEffect={"filled"}
            buttonFunction={handleSubmit}
            isDisable={loaded === false ? true : false}
          />
        </div>
      </div>
    </>
  );
};

export default CommentInput;
