import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";
import { API_BASE_URL } from "../../utils/constant/ApiConstant";

export const headerSlice = createSlice({
  name: "header",
  initialState: {
    fundsFor: "",
  },
  reducers: {
    selectedFundraiserType: (state, action) => {
      state.fundsFor = action.payload ? action.payload : "";
    },
  },
});

export const { selectedFundraiserType } = headerSlice.actions;
