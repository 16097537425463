import { apiRequest } from "../utils/async/apiUtils";
import axios from "axios";

export const loadCountries = async () => {
  try {
    const res = await apiRequest("get", `countries`, {});
    return res?.data;
  } catch (err) {
    return err.response;
  }
};

export const loadIndianCities = async () => {
  try {
    const res = await apiRequest("get", `countries`, {
      queries: {
        name: "India",
        selectValue: "cities",
      },
    });
    return res?.data;
  } catch (err) {
    return err.response;
  }
};

export const getCountryDetails = async (params) => {
  try {
    const res = await axios.get(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${params?.lat}&lon=${params?.lon}`
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};
