import { combineReducers } from "redux";
import { profileSlice } from "./Profile/profileData";
import { headerSlice } from "./Header/HeaderData";
import settingsSlice from "./siteSettings/settingsSlice";

export default combineReducers({
  profile: profileSlice.reducer,
  header: headerSlice.reducer,
  settings: settingsSlice,
});
