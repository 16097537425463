import React, { useEffect, useState } from "react";
import { setTitle } from "../helpers/MetaTag";
import { classNames } from "../helpers/classNames";

import Image from "../components/elements/Image";
import Share from "../components/partials/Share";
import DetailsImage from "../components/partials/fundraiser/DetailsImage";
import Tabs from "../components/elements/Tabs";
import About from "../components/partials/fundraiser/About";
import Updates from "../components/partials/fundraiser/Updates";
import Comments from "../components/partials/fundraiser/Comments";
import Donation from "../components/partials/fundraiser/Donation";
import MetaInfo from "../components/partials/fundraiser/MetaInfo";
import Suppoters from "../components/partials/fundraiser/Suppoters";
import Tag from "../components/elements/Tag";

import Thumbnail01 from "../assets/images/patient-01.png";
import Team01 from "../assets/images/team/team-01.jpg";
import Team02 from "../assets/images/team/team-02.jpg";
import Team03 from "../assets/images/team/team-03.jpg";
import Team04 from "../assets/images/team/team-04.jpg";
import Team05 from "../assets/images/team/team-05.jpg";
import Team06 from "../assets/images/team/team-06.jpg";
import Team07 from "../assets/images/team/team-07.jpg";
import Team08 from "../assets/images/team/team-08.jpg";
import Visa from "../assets/images/visa.svg";
import MasterCard from "../assets/images/mastercard.svg";
import Amex from "../assets/images/amex.svg";
import UPI from "../assets/images/upi.svg";
import QRCode from "../assets/images/qrcode.png";
import GPay from "../assets/images/gpay.png";
import Paytm from "../assets/images/paytm.png";
import PhonePay from "../assets/images/phonepay.png";
import { useParams } from "react-router-dom/dist/umd/react-router-dom.development";
import { loadFundraiserDetails } from "../services/FundrisersService";
import dayjs from "dayjs";
import Documents from "../components/partials/fundraiser/Documents";
import { loadSupporterList } from "../services/SupportersService";
import avater from "../assets/images/no-profile-picture.jpg";
// import noDataImg from "../../../assets/images/extra/No-data.svg";
import noDataImg from "../assets/images/extra/No-data.svg";
import SocialShare from "../components/elements/extra/SocialShere";
import ImageGallery from "react-image-gallery";
import ReactImageGallery from "react-image-gallery";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ImageSkeletonLoader from "../components/elements/loader/ImageSkeletonLoader";

const FundraiserDetails = () => {
  setTitle("Crowd Funding | Browse Fundraiser");
  const [currentImage, setCurrentImage] = useState("");
  const [data, setData] = useState(null);
  const [loaded, setLoaded] = useState(true);
  const { id } = useParams();
  const [comment, setComment] = useState([]);
  const [updatedComment, setUpdatedComment] = useState(null);
  const [supportersList, setsupportersList] = useState([]);

  const articleUrl = `https://crowdfundingfront.iosx.in/fund-raiser/${id}`;
  const articleTitle = { id };
  const articleDescription = { id };

  useEffect(() => {
    loadFundraiserDetails(id, setData, setLoaded);
  }, [id]);

  // useEffect(() => {
  //   data &&
  //     loadSupporterList(data?._id, () => {}).then((res) => {
  //       let data = res?.data?.docs;
  //       const list =
  //         data &&
  //         data.map((elm) => ({
  //           _id: elm?.supporter?._id,
  //           name: elm?.supporter?.name,
  //           image: elm?.supporter?.image?.url,
  //           donation: elm?.totalDonation,
  //           currency: "INR",
  //           currencyClass: "fa-indian-rupee-sign",
  //         }));
  //       setsupportersList(list);
  //     });
  // }, [data]);

  useEffect(() => {
    updatedComment && setComment((pre) => [...pre, updatedComment]);
  }, [updatedComment]);

  useEffect(() => {
    setCurrentImage(
      data && data?.gallery && data.gallery.length > 0 && data?.gallery[0]?.url
    );
  }, [data]);

  useEffect(() => {
    data && data?.comments?.length > 0 && setComment(data?.comments);
  }, [data]);

  const newData = data;
  const pageData = {
    title: newData?.title,
    tag: "Urgent need of funds",
    donation: {
      title: "Donation Details",
      raised: newData?.raised,
      goal: newData?.amount,
      paymentModes: [
        { name: "Visa", image: Visa },
        { name: "Master Card", image: MasterCard },
        { name: "American Express", image: Amex },
        { name: "UPI (Unified Payments Interface)", image: UPI },
      ],
      button: "Donate Now",
      instantPay: {
        image: QRCode,
        title: "Scan with any app",
        mode: [
          { name: "Google P{ay", image: GPay },
          { name: "PAYTM", image: Paytm },
          { name: "Phone Pay", image: PhonePay },
        ],
      },
    },
    metaInformation: {
      createdby: {
        title: "Created by",
        name: "Ray N. Hand",
        image: Team06,
        location: "Akshya Nagar,Bangalore-560016",
        isVerified: true,
      },
      createdfor: {
        title: "Beneficiary",
        name: "Stephanie F. Sanfilippo",
        image: Thumbnail01,
        location: "Akshya Nagar,Bangalore-560016",
        isVerified: true,
      },
    },
    supporters: {
      title: "Supporters",
      count: supportersList && supportersList.length,
      subdata: supportersList,
      // subdata: [
      //   {
      //     _id: 1,
      //     name: "Janet R. Hoffman",
      //     image: Team02,
      //     donation: 180,
      //     currency: "INR",
      //     currencyClass: "fa-indian-rupee-sign",
      //   },
      //   {
      //     _id: 2,
      //     name: "Jeff C. Casey",
      //     image: Team01,
      //     donation: 250,
      //     currency: "INR",
      //     currencyClass: "fa-indian-rupee-sign",
      //   },
      //   {
      //     _id: 3,
      //     name: "Jeremy A. Burrell",
      //     image: Team04,
      //     donation: 600,
      //     currency: "INR",
      //     currencyClass: "fa-indian-rupee-sign",
      //   },
      //   {
      //     _id: 4,
      //     name: "Stephanie F. Sanfilippo",
      //     image: Team03,
      //     donation: 320,
      //     currency: "INR",
      //     currencyClass: "fa-indian-rupee-sign",
      //   },
      //   {
      //     _id: 5,
      //     name: "Carl V. Powers",
      //     image: Team05,
      //     donation: 500,
      //     currency: "INR",
      //     currencyClass: "fa-indian-rupee-sign",
      //   },
      //   {
      //     _id: 6,
      //     name: "Ray N. Hand",
      //     image: Team06,
      //     donation: 900,
      //     currency: "INR",
      //     currencyClass: "fa-indian-rupee-sign",
      //   },
      //   {
      //     _id: 7,
      //     name: "Richard S. Bryden",
      //     image: Team07,
      //     donation: 670,
      //     currency: "INR",
      //     currencyClass: "fa-indian-rupee-sign",
      //   },
      //   {
      //     _id: 8,
      //     name: "Lester B. Heath",
      //     image: Team08,
      //     donation: 480,
      //     currency: "INR",
      //     currencyClass: "fa-indian-rupee-sign",
      //   },
      // ],
    },
  };

  // const thumbnails = [
  //   { _id: 1, image: Thumbnail01 },
  //   { _id: 2, image: Thumbnail02 },
  //   { _id: 3, image: Thumbnail03 },
  //   { _id: 4, image: Thumbnail04 },
  //   { _id: 5, image: Thumbnail05 },
  //   { _id: 6, image: Thumbnail06 },
  //   { _id: 7, image: Thumbnail07 },
  //   { _id: 8, image: Thumbnail08 },
  // ];
  const thumbnails = data && newData?.gallery;

  const aboutData = {
    title: "About",
    content: data && data?.description,
  };

  const updateData = {
    title: "Updates",
    content:
      "<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p> <p>Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like). It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p><p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p> <p>Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like). It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>",
  };

  const metaInfoData = {
    createdFor: {
      image:
        data && data?.beneficiary?.image[0]?.url
          ? data?.beneficiary?.image[0]?.url
          : avater,
      location: data && data?.beneficiary?.address,
      name: data && data?.beneficiary?.name,
      user: data && data?.user,
    },
  };

  let commentss =
    comment &&
    comment.length > 0 &&
    comment.map((elm, index) => ({
      _id: elm?._id,
      name: elm?.user?.name,
      image: elm?.user?.image?.url,
      comments: elm?.comment,
      replies: elm?.replies,
      user: elm?.user,
      time: dayjs(elm?.createdAt).format("MMM D,h:mm A"),
    }));

  const commentData = {
    title: "Comments",
    count: 500,
    id: data && data?._id,
    subdata: commentss,
    // subdata: [
    //   {
    //     _id: 1,
    //     name: "Janet R. Hoffman",
    //     image: Team02,
    //     time: "6 days ago",
    //     comments:
    //       "<p>Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p> <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>",
    //   },
    //   {
    //     _id: 2,
    //     name: "Jeff C. Casey",
    //     image: Team01,
    //     time: "6 days ago",
    //     comments:
    //       "<p>Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p> <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>",
    //   },
    //   {
    //     _id: 3,
    //     name: "Jeremy A. Burrell",
    //     image: Team04,
    //     time: "6 days ago",
    //     comments:
    //       "<p>Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p> <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>",
    //   },
    //   {
    //     _id: 4,
    //     name: "Stephanie F. Sanfilippo",
    //     image: Team03,
    //     time: "6 days ago",
    //     comments:
    //       "<p>Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p> <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>",
    //   },
    //   {
    //     _id: 5,
    //     name: "Carl V. Powers",
    //     image: Team05,
    //     time: "6 days ago",
    //     comments:
    //       "<p>Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p> <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>",
    //   },
    //   {
    //     _id: 6,
    //     name: "Ray N. Hand",
    //     image: Team06,
    //     time: "6 days ago",
    //     comments:
    //       "<p>Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p> <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>",
    //   },
    //   {
    //     _id: 7,
    //     name: "Richard S. Bryden",
    //     image: Team07,
    //     time: "6 days ago",
    //     comments:
    //       "<p>Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p> <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>",
    //   },
    //   {
    //     _id: 8,
    //     name: "Lester B. Heath",
    //     image: Team08,
    //     time: "6 days ago",
    //     comments:
    //       "<p>Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p> <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>",
    //   },
    // ],
  };

  const tabData = [
    {
      _id: 1,
      name: "About",
      component: data && data.description && <About data={aboutData} />,
    },
    {
      _id: 2,
      name: "Supporting Documents",
      component: <Documents data={data && data?.documents} />,
    },
    {
      _id: 3,
      name: "Updates",
      component:
        data &&
        data?.updates &&
        Array.isArray(data?.updates) &&
        data?.updates.length > 0 ? (
          data?.updates?.map((item) => (
            <Updates
              data={{
                title: item?.title,
                content: item?.description,
                _id: item?._id,
              }}
              // link={`/fundraised-update/${id}`}
            />
          ))
        ) : (
          <>
            <div className="flex items-center justify-center">
              <div className="w-1/2">
                <Image
                  src={noDataImg}
                  classes={"w-100 h-100 object-contain object-center"}
                />
              </div>
            </div>
          </>
        ),
    },
    {
      _id: 4,
      name: "Comments",
      component: (
        <Comments
          id={data?._id}
          setCommentt={setUpdatedComment}
          userId={data && data?.userId}
        />
      ),
    },
  ];

  const images =
    data &&
    data?.gallery &&
    data?.gallery.length > 0 &&
    data?.gallery.map((elm) => ({
      original: elm?.url,
      thumbnail: elm?.url,
      customStyles: {
        height: "200px",
        width: "300px",
      },
    }));

  return (
    <>
      <section className="relative overflow-hidden w-full py-10 lg:py-20 bg-gray-100">
        <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 xl:gap-10 3xl:gap-16">
            <div className="relative lg:col-span-2 space-y-6 xl:space-y-8">
              {loaded ? (
                <div className="relative bg-white rounded-3xl">
                  <div className="py-8 px-6">
                    <h1 className="font-Lexend font-semibold text-black text-lg leading-tight sm:leading-normal sm:text-xl xl:text-2xl 2xl:text-3xl">
                      {pageData.title}
                    </h1>
                  </div>

                  {images && images.length > 0 && (
                    <ImageGallery
                      items={images}
                      renderItem={(item) => (
                        <div className="image-gallery-image">
                          <img src={item.original} alt={item.description} />
                        </div>
                      )}
                      showThumbnails={true}
                      showFullscreenButton={false}
                      renderThumbInner={(item) => (
                        <div className="image-gallery-thumbnail-inner">
                          <img src={item?.thumbnail} />
                        </div>
                      )}
                    />
                  )}
                </div>
              ) : (
                <ImageSkeletonLoader />
              )}

              <SocialShare
                url={articleUrl}
                title={articleTitle}
                description={articleDescription}
              />
              <div className="custom-scrollbar bg-carnation-50 rounded-md p-5">
                <Tabs tabs={tabData} />
              </div>
              {/* <Tabs tabs={tabData} /> */}
              {/* <div className="relative bg-white rounded-3xl py-4 xl:px-8 px-4 xl:py-8">
                <Comments data={commentData} />
              </div> */}
            </div>
            <div className="relative lg:space-y-8 grid gap-5 lg:gap-0 lg:block">
              <div className="order-2">
                <Donation data={pageData?.donation} />
              </div>

              <div className="order-1">
                <MetaInfo data={metaInfoData} />
              </div>

              <div className="order-3">
                {/* <Suppoters data={pageData?.supporters} /> */}
                <Suppoters id={data && data?._id} desc={"topDonors"} />
              </div>

              <div className="order-3">
                <Suppoters id={data && data?._id} desc={"supporters"} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FundraiserDetails;
