import React, { useEffect, useState } from "react";
import storyImage01 from "../assets/images/SuccessStory03.png";
import Image from "../components/elements/Image";
import { useParams } from "react-router";
import { storyDetails } from "../services/StoriesService";
import Loader from "../components/elements/loader/Loader";

const SuccessStoryDetails = ({ data }) => {
  const [details, setDetails] = useState({});
  const [loaded, setLoaded] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    storyDetails(id, setDetails, setLoaded);
  }, []);
 

  return loaded === false ? <Loader/> :  (
 
    <div className="relative overflow-hidden w-full  3xl:max-w-9xl mx-auto">
      <div class="relative flex justify-center py-8">
        <div class="font-bold text-slte-800 uppercase text-5xl">
          SUCCESS STORY DETAILS
        </div>
      </div>
      <div className="relative h-[600px]   rounded-2xl overflow-hidden">
        <Image
          src={details?.image?.url}
          alt={""}
          width={"100%"}
          height={"100%"}
          effect={"blur"}
          classes={"object-cover"}
        />
      </div>
      {/* <div className='relative'>
                <h2 className='text-2xl font-medium text-slate-900 leading-8 mt-5'>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected</h2>
            </div> */}
      <div className="flex justify-center">
        <div class="relative w-10/12">
          <h2 class="text-2xl font-medium text-slate-900 leading-8 mt-6">
            {details?.title}
          </h2>
          <div
            class="text-sm font-normal text-slate-500 mt-4 leading-6 "
            dangerouslySetInnerHTML={{ __html: details?.shortDescription }}
          />

          <div
            class="text-sm font-normal text-slate-500 mt-4 leading-6 "
            dangerouslySetInnerHTML={{ __html: details?.description }}
          />

          {/* <div class="text-sm font-normal text-slate-500 mt-4 leading-6 ">
            <p>
              According to health experts, when a person is under stress, these
              hormone levels impact the circadian rhythm, all of which can cause
              heart attacks or cardiovascular issues. In today’s day and time,
              heart attacks have become a severe health concern affecting
              millions of people, especially youngsters worldwide but while the
              causes of heart attacks are multifactorial, recent research has
              suggested an intriguing pattern—fatal heart attacks appear to be
              more common on Mondays.
            </p>
            <p>
              This finding raises questions about the potential reasons behind
              this weekly spike in cardiovascular events so, what could be the
              cause of this phenomenon? In an interview with HT Lifestyle, Dr
              Haresh Mehta, Consultant Interventional Cardiologist at SL Raheja
              Hospital in Mahim and Dr Prashant Pawar, Consultant-Interventional
              Cardiologist at Fortis Hiranandani Hospital in Vashi, talked about
              how stress and Monday Blues are related and shared, “One possible
              explanation for the increased occurrence of fatal heart attacks on
              Mondays is the impact of stress. Mondays often begin a new work
              week, bringing with it a lot of stress and anxiety, especially
              amongst those associated with professional responsibilities.
              Research has shown that elevated stress levels can contribute to
              the development of heart disease and increase the risk of heart
              attacks. Therefore, through the correlation by cause, it is
              plausible that the collective stress experienced by individuals
              returning to work after the weekend contributes to the higher
              prevalence of fatal heart attacks on Mondays.”
            </p>
          </div> */}
          {/* <h2 class="text-2xl font-medium text-slate-900 leading-8 mt-6">
            Why are heart attacks becoming more common on Mondays?
          </h2> */}

          {/* <div class="text-sm font-normal text-slate-500 mt-4 leading-6 ">
            <p>
              According to health experts, when a person is under stress, these
              hormone levels impact the circadian rhythm, all of which can cause
              heart attacks or cardiovascular issues. In today’s day and time,
              heart attacks have become a severe health concern affecting
              millions of people, especially youngsters worldwide but while the
              causes of heart attacks are multifactorial, recent research has
              suggested an intriguing pattern—fatal heart attacks appear to be
              more common on Mondays.
            </p>
            <p>
              This finding raises questions about the potential reasons behind
              this weekly spike in cardiovascular events so, what could be the
              cause of this phenomenon? In an interview with HT Lifestyle, Dr
              Haresh Mehta, Consultant Interventional Cardiologist at SL Raheja
              Hospital in Mahim and Dr Prashant Pawar, Consultant-Interventional
              Cardiologist at Fortis Hiranandani Hospital in Vashi, talked about
              how stress and Monday Blues are related and shared, “One possible
              explanation for the increased occurrence of fatal heart attacks on
              Mondays is the impact of stress. Mondays often begin a new work
              week, bringing with it a lot of stress and anxiety, especially
              amongst those associated with professional responsibilities.
              Research has shown that elevated stress levels can contribute to
              the development of heart disease and increase the risk of heart
              attacks. Therefore, through the correlation by cause, it is
              plausible that the collective stress experienced by individuals
              returning to work after the weekend contributes to the higher
              prevalence of fatal heart attacks on Mondays.”
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SuccessStoryDetails;
