import { useRef, useState } from "react";
import useOutsideClick from "../../../hooks/useClickOutside";
import { useEffect } from "react";
import { removeHtmlTags } from "../../../helpers";
import NotFound from "../../elements/extra/NotFound";

const Select = ({
  register = () => {},
  onChange = () => {},
  options = [],
  placeholder = "Select item",
  name = "",
  rules = {
    required: false,
    message: "",
  },
  errors,
  selectClasses = "",
  setValue = () => {},
  label = "",
  labelClass = "",
  loading = false,
}) => {
  const selectRef = useRef(null);
  const [selected, setSelected] = useState({});
  const [dropdownOpen, setdropdownOpen] = useState(false);

  useOutsideClick(selectRef, () => {
    setdropdownOpen(false);
  });

  const selectHandler = (data) => {
    setSelected(data);
  };

  useEffect(() => {
    if (selected) {
      setValue(name, selected?.value);
      onChange(selected?.value);
    }
  }, [selected]);

  return (
    <div className="w-full">
      {label && (
        <div
          className={"text-sm font-medium text-slate-500 mb-1 " + labelClass}
        >
          {label}
        </div>
      )}
      <select
        {...register(name, { required: rules?.required })}
        className={
          `hidden ${
            errors && Object.keys(errors)?.length > 0 && errors[name]
              ? "border-carnation-400"
              : "border-slate-200 "
          } relative top-0 left-0 w-full h-10 capitalize text-[14px] text-slate-500 flex items-center justify-between focus:ring-0 transition-all duration-200 gap-2 pr-2 pl-3 bg-slate-50 rounded-md border !px-2 focus:border-0 ` +
          selectClasses
        }
        onChange={(e) => onChange(e.target.value)}
      >
        <option value="">{placeholder}</option>
        {Array.isArray(options) &&
          options?.length > 0 &&
          options?.map((item, i) => (
            <option
              key={i.toString()}
              value={item?.value}
              className="capitalize"
            >
              {item?.label}
            </option>
          ))}
      </select>

      <div className="custom-select relative w-full h-10">
        <div
          ref={selectRef}
          onClick={() => setdropdownOpen(!dropdownOpen)}
          className={
            `${
              errors && Object.keys(errors)?.length > 0 && errors[name]
                ? "border-carnation-400"
                : "border-slate-200 "
            } absolute top-0 left-0 w-full h-full capitalize text-[14px] text-slate-500 flex items-center justify-between focus:ring-0 transition-all duration-200 gap-2 pr-2 pl-3 ${
              selected?.label ? "" : "bg-slate-50"
            } rounded-md border !px-2 focus:border-0 ` + selectClasses
          }
        >
          <div>{selected?.label || placeholder}</div>
          <div>
            {!loading ? (
              <i class="fa-regular fa-angle-down"></i>
            ) : (
              <i class="fa-duotone fa-spinner-third animate-spin"></i>
            )}
          </div>
        </div>

        {dropdownOpen && (
          <div className="shadow-sm absolute top-full mt-1 w-full rounded-md z-20 bg-white h-48 max-h-52 overflow-y-auto">
            {Array.isArray(options) && options?.length > 0 && (
              <ul>
                <li
                  onClick={() => {
                    setSelected({});
                    setValue(name, "");
                  }}
                  className="capitalize hover:bg-carnation-50 relative select-none py-2 pl-2 pr-2 text-xs leading-tight cursor-pointer transition-all duration-200 text-slate-500"
                >
                  <div className="flex gap-2 items-center">
                    <span className="w-5 h-5 flex items-center justify-center"></span>
                    <span>{placeholder}</span>
                  </div>
                </li>
                {options?.map((item, i) => (
                  <li
                    key={i.toString()}
                    value={item?.value}
                    className={`capitalize hover:bg-carnation-50 relative select-none py-2 pl-2 pr-2 text-xs leading-tight cursor-pointer transition-all duration-200 text-slate-500 ${
                      selected &&
                      Object.keys(selected)?.length > 0 &&
                      selected?.value === item?.value
                        ? "bg-carnation-50"
                        : ""
                    }`}
                    onClick={() => selectHandler(item)}
                  >
                    <div className="flex gap-2 items-center">
                      <span className="w-5 h-5 flex items-center justify-center">
                        {selected &&
                          Object.keys(selected)?.length > 0 &&
                          selected?.value === item?.value && (
                            <i class="fa-regular fa-check text-carnation-500"></i>
                          )}
                      </span>
                      <div>
                        <span>{item?.label}</span>
                        {item?.subLevel && (
                          <div className="mt-1">{item?.subLevel}</div>
                        )}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
            {Array.isArray(options) && options?.length === 0 && (
              <NotFound title="No data found" />
            )}
          </div>
        )}
      </div>
      {selected?.subLevel && (
        <div className="mt-2 text-xs text-slate-500">{selected?.subLevel}</div>
      )}

      <div className="mt-2">
        {errors && Object.keys(errors)?.length > 0 && errors[name] && (
          <span className="text-xs text-carnation-500 flex gap-2">
            <span>
              <i class="fa-light fa-circle-info"></i>
            </span>
            <span>{rules?.message}</span>
          </span>
        )}
      </div>
    </div>
  );
};

export default Select;
