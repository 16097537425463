import React from "react";
import ReactPaginate from "react-paginate";

const Pagination = ({ pageCount, onPageChange, limit, currentPage }) => {
  return (
    <div>
      <ReactPaginate
        nextClassName={"w-auto h-10"}
        nextLinkClassName={
          "px-2 border border-carnation-500 rounded-lg bg-transparent w-full h-full flex items-center justify-center capitalize"
        }
        nextLabel={`${currentPage + 1 === pageCount ? "" : "next "}`}
        // nextLinkClassName={`px-2 border border-carnation-500 rounded-lg bg-transparent w-full h-full flex items-center justify-center capitalize ${
        //   !isLoading ? "loading" : ""
        // }`}
        // previousLinkClassName={`px-2 border border-carnation-500 rounded-lg bg-transparent w-full h-full flex items-center justify-center capitalize ${
        //   !isLoading ? "loading" : ""
        // }`}
        className={"flex items-center flex-wrap gap-3"}
        pageClassName={"w-10 h-10"}
        pageLinkClassName={
          "px-2 border border-carnation-500 rounded-lg bg-transparent w-full h-full flex items-center justify-center"
        }
        onPageChange={onPageChange}
        pageRangeDisplayed={limit}
        pageCount={pageCount}
        prevRel={"test"}
        previousClassName={"w-auto h-10"}
        previousLinkClassName={
          "px-2 border border-carnation-500 rounded-lg bg-transparent w-full h-full flex items-center justify-center capitalize"
        }
        previousLabel={`${currentPage === 0 ? "" : "previous"}`}
        activeClassName={"bg-carnation-500 text-white rounded-lg"}
        renderOnZeroPageCount={null}
        disabledLinkClassName={"border-none"}
        forcePage={currentPage}
      />
    </div>
  );
};

export default Pagination;
