import React, { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { repostService } from "../../../services/FundrisersService";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";

const RepostFundraiserModal = ({
  isOpen,
  onClose,
  handleSubmit,
  setSelectedDate,
  loaded,
}) => {
  // const [loaded, setloaded] = useState(true);
  // const navigate = useNavigate();

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  // const handleSubmit = () => {
  //   // Handle the submitted date here
  //   repostService({ fundraiserId: id, endDate: selectedDate }, setloaded).then(
  //     (res) => {
  //       onClose();
  //       navigate("/user/fundraised");
  //     }
  //   );

  //   // Close the modal
  // };

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center ${
        isOpen ? "block" : "hidden"
      } bg-black bg-opacity-50 z-50`}
    >
      <div className="modal relative p-4 bg-white w-96 rounded-lg shadow-md">
        <h2 className="text-lg font-bold mb-4">Extend End Date & Repost</h2>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            format={"DD-MM-YYYY"}
            className="w-full h-10 rounded-md bg-slate-50 border border-slate-200 px-3 text-sm text-slate-600 !ring-0 !outline-0 focus:border-carnation-200 focus:bg-carnation-50 transition-all duration-200 "
            onChange={(e) => setSelectedDate(dayjs(e?.$d).format("YYYY-MM-DD"))}
          />
        </LocalizationProvider>
        <div className="flex justify-end mt-4">
          <button
            onClick={onClose}
            className="mr-2 px-4 py-2 text-gray-500 hover:text-gray-900 border border-gray-300 rounded-md"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 border border-carnation-500 text-carnation-500 hover:bg-carnation-500 hover:text-white rounded-md"
          >
            {loaded ? (
              "Repost"
            ) : (
              <div className="animate-spin m-auto rounded-full h-6 w-6 border-t-2 border-white border-solid"></div>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RepostFundraiserModal;
