import React, { useEffect, useState } from "react";
import { setTitle } from "../helpers/MetaTag";
import MainBannerPage from "../components/sections/MainBannerPage";
import ListFundraiser from "../components/sections/ListFundraiser";

import Banner from "../assets/images/banner-page.jpg";
import Patient01 from "../assets/images/patient-01.png";
import Patient02 from "../assets/images/patient-02.png";
import Patient03 from "../assets/images/patient-03.png";
import { loadFundraiserList } from "../services/FundrisersService";
import Loader from "../components/elements/loader/Loader";
import { useParams } from "react-router-dom/dist/umd/react-router-dom.development";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Fundraiser = () => {
  setTitle("Crowd Funding | Browse Fundraiser");
  const [fundraiser, setFundsraiser] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [data, setData] = useState([]);
  const [limit, setlimit] = useState(1000);
  const [offset, setoffset] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [totalFiles, setTotalFiles] = useState([]);

  const topicId = useSelector((state) => state.header.fundsFor);

  useEffect(() => {
    setTotalFiles([]);
    setPage(0);
    setTotalPages(null);
  }, []);
  const fetchData = () => {
    setPage((pre) => pre + 1);
    setoffset((pre) => pre + limit);
  };
  useEffect(() => {
    Array.isArray(data) &&
      data.length > 0 &&
      data.map((ele) => setTotalFiles((pre) => [...pre, ele]));
  }, [data]);

  function removeDuplicatesFromArray(array) {
    const uniqueObjects = new Map();
    // Filter out duplicates and keep only the first occurrence
    const filteredArray =
      array &&
      array.filter((obj) => {
        const objString = JSON.stringify(obj);
        if (!uniqueObjects.has(objString)) {
          uniqueObjects.set(objString, true);
          return true;
        }
        return false;
      });

    return filteredArray;
  }

  const finalData = removeDuplicatesFromArray(totalFiles);

  useEffect(() => {
    topicId
      ? loadFundraiserList(
          {
            limit,
            offset,
            topicId: topicId,
            status: 1,
          },
          setData,
          setLoaded
        ).then((res) => {
          setTotalPages(res?.data?.totalPages);
          setFundsraiser(res?.data?.docs);
        })
      : loadFundraiserList(
          { limit, offset, status: 1 },
          setData,
          setLoaded
        ).then((res) => {
          // console.log(res,"res")
          setTotalPages(res?.data?.totalPages);
          setFundsraiser(res?.data?.docs);
        });
  }, [topicId, limit, offset]);

  const bannerData = {
    image: Banner,
    title: "Stand Up for the Future",
    description:
      "Join the Movement to Protect Young Lives and Make a Lasting Impact",
    actionLink: "/",
    actionLabel: "Start giving monthly",
  };
  let dataaa =
    finalData &&
    finalData.map((elm) => ({
      _id: elm?._id,
      image: elm?.gallery,
      name: elm?.title,
      description: elm?.description,
      slug: elm?.slug,
      raised: elm?.raised,
      goal: elm?.amount,
      shareLink: "",
      shareText: "Share",
      donateLink: "",
      donateText: "Donate",
      smallImage: false,
    }));

  const fundRaiserData = {
    title: "Our Top Fundraisers",
    subtitle:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form",
    subdata: dataaa,
  };

  const info = {
    title: "Our Top Fundraisers",
    subtitle:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form",
  };

  return (
    <>
      <>
        <MainBannerPage data={bannerData} loaded={loaded} />
        <ListFundraiser
          data={fundRaiserData}
          page={page}
          setPage={setPage}
          limit={limit}
          setlimit={setlimit}
          offset={offset}
          loaded={loaded}
        />
      </>
    </>
  );
};

export default Fundraiser;
