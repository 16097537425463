import React, { useState } from "react";
import Steps from "./Steps";
import Button from "../../form/Button";
import Input from "../../form/Input";
import { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import NgoContact from "../../elements/pagesections/fundraisingSteps/NgoContact";
import MultiTypesFileUploads from "../../form/MultiTypesFileUploads";
import QuilEditor from "../../elements/quilEditor/QuilEditor";
import {
  hasArrayData,
  nameHasArrayData,
  removeHtmlTags,
} from "../../../helpers";
import { toast } from "react-toastify";

const StepFour = ({
  steps,
  currentStep,
  stepsData,
  setCurrentStep,
  fundraiserData,
  setStepsData = () => {},
  onSubmit = () => {},
  flag,
  setFlag,
  isSubmit = true,
}) => {
  const token = stepsData?.topic?.name;
  const [documents, setDocuments] = useState();
  const [gallery, setGallery] = useState();

  const handleBack = () => {
    setCurrentStep((Number(currentStep) - 1).toString());
  };

  const onHandleChange = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    if (e.target.value) {
      setStepsData((preState) => ({ ...preState, [name]: value }));
    } else {
      delete stepsData[name];
      setStepsData({ ...stepsData });
    }
  };

  useEffect(() => {
    if (documents) {
      if (stepsData?.documents) {
        setStepsData((pre) => ({
          ...pre,
          documents: [...pre?.documents, { ...documents }],
        }));
      } else {
        setStepsData((pre) => ({
          ...pre,
          documents: [{ ...documents }],
        }));
      }
    }
  }, [documents]);

  useEffect(() => {
    if (gallery) {
      if (stepsData?.gallery) {
        setStepsData((pre) => ({
          ...pre,
          gallery: [...pre?.gallery, { ...gallery }],
        }));
      } else {
        setStepsData((pre) => ({
          ...pre,
          gallery: [{ ...gallery }],
        }));
      }
    }
  }, [gallery]);

  const onSubmitHandler = () => {
    if (stepsData?.topic?.name === "NGO/Charity") {
      if (stepsData?.isContactPerson) {
        if (!stepsData[`detailsOfPan[nameInPan]`]) {
          toast.info("Enter name acording to pan");
        } else {
          if (!stepsData[`detailsOfPan[panNumber]`]) {
            toast.info("Enter pan number");
          } else {
            if (stepsData?.is80G) {
              if (stepsData?.certificate80G) {
                if (
                  Array.isArray(stepsData?.certificate80G) &&
                  stepsData?.certificate80G?.length > 0
                ) {
                  if (!stepsData[`detailsOf80G[email]`]) {
                    toast.info("please enter email");
                  } else {
                    if (!stepsData[`detailsOf80G[phoneCode]`]) {
                      toast.info("please select country and phone code");
                    } else {
                      if (!stepsData[`detailsOf80G[phone]`]) {
                        toast.info("Please enter phone number");
                      } else {
                        if (!stepsData[`detailsOf80G[organisationType]`]) {
                          toast.info("Select organization type");
                        } else {
                          if (
                            stepsData[`detailsOf80G[organisationType]`] ===
                            "Trust"
                          ) {
                            if (
                              stepsData[`detailsOf80G[registrationSection]`]
                            ) {
                              if (
                                !stepsData[`detailsOf80G[registrationNumber]`]
                              ) {
                                toast.info("Please enter reg no");
                              } else {
                                if (
                                  !stepsData[
                                    `detailsOf80G[registrationAddress]`
                                  ]
                                ) {
                                  toast.info("Please enter address");
                                } else {
                                  // ======================================================================
                                  // if isForeignFund select or the value is true ==============>
                                  // ======================================================================
                                  if (stepsData?.isForeignFund) {
                                    if (
                                      !stepsData[
                                        `detailsOfForeignFund[is501c3]`
                                      ]
                                    ) {
                                      if (
                                        !stepsData[
                                          `detailsOfForeignFund[isFcra]`
                                        ]
                                      ) {
                                        toast.info(
                                          "Please select Foreign Fund Type"
                                        );
                                      } else {
                                        if (!stepsData?.fcraCertificate) {
                                          toast.info(
                                            "Please select fcra certificate"
                                          );
                                        } else {
                                          if (
                                            Array.isArray(
                                              stepsData?.fcraCertificate
                                            ) &&
                                            stepsData?.fcraCertificate?.length >
                                              0
                                          ) {
                                            if (
                                              !stepsData[
                                                `detailsOfForeignFund[fcraRegistrationNumber]`
                                              ]
                                            ) {
                                              toast.info(
                                                "Please enter FCRA Registration number"
                                              );
                                            } else {
                                              onSubmit();
                                            }
                                          } else {
                                            toast.info(
                                              "Please select fcra certificate"
                                            );
                                          }
                                        }
                                      }
                                    } else {
                                      if (
                                        !stepsData[
                                          `detailsOfForeignFund[name501c3]`
                                        ]
                                      ) {
                                        toast.info(
                                          "Please enter name for 501c3"
                                        );
                                      } else {
                                        if (
                                          !stepsData[
                                            `detailsOfForeignFund[employeeId]`
                                          ]
                                        ) {
                                          toast.info(
                                            "Please enter name for employee id"
                                          );
                                        } else {
                                          if (
                                            !stepsData[
                                              `detailsOfForeignFund[address][address1]`
                                            ]
                                          ) {
                                            toast.info(
                                              "Please enter address for 501c3"
                                            );
                                          } else {
                                            if (
                                              !stepsData[
                                                `detailsOfForeignFund[authorisedRepresentative]`
                                              ]
                                            ) {
                                              toast.info(
                                                "please enter authorised representative of the organisation"
                                              );
                                            } else {
                                              onSubmit();
                                            }
                                          }
                                        }
                                      }
                                    }
                                    // ======================================================================
                                    // end section ==============>
                                    // ======================================================================
                                  } else {
                                    onSubmit();
                                  }
                                }
                              }
                            } else {
                              toast.info("Please select registration section");
                            }
                          } else {
                            if (
                              !stepsData[`detailsOf80G[registrationNumber]`]
                            ) {
                              toast.info("Please enter reg no");
                            } else {
                              if (
                                !stepsData[`detailsOf80G[registrationAddress]`]
                              ) {
                                toast.info("Please enter address");
                              } else {
                                onSubmit();
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                } else {
                  toast.info("Add 80g certificate");
                }
              } else {
                toast.info("Add 80g certificate");
              }
            } else {
              // ======================================================================
              // if isForeignFund select or the value is true ==============>
              // ======================================================================
              if (stepsData?.isForeignFund) {
                if (!stepsData[`detailsOfForeignFund[is501c3]`]) {
                  if (!stepsData[`detailsOfForeignFund[isFcra]`]) {
                    toast.info("Please select Foreign Fund Type");
                  } else {
                    if (!stepsData?.fcraCertificate) {
                      toast.info("Please select fcra certificate");
                    } else {
                      if (
                        Array.isArray(stepsData?.fcraCertificate) &&
                        stepsData?.fcraCertificate?.length > 0
                      ) {
                        if (
                          !stepsData[
                            `detailsOfForeignFund[fcraRegistrationNumber]`
                          ]
                        ) {
                          toast.info("Please enter FCRA Registration number");
                        } else {
                          onSubmit();
                        }
                      } else {
                        toast.info("Please select fcra certificate");
                      }
                    }
                  }
                } else {
                  if (!stepsData[`detailsOfForeignFund[name501c3]`]) {
                    toast.info("Please enter name for 501c3");
                  } else {
                    if (!stepsData[`detailsOfForeignFund[employeeId]`]) {
                      toast.info("Please enter name for employee id");
                    } else {
                      if (
                        !stepsData[`detailsOfForeignFund[address][address1]`]
                      ) {
                        toast.info("Please enter address for 501c3");
                      } else {
                        if (
                          !stepsData[
                            `detailsOfForeignFund[authorisedRepresentative]`
                          ]
                        ) {
                          toast.info(
                            "please enter authorised representative of the organisation"
                          );
                        } else {
                          onSubmit();
                        }
                      }
                    }
                  }
                }
                // ======================================================================
                // end section ==============>
                // ======================================================================
              } else {
                onSubmit();
              }
            }
          }
        }
      } else {
        if (!stepsData[`contactPerson[name]`]) {
          toast.info("Enter name");
        } else {
          if (!stepsData[`contactPerson[email]`]) {
            toast.info("Enter email");
          } else {
            if (!stepsData[`contactPerson[phoneCode]`]) {
              toast.info("Enter phone code");
            } else {
              if (!stepsData[`contactPerson[phone]`]) {
                toast.info("Enter phone");
              } else {
                onSubmit();
              }
            }
          }
        }
      }
    } else {
      if (
        hasArrayData(stepsData, [
          "title",
          "description",
          "documents",
          "gallery",
        ])
      ) {
        onSubmit();
      } else {
        toast.info(
          `Please enter ${nameHasArrayData(
            stepsData,
            ["title", "description", "documents", "gallery"],
            [
              "title",
              "description",
              "Supporting Documents",
              "Supporting Photography",
            ]
          )}`
        );
      }
    }
  };

  return (
    <>
      <div className="text-center space-y-2">
        <h1 className="text-base xl:text-lg font-Lexend font-semibold">
          Raising funds for {token} purpose
        </h1>
        <Steps data={steps} currentStep={currentStep} />
      </div>
      <form className="mt-8 space-y-4 mx-auto">
        {token !== "NGO/Charity" ? (
          <>
            <div className="relative">
              <Input
                inputPlaceholder={"Enter title."}
                label={"Fundraiser Title"}
                inputName={"title"}
                inputValue={stepsData?.title}
                onChange={(e) => onHandleChange(e)}
                errorText={"Fundraiser title is required!"}
                errorType={stepsData?.title ? "" : "danger"}
              />
            </div>
            <div className="relative">
              <QuilEditor
                placeholder="Write your story simple & specific"
                name="description"
                value={stepsData?.description}
                onChange={(e) => {
                  if (removeHtmlTags(e.target.value)) {
                    onHandleChange(e);
                  } else {
                    delete stepsData?.description;
                    setStepsData({ ...stepsData });
                  }
                }}
                errorText="Description is required!"
                errorType={stepsData?.description ? "" : "danger"}
              />
            </div>
            <div className="relative">
              <div class="text-sm font-medium text-slate-500 mb-1">
                Supporting Documents
              </div>
              <MultiTypesFileUploads
                files={stepsData?.documents}
                errorText={"Supporting documents are required!"}
                errorType={stepsData?.documents.length > 0 ? "" : "danger"}
                fileType={stepsData?.documents?.map((item) => item?.type)}
                onChange={(e) => {
                  setDocuments({
                    _id: crypto.randomUUID(),
                    type: e.target.files[0]?.type.split("/")[1],
                    file: e.target.files[0],
                    name: e.target.files[0]?.name,
                  });
                }}
                removeFile={(e) => {
                  setStepsData((preState) => ({
                    ...preState,
                    documents: preState?.documents?.filter(
                      (item) => item?._id !== e._id
                    ),
                  }));
                }}
                accept={["jpg", "jpeg", "png", "pdf"]}
              />
            </div>
            <div className="relative">
              <div class="text-sm font-medium text-slate-500 mb-1">
                Supporting Photography
              </div>
              <MultiTypesFileUploads
                files={stepsData?.gallery}
                errorText={"Supporting photography is required!"}
                errorType={stepsData?.gallery.length > 0 ? "" : "danger"}
                fileType={stepsData?.gallery?.map((item) => item?.type)}
                onChange={(e) => {
                  setGallery({
                    _id: crypto.randomUUID(),
                    type: e.target.files[0]?.type.split("/")[1],
                    file: e.target.files[0],
                    name: e.target.files[0]?.name,
                  });
                }}
                removeFile={(e) => {
                  setStepsData((preState) => ({
                    ...preState,
                    gallery: preState?.gallery?.filter(
                      (item) => item?._id !== e._id
                    ),
                  }));
                }}
                accept={["jpg", "jpeg", "png"]}
              />
            </div>
          </>
        ) : (
          <NgoContact stepsData={stepsData} setStepsData={setStepsData} />
        )}

        <div className="relative flex justify-center gap-3">
          <Button
            buttonClasses={"w-full"}
            buttonType={"button"}
            buttonLabel={"Back"}
            buttonLabelClasses={""}
            buttonEffect={"filled"}
            buttonFunction={handleBack}
          />
          <Button
            buttonClasses={"w-full"}
            buttonType={"button"}
            buttonLabel={"Submit"}
            buttonLabelClasses={""}
            buttonEffect={"filled"}
            buttonFunction={onSubmitHandler}
            isDisable={!isSubmit}
          />
        </div>
      </form>
      <div className="text-xs text-slate-500 mt-4 text-center">
        By continuing you agree to
        <Button
          buttonClasses={
            "!px-0 !h-auto !bg-transparent !rounded-none !inline-flex mx-1"
          }
          buttonHasLink={true}
          buttonLink={"/"}
          buttonLabel={"Terms of Service"}
          buttonLabelClasses={
            "font-semibold text-carnation-500 !text-xs hover:!underline"
          }
        />
        and
        <Button
          buttonClasses={
            "!px-0 !h-auto !bg-transparent !rounded-none !inline-flex mx-1"
          }
          buttonHasLink={true}
          buttonLink={"/"}
          buttonLabel={"Privacy Policy"}
          buttonLabelClasses={
            "font-semibold text-carnation-500 !text-xs hover:!underline"
          }
        />
      </div>
    </>
  );
};

export default StepFour;
