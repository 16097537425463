import React, { useEffect } from "react";
import { useState } from "react";
import Input from "../../../../components/form/Input";
import Select from "../../../../components/form/Select";
import Button from "../../../../components/form/Button";
import Logo from "../../../../assets/images/logo.png";
import {
  Link,
  useNavigate,
} from "react-router-dom/dist/umd/react-router-dom.development";
import Image from "../../../../components/elements/Image";
import { SignUpService } from "../../../../services/FundrisersService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import noImage from "../../../../assets/images/no-profile-picture.jpg";
import {
  getCountryDetails,
  loadCountries,
} from "../../../../services/CountryService";
import PanSection from "../reg_for_ngo/PanSection";
import TaxSection from "../reg_for_ngo/TaxSection";
import ForeignFunds from "../reg_for_ngo/ForeignFunds";
import Address from "../reg_for_ngo/Address";
import { numReg } from "../../../../helpers";
import SearchWithDropdown from "../../../form/SearchWithDropdown";

const UserReg = ({
  setFillUp = () => {},
  regFormData = {},
  fillUp,
  setRegFormData = () => {},
}) => {
  const authToken = localStorage.getItem("auth_token");
  const navigate = useNavigate();
  const [loaded, setloaded] = useState(true);
  const [coords, setCoords] = useState({
    lat: undefined,
    lon: undefined,
  });
  const [countryList, setCountryList] = useState([]);

  // ============================>

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setCoords({
        lat: position?.coords?.latitude,
        lon: position?.coords?.longitude,
      });
    });
  }, []);

  // get country list ===========>

  useEffect(() => {
    loadCountries().then((res) => {
      if (res?.status === 200) {
        setCountryList(
          res?.docs?.map((country) => ({
            name: "+" + country?.phone_code?.replace("+", ""),
            country_name: country?.name,
            _id: country?._id,
          }))
        );
      }
    });
  }, []);

  // get current country details ==========>

  useEffect(() => {
    if (coords?.lon && coords?.lat && countryList) {
      getCountryDetails({ lat: coords?.lat, lon: coords?.lon }).then((res) => {
        if (!regFormData?.phoneCode) {
          setRegFormData((pre) => ({
            ...pre,
            phoneCode: countryList?.find(
              (item) => item?.country_name === res?.address?.country
            )?.name,
          }));
        }
      });
    }
  }, [coords, countryList]);

  const signUp = () => {
    !authToken &&
      SignUpService(
        {
          name: regFormData?.name,
          email: regFormData?.email,
          phoneCode: regFormData?.phoneCode,
          phone: regFormData?.phone,
          password: regFormData?.password,
          image: regFormData?.profileImage,
          // role: formFor,
          // ...ngoData,
        },
        () => {},
        setloaded
      ).then((res) => {
        if (res?.data?.status === 200) {
          setFillUp(true);
          toast.success(res?.data?.message);
        } else {
          setloaded(true);
          toast.error(res?.data?.message);
        }
        if (res?.data?.status === 403) {
          setFillUp(true);
          toast.info(res?.data?.message);
        }
      });
  };

  const handleSignUp = () => {
    // Check if any field is empty
    const requiredFields = ["name", "email", "phone", "password", "phoneCode"];
    const missingFields = requiredFields.filter((field) => !regFormData[field]);

    if (missingFields.length > 0) {
      // Display toast message for missing fields
      toast.error(
        `Please provide information for: ${missingFields.join(", ")}`
      );
      return;
    } else {
      signUp();
    }
  };

  const onChangeHandler = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setRegFormData((pre) => ({ ...pre, [name]: value }));
  };

  return (
    <>
      <div>
        <div className="w-28 h-28 relative m-auto">
          <div className="w-full h-full overflow-hidden rounded-full border-2 border-carnation-500">
            <Image
              src={
                regFormData?.profileImage
                  ? regFormData?.profileImage instanceof File
                    ? URL.createObjectURL(regFormData?.profileImage)
                    : regFormData?.profileImage
                  : noImage
              }
              alt={"Logo"}
              width={"100%"}
              height={"100%"}
              effect={"blur"}
              classes={"object-contain object-center"}
            />
          </div>
          <div className="absolute bottom-1 right-1 w-8 h-8 overflow-hidden border-2 border-carnation-500 text-slate-800 bg-white rounded-full flex items-center justify-center text-base">
            <input
              type="file"
              className="absolute top-0 right-0 opacity-0 w-[1000px] h-full z-[1] cursor-pointer"
              onChange={(e) =>
                onChangeHandler({
                  target: { name: "profileImage", value: e.target.files[0] },
                })
              }
            />
            <i className="fa-regular fa-fw fa-camera"></i>
          </div>
        </div>
        <div className="relative mt-2">
          <Input
            label={""}
            labelClasses={"!text-xs"}
            inputType={"text"}
            inputPlaceholder={"Enter your full name"}
            errorType={regFormData?.name ? "" : "danger"}
            inputValue={regFormData?.name}
            inputName={"name"}
            onChange={(e) => onChangeHandler(e)}
            errorText={"Full name is required!"}
          />
        </div>
        <div className="relative mt-2">
          <Input
            label={""}
            labelClasses={"!text-xs"}
            inputType={"email"}
            inputPlaceholder={"Enter your email"}
            errorType={regFormData?.email ? "" : "danger"}
            errorText={"Email is required!"}
            inputValue={regFormData?.email}
            inputName={"email"}
            onChange={(e) => onChangeHandler(e)}
          />
        </div>
        <div className="relative mt-2">
          <SearchWithDropdown
            placeholder={"Type three letter and find country"}
            data={
              !fillUp &&
              countryList?.find((item) => item?.name === regFormData?.phoneCode)
                ?.country_name
            }
            dropdownData={[
              { name: "Select country" },
              ...countryList?.map((item) => ({
                name: item?.country_name,
                country_code: item?.name,
                _id: item?._id,
              })),
            ]}
            setCustomSelecedvalue={(value) => {
              onChangeHandler({
                target: { name: "phoneCode", value: value?.country_code },
              });
            }}
          />
        </div>
        <div className="relative flex mt-2">
          <div className="w-20 min-w-[80px]">
            <Select
              xPlacement={"left"}
              dropdownData={countryList}
              dropdownClass={"max-h-40"}
              dropdownButtonClass={"!px-2 !border-r-none rounded-r-none"}
              selectedValue={
                countryList?.find(
                  (item) => item?.name === regFormData?.phoneCode
                )?._id
              }
              setCustomSelecedvalue={(val) => {
                onChangeHandler({
                  target: { name: "phoneCode", value: val?.name },
                });
              }}
            />
          </div>
          <div className="w-full">
            <Input
              inputPlaceholder={"Enter your phone number"}
              inputClasses={"!border-l-none rounded-l-none -ml-[1px]"}
              inputValue={regFormData?.phone}
              errorText={"Phone number is required!"}
              errorType={regFormData?.phone ? "" : "danger"}
              inputName={"phone"}
              onChange={(e) => {
                if (numReg.test(e.target.value)) {
                  if (e.target.value?.length > 10) {
                    return;
                  } else {
                    onChangeHandler(e);
                  }
                } else {
                  return;
                }
              }}
            />
          </div>
        </div>
        <div className="relative mt-2">
          <Input
            label={""}
            labelClasses={"!text-xs"}
            inputType={"password"}
            inputPlaceholder={"Enter your password"}
            inputName={"password"}
            inputValue={regFormData?.password}
            errorType={regFormData?.password ? "" : "danger"}
            errorText={"Password is required!"}
            onChange={(e) => onChangeHandler(e)}
          />
        </div>
      </div>
      <div className="relative flex justify-center mt-5">
        <Button
          buttonClasses={"w-full"}
          buttonType={"button"}
          buttonLabel={"Signup"}
          buttonLabelClasses={""}
          buttonEffect={"filled"}
          buttonFunction={handleSignUp}
          isDisable={loaded === false ? true : false}
        />
      </div>
      {!authToken && (
        <div className="flex items-center justify-center mt-2 gap-2 text-sm text-slate-600">
          <span>Already have an account?</span>
          <Button
            buttonClasses={"!px-0 !h-auto !bg-transparent !rounded-none"}
            buttonHasLink={true}
            buttonLink={"/signin"}
            buttonLabel={"Signin"}
            buttonLabelClasses={
              "font-semibold text-carnation-500 !text-sm hover:!underline"
            }
          />
          <span>here</span>
        </div>
      )}
    </>
  );
};

export default UserReg;
