// import React from 'react';
import React, { useEffect, useState } from "react";
import blogIamge from "../assets/images/patient-05.png";
import Image from "../components/elements/Image";
import { useParams } from "react-router";
import dayjs from "dayjs";
import Loader from "../components/elements/loader/Loader";
import Image1 from "../assets/images/clock.png";
import Image2 from "../assets/images/location.png";
import Facebook from "../assets/images/facebook.svg";
import { Link } from "react-router-dom";
import Button from "../components/form/Button";
import { loadJobDetails } from "../services/CareerService";
import { useSelector } from "react-redux";
// import Button from "../form/Button";

const JobDetails = () => {
  const [details, setDetails] = useState({});
  const [loaded, setLoaded] = useState(true);
  const [flag, setflag] = useState(false);
  const profileData = useSelector((state) => state.profile.profileData);
  const { id } = useParams();

  useEffect(() => {
    profileData &&
      details &&
      Array.isArray(details?.applicant) &&
      details?.applicant.length > 0 &&
      details?.applicant.map(
        (elm) => elm?.userId === profileData?._id && setflag(true)
      );
  }, [details, profileData]);

  useEffect(() => {
    loadJobDetails(id, setLoaded).then((res) => {
      setDetails(res?.data.data);
    });
  }, []);

  return (
    <>
      {loaded === false ? (
        <Loader />
      ) : (
        <section className="relative w-full py-10 lg:py-20 bg-gray-100">
          <div className="relative flex justify-center mb-4">
            <div className="text-3xl font-bold text-slte-800">Job Details</div>
          </div>
          <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
            <div className="relative flex items-start justify-start gap-4">
              <div className="bg-white p-6 w-9/12 relative z-0">
                <div className="reltative border-b border-solid border-slate-200 pb-8">
                  <div className="text-slate-900 text-2xl font-medium">
                    {details?.title}
                  </div>
                  <div className="flex items-center gap-6 text-slate-600 mt-3">
                    <div className="flex items-center gap-1">
                      <div className="w-4 h-100% overflow-hidden flex justify-center text-slate-900 relative z-10 font-medium text-sm">
                        <Image
                          src={Image2}
                          alt={""}
                          width={"100%"}
                          height={"100%"}
                          effect={"blur"}
                          classes={"object-cover"}
                        />
                      </div>
                      <div className="text-sm text-slate-600 font-medium">
                        {details?.location?.address},{details?.location?.city},
                        {details?.location?.zipCode},{details?.location?.state}
                      </div>
                    </div>
                    <div className="flex items-center gap-1">
                      <div className="w-4 h-100% overflow-hidden flex justify-center text-black relative z-10">
                        <Image
                          src={Image1}
                          alt={""}
                          width={"100%"}
                          height={"100%"}
                          effect={"blur"}
                          classes={"object-cover"}
                        />
                      </div>
                      <div className="text-sm text-slate-600 font-medium">
                        {dayjs(details?.createdAt).format(
                          "dddd, MMMM D, YYYY h:mm A"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="flex items-center gap-2 text-slate-600 font-medium text-sm">
                      <div className="relative w-[100px]">Category :</div>
                      <div className="relative w-[cal(100%_-_100px)]">
                        {details?.jobCategories?.map(
                          (elm, index) => elm.category + ", "
                        )}
                      </div>
                    </div>
                    <div className="flex items-center gap-2 text-slate-600 mt-1 font-medium text-sm">
                      <div className="relative w-[100px]">Type :</div>
                      <div className="relative w-[cal(100%_-_100px)]">
                        {details?.jobTypes?.map(
                          (elm, index) => elm.type + ", "
                        )}
                      </div>
                    </div>
                    <div className="flex items-center gap-2 text-slate-600 mt-1 font-medium text-sm">
                      <div className="relative w-[100px]">Schedules :</div>
                      <div className="relative w-[cal(100%_-_100px)]">
                        {details?.jobSchedules?.map(
                          (elm, index) => elm.schedule + ", "
                        )}
                      </div>
                    </div>
                    <div className="flex items-center gap-2 text-slate-600 mt-1 font-medium text-sm">
                      <div className="relative w-[100px]">Shift :</div>
                      <div className="relative w-[cal(100%_-_100px)]">
                        {details?.jobSchedules?.map(
                          (elm, index) => elm.shift + ", "
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="relative py-6 pt-8"
                  dangerouslySetInnerHTML={{ __html: details?.description }}
                />
              </div>
              <div className="bg-white p-4 w-3/12 relative z-0">
                <div className="flex items-center justify-center">
                  <Button
                    buttonClasses={"!px-8 !h-10  w-full"}
                    buttonHasLink={true}
                    buttonLink={`/apply-jobs/${details?.slug}`}
                    buttonLabel={flag ? "Already Applied" : "Apply this job"}
                    buttonLabelClasses={"text-sm"}
                    buttonEffect={"filled"}
                    buttonType={"button"}
                    isDisable={flag}
                  />
                </div>
                <div className="relative flex flex-col justify-center items-center mt-4 pb-4">
                  <div className="text-slate-900 font-normal text-sm">
                    Share with someone awesome
                  </div>
                  <div className="flex items-center gap-2 mt-4">
                    <Link to={"/"}>
                      <div className="w-10 h-10 bg-slate-200 rounded-full flex justify-center items-center overflow-hidden text-slate-900 relative z-10 font-medium text-sm">
                        <i class="fa-brands fa-facebook-f text-base text-slate-700"></i>
                      </div>
                    </Link>
                    <Link to={"/"}>
                      <div className="w-10 h-10 bg-slate-200 rounded-full flex justify-center items-center overflow-hidden text-slate-900 relative z-10 font-medium text-sm">
                        <i class="fa-brands fa-instagram text-base text-slate-700"></i>
                      </div>
                    </Link>
                    <Link to={"/"}>
                      <div className="w-10 h-10 bg-slate-200 rounded-full flex justify-center items-center overflow-hidden text-slate-900 relative z-10 font-medium text-sm">
                        <i class="fa-brands fa-twitter text-base text-slate-700"></i>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="flex flex-col justify-center items-center relative pt-4 border-t border-solid border-slate-200">
                  <div className="uppercase text-slate-600 font-normal text-base">
                    SIMILAR OPENINGS
                  </div>
                  <div className="flex mt mt-2">
                    <div className="flex flex-col items-start justify-start">
                      <Link to={"/"}>
                        <div className="text-slate-900 text-base hover:text-carnation-500 transition">
                          Relationship Executive - Bangalore
                        </div>
                      </Link>
                      <div className="text-sm text-slate-500 font-normal">
                        Bangalore
                      </div>
                    </div>
                    <div></div>
                  </div>
                  <div className="mt-6">
                    <Link to={"/careers"}>
                      <div className=" text-carnation-500 text-base hover:text-slate-900 transition">
                        View all job
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default JobDetails;
