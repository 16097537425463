import { apiRequest } from "../utils/async/apiUtils";

export const loadSiteSettingsDetails = async () => {
  try {
    const res = await apiRequest("get", `site-settings`);
    return res;
  } catch (err) {
    return err;
  }
};
