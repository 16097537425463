import React, { useEffect, useState } from "react";
import { setTitle } from "../helpers/MetaTag";
import MainBannerPage from "../components/sections/MainBannerPage";

import Banner from "../assets/images/banner-page.jpg";
import Patient01 from "../assets/images/patient-01.png";
import Patient02 from "../assets/images/patient-02.png";
import Patient03 from "../assets/images/patient-03.png";
import ListArticle from "../components/sections/ListArticle";
import SideArticle from "../components/items/SideArticle";
import { loadPostsList } from "../services/PostService";
import Loader from "../components/elements/loader/Loader";

const News = () => {
  setTitle("Crowd Funding | News");

  const [pageAction, setPageAction] = useState({
    limit: 10,
    offset: 0,
    sortQuery: "-createdAt",
    type: "news",
    selectValue:
      "title,slug,shortDescription,author,publishDate,image,description,type,status,featured,ordering,createdAt",
  });
  const [newsData, setNewsData] = useState({
    loaded: true,
    data: [],
    count: 0,
  });

  // list fetch of blog =========>
  useEffect(() => {
    setNewsData({ loaded: false, data: [], count: 0 });
    loadPostsList(pageAction).then((res) => {
      if (res?.status === 200) {
        setNewsData({
          loaded: true,
          data: res?.docs?.filter(item=>item?.status==1).map((item) => ({
            ...item,
            type: "grid",
            postType: "news",
          })),
          count: res?.totalDocs,
        });
      } else {
        setNewsData({ loaded: true, data: [], count: 0 });
      }
    });
  }, [pageAction]);

  const bannerData = {
    image: Banner,
    title: "NEWS",
    description:
      "It is a long established fact that a reader will be distractedwhen  layout. The point of using Lorem Ipsum is that it has a more-or-less normal ,",
    actionLink: "/",
    actionLabel: "Start giving monthly",
  };

  const blogData = {
    title: "Our Top Fundraisers",
    headline: "Recent Post",
    subtitle:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form",
    type: "grid",
    subdata: [
      {
        _id: 1,
        image: Patient01,
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        // raised:70000,
        // goal: 100000,
        // shareLink:"",
        // shareText:"Share",
        // donateLink:"",
        // donateText:"Donate"
        dateText: "06 May 2023",
        monthAgo: "2Month",
        smallImage: true,
        type: "grid",
        boxRow: true,
        fontSize: true,
        type: "date",
        btnText: "read more",
      },
      {
        _id: 2,
        image: Patient02,
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elitLorem ipsum dolor sit amet, consectetur adipiscing elit",
        // raised:70000,
        // goal: 100000,
        // shareLink:"",
        // shareText:"Share",
        // donateLink:"",
        // donateText:"Donate"
        dateText: "06 May 2023",
        monthAgo: "2Month",
        smallImage: true,
        type: "grid",
        boxRow: true,
        fontSize: true,
        type: "date",
      },
      {
        _id: 3,
        image: Patient03,
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elitLorem ipsum dolor sit amet, consectetur adipiscing elit",
        // raised:70000,
        // goal: 100000,
        // shareLink:"",
        // shareText:"Share",
        // donateLink:"",
        // donateText:"Donate"
        dateText: "06 May 2023",
        monthAgo: "2Month",
        smallImage: true,
        type: "grid",
        boxRow: true,
        fontSize: true,
        type: "date",
      },
      {
        _id: 4,
        image: Patient02,
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elitLorem ipsum dolor sit amet, consectetur adipiscing elit",
        // raised:70000,
        // goal: 100000,
        // shareLink:"",
        // shareText:"Share",
        // donateLink:"",
        // donateText:"Donate"
        dateText: "06 May 2023",
        monthAgo: "2Month",
        smallImage: true,
        type: "grid",
        boxRow: true,
        fontSize: true,
        type: "date",
      },
      {
        _id: 5,
        image: Patient03,
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elitLorem ipsum dolor sit amet, consectetur adipiscing elit",
        // raised:70000,
        // goal: 100000,
        // shareLink:"",
        // shareText:"Share",
        // donateLink:"",
        // donateText:"Donate"
        dateText: "06 May 2023",
        monthAgo: "2Month",
        smallImage: true,
        type: "grid",
        boxRow: true,
        fontSize: true,
        type: "date",
      },
      {
        _id: 6,
        image: Patient01,
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elitLorem ipsum dolor sit amet, consectetur adipiscing elit",
        // raised:70000,
        // goal: 100000,
        // shareLink:"",
        // shareText:"Share",
        // donateLink:"",
        // donateText:"Donate"
        dateText: "06 May 2023",
        monthAgo: "2Month",
        smallImage: true,
        type: "grid",
        boxRow: true,
        fontSize: true,
        type: "date",
      },
      {
        _id: 7,
        image: Patient03,
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elitLorem ipsum dolor sit amet, consectetur adipiscing elit",
        // raised:70000,
        // goal: 100000,
        // shareLink:"",
        // shareText:"Share",
        // donateLink:"",
        // donateText:"Donate"
        dateText: "06 May 2023",
        monthAgo: "2Month",
        smallImage: true,
        type: "grid",
        boxRow: true,
        fontSize: true,
        type: "date",
        dateText: false,
      },
      {
        _id: 8,
        image: Patient01,
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elitLorem ipsum dolor sit amet, consectetur adipiscing elit",
        // raised:70000,
        // goal: 100000,
        // shareLink:"",
        // shareText:"Share",
        // donateLink:"",
        // donateText:"Donate"
        dateText: "06 May 2023",
        monthAgo: "2Month",
        smallImage: true,
        type: "grid",
        boxRow: true,
        fontSize: true,
        type: "date",
      },
      {
        _id: 9,
        image: Patient02,
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        // raised:70000,
        // goal: 100000,
        // shareLink:"",
        // shareText:"Share",
        // donateLink:"",
        // donateText:"Donate"
        dateText: "06 May 2023",
        monthAgo: "2Month",
        smallImage: true,
        type: "grid",
        boxRow: true,
        fontSize: true,
        type: "date",
      },
    ],
  };

  const articleData = {
    title: "Our Top Fundraisers",
    subtitle:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form",
    headline: "Recent Post",
    type: "grid",
    subdata: [
      {
        _id: 1,
        image: Patient01,
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        // description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        // raised:70000,
        // goal: 100000,
        // shareLink:"",
        // shareText:"Share",
        // donateLink:"",
        // donateText:"Donate"
        dateText: "06 May 2023",
        monthAgo: "2Month",
        smallImage: true,
        type: "grid",
        boxRow: true,
        fontSize: true,
        type: "date",
        btnText: "read more",
      },
      {
        _id: 2,
        image: Patient02,
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        // description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elitLorem ipsum dolor sit amet, consectetur adipiscing elit",
        // raised:70000,
        // goal: 100000,
        // shareLink:"",
        // shareText:"Share",
        // donateLink:"",
        // donateText:"Donate"
        dateText: "06 May 2023",
        monthAgo: "2Month",
        smallImage: true,
        type: "grid",
        boxRow: true,
        fontSize: true,
        type: "date",
      },
      {
        _id: 3,
        image: Patient03,
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        // description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elitLorem ipsum dolor sit amet, consectetur adipiscing elit",
        // raised:70000,
        // goal: 100000,
        // shareLink:"",
        // shareText:"Share",
        // donateLink:"",
        // donateText:"Donate"
        dateText: "06 May 2023",
        monthAgo: "2Month",
        smallImage: true,
        type: "grid",
        boxRow: true,
        fontSize: true,
        type: "date",
      },
      {
        _id: 4,
        image: Patient02,
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        // description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elitLorem ipsum dolor sit amet, consectetur adipiscing elit",
        // raised:70000,
        // goal: 100000,
        // shareLink:"",
        // shareText:"Share",
        // donateLink:"",
        // donateText:"Donate"
        dateText: "06 May 2023",
        monthAgo: "2Month",
        smallImage: true,
        type: "grid",
        boxRow: true,
        fontSize: true,
        type: "date",
      },
      {
        _id: 5,
        image: Patient03,
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        // description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elitLorem ipsum dolor sit amet, consectetur adipiscing elit",
        // raised:70000,
        // goal: 100000,
        // shareLink:"",
        // shareText:"Share",
        // donateLink:"",
        // donateText:"Donate"
        dateText: "06 May 2023",
        monthAgo: "2Month",
        smallImage: true,
        type: "grid",
        boxRow: true,
        fontSize: true,
        type: "date",
      },
      {
        _id: 6,
        image: Patient01,
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        // description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elitLorem ipsum dolor sit amet, consectetur adipiscing elit",
        // raised:70000,
        // goal: 100000,
        // shareLink:"",
        // shareText:"Share",
        // donateLink:"",
        // donateText:"Donate"
        dateText: "06 May 2023",
        monthAgo: "2Month",
        smallImage: true,
        type: "grid",
        boxRow: true,
        fontSize: true,
        type: "date",
      },
      {
        _id: 7,
        image: Patient03,
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        // description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elitLorem ipsum dolor sit amet, consectetur adipiscing elit",
        // raised:70000,
        // goal: 100000,
        // shareLink:"",
        // shareText:"Share",
        // donateLink:"",
        // donateText:"Donate"
        dateText: "06 May 2023",
        monthAgo: "2Month",
        smallImage: true,
        type: "grid",
        boxRow: true,
        fontSize: true,
        type: "date",
        dateText: false,
      },
      {
        _id: 8,
        image: Patient01,
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        // description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elitLorem ipsum dolor sit amet, consectetur adipiscing elit",
        // raised:70000,
        // goal: 100000,
        // shareLink:"",
        // shareText:"Share",
        // donateLink:"",
        // donateText:"Donate"
        dateText: "06 May 2023",
        monthAgo: "2Month",
        smallImage: true,
        type: "grid",
        boxRow: true,
        fontSize: true,
        type: "date",
      },
      {
        _id: 9,
        image: Patient02,
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        // description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        // raised:70000,
        // goal: 100000,
        // shareLink:"",
        // shareText:"Share",
        // donateLink:"",
        // donateText:"Donate"
        dateText: "06 May 2023",
        monthAgo: "2Month",
        smallImage: true,
        type: "grid",
        boxRow: true,
        fontSize: true,
        type: "date",
      },
    ],
  };

  return (
    <>
      <MainBannerPage data={bannerData} />
      {newsData?.loaded === false ? (
        <Loader />
      ) : (
        <>
          <ListArticle data={newsData?.data} type={"grid"} />{" "}
          <SideArticle data={newsData?.data} />
        </>
      )}
    </>
  );
};

export default News;
