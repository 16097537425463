import { API_BASE_URL } from "../constant/ApiConstant";
import axios from "axios";

// Function to handle file upload
const mediaUploadWithLoading = async (
  method = "get",
  url,
  data,
  setProgress = () => {}
) => {
  try {
    const formData = new FormData();
    Object.keys(data)?.length > 0 &&
      Object.keys(data)?.map((item) => formData.append(item, data[item]));

    // Make a POST request using Axios
    const response = await axios[method.toLowerCase()](
      API_BASE_URL + url,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": "Bearer " + localStorage.getItem("auth_token"),
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          setProgress(progress);
          // You can update a progress bar or display the progress percentage
        },
      }
    );
    setProgress(0)
    return response;
  } catch (error) {
    return error;
  }
};

export default mediaUploadWithLoading;
