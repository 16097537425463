import React from "react";
import Button from "../../form/Button";
import { apiRequest } from "../../../utils/async/apiUtils";

const Delete = ({ onCancel = () => {}, data = {}, response = () => {} }) => {
  const deleteHandler = async () => {
    try {
      await apiRequest("patch", data?.api_end, { body: data?.data }).then(
        (res) => {
          response(res);
        }
      );
    } catch (error) {
      response(error);
    }
  };

  return (
    <div className="flex gap-3 justify-center items-center">
      <Button
        buttonHasLink={false}
        buttonFunction={() => {
          onCancel(false);
        }}
        buttonLabel={"Cancel"}
      />
      <Button
        buttonHasLink={false}
        buttonLabel={"Delete"}
        buttonFunction={deleteHandler}
      />
    </div>
  );
};

export default Delete;
