import React from "react";
import Image from "../elements/Image";

const SingleMetaInfo = ({ data }) => {
  return (
    <>
      <div className="relative flex items-center gap-2 xl:gap-5 py-5 px-4 xl:px-6">
        <div className="w-12 xl:w-16 3xl:w-20 h-12 xl:h-16 3xl:h-20 min-w-[48px] xl:min-w-[64px] 3xl:min-w-[80px] rounded-full overflow-hidden">
          <Image
            src={data && data?.user?.image?.url}
            alt={"Photo"}
            width={"100%"}
            height={"100%"}
            effect={"blur"}
            classes={"object-cover"}
          />
        </div>
        <div className="w-full flex-grow flex-shrink xl:space-y-1">
          <div className="flex items-center justify-between">
            <h4 className="text-xs xl:text-lg 2xl:text-xl font-semibold text-black">
              {/* {data?.title} */}
              Campaigner
            </h4>
            {data?.isVerified && (
              <div className="text-[10px] xl:text-xs py-0 xl:py-1 px-1 xl:px-2 rounded bg-green-100 border border-green-300 text-green-500">
                Verified
              </div>
            )}
          </div>
          <div className="relative">
            <div className="text-sm font-semibold text-black">
              {data?.user?.name}
            </div>
            <div className="text-xs text-slate-600">
              {data?.location && (
                <i
                  className={"fa-regular fa-fw " + "fa-solid fa-location-dot"}
                ></i>
              )}

              {data?.location?.address1 || data?.location?.city}
            </div>
          </div>
        </div>
      </div>

      <div className="relative flex items-center gap-2 xl:gap-5 py-5 px-4 xl:px-6">
        <div className="w-12 xl:w-16 3xl:w-20 h-12 xl:h-16 3xl:h-20 min-w-[48px] xl:min-w-[64px] 3xl:min-w-[80px] rounded-full overflow-hidden">
          <Image
            src={data && data?.image}
            alt={"Photo"}
            width={"100%"}
            height={"100%"}
            effect={"blur"}
            classes={"object-cover"}
          />
        </div>
        <div className="w-full flex-grow flex-shrink xl:space-y-1">
          <div className="flex items-center justify-between">
            <h4 className="text-xs xl:text-lg 2xl:text-xl font-semibold text-black">
              {/* {data?.title} */}
              Beneficiary
            </h4>
            {data?.isVerified && (
              <div className="text-[10px] xl:text-xs py-0 xl:py-1 px-1 xl:px-2 rounded bg-green-100 border border-green-300 text-green-500">
                Verified
              </div>
            )}
          </div>
          <div className="relative">
            <div className="text-sm font-semibold text-black">{data?.name}</div>
            <div className="text-xs text-slate-600">
            {data?.location && (
                <i
                  className={"fa-regular fa-fw " + "fa-solid fa-location-dot"}
                ></i>
              )}
              {data?.location?.address1 || data?.location?.city}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleMetaInfo;
