import Button from "../../../form/Button";
import FileUpload from "../../../form/FileUpload";
import NotFound from "../../extra/NoDataFound";
import Progress from "../../extra/Progress";
import Storage from "./components/storage/Storage";

const Media = ({
  data = [],
  progress = 0,
  onChange = () => {},
  setData = () => {},
  loading = false,
}) => {
  return (
    <div
      className={`w-full h-full relative ${
        Array.isArray(data) && data?.length === 0
          ? "flex items-center justify-center"
          : ""
      }`}
    >
      {!loading && Array.isArray(data) && data?.length === 0 && (
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
          <div className="w-2/3">
            {progress > 0 && Array.isArray(data) && data?.length === 0 && (
              <div className="flex flex-col items-center mb-4">
                <Progress progress={progress} />
                <span className="text-[10px] font-semibold text-green-500 mt-2">
                  Uploading... {progress}%
                </span>
              </div>
            )}
            <div className="text-center">
              You can upload video files up to 100 MB in size and in mp4 format.
            </div>
            <div className="mt-2 flex justify-center">
              <FileUpload onChange={(val) => onChange(val)} className="w-1/2" />
            </div>
          </div>
        </div>
      )}
      {Array.isArray(data) && (
        <Storage
          data={data}
          progress={progress}
          loading={loading}
          setData={setData}
        />
      )}
    </div>
  );
};

export default Media;
