import React, { useEffect, useRef, useState } from "react";
import Image from "../elements/Image";
import { classNames } from "../../helpers/classNames";

const ImageUploader = ({
  label,
  labelClasses,
  onChange = () => {},
  removeFile = () => {},
  files,
}) => {
  const imageRef = useRef(null);

  const selectImageHandler = (e) => {
    onChange(e);
  };

  return (
    <>
      {label && (
        <div
          className={classNames(
            "text-sm font-medium text-slate-500 mb-1",
            labelClasses
          )}
        >
          {label}
        </div>
      )}
      <div className="flex flex-wrap gap-4 pt-2">
        {files && !Array.isArray(files) ? (
          <div className="relative">
            <div className="w-16 h-16 overflow-hidden border border-slate-200 bg-white rounded-md">
              <Image
                src={files instanceof File ? URL.createObjectURL(files) : files}
                width={"100%"}
                height={"100%"}
                effect={"blur"}
                classes={"object-cover"}
              />
            </div>
            <button
              onClick={() => {
                removeFile(files);
                imageRef.current.value = null;
              }}
              type="button"
              className="w-5 h-5 rounded-full bg-white shadow absolute -top-2.5 -right-2.5 text-red-500 text-sm"
            >
              <i className="fa-regular fa-fw fa-xmark"></i>
            </button>
          </div>
        ) : (
          <>
            {Array.isArray(files) &&
              files?.length > 0 &&
              files?.map((file, i) => (
                <div className="relative" key={file?._id || i}>
                  <div className="w-16 h-16 overflow-hidden border border-slate-200 bg-white rounded-md">
                    <Image
                      src={
                        file?.file instanceof File
                          ? URL.createObjectURL(file?.file)
                          : file?.file
                      }
                      width={"100%"}
                      height={"100%"}
                      effect={"blur"}
                      classes={"object-cover"}
                    />
                  </div>
                  <button
                    onClick={() => {
                      removeFile(file);
                      imageRef.current.value = null;
                    }}
                    type="button"
                    className="w-5 h-5 rounded-full bg-white shadow absolute -top-2.5 -right-2.5 text-red-500 text-sm"
                  >
                    <i className="fa-regular fa-fw fa-xmark"></i>
                  </button>
                </div>
              ))}
          </>
        )}
        <div className="relative">
          <div className="relative w-16 h-16 overflow-hidden border-2 border-dashed border-slate-200 text-slate-500 bg-slate-50 rounded-md flex items-center justify-center flex-col gap-1 text-xl leading-none">
            <input
              ref={imageRef}
              type="file"
              onChange={(e) => {
                selectImageHandler(e);
                imageRef.current.value = null;
              }}
              className="absolute top-0 right-0 opacity-0 w-[1000px] h-full z-[1] cursor-pointer"
            />
            <i className="fa-regular fa-fw fa-plus"></i>
            <div className="text-[10px] font-medium uppercase">Images</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageUploader;
