import React from "react";
import Tabs from "../../components/elements/Tabs";

import FundraiserImage01 from "../../assets/images/patient-01.png";
import FundraiserImage02 from "../../assets/images/patient-02.png";
import FundraiserImage03 from "../../assets/images/patient-03.png";
import FundraiserImage04 from "../../assets/images/patient-04.png";
import FundraiserImage05 from "../../assets/images/patient-05.png";
import FundraiserImage06 from "../../assets/images/patient-06.png";
import FundraiserImage07 from "../../assets/images/patient-07.png";
import FundraiserImage08 from "../../assets/images/patient-08.png";
import Team01 from "../../assets/images/team/team-01.jpg";
import Team02 from "../../assets/images/team/team-02.jpg";
import Team03 from "../../assets/images/team/team-03.jpg";
import Team04 from "../../assets/images/team/team-04.jpg";
import Team05 from "../../assets/images/team/team-05.jpg";
import Team06 from "../../assets/images/team/team-06.jpg";
import Team07 from "../../assets/images/team/team-07.jpg";
import Team08 from "../../assets/images/team/team-08.jpg";
import FundraisedList from "../../components/partials/fundraised/FundraisedList";
import { loadFundraiserList } from "../../services/FundrisersService";
import { useState } from "react";
import { useEffect } from "react";
import Loader from "../../components/elements/loader/Loader";
import { loadFundsForList } from "../../services/fundsForService";
import noData from "../../assets/images/no-data-found.png";
import dayjs from "dayjs";
import { loadSupporterList } from "../../services/SupportersService";
import NoDataFound from "../../components/elements/extra/NoDataFound";
import Pagination from "../../components/elements/Pagination";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import BannerSkeletonLoader from "../../components/elements/loader/BannerSkeletonLoader";
import LongCardSkeletonLoader from "../../components/elements/loader/LongCardSkeletonLoader";

const Completed = () => {
  const [topics, settopics] = useState([]);
  const [topicId, settopicId] = useState("");
  const [data, setData] = useState([]);
  const [loaded, setloaded] = useState(true);
  const [limit, setlimit] = useState(5);
  const [offset, setoffset] = useState(0);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(null);
  const [totalFiles, setTotalFiles] = useState([]);
  const [flag, setFlag] = useState(true);
  const [totalDocs, setTotalDocs] = useState(null);
  const [supporter, setsupporter] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(1);
  const [hasNextPage, sethasNextPage] = useState(false);

  useEffect(() => {
    loadSupporterList({}, setsupporter, setloaded).then((res) => {});
  }, []);
  const supporters =
    Array.isArray(supporter) &&
    supporter.length > 0 &&
    supporter.map((elm) => ({
      _id: elm?.supporter?._id,
      name: elm?.supporter?.name,
      image: elm?.supporter?.image?.url,
    }));

  useEffect(() => {
    setTotalFiles([]);
  }, []);

  useEffect(() => {
    loadFundsForList(
      () => {},
      settopics,
      () => {}
    ).then((res) => {});
  }, []);

  function removeDuplicatesFromArray(array) {
    const uniqueObjects = new Map();

    // Filter out duplicates and keep only the first occurrence
    const filteredArray =
      array &&
      array.filter((obj) => {
        const objString = JSON.stringify(obj);
        if (!uniqueObjects.has(objString)) {
          uniqueObjects.set(objString, true);
          return true;
        }
        return false;
      });

    return filteredArray;
  }

  const finalData = removeDuplicatesFromArray(totalFiles);

  useEffect(() => {
    loadFundraiserList(
      { limit, offset, topicId, status: 5 },
      setData,
      setloaded
    ).then((res) => {
      // setTotalPages(res?.data?.totalPages);
      setCurrentPage(res?.page - 1);
      setCount(res?.totalPages);
      sethasNextPage(res?.hasNextPage);
      if (flag === true && res?.data?.status === 200) {
        // currentPage: res.page - 1,
        // count: res.totalPages,
        // hasNextPage: res.hasNextPage,

        setTotalDocs(res?.data?.totalDocs);
        setFlag(false);
      }
    });
  }, [offset, topicId]);

  const fetchData = () => {
    setPage((prevPage) => prevPage + 1);
    setoffset((prevOffset) => prevOffset + limit);
  };
  useEffect(() => {
    Array.isArray(data) &&
      data.length > 0 &&
      data.map((ele) => setTotalFiles((pre) => [...pre, ele]));
  }, [data]);

  function calculateRemainingDays(targetDateString) {
    // Convert the target date string to a Date object
    const targetDate = new Date(targetDateString);

    // Get the current date
    const currentDate = new Date();

    // Calculate the time difference in milliseconds
    const timeDifferenceMillis = targetDate - currentDate;

    // Calculate the number of days remaining
    const daysRemaining = Math.ceil(
      timeDifferenceMillis / (1000 * 60 * 60 * 24)
    );

    return daysRemaining;
  }

  const fundraisedListData =
    data &&
    data.length > 0 &&
    data.map((elm) => ({
      _id: elm?._id,
      title: elm?.title,
      image: elm.gallery && elm?.gallery.length > 0 && elm?.gallery[0].url,
      description: elm?.description,
      status: elm?.status,
      remainingDays:
        calculateRemainingDays(dayjs(elm?.endDate).format("YYYY-MM-DD")) || "",
      raised: 70000,
      goal: elm?.amount,
      commission: elm?.adminPercentage,
      link: `/fundraiser/${elm?.slug}`,
      dashboardLink: `/dashboard/${elm?.slug}`,
      supporters: supporters,
    }));

  const handlePageChange = (e) => {
    setoffset(e.selected * limit);
  };

  return (
    <>
      {!loaded ? (
        <>
          <LongCardSkeletonLoader />
          <LongCardSkeletonLoader />
          <LongCardSkeletonLoader />
          <LongCardSkeletonLoader />
          <LongCardSkeletonLoader />
          <LongCardSkeletonLoader />
        </>
      ) : Array.isArray(finalData) && finalData.length > 0 ? (
        <FundraisedList
          page={page}
          totalPages={totalPages}
          limit={limit}
          offset={offset}
          data={
            Array.isArray(fundraisedListData) &&
            fundraisedListData.length > 0 &&
            fundraisedListData
          }
          fetchData={fetchData}
        />
      ) : (
        // <img src={noData} />
        <NoDataFound />
      )}

      <div className="py-5 w-full flex justify-center items-center">
        <Pagination
          onPageChange={handlePageChange}
          pageCount={count}
          limit={limit}
          currentPage={currentPage}
          // isLoading={loaded}
        />
      </div>
    </>
  );
};

export default Completed;
