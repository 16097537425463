import React from 'react';
import Image from "../elements/Image";
import Image2 from "../../assets/images/crowd-funding2.jpg";
import BannerFormAlt from '../partials/BannerFormAlt';
import Button from '../form/Button';

const BannerMedicalFund = ({ data }) => {

    return (
        <>
            <section className="relative overflow-hidden w-full z-0 sm:h-[500px] flex items-center py-10 sm:py-0">
                <div className="absolute top-0 left-0 w-full h-full z-[-1] after:absolute after:content-[''] after:w-full after:h-full after:top-0 after:left-0 after:bg-gradient-to-r after:from-white/95 after:from-10% after:via-white/80 after:via-50%">
                    <Image src={data.image} alt={''} width={'100%'} height={'100%'} effect={'blur'} classes={'object-cover saturate-200 object-center'} />
                </div>
                <div className="relative z-[1] w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto flex items-center">
                    <div className="relative z-[1] w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto flex items-center">
                        {/* <div className="w-full sm:w-1/2 md:w-1/2 lg:w-3/5 xl:w-3/5 2xl:w-1/2 3xl:w-2/5 space-y-8">
                            <div className="space-y-4">
                                <h1 className="font-Lexend  text-3xl sm:text-4xl xl:text-5xl uppercase font-bold text-black" dangerouslySetInnerHTML={{ __html: data.title }}></h1>
                            </div>
                        </div> */}
                        <div className='w-4/5 sm:w-2/3 md:w-2/3 lg:w-3/5 xl:w-3/5 2xl:w-1/2 3xl:w-2/5 space-y-8'>
                            <h2 className='font-Lexend sm:text-4xl md:text-3xl xl:text-5xl font-bold text-black leading-6' dangerouslySetInnerHTML={{__html: data.title}}></h2>
                           
                            <div className='flex mt-8 lg:gap-8 gap-4 '>
                               { data.subdata.map((item,index)=>
                            <div className='flex flex-col items-start gap-0'>
                                    <div className='font-bold uppercase text-sm sm:text-base md:text-xl xl:text-xl text-carnation-500 whitespace-nowrap xs-whitespace-wrap'>{item.data}</div>
                                    <div className='font-bold uppercase text-sm sm:text-base md:text-xl xl:text-xl text-slate-700 '>{item.dataText}</div>
                                </div>)
                                }
                                {/* <div className='flex flex-col items-start gap-0'>
                                    <div className='font-bold uppercase text-2xl text-carnation-500'>{data.donorNumber}</div>
                                    <div className='font-bold uppercase text-xl text-slate-700'>{data.donorText}</div>
                                </div>
                                <div className='flex flex-col items-start gap-0'>
                                    <div className='font-bold uppercase text-2xl text-carnation-500'>{data.donorText}</div>
                                    <div className='font-bold uppercase text-xl text-slate-700'>{data.donorText}</div>
                                </div> */}
                            </div>
                            <div className="flex items-center !mt-10">
                                <Button
                                    buttonClasses={'!px-5'}
                                    buttonHasLink={true}
                                    buttonLink={data.actionLink}
                                    buttonLabel={data.actionLabel}
                                    buttonEffect={"filled"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {data.statistics?.length > 0 &&
                <section className="relative w-full flex items-start bg-white py-5 sm:py-0">
                    <div className="relative flex justify-center w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
                        <div className="w-full sm:w-auto flex flex-col sm:flex-row gap-6 py-4 px-5 bg-gray-100 rounded-xl sm:-translate-y-1/2">
                            {data.statistics?.map((item) => (
                                <div className="flex flex-col items-center justify-center bg-white py-8 px-4 rounded-xl min-w-[200px]" key={item._id}>
                                    <div className="text-xl text-carnation-500 font-semibold">{item.data}</div>
                                    <h4 className="text-base text-slate-600">{item.label}</h4>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            }

        </>
    );
};

export default BannerMedicalFund;