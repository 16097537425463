import React from "react";
import { classNames } from "../../helpers/classNames";

const SingleMobileApp = ({ data, index }) => {
  return (
    <>
      <div
        className={classNames(
          "relative flex flex-col space-y-1 lg:space-y-2",
          data?.classess
        )}
      >
        <div className="w-8 h-8 lg:w-10 lg:h-10 rounded-xl rounded-tl-none bg-carnation-500 text-white font-semibold text-lg flex items-center justify-center">
          {index}
        </div>
        {/* <h4 className="font-semibold text-black text-base xl:text-lg">
          {data?.name}
        </h4> */}
        {/* <div className="text-xs xl:text-sm text-slate-600">{data.description}</div> */}
        <div
          className="text-xs xl:text-sm text-slate-600"
          dangerouslySetInnerHTML={{ __html: data?.description }}
        />
      </div>
    </>
  );
};

export default SingleMobileApp;
