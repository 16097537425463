import React, { Fragment, useEffect, useState } from "react";
import Image from "../components/elements/Image";
import Button from "../components/form/Button";
import { Link, NavLink } from "react-router-dom";
import { classNames } from "../helpers/classNames";
import { UpdateImage } from "../services/ProfileService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PostSidebar = ({ user, setActive, isActive, id }) => {
  const [menuToggle, setMenuToggle] = useState(false);
  const [imagee, setimage] = useState(null);
  const [loaded, setloaded] = useState(true);

  const data = [
    {
      _id: 1,
      label: "Dashboard",
      icon: "fa-solid fa-gauge",
      link: `/dashboard/${id}`,
      standout: false,
    },
    {
      _id: 2,
      label: "Edit Post",
      icon: "fa-solid fa-pen-nib",
      link: `/fundraised-edit/${id}`,
      standout: false,
    },
    {
      _id: 3,
      label: "Update",
      icon: "fa-solid fa-file-pen",
      link: `/fundraised-update/${id}`,
      standout: false,
    },
    {
      _id: 4,
      label: "View Fundraisers",
      icon: "fa-hand-holding-dollar",
      link: `/fundraised-supporters/${id}`,
      standout: false,
    },
    {
      _id: 5,
      label: "Withdraw",
      icon: "fa-money-bill-transfer",
      link: `/fundraised-withdraw/${id}`,
      standout: false,
    },
    {
      _id: 6,
      label: "Promotion",
      icon: "fa-solid fa-up-right-from-square",
      link: `/fundraised-promotion/${id}`,
      standout: false,
    },
    {
      _id: 7,
      label: "Help",
      icon: "fa-solid fa-people-arrows",
      link: `/fundraised-help/${id}`,
      standout: false,
    },
  ];

  useEffect(() => {
    imagee &&
      UpdateImage(imagee, setloaded).then((res) => {
        if (res?.data?.status === 200) {
          toast.success(res?.data?.message);
          setimage(null);
        } else {
          toast.error(res?.data?.message);
        }
      });
  }, [imagee]);

  return (
    <>
      <div className="relative border-r border-slate-200 w-full bg-white divide-y xl:rounded-l-lg  divide-slate-100">
        {data && data.length > 0 && (
          <div
            className={classNames(
              "fixed z-10 xl:relative top-24 xl:top-auto left-0 xl:left-auto w-80 xl:w-auto h-[calc(100vh-6rem)] xl:h-auto bg-white xl:bg-transparent flex flex-col xl:flex-row xl:items-center xl:justify-end gap-8 py-5 shadow-2xl xl:shadow-none xl:!translate-x-0 transition:all duration-300",
              menuToggle ? "translate-x-0" : "-translate-x-full"
            )}
          >
            <div className="relative px-2 space-y-1 w-full">
              {data.map((item) => (
                <Fragment key={item._id}>
                  {item.separator ? (
                    <div className="border-t border-slate-200"></div>
                  ) : item.link ? (
                    <NavLink
                      to={item.link}
                      className={({ isActive }) =>
                        "group flex items-center py-2 px-4 gap-3 rounded-md text-slate-900 font-medium transition-all duration-200 hover:text-white hover:bg-carnation-500 " +
                        (isActive
                          ? "text-white bg-carnation-500"
                          : "text-slate-800 bg-white")
                      }
                    >
                      {({ isActive }) => (
                        <>
                          <div
                            className={
                              "w-5 h-5 flex items-center justify-center text-lg group-hover:text-carnation-100 transition-all duration-200 " +
                              (isActive
                                ? "text-carnation-100"
                                : "text-carnation-500")
                            }
                          >
                            <i className={"fa-regular fa-fw " + item.icon}></i>
                          </div>
                          <div className="text-xs uppercase">{item.label}</div>
                        </>
                      )}
                    </NavLink>
                  ) : (
                    <button
                      type="button"
                      className={classNames(
                        "group w-full flex items-center py-2 px-4 gap-3 rounded-md text-slate-900 font-medium transition-all duration-200 hover:text-white hover:bg-carnation-500"
                      )}
                      onClick={item.onclick}
                    >
                      <div className="w-5 h-5 flex items-center justify-center text-lg text-carnation-500 group-hover:text-carnation-100 transition-all duration-200">
                        <i className={"fa-regular fa-fw " + item.icon}></i>
                      </div>
                      <div className="text-xs uppercase">{item.label}</div>
                    </button>
                  )}
                </Fragment>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default PostSidebar;
