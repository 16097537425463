import React from "react";
import StorageItem from "./StorageItem";
import Progress from "../../../../extra/Progress";
import StorageItemLoader from "./StorageItemLoader";

const Storage = ({
  data = [],
  progress,
  loading,
  setData = () => {},
  fundraiserId,
}) => {
  return (
    <div className="mt-3 flex gap-2 flex-wrap">
      {data && Array.isArray(data) && data?.length > 0 && progress > 0 && (
        <div className="w-28 shadow-sm flex flex-col justify-center items-center bg-gray-100 rounded-md overflow-hidden h-32 min-h-32 max-h-32 p-2">
          <Progress progress={progress} />
          <span className="text-[10px] font-semibold text-green-500 mt-2">
            Uploading... {progress}%
          </span>
        </div>
      )}
      {Array.isArray(data) &&
        data?.length > 0 &&
        !loading &&
        data?.map((item) => (
          <React.Fragment key={item?._id}>
            <StorageItem
              data={item}
              setData={setData}
              fundraiserId={fundraiserId}
            />
          </React.Fragment>
        ))}
      {loading && <StorageItemLoader count={30} />}
    </div>
  );
};

export default Storage;
