import { useState, useEffect } from "react";
import SelectOptionHeader from "../../../../form/SelectOptionHeader";
import {
  loadFundraiserRelationList,
  loadHospitalList,
  loadMedicalConditionList,
} from "../../../../../services/FundrisersService";
import Input from "../../../../form/Input";
import Select from "../../../../form/Select";
import { numReg } from "../../../../../helpers";
import SearchWithDropdown from "../../../../form/SearchWithDropdown";

const MedicalForm = ({
  data,
  setData = () => {},
  countryList = [],
  currentCountry,
}) => {
  const [hospitalListData, sethospitalListData] = useState({
    loaded: true,
    data: [],
  });
  const [medicalConditionData, setmedicalConditionData] = useState({
    loaded: true,
    data: [],
  });
  const [relationList, setRelationList] = useState({
    loaded: true,
    data: [],
  });

  const ageData = [
    { name: "Below 5 years" },
    { name: "6 -10 years" },
    { name: "11 - 17 years" },
    { name: "18 - 30 years" },
    { name: "30 - 40 years" },
    { name: "Above 40 years" },
  ];

  const hospitalStatusData = [
    { name: "Currently hospitalised" },
    { name: "Does not require hospitalisation" },
    { name: "Recently discharged from the hospital" },
    { name: "Will be hospitalised soon" },
  ];

  useEffect(() => {
    loadFundraiserRelationList(
      () => {},
      () => {},
      { limit: 100 }
    ).then((res) => {
      setRelationList({ loaded: false, data: [] });
      if (res.status === 200) {
        setRelationList({
          loaded: true,
          data: res.data.docs?.map((item) => ({
            optionHeader: item?.name,
            options: item.childs,
          })),
        });
      } else {
        setRelationList({ loaded: true, data: [] });
      }
    });
  }, []);

  useEffect(() => {
    setmedicalConditionData({ loaded: false, data: [] });
    loadMedicalConditionList().then((res) => {
      if (res.data.status === 200) {
        setmedicalConditionData({ loaded: true, data: res.data.docs });
      } else {
        setmedicalConditionData({ loaded: true, data: [] });
      }
    });
  }, []);

  useEffect(() => {
    loadHospitalList(
      () => {},
      () => {},
      { limit: 10 }
    ).then((res) => {
      sethospitalListData({ loaded: false, data: [] });
      if (res.data.status === 200) {
        sethospitalListData({ loaded: true, data: res?.data?.docs });
      } else {
        sethospitalListData({ loaded: true, data: [] });
      }
    });
  }, []);

  const onHandleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (e.target.value) {
      setData((pre) => ({ ...pre, [name]: value }));
    } else {
      delete data[name];
      setData({ ...data });
    }
  };

  return (
    <>
      <div className="flex gap-3 mb-3">
        <div className="relative w-full">
          <Input
            isInputGroup={false}
            label={"Patient's full name"}
            inputPlaceholder={"Enter patient's full name"}
            errorType={"info"}
            inputName={"beneficiary[name]"}
            inputValue={data?.[`beneficiary[name]`]}
            errorText={"Name as mentioned in Patient’s Aadhar card"}
            onChange={(e) => onHandleChange(e)}
            isDisabled={false}
          />
        </div>
        <div className="relative w-full">
          <SelectOptionHeader
            xPlacement={"left"}
            label={"Relation with fundraiser?"}
            dropdownData={relationList?.data}
            dropdownClass={"max-h-60 w-full"}
            dropdownButtonClass={""}
            selectedValueType={"_id"}
            selectedValue={data?.relationWithFundraiser}
            setCustomSelecedvalue={(value) =>
              onHandleChange({
                target: { name: "relationWithFundraiser", value: value?._id },
              })
            }
          />
        </div>
      </div>

      <div className="flex gap-3 mb-3">
        <div className="relative w-full">
          <Select
            xPlacement={"left"}
            label={"Patient's age"}
            dropdownData={[{ name: "Select patient's age" }, ...ageData]}
            dropdownClass={"max-h-60 w-full"}
            dropdownButtonClass={""}
            selectedValueType={"name"}
            selectedValue={data?.[`beneficiary[age]`]}
            setCustomSelecedvalue={(value) => {
              if (value?.name === "Select patient's age") {
                delete data?.[`beneficiary[age]`];
                setData({ ...data });
                return;
              } else {
                if (
                  value?.name != "6 -10 years" ||
                  value?.name != "11 - 17 years" ||
                  value?.name != "Below 5 years"
                ) {
                  delete data[`beneficiary[parentName]`];
                  setData({...data})
                  onHandleChange({
                    target: { name: "beneficiary[age]", value: value?.name },
                  });
                } else {
                  onHandleChange({
                    target: { name: "beneficiary[age]", value: value?.name },
                  });
                }
              }
            }}
          />
        </div>
        <div className="relative w-full">
          <div class="text-sm font-medium text-slate-500 mb-1">
            {data?.[`beneficiary[age]`] === "Below 5 years" ||
            data?.[`beneficiary[age]`] === "6 -10 years" ||
            data?.[`beneficiary[age]`] === "11 - 17 years"
              ? "Gardian Contact No."
              : "Contact No."}
          </div>
          <div className="relative flex">
            <div className="w-20 min-w-[80px]">
              <Select
                xPlacement={"left"}
                dropdownData={countryList}
                dropdownClass={"max-h-40"}
                dropdownButtonClass={"!px-2 !border-r-none rounded-r-none"}
                selectedValue={
                  Array.isArray(countryList) &&
                  countryList?.length > 0 &&
                  countryList?.find(
                    (item) => item?.name === data?.[`beneficiary[phoneCode]`]
                  )?._id
                }
                setCustomSelecedvalue={(val) =>
                  onHandleChange({
                    target: {
                      name: "beneficiary[phoneCode]",
                      value: val?.name,
                    },
                  })
                }
              />
            </div>
            <div className="w-full">
              <Input
                inputPlaceholder={"Enter your phone number"}
                inputClasses={"!border-l-none rounded-l-none -ml-[1px]"}
                inputName={"beneficiary[phone]"}
                inputValue={data?.[`beneficiary[phone]`]}
                onChange={(e) => {
                  if (numReg.test(e.target.value)) {
                    if (e.target.value?.length > 10) {
                      return;
                    } else {
                      onHandleChange(e);
                    }
                  } else {
                    e.target.value = "";
                    return;
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex gap-2 mb-3">
        {(data?.[`beneficiary[age]`] === "Below 5 years" ||
          data?.[`beneficiary[age]`] === "6 -10 years" ||
          data?.[`beneficiary[age]`] === "11 - 17 years") && (
          <div className="relative w-full">
            <Input
              label="Gardian name"
              inputPlaceholder={"Enter name"}
              inputValue={data?.[`beneficiary[parentName]`] || ""}
              inputName={"beneficiary[parentName]"}
              onChange={(e) => onHandleChange(e)}
            />
          </div>
        )}
        <div className="relative w-full">
          <Select
            xPlacement={"left"}
            label={"Patient's Gender"}
            dropdownData={[
              { name: "Patient's Gender" },
              { name: "male" },
              { name: "female" },
              { name: "others" },
            ]}
            dropdownClass={"max-h-60 w-full"}
            dropdownButtonClass={""}
            selectedValueType={"name"}
            selectedValue={data?.[`beneficiary[gender]`]}
            setCustomSelecedvalue={(value) => {
              if (value?.name === "Patient's Gender") {
                delete data?.[`beneficiary[gender]`];
                setData({ ...data });
              } else {
                onHandleChange({
                  target: { name: "beneficiary[gender]", value: value?.name },
                });
              }
            }}
          />
        </div>
      </div>

      <div className="flex gap-3 mb-3">
        <div className="relative w-full">
          <Input
            isInputGroup={true}
            inputGroupIcon={"fa-regular fa-city"}
            inputGroupPosition={"right"}
            inputClasses={"!border-r-0 !rounded-r-none"}
            label={"Patient's City"}
            inputValue={data?.[`beneficiary[address][city]`]}
            inputName={"beneficiary[address][city]"}
            onChange={(e) => onHandleChange(e)}
          />
        </div>
        <div className="relative w-full">
          <SearchWithDropdown
            label={"Ailment / Medical Condition"}
            placeholder={"Type your Medical Condition here"}
            data={
              medicalConditionData?.data?.find(
                (item) => item?._id === data?.[`hospitalDetails[ailment]`]
              )?.name
            }
            dropdownData={[
              { name: "Select medical condition" },
              ...medicalConditionData?.data,
            ]}
            setCustomSelecedvalue={(value) => {
              if (value?.name === "Select medical condition") {
                delete data?.[`hospitalDetails[ailment]`];
                setData({ ...data });
                return;
              } else {
                onHandleChange({
                  target: {
                    name: "hospitalDetails[ailment]",
                    value: value?._id,
                  },
                });
              }
            }}
          />
        </div>
      </div>

      <div className="flex gap-3 mb-3">
        <div className="relative w-full">
          <Select
            xPlacement={"left"}
            label={"Hospitalisation status"}
            dropdownData={[
              { name: "Select hospitalisation status" },
              ...hospitalStatusData,
            ]}
            dropdownClass={"max-h-60 w-full"}
            dropdownButtonClass={""}
            selectedValueType={"name"}
            selectedValue={data?.[`hospitalDetails[hospitalisation]`]}
            setCustomSelecedvalue={(value) => {
              if (value.name === "Select hospitalisation status") {
                delete data?.[`hospitalDetails[hospitalisation]`];
                setData({ ...data });
              } else {
                onHandleChange({
                  target: {
                    name: "hospitalDetails[hospitalisation]",
                    value: value?.name,
                  },
                });
              }
            }}
          />
        </div>
        <div className="relative w-full">
          <SearchWithDropdown
            label={"Hospital"}
            data={
              hospitalListData?.data?.find(
                (item) => item?._id === data?.[`hospitalDetails[name]`]
              )?.name
            }
            placeholder={"Type your Hospital Name here"}
            dropdownData={hospitalListData?.data}
            setCustomSelecedvalue={(value) => {
              onHandleChange({
                target: { name: "hospitalDetails[name]", value: value?._id },
              });
            }}
          />
        </div>
      </div>
    </>
  );
};

export default MedicalForm;
