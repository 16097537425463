import { useRef } from "react";
import { classNames } from "../../helpers/classNames";
import Image from "../elements/Image";
import { toast } from "react-toastify";

const MultiTypesFileUploads = ({
  files = [],
  fileType = "jpg",
  label = "",
  labelClasses = "",
  accept = ["jpg"],
  onChange = () => {},
  removeFile = () => {},
  errorText,
  errorType,
}) => {
  const imageRef = useRef(null);
  const image = ["png", "jpg", "jpeg"];
  const application = ["pdf"];

  const selectImageHandler = (e) => {
    const type = e.target.files[0].type?.split("/")[1];
    if (accept.includes(type)) {
      onChange(e);
      imageRef.current.value = null;
    } else {
      toast.info(`Please select only ${accept?.map((item) => item)}`);
      imageRef.current.value = null;
    }
  };

  return (
    <>
      {label && (
        <div
          className={classNames(
            "text-sm font-medium text-slate-500 mb-1",
            labelClasses
          )}
        >
          {label}
        </div>
      )}
      <div className="flex flex-wrap gap-4">
        {files && !Array.isArray(files) ? (
          <div className="relative">
            <div
              className="w-16 h-16 overflow-hidden border border-slate-200 bg-white rounded-md"
              title={files?.name}
            >
              {image?.includes(fileType) && (
                <Image
                  src={
                    files instanceof File ? URL.createObjectURL(files) : files
                  }
                  width={"100%"}
                  height={"100%"}
                  effect={"blur"}
                  classes={"object-cover"}
                />
              )}
              {application?.includes(fileType) && (
                <div className="w-full h-full flex items-center justify-center">
                  <i class="fa-duotone fa-file-pdf text-3xl"></i>
                </div>
              )}
            </div>
            <button
              onClick={() => {
                removeFile(files);
                imageRef.current.value = null;
              }}
              type="button"
              className="w-5 h-5 rounded-full bg-white shadow absolute -top-2.5 -right-2.5 text-red-500 text-sm"
            >
              <i className="fa-regular fa-fw fa-xmark"></i>
            </button>
          </div>
        ) : (
          <>
            {Array.isArray(files) &&
              files?.length > 0 &&
              files?.map((file, i) => (
                <div className="relative" key={file?._id || i}>
                  <div className="w-16 h-16 overflow-hidden border border-slate-200 bg-white rounded-md">
                    {image?.includes(fileType[i]) && (
                      <Image
                        src={
                          file?.file instanceof File
                            ? URL.createObjectURL(file?.file)
                            : file?.file
                        }
                        width={"100%"}
                        height={"100%"}
                        effect={"blur"}
                        classes={"object-cover"}
                      />
                    )}
                    {application?.includes(fileType[i]) && (
                      <a href={file?.file} target="_blank">
                        <div className="w-full h-full flex items-center justify-center">
                          <i class="fa-duotone fa-file-pdf text-3xl"></i>
                        </div>
                      </a>
                    )}
                  </div>
                  <button
                    onClick={() => {
                      removeFile(file);
                      imageRef.current.value = null;
                    }}
                    type="button"
                    className="w-5 h-5 rounded-full bg-white shadow absolute -top-2.5 -right-2.5 text-red-500 text-sm"
                  >
                    <i className="fa-regular fa-fw fa-xmark"></i>
                  </button>
                </div>
              ))}
          </>
        )}
        <div className="relative">
          <div className="relative w-16 h-16 overflow-hidden border-2 border-dashed border-slate-200 text-slate-500 bg-slate-50 rounded-md flex items-center justify-center flex-col gap-1 text-xl leading-none">
            <input
              ref={imageRef}
              type="file"
              onChange={(e) => selectImageHandler(e)}
              className="absolute top-0 right-0 opacity-0 w-[1000px] h-full z-[1] cursor-pointer"
            />
            <i className="fa-regular fa-fw fa-plus"></i>
            <div className="text-[10px] font-medium uppercase">Images</div>
          </div>
        </div>
      </div>

      {errorType && errorType === "danger" && (
        <div className="text-xs text-red-600 mt-1">
          <i className="fa-regular fa-fw fa-square-exclamation text-sm mr-1"></i>
          {errorText}
        </div>
      )}
      {errorType && errorType === "warning" && (
        <div className="text-xs text-amber-600 mt-1">
          <i className="fa-regular fa-fw fa-triangle-exclamation text-sm mr-1"></i>
          {errorText}
        </div>
      )}
      {errorType && errorType === "success" && (
        <div className="text-xs text-green-600 mt-1">
          <i className="fa-regular fa-fw fa-circle-check text-sm mr-1"></i>
          {errorText}
        </div>
      )}
      {errorType && errorType === "info" && (
        <div className="text-xs text-sky-600 mt-1">
          <i className="fa-regular fa-fw fa-circle-info text-sm mr-1"></i>
          {errorText}
        </div>
      )}
    </>
  );
};

export default MultiTypesFileUploads;
