import React from 'react';

import ImageFunding from '../assets/images/SuccessStory02.png'
import { Link } from 'react-router-dom';
// import Button from '../form/Button';
// import Careers from '../../pages/Careers';
// import ListFundraiser from './ListFundraiser';
import MedicalCrowdfundingList from '../components/sections/MedicalCrowdfundingList';
import Image from '../components/elements/Image';
import BannerMedicalFund from '../components/sections/BannerMedicalFund';
import BannerImage from '../assets/images/collegeStudent.jpg';

const EducationCrowdfunding = ({ data }) => {
    const fundRaiserData = {
        title: "Our Top Fundraisers",
        subtitle: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form",
        subdata: [
            {
                _id: 1,
                raised: 70000,
                goal: 100000,
                shareLink: "",
                shareText: "Share",
                donateLink: "",
                donateText: "Donate",
                smallImage: false,
            },
        ]
    }


    const bannerData = {
        image: BannerImage,
        title: "Fundraising for Education",
        actionLink:"/new-fundraising",
        actionLabel:"Start a Fundraiser",
        subdata: [
            {
                data: "0%",
                dataText: "PLATFORM FEE",

            },
            {
                data: "72 LAKH+",
                dataText: "DONORS",
            },
            {
                data: "32 LAKH+",
                dataText: "FUNDRAISERS",
            },
        ]
    }
    return (
        <>
            <BannerMedicalFund data={bannerData} />
            <div className='relative overflow-hidden w-full py-20 bg-gray-100 '>
                <div className='relative z-[1] w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto'>
                    <div className='space-y-10'>
                        <div className='space-y-4 text-center'>
                            <div className='flex flex-col justify-center items-center'>
                                {/* <></> */}
                                <h2 className='font-Lexend text-3xl sm:text-4xl xl:text-5xl font-bold text-black'>Crowdfunding for Education</h2>
                                <div className='text-sm xl:text-base text-slate-600 max-w-xl mx-auto mt-3'>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium</div>
                            </div>
                        </div>
                        <div className='relative'>
                            <div className='bg-white rounded p-3 flex flex-col xl:flex-row items-start gap-4'>
                                <div className='h-[180px] overflow-hidden rounded w-full xl:w-2/3'>
                                    <Image src={BannerImage} alt={''} width={'100%'} height={'100%'} effect={'blur'} classes={'object-cover saturate-200 object-center'} />
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <h4 className='text-xl font-semibold text-slate-800'>Contrary to popular belief, Lorem Ipsum is not simply random text.</h4>
                                    <div className='text-sm text-slate-500 leading-6'>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.</div>
                                </div>
                                <MedicalCrowdfundingList data={fundRaiserData} />
                            </div>
                        </div>
                        <div className='relative'>
                            <div className='bg-white rounded p-3 flex flex-col xl:flex-row items-start gap-4'>
                                <div className='h-[180px] overflow-hidden rounded w-full xl:w-2/3'>
                                    <Image src={BannerImage} alt={''} width={'100%'} height={'100%'} effect={'blur'} classes={'object-cover saturate-200 object-center'} />
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <h4 className='text-xl font-semibold text-slate-800'>Contrary to popular belief, Lorem Ipsum is not simply random text.</h4>
                                    <div className='text-sm text-slate-500 leading-6'>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.</div>
                                </div>
                                <MedicalCrowdfundingList data={fundRaiserData} />
                            </div>
                        </div>
                        <div className='relative'>
                            <div className='bg-white rounded p-3 flex flex-col xl:flex-row items-start gap-4'>
                                <div className='h-[180px] overflow-hidden rounded w-full xl:w-2/3'>
                                    <Image src={BannerImage} alt={''} width={'100%'} height={'100%'} effect={'blur'} classes={'object-cover saturate-200 object-center'} />
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <h4 className='text-xl font-semibold text-slate-800'>Contrary to popular belief, Lorem Ipsum is not simply random text.</h4>
                                    <div className='text-sm text-slate-500 leading-6'>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.</div>
                                </div>
                                <MedicalCrowdfundingList data={fundRaiserData} />
                            </div>
                        </div>
                        <div className='relative'>
                            <div className='bg-white rounded p-3 flex flex-col xl:flex-row items-start gap-4'>
                                <div className='h-[180px] overflow-hidden rounded w-full xl:w-2/3'>
                                    <Image src={BannerImage} alt={''} width={'100%'} height={'100%'} effect={'blur'} classes={'object-cover saturate-200 object-center'} />
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <h4 className='text-xl font-semibold text-slate-800'>Contrary to popular belief, Lorem Ipsum is not simply random text.</h4>
                                    <div className='text-sm text-slate-500 leading-6'>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.</div>
                                </div>
                                <MedicalCrowdfundingList data={fundRaiserData} />
                            </div>
                        </div>
                    </div>
                    <div className='relative pt-12'>
                        <div className='bg-white px-6 py-6 rounded-md'>
                            <div className='relative'>
                                <div className='relative flex flex-col items-center py-6 pb-10 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl'>
                                    <h2 className='font-Lexend font-semibold text-black text-lg leading-tight sm:leading-normal sm:text-xl xl:text-2xl 2xl:text-3xl'>Most Promising Online Crowdfunding Platform to Raise Funds for Education?</h2>
                                </div>
                            </div>
                            <div className='relative grid lg:grid-cols-3 gap-6'>
                                <div className='flex flex-col gap-2'>
                                    <i class="fa-light fa-money-bill-1 text-4xl text-slate-800"></i>
                                    <div className='flex flex-col'>
                                        <h4 className='text-md font-semibold text-slate-900 mb-2'>Lorem Ipsum is simply dummy text </h4>
                                        <div className='text-slate-400 font-normal text-sm leading-6'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</div>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <i class="fa-light fa-money-bill-1 text-4xl text-slate-800"></i>
                                    <div className='flex flex-col'>
                                        <h4 className='text-md font-semibold text-slate-900 mb-2'>Lorem Ipsum is simply dummy text </h4>
                                        <div className='text-slate-400 font-normal text-sm leading-6'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</div>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <i class="fa-light fa-money-bill-1 text-4xl text-slate-800"></i>
                                    <div className='flex flex-col'>
                                        <h4 className='text-md font-semibold text-slate-900 mb-2'>Lorem Ipsum is simply dummy text </h4>
                                        <div className='text-slate-400 font-normal text-sm leading-6'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</div>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <i class="fa-light fa-money-bill-1 text-4xl text-slate-800"></i>
                                    <div className='flex flex-col'>
                                        <h4 className='text-md font-semibold text-slate-900 mb-2'>Lorem Ipsum is simply dummy text </h4>
                                        <div className='text-slate-400 font-normal text-sm leading-6'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</div>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <i class="fa-light fa-money-bill-1 text-4xl text-slate-800"></i>
                                    <div className='flex flex-col'>
                                        <h4 className='text-md font-semibold text-slate-900 mb-2'>Lorem Ipsum is simply dummy text </h4>
                                        <div className='text-slate-400 font-normal text-sm leading-6'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</div>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <i class="fa-light fa-money-bill-1 text-4xl text-slate-800"></i>
                                    <div className='flex flex-col'>
                                        <h4 className='text-md font-semibold text-slate-900 mb-2'>Lorem Ipsum is simply dummy text </h4>
                                        <div className='text-slate-400 font-normal text-sm leading-6'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default EducationCrowdfunding;