import { useState } from "react";
import Input from "../../../../components/form/Input";
import Select from "../../../../components/form/Select";
import MultiTypesFileUploads from "../../../form/MultiTypesFileUploads";
import TextAreaAutoSize from "../../../form/TextareaAutoSize";
import { useEffect } from "react";

const ForeignFunds = ({
  foreignFundsData,
  setForeignFundsData = () => {},
  stepsData = {},
  setStepsData = () => {},
}) => {
  const [selectFundType, setSelectFundType] = useState();
  const [foreignList, setForeignList] = useState([
    { _id: 0, name: "Yes", value: true, checked: false },
    { _id: 1, name: "No", value: false, checked: true },
  ]);
  const [isForeignFunds, setIsForeignFunds] = useState(false);

  const [foreignFundsType, setForeignFundsType] = useState([
    {
      name: "FCRA (Foreign Contribution (Regulation) Act)",
      checked: false,
    },
    { name: "501©(3)", checked: false },
  ]);

  // const onChangeHandler = (e) => {
  //   const name = e?.target?.name;
  //   const value = e?.target?.value;
  //   if (e.target.value) {
  //     setForeignFundsData((pre) => ({ ...pre, [name]: value }));
  //   } else {
  //     delete foreignFundsData[name];
  //     delete stepsData[name];
  //     setForeignFundsData({ ...foreignFundsData });
  //     setStepsData({ ...stepsData });
  //   }
  // };

  const onChangeHandler = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    if (value !== undefined) {
      setForeignFundsData((pre) => ({ ...pre, [name]: value }));
    } else {
      if (name === "isForeignFund") {
        setForeignFundsData((pre) => ({ ...pre, [name]: false }));
      } else {
        delete foreignFundsData[name];
        delete stepsData[name];
        setForeignFundsData({ ...foreignFundsData });
        setStepsData({ ...stepsData });
      }
    }
  };

  // useEffect(() => {
  //   setIsForeignFunds(foreignFundsData?.isForeignFund);
  //   setForeignList((pre) =>
  //     pre.map((item) =>
  //       item.value == foreignFundsData?.isForeignFund
  //         ? { ...item, checked: true }
  //         : { ...item, checked: false }
  //     )
  //   );
  // }, [foreignFundsData?.isForeignFund]);

  useEffect(() => {
    if (foreignFundsData?.[`detailsOfForeignFund[isFcra]`] === true) {
      setSelectFundType("FCRA (Foreign Contribution (Regulation) Act)");
      setForeignFundsType([
        {
          name: "FCRA (Foreign Contribution (Regulation) Act)",
          checked: true,
        },
        { name: "501©(3)", checked: false },
      ]);
    }
    if (foreignFundsData?.[`detailsOfForeignFund[is501c3]`] === true) {
      setSelectFundType("501©(3)");
      setForeignFundsType([
        {
          name: "FCRA (Foreign Contribution (Regulation) Act)",
          checked: false,
        },
        { name: "501©(3)", checked: true },
      ]);
    }
  }, [
    foreignFundsData?.[`detailsOfForeignFund[isFcra]`],
    foreignFundsData?.[`detailsOfForeignFund[is501c3]`],
  ]);

  useEffect(() => {
    if (foreignFundsData?.isForeignFund !== undefined) {
      setIsForeignFunds(foreignFundsData.isForeignFund);
      setForeignList((prev) =>
        prev.map((item) => ({
          ...item,
          checked: item.value === foreignFundsData.isForeignFund,
        }))
      );
    }
  }, [foreignFundsData?.isForeignFund]);

  const handleDataChange = (value) => {
    setIsForeignFunds(value);
    setForeignList((prev) =>
      prev.map((item) => ({
        ...item,
        checked: item.value === value,
      }))
    );
    onChangeHandler({ target: { name: "isForeignFund", value } });
  };

  return (
    <>
      <div className="relative flex justify-between">
        <h4 className="text-sm font-semibold text-carnation-500 mt-3 mb-2">
          Do you wish to raise foreign funds?
        </h4>
        <div className="grid grid-cols-2 gap-2 mt-2">
          {foreignList?.map((item) => (
            <div
              key={item?._id}
              className="relative w-full overflow-hidden z-0 flex"
            >
              <input
                checked={item?.checked}
                value={item?.value}
                type="radio"
                name="foreignFunds"
                // onChange={(e) => {
                //   if (e.target.value == "false") {
                //     setForeignFundsType((pre) =>
                //       pre.map((item) => ({ ...item, checked: false }))
                //     );
                //   }
                //   setForeignFundsData({});
                //   setForeignList((pre) =>
                //     pre.map((item) => ({
                //       ...item,
                //       checked: item?.checked == true ? false : true,
                //     }))
                //   );
                //   setIsForeignFunds(e.target.value == "true" ? true : false);
                //   onChangeHandler({
                //     target: {
                //       name: "isForeignFund",
                //       value: e.target.value == "true" ? true : false,
                //     },
                //   });
                // }}
                onChange={() => handleDataChange(item.value)}
                required
                className="peer absolute z-[1] cursor-pointer top-0 left-0 w-full h-full opacity-0"
              />
              <div className="w-full relative bg-slate-50 border border-slate-200 text-xs font-semibold px-2 py-2 flex items-center justify-center leading-tight text-center rounded-md text-slate-600 transition-all duration-200 peer-checked:bg-carnation-500 peer-checked:border-carnation-500 peer-checked:text-white">
                {item.name}
              </div>
            </div>
          ))}
        </div>
      </div>
      {isForeignFunds && (
        <>
          <div className="relative mt-2">
            <Select
              label={"Foreign Fund Type"}
              // errorText={"Foreign fund type is required!"}
              // errorType={foreignFundsType ? "" : "danger"}
              xPlacement={"left"}
              dropdownData={[{ name: "Select type" }, ...foreignFundsType]}
              dropdownClass={"max-h-60 w-full"}
              dropdownButtonClass={""}
              selectedValueType={"name"}
              selectedValue={
                foreignFundsType.find((item) => item?.checked === true)?.name
              }
              setCustomSelecedvalue={(value) => {
                if (value.name === "Select type") {
                  setForeignFundsType((pre) =>
                    pre.map((item) => ({ ...item, checked: false }))
                  );
                  setSelectFundType();
                  delete foreignFundsData["detailsOfForeignFund[isFcra]"];
                  delete foreignFundsData["detailsOfForeignFund[is501c3]"];
                  return;
                } else {
                  setSelectFundType(value?.name);
                  setForeignFundsData((pre) => ({
                    ...pre,
                    "detailsOfForeignFund[isFcra]":
                      value?.name ==
                      "FCRA (Foreign Contribution (Regulation) Act)"
                        ? true
                        : false,
                    "detailsOfForeignFund[is501c3]":
                      value?.name !=
                      "FCRA (Foreign Contribution (Regulation) Act)"
                        ? true
                        : false,
                  }));
                }
              }}
            />
          </div>
          {selectFundType == "FCRA (Foreign Contribution (Regulation) Act)" && (
            <>
              <div className="text-sm font-medium text-slate-500 my-1">
                FCRA Certificate
              </div>
              <div className="border border-carnation-500 rounded-md p-2 mt-3">
                <div className="relative">
                  <MultiTypesFileUploads
                    files={foreignFundsData?.fcraCertificate}
                    fileType={foreignFundsData?.fcraCertificate?.map(
                      (item) => item?.type
                    )}
                    errorText={"FCRA certificate image is required!"}
                    errorType={
                      foreignFundsData?.fcraCertificate &&
                      foreignFundsData?.fcraCertificate.length > 0
                        ? ""
                        : "danger"
                    }
                    onChange={(e) => {
                      if (foreignFundsData?.fcraCertificate) {
                        setForeignFundsData((pre) => ({
                          ...pre,
                          fcraCertificate: [
                            ...pre.fcraCertificate,
                            {
                              _id: crypto.randomUUID(),
                              type: e.target.files[0]?.type.split("/")[1],
                              file: e.target.files[0],
                              name: e.target.files[0]?.name,
                            },
                          ],
                        }));
                      } else {
                        setForeignFundsData((pre) => ({
                          ...pre,
                          fcraCertificate: [
                            {
                              _id: crypto.randomUUID(),
                              type: e.target.files[0]?.type.split("/")[1],
                              file: e.target.files[0],
                              name: e.target.files[0]?.name,
                            },
                          ],
                        }));
                      }
                    }}
                    removeFile={(file) => {
                      setForeignFundsData((pre) => ({
                        ...pre,
                        fcraCertificate: pre?.fcraCertificate?.filter(
                          (item) => item?._id != file?._id
                        ),
                      }));
                    }}
                    accept={["jpg", "jpeg", "png", "pdf"]}
                  />
                </div>
              </div>
              <div className="relative mt-3">
                <Input
                  inputPlaceholder={"Enter FCRA registration number."}
                  label={"FCRA Registration number"}
                  inputName={"detailsOfForeignFund[fcraRegistrationNumber]"}
                  inputValue={
                    foreignFundsData?.[
                      `detailsOfForeignFund[fcraRegistrationNumber]`
                    ]
                  }
                  errorText={"FCRA registration number is required!"}
                  errorType={
                    foreignFundsData?.[
                      `detailsOfForeignFund[fcraRegistrationNumber]`
                    ]
                      ? ""
                      : "danger"
                  }
                  onChange={(e) => onChangeHandler(e)}
                />
              </div>
            </>
          )}
          {selectFundType == "501©(3)" && (
            <>
              <h4 className="text-sm font-semibold text-carnation-500 mt-3 mb-2">
                501©(3) details
              </h4>
              <div className="relative mt-2">
                <Input
                  label={"Registered Name"}
                  labelClasses={"!text-xs"}
                  inputType={"text"}
                  inputPlaceholder={"Enter registered name 501(c)(3)"}
                  inputValue={
                    foreignFundsData?.[`detailsOfForeignFund[name501c3]`]
                  }
                  inputName={"detailsOfForeignFund[name501c3]"}
                  onChange={(e) => onChangeHandler(e)}
                  errorText={"Registered name is required!"}
                  errorType={
                    foreignFundsData?.[`detailsOfForeignFund[name501c3]`]
                      ? ""
                      : "danger"
                  }
                />
              </div>
              <div className="relative mt-2">
                <Input
                  label={"Employee Id"}
                  labelClasses={"!text-xs"}
                  inputType={"text"}
                  inputPlaceholder={"Enter employee id number"}
                  errorType={
                    foreignFundsData?.[`detailsOfForeignFund[employeeId]`]
                      ? ""
                      : "danger"
                  }
                  errorText={"Mmployee Id is reruired!"}
                  inputValue={
                    foreignFundsData?.[`detailsOfForeignFund[employeeId]`]
                  }
                  inputName={"detailsOfForeignFund[employeeId]"}
                  onChange={(e) => onChangeHandler(e)}
                />
              </div>
              {/* ========= Location section =========== */}

              <div className="location-for-503">
                <div className="flex gap-2 mt-2">
                  <div className="relative w-full">
                    <TextAreaAutoSize
                      errorText={"Address is required!"}
                      errorType={
                        foreignFundsData?.[
                          `detailsOfForeignFund[address][address1]`
                        ]
                          ? ""
                          : "error"
                      }
                      label={"Address"}
                      inputPlaceholder={"Enter address"}
                      inputName={"detailsOfForeignFund[address][address1]"}
                      inputValue={
                        foreignFundsData?.[
                          `detailsOfForeignFund[address][address1]`
                        ]
                      }
                      onChange={(e) => onChangeHandler(e)}
                    />
                  </div>
                </div>
              </div>

              <div className="relative mt-2">
                <Input
                  label={"Authorised representative of the organisation"}
                  labelClasses={"!text-xs"}
                  inputType={"text"}
                  inputPlaceholder={
                    "Authorised representative of the organisation"
                  }
                  errorType={
                    foreignFundsData?.[
                      `detailsOfForeignFund[authorisedRepresentative]`
                    ]
                      ? ""
                      : "danger"
                  }
                  errorText={"This field is required!"}
                  inputValue={
                    foreignFundsData?.[
                      `detailsOfForeignFund[authorisedRepresentative]`
                    ]
                  }
                  inputName={"detailsOfForeignFund[authorisedRepresentative]"}
                  onChange={(e) => onChangeHandler(e)}
                />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ForeignFunds;
