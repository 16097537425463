import { Menu } from "@headlessui/react";
import React from "react";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";

const DropdownMenu = ({ children, menu = [] }) => {
  const navigate = useNavigate();

  return (
    <div className={`relative`}>
      <Menu>
        <Menu.Button>{children}</Menu.Button>
        <div className={`relative`}>
          <Menu.Items
            className={`flex flex-col absolute mt-1 min-w-[200px] max-w-fit rounded-md bg-white py-1 -right-10 shadow-md px-1`}
          >
            {/* <div className="box w-[10px] h-[10px] rotate-45 absolute -top-1 left-[55%] -z-10 bg-white shadow-md"></div> */}
            {Array.isArray(menu) &&
              menu.length > 0 &&
              menu.map((link) => (
                <Menu.Item
                  key={link._id || crypto.randomUUID().toString()}
                  className="ui-active:bg-carnation-500 ui-active:text-white hover:bg-carnation-500 hover:!text-white ui-not-active:bg-white ui-not-active:text-black px-2 py-1 rounded-md cursor-pointer"
                >
                  <div
                    className="text-xs uppercase font-medium flex gap-2 items-center"
                    onClick={(event) =>
                      link?.function
                        ? link.function(event)
                        : navigate(link?.link)
                    }
                  >
                    {link?.icon && (
                      <span className="text-lg">
                        <i className={link?.icon}></i>
                      </span>
                    )}
                    <span>{link?.label}</span>
                  </div>
                </Menu.Item>
              ))}
          </Menu.Items>
        </div>
      </Menu>
    </div>
  );
};

export default DropdownMenu;
