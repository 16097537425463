import React, { useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react"; // Import Swiper components
import "swiper/swiper-bundle.css"; // Import Swiper styles
import SingleFundRaiser from "../items/SingleFundRaiser";
import SingleCategory from "../items/SingleCategory";
import Skeleton from "react-loading-skeleton";

const Categories = ({ data, loaded }) => {
  const swiperParams = {
    pagination: {
      clickable: true,
      dynamicBullets: true,
    },
    breakpoints: {
      0: {
        slidesPerView: 1.2,
        spaceBetween: 20,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1280: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1900: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
    },
  };

  return (
    <section className="relative overflow-hidden w-full py-20 bg-gray-100">
      <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
        <div className="space-y-10">
          <div className="space-y-4 text-center">
            <h2 className="font-Lexend text-3xl sm:text-4xl xl:text-5xl font-bold text-black">
              {/* {data?.title} */}
              Causes You Can Raise Funds For
            </h2>
            {/* <div className="text-sm xl:text-base text-slate-600 max-w-xl mx-auto">{data?.subtitle}</div> */}
          </div>

          {loaded ? (
            data &&
            data?.length > 0 && (
              <div className="relative">
                <Swiper {...swiperParams} className="pb-10 topFundraiser">
                  {data?.map((item, index) => (
                    <SwiperSlide key={index} className="!h-auto flex">
                      <SingleCategory data={item} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            )
          ) : (
            <div className="flex gap-[40px] justify-center">
              <div className="h-[100px] w-[100px]">
                <Skeleton
                  circle
                  height="100%"
                  containerClassName="avatar-skeleton"
                />
                <Skeleton />
              </div>

              <div className="h-[100px] w-[100px]">
                <Skeleton
                  circle
                  height="100%"
                  containerClassName="avatar-skeleton"
                />
                <Skeleton />
              </div>

              <div className="h-[100px] w-[100px]">
                <Skeleton
                  circle
                  height="100%"
                  containerClassName="avatar-skeleton"
                />
                <Skeleton />
              </div>
            </div>
          )}
          {/* {data && data?.length > 0 && (
            <div className="relative">
              <Swiper {...swiperParams} className="pb-10 topFundraiser">
                {data?.map((item, index) => (
                  <SwiperSlide key={index} className="!h-auto flex">
                    
                      <SingleCategory data={item} />
                    
                    
                  
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )} */}
        </div>
      </div>
    </section>
  );
};

export default Categories;
