import React, { useEffect, useState } from "react";
import Image from "../elements/Image";
import {
  AddReplyService,
  DeleteCommentsService,
  DeleteReplyService,
  EditCommentsService,
  EditReplyService,
} from "../../services/FundrisersService";
import { toast } from "react-toastify";
import CommentEditModal from "../elements/modal/CommentEditModal";
import { useSelector } from "react-redux";
import ReplyInput from "../partials/fundraiser/ReplyInput";
import DeleteConfirmationModal from "../elements/modal/DeleteModal";
import dayjs from "dayjs";
import ReplyEditModal from "../elements/modal/ReplyEditModal";
// import "react-toastify/dist/ReactToastify.css";

const SingleComment = ({ data, setallComments, userId }) => {
  const [loaded, setLoaded] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReplyModalOpen, setIsReplyModalOpen] = useState(false);
  const [isDelete, setisDelete] = useState(false);
  const [isReplyDelete, setIsReplyDelete] = useState(false);
  const [submittedValue, setSubmittedValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [replyInputValue, setreplyInputValue] = useState("");
  const [isReply, setisReply] = useState(false);
  const [replyList, setreplyList] = useState([]);
  const profileData = useSelector((state) => state.profile.profileData);
  const user = {
    _id: profileData && profileData?._id,
    email: profileData && profileData?.email,
    image: profileData && profileData?.image,
    name: profileData && profileData?.name,
  };
  const [replyValue, setreplyValue] = useState({
    user: user,
  });
  const [replyInput, setreplyInput] = useState(null);
  const [temp, settemp] = useState("");
  const [replyId, setReplyId] = useState(null);

  useEffect(() => {
    data &&
      data?.replies &&
      Array.isArray(data?.replies) &&
      data?.replies.length > 0 &&
      setreplyList(data?.replies);
  }, [data]);

  const openModal = () => {
    setIsModalOpen(true);
    setInputValue(data && data?.comment);
  };
  const openReplyModal = (value) => {
    setIsReplyModalOpen(true);
    const xxx = data && data?.replies.filter((elm) => elm?._id === value?._id);
    setreplyInputValue(xxx[0]);
  };
  const openDeleteModal = () => {
    setisDelete(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setInputValue("");
  };
  const closeReplyModal = () => {
    setIsReplyModalOpen(false);
    setreplyInputValue("");
  };
  const onCancel = () => {
    setisDelete(false);
    setLoaded(true);
  };
  const onReplyModalCancel = () => {
    setIsReplyDelete(false);
  };

  const handleModalSubmit = (value) => {
    // Handle the submitted value (e.g., save it or perform an action)
    EditCommentsService({ id: data && data?._id, comment: value }).then(
      (res) => {
        if (res?.data?.status === 200) {
          setallComments((prev) =>
            prev.map((elm) => {
              if (elm?._id === data?._id) {
                return { ...elm, comment: value };
              }
              return elm; // Add this line to return the original element for other items
            })
          );
          setInputValue(value);
          toast.success(res?.data?.message);
        } else {
          toast.error(res?.data?.message);
        }
      }
    );
  };

  const handleReplyModalSubmit = (reply) => {
    EditReplyService({
      commentId: data && data?._id,
      reply: reply?.reply,
      replyId: reply?._id,
    }).then((res) => {
      if (res?.data?.status === 200) {
        setreplyList((prev) =>
          prev.map((elm) => {
            if (elm?._id === reply?._id) {
              return { ...elm, reply: reply?.reply };
            }
            return elm; // Add this line to return the original element for other items
          })
        );
        setreplyValue(reply);
        // setreplyInputValue(reply)
        toast.success(res?.data?.message);
      } else {
        toast.error(res?.data?.message);
      }
    });
  };

  const handlCommentDelete = ({ commentId }) => {
    DeleteCommentsService({ commentId: commentId }, setLoaded).then((res) => {
      if (res?.data?.status === 200) {
        setallComments((pre) => pre.filter((elm) => elm?._id !== commentId));
        toast.success(res?.data?.message);
        setisDelete(false);
      } else {
        toast.error(res?.data?.message);
        setisDelete(false);
        setLoaded(true);
      }
    });
  };

  const handlReplyDelete = ({ commentId, replyId }) => {
    replyId &&
      DeleteReplyService(
        { commentId: commentId, replyId: replyId },
        setLoaded
      ).then((res) => {
        if (res?.data?.status === 200) {
          setreplyList((pre) => pre.filter((elm) => elm?._id !== replyId));
          toast.success(res?.data?.message);
          setIsReplyDelete(false);
        } else {
          toast.error(res?.data?.message);
          setIsReplyDelete(false);
          setLoaded(true);
        }
      });
  };

  const onChange = (e) => {
    setreplyInput(e.target.value);
    settemp(e.target.value);
    setreplyValue((prev) => ({
      ...prev,
      createdAt: new Date(),
      reply: e.target.value,
    }));
  };

  const handleSubmit = () => {
    //  profileData && setreplyList((pre) => [...pre, replyValue]);
    replyInput
      ? AddReplyService(
          { commentId: data?._id, reply: replyInput },
          setLoaded
        ).then((res) => {
          if (res?.data?.status === 200) {
            toast.success(res?.data?.message);
            // profileData && setreplyList((pre) => [...pre, replyValue]);
            setreplyList(res?.data?.data?.replies);
            setisReply(false);
            setreplyInput("");
            // setreplyValue("");
            setisReply(false);
          } else if (res?.data?.status === 400) {
            toast.error(res?.data?.message);
            // setreplyValue("")
            setisReply(false);
            setLoaded(true);
          }
        })
      : toast.error("Reply is required!");
    setreplyInput("");
  };

  return (
    <>
      <div className="relative flex gap-3 xl:gap-5 py-5">
        <div className="w-10 sm:w-16 xl:w-20 h-10 sm:h-16 xl:h-20 min-w-[40px] sm:min-w-[64px] xl:min-w-[80px] rounded-full overflow-hidden">
          <Image
            src={data?.user?.image?.url}
            alt={""}
            width={"100%"}
            height={"100%"}
            effect={"blur"}
            classes={"object-cover"}
          />
        </div>
        <div className="w-full flex-grow flex-shrink space-y-1 xl:space-y-3">
          <div className="flex items-center justify-between">
            <div>
              <h4 className="text-sm xl:text-base font-semibold text-black">
                {data?.user?.name}
              </h4>
              <div className="text-[10px] xl:text-xs text-slate-400">
                {dayjs(data?.createdAt).format("	MMM D, YYYY h:mm A")}
              </div>
            </div>
            <div className="flex gap-3">
              {/* {userId && profileData && userId === profileData?._id && ( )} */}
              {profileData && data && data?.user?._id === profileData?._id && (
                <>
                  <div
                    className="border text-sm w-[60px] text-center p-1 rounded-lg bg-blue-500 text-white cursor-pointer"
                    onClick={() => openModal()}
                  >
                    Edit
                  </div>
                  <div
                    className="border text-sm w-[60px] text-center p-1 rounded-lg bg-green-500 text-white cursor-pointer"
                    onClick={() => setisReply(!isReply)}
                  >
                    Reply
                  </div>
                  <div
                    className="border text-sm w-[60px] text-center p-1 rounded-lg bg-carnation-500 text-white cursor-pointer"
                    onClick={() => openDeleteModal()}
                  >
                    Delete
                  </div>
                </>
              )}
            </div>
          </div>

          <div
            className="text-xs sm:text-[13px] xl:text-sm text-slate-600 space-y-1 xl:space-y-2"
            dangerouslySetInnerHTML={{ __html: data?.comment }}
          ></div>

          {/* R E P L Y   S E C T I O N */}

          {replyList &&
            replyList.length > 0 &&
            replyList.map((elm, index) => (
              <div
                className="relative flex gap-3 xl:gap-5 py-5 border-t-[1px] items-center"
                key={index}
              >
                <div className="w-10 h-10 rounded-full overflow-hidden">
                  <Image
                    src={elm?.user?.image?.url}
                    alt={""}
                    width={"100%"}
                    height={"100%"}
                    effect={"blur"}
                    classes={"object-cover"}
                  />
                </div>
                <div className="w-full flex-grow flex-shrink space-y-1 xl:space-y-3">
                  <div className="flex items-center justify-between">
                    <div>
                      <h4 className="text-sm font-semibold text-black">
                        {elm?.user?.name}
                      </h4>
                      <div className="text-[10px] xl:text-xs text-slate-400">
                        {dayjs(elm?.createdAt).format("	MMM D, YYYY h:mm A")}
                      </div>
                    </div>
                    {profileData && elm?.user?._id === profileData?._id && (
                      <div className="flex gap-3">
                        <div
                          className="border text-sm w-[60px] text-center p-1 rounded-lg bg-blue-500 text-white cursor-pointer"
                          onClick={() => openReplyModal(elm)}
                        >
                          Edit
                        </div>
                        <div
                          className="border text-sm w-[60px] text-center p-1 rounded-lg bg-carnation-500 text-white cursor-pointer"
                          onClick={() => {
                            // DeleteReplyService({
                            //   replyId: elm?._id,
                            //   commentId: data?._id,
                            // }).then((res) => {
                            // });
                            setReplyId(elm?._id);
                            setIsReplyDelete(true);
                            // openDeleteModal();
                          }}
                        >
                          Delete
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="flex justify-between items-center">
                    <div
                      className="text-xs sm:text-[13px] xl:text-sm text-slate-600 space-y-1 xl:space-y-2"
                      dangerouslySetInnerHTML={{ __html: elm?.reply }}
                    ></div>
                  </div>
                </div>
              </div>
            ))}

          {isReply === true && (
            <div className="ml-[50px] border-t pt-4">
              {" "}
              <ReplyInput
                onChange={onChange}
                handleSubmit={handleSubmit}
                loaded={loaded}
                inputValue={replyInput}
              />
            </div>
          )}
        </div>
      </div>

      {/* <div className="ml-[100px]">
        <div className=" w-10 sm:w-16 xl:w-20 h-10 sm:h-16 xl:h-20 min-w-[40px] sm:min-w-[64px] xl:min-w-[80px] rounded-full overflow-hidden">
          <Image
            src={data.image}
            alt={""}
            width={"100%"}
            height={"100%"}
            effect={"blur"}
            classes={"object-cover"}
          />
        </div>
        <div className="w-full flex-grow flex-shrink space-y-1 xl:space-y-3">
          <div className="flex items-center justify-between">
            <h4 className="text-sm xl:text-base font-semibold text-black">
              {data.name}
            </h4>
            <div className="text-[10px] xl:text-xs text-slate-400">
              {data.time}
            </div>
          </div>
        </div>
      </div> */}
      <CommentEditModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onSubmit={handleModalSubmit}
        inputValue={inputValue}
        setInputValue={setInputValue}
      />
      <ReplyEditModal
        isOpen={isReplyModalOpen}
        onClose={closeReplyModal}
        onSubmit={handleReplyModalSubmit}
        inputValue={replyInputValue}
        setInputValue={setreplyInputValue}
      />
      <DeleteConfirmationModal
        isOpen={isDelete}
        onCancel={onCancel}
        loaded={loaded}
        onConfirm={() =>
          handlCommentDelete({
            commentId: data && data?._id,
            replyId: replyId && replyId,
          })
        }
      />
      <DeleteConfirmationModal
        isOpen={isReplyDelete}
        onCancel={onReplyModalCancel}
        loaded={loaded}
        onConfirm={() =>
          handlReplyDelete({
            commentId: data && data?._id,
            replyId: replyId && replyId,
          })
        }
      />
    </>
  );
};

export default SingleComment;
