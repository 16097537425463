import { apiRequest } from "../utils/async/apiUtils";

export const loadPostsList = async (params) => {
  try {
    const res = await apiRequest("get", "posts", {
      queries: {
        ...params
      },
    });

    return res.data;
  } catch (err) {
    return err?.response;
  }
};

export const loadPostDetails = async (
  id,
  setLoaded = () => {}
) => {
  setLoaded(false);
  try {
    const res = await apiRequest("get", `posts/${id}`, {});

    const data = res;
    if (data.status === 200) {
      setLoaded(true);
    }
    return res
  } catch (err) {
    setLoaded(true);
  }
};
