import React from "react";
import { useNavigate } from "react-router-dom";
import UpdateModal from "../../elements/modal/UpdateModal";
import { useState } from "react";
import { useParams } from "react-router-dom/dist/umd/react-router-dom.development";
import {
  DeleteUpdatesService,
  EditUpdatService,
  loadFundraiserDetails,
} from "../../../services/FundrisersService";
import { useEffect } from "react";
import { toast } from "react-toastify";
import Image from "../../elements/Image";
import noDataImg from "../../../assets/images/extra/No-data.svg";
import { useSelector } from "react-redux";
import DeleteConfirmationModal from "../../elements/modal/DeleteModal";

const Updates = ({ data, setData = () => {}, item, link = "/" }) => {
  const authData = useSelector((state) => state?.profile?.profileData);

  const [isOpen, setisOpen] = useState(false);
  const navigate = useNavigate();
  const [updated, setupdated] = useState({});
  const [formData, setFormData] = useState({});
  const [details, setdetails] = useState(null);
  const [loaded, setloaded] = useState(false);
  const [isDelete, setisDelete] = useState(false);
  const [updateId, setUpdateId] = useState(null);
  const [isUpdateDelete, setIsUpdateDelete] = useState(false);
  const { id } = useParams();

  const onClose = () => {
    setisOpen(false);
  };

  useEffect(() => {
    setupdated(data);
  }, [data]);
  useEffect(() => {
    setupdated({ title: formData?.title, content: formData?.description });
  }, [formData]);

  useEffect(() => {
    loadFundraiserDetails(id, setdetails, setloaded);
  }, [id]);


  const handleSubmit = () => {
    EditUpdatService(
      {
        ...formData,
        id: details && details._id,
        updateId: data && data?._id,
      },
      setloaded
    ).then((res) => {
      if (res?.data?.status === 200) {
        toast.success(res?.data?.message);
        onClose();
        setFormData({
          title: res?.data?.data?.title,
          description: res?.data?.data?.description,
        });
      } else {
        toast.error(res?.data?.message);
      }
    });
  };
  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData((pre) => ({ ...pre, [name]: value }));
  };

  useEffect(() => {
    setFormData({
      title: data && data?.title,
      description: data && data?.content,
    });
  }, [data]);
  const openDeleteModal = () => {
    setisDelete(true);
  };
  const onCancel = () => {
    setisDelete(false);
    setloaded(true);
  };

  const handlUpdateDelete = ({ updateId, id }) => {
    DeleteUpdatesService({ updateId: updateId, id: id }, setloaded).then(
      (res) => {
        if (res?.data?.status === 200) {
          setData((prevData) => {
            const filteredData = prevData?.updates?.filter((elm) => elm?._id !== updateId);
            return { ...prevData, updates: filteredData };
          });
          toast.success(res?.data?.message);
          setisDelete(false);
        } else {
          toast.error(res?.data?.message);
          setisDelete(false);
          setloaded(true);
        }
      }
    );
  };
  
  const onUpdateModalCancel = () => {
    setIsUpdateDelete(false);
  };
  // console.log(data, "datatattatatatfatfatfatf");

  return (
    <>
      {updated?.content ? (
        <>
          <div className="relative mt-4">
            <div className="flex items-center justify-between">
              <h4 className="text-lg font-semibold text-black">
                {updated?.title}
              </h4>
              {authData?._id && (
                // <span
                //   className="cursor-pointer"
                //   // onClick={() => navigate(link, { state: data })}
                //   onClick={() => setisOpen(!isOpen)}
                // >
                //   <i class="fa-light fa-pen-to-square"></i>
                // </span>
                <>
                  <div className="flex gap-3">
                    <div
                      className="border text-sm w-[60px] text-center p-1 rounded-lg bg-green-500 text-white cursor-pointer"
                      onClick={() => setisOpen(!isOpen)}
                    >
                      Edit
                    </div>
                    <div
                      className="border text-sm w-[60px] text-center p-1 rounded-lg bg-carnation-500 text-white cursor-pointer"
                      onClick={() => openDeleteModal()}
                    >
                      Delete
                    </div>
                  </div>
                </>
              )}
            </div>
            <div
              className="text-[13px] xl:text-sm text-slate-600 space-y-3"
              dangerouslySetInnerHTML={{ __html: updated?.content }}
            ></div>
          </div>
          {authData?._id && (
            <UpdateModal
              isOpen={isOpen}
              onClose={onClose}
              formData={formData}
              handleSubmit={handleSubmit}
              onChangeHandler={onChangeHandler}
              loaded={loaded}
            />
          )}
        </>
      ) : (
        <div className="flex items-center justify-center">
          <div className="w-1/2">
            <Image
              src={noDataImg}
              classes={"w-100 h-100 object-contain object-center"}
            />
          </div>
        </div>
      )}
      <DeleteConfirmationModal
        isOpen={isDelete}
        onCancel={onCancel}
        loaded={loaded}
        onConfirm={() =>
          handlUpdateDelete({
            id: data && data?.fundraiserId,
            updateId: data && data?._id,
          })
        }
      />
    </>
  );
};

export default Updates;
