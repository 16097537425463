import React, { useEffect, useState } from "react";
import PostSidebar from "../../../shared/PostSidebar";
import { useParams } from "react-router-dom/dist/umd/react-router-dom.development";
import { loadFundraiserDetails } from "../../../services/FundrisersService";
import { loadSupporterList } from "../../../services/SupportersService";
import Loader from "../../elements/loader/Loader";
import SupporterList from "../supporter/SupporterList";
import noData from "../../../assets/images/no-data-found.png";
import dayjs from "dayjs";
import NoDataFound from "../../elements/extra/NoDataFound";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import FormSkeletonLoader from "../../elements/loader/FormSkeletonLoader";

const FundraisedSupporters = () => {
  const [data, setdata] = useState(null);
  const [loaded, setloaded] = useState(true);
  const [supporters, setsupporters] = useState([]);
  const [flag, setflag] = useState(true);

  const { id } = useParams();
  useEffect(() => {
    loadFundraiserDetails(id, setdata, setloaded).then((res) => {
      setflag(false);
    });
  }, [id]);

  useEffect(() => {
    data &&
      loadSupporterList(
        { fundRaiserId: data?._id },
        setsupporters,
        setloaded
      ).then((res) => {});
  }, [data]);

  const supporterListDataa =
    Array.isArray(supporters) &&
    supporters.length > 0 &&
    supporters.map((elm, index) => ({
      _id: elm?._id,
      name: elm?.supporter?.name,
      image: elm?.supporter?.image?.url,
      donation: elm?.totalDonation,
      donationList:
        Array.isArray(elm.donations) &&
        elm.donations.length > 0 &&
        elm.donations.map((ele, index) => ({
          _id: index,
          title: ele?.transactionInfo,
          donation: ele?.donation,
          date: dayjs(ele?.createdAt).format("MMM D, YYYY"),
          transactionId: ele?.transactionId,
          method: ele?.paymentMethod,
          status: ele?.paymentStatus,
        })),
    }));

  return (
    <section className="relative  overflow-hidden w-full py-5 bg-gray-100">
      <div className="relative z-[1] w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
        <div className="w-full flex md:flex-nowrap flex-wrap gap-4 md:gap-0">
          <div className="w-full md:w-80 min-w-[320px] ">
            <PostSidebar id={id} />
          </div>

          {!loaded ? (
            // <Skeleton count={30} />
            <FormSkeletonLoader />
          ) : Array.isArray(supporters) && supporters.length < 1 ? (
            <NoDataFound />
          ) : (
            <div className="p-3 relative bg-white rounded-r-lg w-full max-w-[calc(100%-320px)])] overflow-hidden">
              {Array.isArray(supporters) && supporters.length > 0 && (
                <SupporterList data={supporterListDataa} loaded={loaded} />
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default FundraisedSupporters;
