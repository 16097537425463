import React, { useState } from "react";
import { setTitle } from "../helpers/MetaTag";
import { Link } from "react-router-dom";

import Image from "../components/elements/Image";
import Input from "../components/form/Input";
import Button from "../components/form/Button";

import Logo from "../assets/images/logo.png";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  setTitle("Crowd Funding | ForgotPassword");
  const [email, setEmail] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(email);
    if (!email) {
      toast.error("Email is required!");
    }
  };
  return (
    <>
      <div className="w-full min-h-screen bg-gray-100 flex items-center justify-center py-5 px-5">
        <div className="w-full max-w-md xl:max-w-lg bg-white py-10 px-4 sm:px-10 rounded-3xl">
          <div className="mx-auto max-w-sm">
            <div className="w-full h-16 mx-auto mb-8">
              <Link to={"/"}>
                <Image
                  src={Logo}
                  alt={"Logo"}
                  width={"100%"}
                  height={"100%"}
                  effect={"blur"}
                  classes={"object-contain"}
                />
              </Link>
            </div>
            <div className="text-center space-y-2">
              <h1 className="text-2xl xl:text-3xl font-Lexend font-semibold">
                Forgot Password?
              </h1>
              <div className="text-sm text-slate-600">
                Enter your email address to get rest link on your email address.
              </div>
            </div>
            <form className="py-8 space-y-4 mx-auto" onSubmit={handleSubmit}>
              <div className="relative">
                <Input
                  label={""}
                  labelClasses={"!text-xs"}
                  inputType={"email"}
                  inputName={"email"}
                  inputPlaceholder={"Enter your email"}
                  errorType={email ? "" : "danger"}
                  errorText={"Email is required!"}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="relative flex justify-center">
                <Button
                  buttonClasses={"w-full"}
                  buttonType={"submit"}
                  buttonLabel={"Continue"}
                  buttonLabelClasses={""}
                  buttonEffect={"filled"}
                />
              </div>
            </form>
            <div className="flex items-center justify-center mt-4 gap-2 text-sm text-slate-600">
              <span>Already have an account?</span>
              <Button
                buttonClasses={"!px-0 !h-auto !bg-transparent !rounded-none"}
                buttonHasLink={true}
                buttonLink={"/signin"}
                buttonLabel={"Signin"}
                buttonLabelClasses={
                  "font-semibold text-carnation-500 !text-sm hover:!underline"
                }
              />
              <span>here</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
