import React, { useState } from "react";
import Image from "../components/elements/Image";
import Button from "../components/form/Button";
import { Link, NavLink } from "react-router-dom";
import Dropdown from "../components/form/Dropdown";
import { classNames } from "../helpers/classNames";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";
import { useDispatch } from "react-redux";
import { selectedFundraiserType } from "../store/Header/HeaderData";
import DropdownMenu from "../components/elements/DropdownMenu";
import Modal from "../components/elements/modal/modal";

const DefaultHeader = ({ data, user }) => {
  const [isOpen, setOpen] = useState(false);
  const [menuToggle, setMenuToggle] = useState(false);
  const token = localStorage.getItem("auth_token");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dropdownMenu = [
    {
      _id: 2,
      label: "My Fundraiser",
      link: "/user/fundraised",
      icon: "fa-regular fa-fw fa-hands-holding-dollar",
    },
    {
      _id: 3,
      label: "My Donation",
      link: "/user/supported-fundraiser",
      icon: "fa-regular fa-fw fa-circle-dollar-to-slot",
    },
    {
      _id: 3,
      label: "Log Out",
      icon: "fa-regular fa-fw fa-arrow-right-from-arc",
      function: (val) => {
        setOpen(true);
      },
    },
  ];

  return (
    <>
      <header className="sticky z-30 top-0 w-full flex items-center h-24 bg-white shadow">
        <div className="w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto flex items-center">
          <div className="max-w-[200px] h-16 mr-auto">
            <Link to={"/"} className="block w-full h-full">
              <Image
                src={data.logo}
                alt={"Logo"}
                width={"100%"}
                height={"100%"}
                effect={"blur"}
                classes={"object-contain object-left"}
              />
            </Link>
          </div>
          <div
            className={classNames(
              "fixed z-10 xl:relative top-0 xl:top-auto left-0 xl:left-auto w-80 xl:w-auto h-full xl:h-auto bg-white xl:bg-transparent flex flex-col xl:flex-row xl:items-center xl:justify-end gap-8 py-5 xl:py-0 shadow-2xl xl:shadow-none xl:!translate-x-0 transition:all duration-300",
              menuToggle ? "translate-x-0" : "-translate-x-full"
            )}
          >
            <div className="flex items-center justify-center xl:hidden border-b border-slate-200 pb-5">
              <div className="h-14 mx-auto">
                <Image
                  src={data.logo}
                  alt={"Logo"}
                  width={"100%"}
                  height={"100%"}
                  effect={"blur"}
                  classes={"object-contain object-center"}
                />
              </div>
            </div>
            {data?.menu?.length > 0 && (
              <div className="block xl:flex items-center space-y-4 xl:space-y-0">
                {data?.menu?.map((item) => (
                  <div className="relative" key={item._id}>
                    {item.isParent ? (
                      <Dropdown
                        data={item.subdata}
                        buttonClass={"!border-none group !w-full xl:!w-auto"}
                        buttonLabelClass={
                          "font-medium text-slate-800 group-hover:text-carnation-500"
                        }
                        buttonIcon={true}
                        buttonLabel={item.name}
                        handleSelect={(data) => {
                          dispatch(selectedFundraiserType(data?._id));
                          navigate(`/fundraiser`);
                        }}
                        dropdownContainer={
                          "border border-slate-200 !relative xl:!absolute translate-y-0 xl:translate-y-auto w-[calc(100%-16px)] xl:w-56 shadow-none xl:shadow-lg mx-2 xl:border-none !-mt-10 xl:!mt-1 mb-14 xl:mb-0"
                        }
                      />
                    ) : (
                      <NavLink
                        onClick={() => dispatch(selectedFundraiserType(""))}
                        to={item.link}
                        className={({ isActive }) =>
                          "px-3 block font-medium hover:text-carnation-500 transition-all duration-200 " +
                          (isActive ? "text-carnation-500" : "text-slate-800")
                        }
                      >
                        {item.name}
                      </NavLink>
                    )}
                  </div>
                ))}
              </div>
            )}
            {data.options && (
              <div className="flex items-center justify-center xl:justify-end gap-4 mt-auto border-t border-slate-200 pt-5 xl:border-none xl:p-0">
                {data?.options?.actionLabel && data?.options?.actionLink && (
                  <Button
                    buttonClasses={"!px-5 !h-10"}
                    buttonHasLink={true}
                    buttonLink={data?.options?.actionLink}
                    buttonLabel={data?.options?.actionLabel}
                    buttonEffect={"filled"}
                  />
                )}

                {token === null ? (
                  <Link to={"/new-fundraising"}>
                    <div>
                      {data?.options?.signinLabel &&
                        data?.options?.signinLink && (
                          <Button
                            buttonClasses={
                              "!px-5 !h-auto !bg-transparent !rounded-none group"
                            }
                            buttonHasLink={true}
                            buttonLink={data?.options?.signinLink}
                            buttonLabel={data?.options?.signinLabel}
                            buttonLabelClasses={
                              "text-base text-slate-800 font-medium group-hover:text-carnation-500"
                            }
                          />
                        )}
                    </div>
                  </Link>
                ) : (
                  <DropdownMenu menu={dropdownMenu}>
                    <div className="w-10 h-10 min-w-[40px] overflow-hidden rounded-full border border-slate-300">
                      <Image
                        src={user.image}
                        alt={user.name}
                        width={"100%"}
                        height={"100%"}
                        effect={"blur"}
                        classes={"object-cover"}
                      />
                    </div>
                  </DropdownMenu>
                )}
              </div>
            )}
          </div>
          <div className="ml-auto xl:hidden">
            <Button
              buttonClasses={"!bg-carnation-500"}
              buttonHasLink={false}
              buttonFunction={() => setMenuToggle(!menuToggle)}
              buttonIcon={"fa-regular fa-bars"}
              buttonIconPosition={"left"}
            />
          </div>
        </div>
        <div
          className={classNames(
            "fixed z-[9] w-full h-full top-0 left-0 bg-carnation-500/75 transition-all duration-300",
            menuToggle ? "opacity-100 visible" : "opacity-0 invisible"
          )}
          onClick={() => setMenuToggle(!menuToggle)}
        ></div>
        <Modal isOpen={isOpen} setOpen={setOpen} />
      </header>
    </>
  );
};

export default DefaultHeader;
