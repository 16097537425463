import { useState } from "react";
import UserReg from "../components/elements/pagesections/register/UserReg";
import Button from "../components/form/Button";
import { ToastContainer } from "react-toastify";
import Logo from "../assets/images/logo.png";
import { Link } from "react-router-dom/dist/umd/react-router-dom.development";
import Image from "../components/elements/Image";
import VerifyOTP from "./VerifyOTP";
import { useLocation } from "react-router-dom";

const Register = () => {
  const [fillUp, setFillUp] = useState(false);
  const [regFormData, setRegFormData] = useState({ phoneCode: "+91" });

  return (
    <div className="w-full min-h-screen bg-gray-100 flex items-center justify-center   px-5 ">
      {!fillUp ? (
        <div className="w-full max-w-md xl:max-w-lg bg-white px-4 py-5 sm:px-10 rounded-3xl">
          <form className="space-y-4 mx-auto w-[400px]">
            <div className="w-full h-16 mx-auto mb-8">
              <Link to={"/"}>
                <Image
                  src={Logo}
                  alt={"Logo"}
                  width={"100%"}
                  height={"100%"}
                  effect={"blur"}
                  classes={"object-contain"}
                />
              </Link>
            </div>
            <div className="text-center space-y-2">
              <h1 className="text-2xl xl:text-3xl font-Lexend font-semibold">
                Register Form
              </h1>
              <div className="text-sm text-slate-600">
                Signup to create your account.
              </div>
            </div>
            <div className="mt-5">
              <UserReg
                fillUp={fillUp}
                setFillUp={setFillUp}
                regFormData={regFormData}
                setRegFormData={setRegFormData}
              />
            </div>
          </form>
        </div>
      ) : (
        <VerifyOTP setFillUp={setFillUp} data={regFormData} type={"signup"} />
      )}
    </div>
  );
};

export default Register;
