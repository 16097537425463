import React from "react";
import Image from "../../elements/Image";
import Button from "../../form/Button";
import { classNames } from "../../../helpers/classNames";

const Donation = ({ data }) => {

  return (
    <>
      <div className="relative bg-white rounded-3xl">
        <div className="py-6 xl:py-8 px-4 xl:px-6">
          <div className="flex sm:justify-center border-b border-slate-200 pb-4">
            <h2 className="font-semibold sm:font-medium text-black text-lg sm:text-xl 2xl:text-2xl text-center">
              {data?.title}
            </h2>
          </div>
          <div className="relative sm:flex items-center gap-5 lg:block">
            <div className="relative w-full">
              <div className="relative bg-white pt-10 pb-4 my-4">
                <div className="relative flex bg-slate-200 rounded-full w-full h-2">
                  <div
                    className="relative bg-carnation-500 rounded-full"
                    style={{ width: ((data?.raised / data?.goal) * 100).toFixed(2) + "%" }}
                  >
                    <div className="absolute w-4 h-4 rounded-full top-1/2 -translate-y-1/2 -right-2 bg-carnation-500"></div>
                    <div className="absolute w-8 h-5 rounded-full -top-7 -right-4 text-xs xl:text-sm text-center font-medium text-slate-600">
                      {((data?.raised / data?.goal) * 100).toFixed(2) + "%"}
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-between mt-3">
                  <div className="text-xs xl:text-sm font-medium text-slate-600">
                    {data?.raised?.toLocaleString() + " Raised"}
                  </div>
                  <div className="text-xs xl:text-sm font-medium text-slate-600">
                    {data?.goal?.toLocaleString() + " Goal"}
                  </div>
                </div>
              </div>
              {data.paymentModes?.length > 0 && (
                <div className="flex gap-2">
                  {data.paymentModes?.map((item, index) => (
                    <div
                      className="w-full flex-grow flex-shrink h-10 bg-slate-100 py-2 px-2 xl:px-4 overflow-hidden rounded-lg"
                      key={index + item?.name}
                      title={item?.name}
                    >
                      <Image
                        src={item?.image}
                        alt={""}
                        width={"100%"}
                        height={"100%"}
                        effect={"blur"}
                        classes={"object-contain"}
                      />
                    </div>
                  ))}
                </div>
              )}
              <div className="flex justify-end mt-6">
                <Button
                  buttonClasses={"w-full !px-8"}
                  buttonType={"submit"}
                  buttonLabel={data?.button}
                  buttonLabelClasses={""}
                  buttonEffect={"filled"}
                />
              </div>
            </div>
            {data?.instantPay && (
              <div className="w-full sm:flex relative lg:block">
                <div
                  className={classNames(
                    "relative w-full sm:w-auto lg:w-full mx-auto sm:mx-0 lg:mx-auto my-5 flex items-center justify-center before:absolute before:content-[''] before:border-slate-300",
                    "before:w-full before:h-auto before:border-t before:top-1/2 before:left-0",
                    "sm:before:w-auto sm:before:h-full sm:before:border-l sm:before:top-auto sm:before:left-1/2",
                    "lg:before:w-full lg:before:h-auto lg:before:border-t lg:before:top-1/2 lg:before:left-0"
                  )}
                >
                  <div className="bg-white relative z-10 py-0 sm:py-5 lg:px-5 lg:py-0 text-sm text-slate-600">
                    Or
                  </div>
                </div>

                <div className="relative mx-auto lg:mx-0 py-5 lg:py-0">
                  <div className="relative w-40 lg:w-60 h-40 lg:h-60 bg-gray-100 overflow-hidden mx-auto mb-5">
                    <div className="opacity-50">
                      <Image
                        src={data?.instantPay?.image}
                        alt={""}
                        width={"100%"}
                        height={"100%"}
                        effect={"blur"}
                        classes={"object-contain"}
                      />
                    </div>
                    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                      <Button
                        buttonClasses={
                          "!px-4 !py-2 !bg-white !rounded-full !h-auto shadow opacity-95 hover:opacity-100"
                        }
                        buttonType={"submit"}
                        buttonLabel={"Generate QR Code"}
                        buttonLabelClasses={
                          "text-xs font-semibold whitespace-nowrap text-slate-600"
                        }
                      />
                    </div>
                  </div>
                  <div className="text-sm font-medium text-slate-700 text-center">
                    {data.instantPay?.title}
                  </div>
                  {data.instantPay?.mode.length > 0 && (
                    <div className="flex flex-wrap justify-center gap-2 mt-4">
                      {data.instantPay?.mode.map((item, index) => (
                        <button
                          type="button"
                          className="w-10 h-10 overflow-hidden rounded-full"
                          key={index + item?.name}
                          title={item?.name}
                        >
                          <Image
                            src={item?.image}
                            alt={""}
                            width={"100%"}
                            height={"100%"}
                            effect={"blur"}
                            classes={"object-contain"}
                          />
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Donation;
