import { apiRequest } from "../utils/async/apiUtils";

export const LoadPageData = async (
    params,
    setList = () => {},
    setLoaded = () => {},
  ) => {
    setLoaded(false);
  
    try {
      const res = await apiRequest("get", "pages", {
        queries: {
          ...params,
          selectValue:
            "title, description,shortDescription, slug, image, content, status, createdAt, ordering",
        },
      });
  
      const data = res.data;
      setList(data.docs);
      setLoaded(true);
      return res
    } catch (err) {
      setLoaded(true);
    }
  };