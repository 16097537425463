import React from "react";
import { Link } from "react-router-dom";
import Image from "../elements/Image";
import Button from "../form/Button";

const SingleCategory = ({ data }) => {
  return (
    <>
      <div
        className={
          "py-6 px-4 w-full flex flex-col text-center bg-white rounded-4xl rounded-tl-none transition-all duration-200 hover:border "
        }
      >
        <div className={"w-full"}>
          {/* <Image
              src={data && data?.image[0]?.url}
              alt={""}
              width={"100%"}
              height={"100%"}
              effect={"blur"}
              classes={"object-cover"}
            /> */}
          <i
            class={`fa-regular text-carnation-500 ${data?.icon} text-[60px]`}
            width={"100%"}
            height={"100%"}
          ></i>
        </div>
        <div className="relative">
          <h4 className="text-xl uppercase font-Lexend text-slate-900 leading-tight font-bold mt-2">
            {data?.category}
          </h4>
        </div>
      </div>
    </>
  );
};

export default SingleCategory;
