import React from "react";
import noFoundImg from "../../../assets/images/not-found.svg";

const NotFound = ({title="Title"}) => {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <img src={noFoundImg} className="w-2/3 h-2/3 object-contain" />
      <div className="text-center font-bold text-md text-gray-400 my-2">{title}</div>
    </div>
  );
};

export default NotFound;
