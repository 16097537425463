import React from 'react';
import SingleTeam from "../items/SingleTeam";

const ListTeam = ({ data }) => {
  return (
    <>
      <section className="relative overflow-hidden w-full py-20 bg-gray-100">
        <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
          {data?.length > 0 &&
            <div className="relative grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-x-5 2xl:gap-x-10 3xl:gap-x-14 gap-y-8 sm:gap-y-6 lg:gap-y-14">
              {data?.map((item) => (
                <SingleTeam key={item._id} data={item} />
              ))}
            </div>
          }
        </div>
      </section>
    </>
  );
};

export default ListTeam;