import React from "react";
import { useState } from "react";
import Documents from "../components/elements/pagesections/DocsUpload/Documents";
import Media from "../components/elements/pagesections/DocsUpload/Media";
import FileUpload from "../components/form/FileUpload";
import { useParams } from "react-router-dom";
import { uploadDocoments, uploadDocomentsLists } from "../services/BankService";
import { useEffect } from "react";
import { toast } from "react-toastify";
import mediaUploadWithLoading from "../utils/async/mediaUploadWithLoading";
import Progress from "../components/elements/extra/Progress";
import PostSidebar from "../shared/PostSidebar";
import { loadFundraiserDetails } from "../services/FundrisersService";

const DocsUpload = () => {
  const { id } = useParams();
  const [activTab, setActiveTab] = useState("documents");
  const [uploadState, setUploadState] = useState(0);
  const [data, setData] = useState(null);
  const [storage, setStorage] = useState({
    loaded: true,
    data: [],
  });

  useEffect(() => {
    loadFundraiserDetails(id, setData);
  }, [id]);

  useEffect(() => {
    if (id) {
      setStorage({
        loaded: false,
        data: [],
      });
      uploadDocomentsLists({ id: data && data?._id }).then((res) => {
        if (res?.data?.status === 200) {
          setStorage({
            loaded: true,
            data: res?.data?.data,
          });
        } else {
          setStorage({
            loaded: true,
            data: [],
          });
          toast.info(res?.data?.message);
        }
      });
    }
  }, [data]);

  const uploadHandler = (value) => {
    data &&
      mediaUploadWithLoading(
        "patch",
        `upload-documents/` + data?._id,
        { "bankDocs[0]": value },
        setUploadState
      ).then((res) => {
        if (res?.data?.status === 200) {
          setStorage((pre) => ({
            ...pre,
            data: [
              Array.isArray(res?.data?.data)
                ? res?.data?.data.at(-1)
                : res?.data?.data,
              ...pre.data,
            ],
          }));
          if (
            res?.data?.data?.mediaType == "audio" ||
            res?.data?.data?.mediaType == "video"
          ) {
            setActiveTab("media");
          } else {
            setActiveTab("documents");
          }
          toast.success(res?.data?.message);
        } else {
          toast.info(res?.data?.message);
        }
      });
  };

  const tabs = [
    {
      _id: crypto.randomUUID(),
      label: "documents",
      component: (
        <Documents
          setData={setStorage}
          data={storage?.data?.filter(
            (item) =>
              !(item?.mediaType == "audio" || item?.mediaType == "video")
          )}
          loading={!storage?.loaded}
          progress={uploadState}
          onChange={(file) => uploadHandler(file)}
          fundraiserId={data && data?._id}
        />
      ),
    },
    {
      _id: crypto.randomUUID(),
      label: "media",
      component: (
        <Media
          setData={setStorage}
          data={storage?.data?.filter(
            (item) => item?.mediaType == "audio" || item?.mediaType == "video"
          )}
          loading={!storage?.loaded}
          progress={uploadState}
          onChange={(file) => uploadHandler(file)}
        />
      ),
    },
  ];

  return (
    <section className="h-full">
      <div className="relative z-[1] w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
        <div className="w-full flex md:flex-nowrap flex-wrap gap-4 md:gap-0">
          <div className="w-full md:w-80 min-w-[320px] ">
            <PostSidebar id={id} />
          </div>
          <div className="p-5 h-full w-full">
            {/* header sec */}
            <div className="flex justify-between items-center">
              <div className="tab flex gap-1 bg-carnation-200 p-1 rounded-md w-fit">
                {tabs?.map((item) => (
                  <div
                    key={item?._id}
                    onClick={() => setActiveTab(item.label)}
                    className={`${
                      item.label === activTab
                        ? "!bg-carnation-500 text-white"
                        : ""
                    } capitalize cursor-pointer px-2 py-1 rounded-md min-w-[130px] w-fit bg-white text-carnation-600 text-center`}
                  >
                    {item?.label}
                  </div>
                ))}
              </div>
              {storage && storage?.data && storage?.data?.length > 0 && (
                <FileUpload
                  onChange={(val) => uploadHandler(val)}
                  className="w-[120px]"
                />
              )}
            </div>
            {/* body sec */}
            <div className="file h-full mt-40 w-full">
              {tabs.find((item) => item.label === activTab)?.component}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DocsUpload;
