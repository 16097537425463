import React, { useEffect, useState } from "react";
import Input from "../../form/Input";
import Select from "../../form/Select";
import Button from "../../form/Button";
import SearchWithDropdown from "../../form/SearchWithDropdown";
import Steps from "./Steps";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  hasArrayData,
  nameHasArrayData,
  numReg,
  removeHtmlTags,
} from "../../../helpers";
import {
  getCountryDetails,
  loadCountries,
} from "../../../services/CountryService";
import MedicalForm from "../../elements/pagesections/fundraisingSteps/MedicalForm";
import QuilEditor from "../../elements/quilEditor/QuilEditor";
import MultiTypesFileUploads from "../../form/MultiTypesFileUploads";
import { loadCityList } from "../../../services/ProfileService";
import { useSelector } from "react-redux";
import { loadFundraiserRelationList } from "../../../services/FundrisersService";

const StepThree = ({
  steps,
  currentStep,
  setCurrentStep = () => {},
  stepsData,
  fundraiserData,
  setFundraiserData = () => {},
  setStepsData = () => {},
  formData,
  setFormData = () => {},
}) => {
  const authData = useSelector((state) => state?.profile?.profileData);
  const [loaded, setloaded] = useState(true);
  const [relationList, setrelationList] = useState([]);

  const [country, setCountry] = useState("");
  const [cityList, setcityList] = useState([]);
  const [gallery, setGallery] = useState();
  const token = stepsData?.topic?.name;
  const [countryList, setCountryList] = useState([]);
  const [currentCountry, setCurrentCountry] = useState({});
  const [coords, setCoords] = useState({
    lat: undefined,
    lon: undefined,
  });

  // get country list ===========>

  useEffect(() => {
    loadCountries().then((res) => {
      if (res?.status === 200) {
        setCountryList(
          res?.docs?.map((country) => ({
            name: "+" + country?.phone_code?.replace("+", ""),
            country_name: country?.name,
            _id: country?._id,
          }))
        );
      }
    });
  }, []);

  // get current country details ==========>

  useEffect(() => {
    if (coords?.lon && coords?.lat && countryList) {
      getCountryDetails({ lat: coords?.lat, lon: coords?.lon }).then((res) => {
        setCurrentCountry({
          name: countryList?.find(
            (item) => item?.country_name === res?.address?.country
          )?.name,
        });
        setStepsData((pre) => ({
          ...pre,
          "beneficiary[phoneCode]": countryList?.find(
            (item) => item?.country_name === res?.address?.country
          )?.name,
        }));
      });
    } else {
      setStepsData((pre) => ({
        ...pre,
        "beneficiary[phoneCode]": "+91",
        "beneficiary[address][country]": "India",
      }));
      setCountry("India");
    }
  }, [coords, countryList]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setCoords({
        lat: position?.coords?.latitude,
        lon: position?.coords?.longitude,
      });
    });
  }, []);

  // load city list =======>

  useEffect(() => {
    if (country && country.toLowerCase() === "india") {
      loadCityList({ name: country }).then((res) => {
        if (res?.data?.status === 200) {
          setcityList(res.data.docs[0]?.cities.map((city) => ({ name: city })));
        }
      });
    }
  }, [country]);

  useEffect(() => {
    setloaded(false);
    loadFundraiserRelationList().then((res) => {
      if (res?.data?.status === 200) {
        setrelationList(
          res.data?.docs?.map((item) => item.childs).flat(Infinity)
        );
        setloaded(true);
      } else {
        setloaded(true);
      }
    });
  }, [token]);

  useEffect(() => {
    if (gallery) {
      if (stepsData?.gallery) {
        setStepsData((pre) => ({
          ...pre,
          gallery: [...pre?.gallery, { ...gallery }],
        }));
      } else {
        setStepsData((pre) => ({
          ...pre,
          gallery: [{ ...gallery }],
        }));
      }
    }
  }, [gallery]);

  useEffect(() => {
    if (stepsData?.relationWithFundraiser) {
      if (
        relationList?.find(
          (item) => item?._id === stepsData?.relationWithFundraiser
        )?.name === "Myself" ||
        relationList?.find(
          (item) => item?._id === stepsData?.relationWithFundraiser
        )?.name === "self"
      ) {
        setStepsData((pre) => ({
          ...pre,
          "beneficiary[name]": authData?.name,
        }));
      }
    }
  }, [stepsData?.relationWithFundraiser, relationList]);

  const handleContinue = () => {
    const medicalForm = [];
    const medicalFormMessage = [];
    if (
      stepsData[`beneficiary[age]`] === "Below 5 years" ||
      stepsData[`beneficiary[age]`] === "6 -10 years" ||
      stepsData[`beneficiary[age]`] === "11 - 17 years"
    ) {
      medicalForm.push("beneficiary[parentName]");
      medicalFormMessage.push("Gardian name");
    } else {
      medicalForm.filter((item) => item !== "beneficiary[parentName]");
      medicalFormMessage.filter((item) => item !== "Gardian name");
    }

    if (token === "Medical Treatment") {
      if (
        hasArrayData(Object.keys(stepsData), [
          "beneficiary[name]",
          "beneficiary[address][country]",
          "beneficiary[age]",
          "beneficiary[phone]",
          ...medicalForm,
          "beneficiary[gender]",
          "beneficiary[address][city]",
          "hospitalDetails[ailment]",
          "hospitalDetails[hospitalisation]",
          "hospitalDetails[name]",
        ])
      ) {
        setCurrentStep((Number(currentStep) + 1).toString());
      } else {
        toast.info(
          `Please select or enter ${nameHasArrayData(
            Object.keys(stepsData),
            [
              "beneficiary[name]",
              "beneficiary[address][country]",
              "beneficiary[age]",
              "beneficiary[phone]",
              ...medicalForm,
              "beneficiary[gender]",
              "beneficiary[address][city]",
              "hospitalDetails[ailment]",
              "hospitalDetails[hospitalisation]",
              "hospitalDetails[name]",
            ],
            [
              "Patient's full name",
              "country",
              "Patient's age",
              "Contact number",
              ...medicalFormMessage,
              "Patient's Gender",
              "Patient's City",
              "ailment",
              "hospitalisation status",
              "Hospital name",
            ]
          )}`
        );
      }
    }
    if (token === "NGO/Charity") {
      if (hasArrayData(stepsData, ["gallery", "title", "description"])) {
        setCurrentStep((Number(currentStep) + 1).toString());
      } else {
        toast.info(
          `Please select ${nameHasArrayData(
            stepsData,
            ["gallery", "title", "description"],
            ["gallery", "title", "description"]
          )}`
        );
      }
    }
    if (token === "Other Cause") {
      if (
        hasArrayData(Object.keys(stepsData), [
          "beneficiary[name]",
          "beneficiary[phoneCode]",
          "beneficiary[address][city]",
          "beneficiary[phone]",
        ])
      ) {
        setCurrentStep((Number(currentStep) + 1).toString());
      } else {
        toast.info(
          `Please select or enter ${nameHasArrayData(
            Object.keys(stepsData),
            [
              "beneficiary[name]",
              "beneficiary[phoneCode]",
              "beneficiary[address][city]",
              "beneficiary[phone]",
            ],
            ["name", "Phone code or country", "city", "phone"]
          )}`
        );
      }
    }
    // setCurrentStep((Number(currentStep) + 1).toString());
  };

  const handleBack = () => {
    setCurrentStep((Number(currentStep) - 1).toString());
  };

  const onHandleChange = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    if (e.target.value) {
      setStepsData((preState) => ({ ...preState, [name]: value }));
    } else {
      delete stepsData[name];
      setStepsData({ ...stepsData });
    }
  };

  return (
    <>
      <div className="text-center space-y-2">
        <h1 className="text-base xl:text-lg font-Lexend font-semibold">
          Raising funds for {token} purpose
        </h1>
        <Steps data={steps} currentStep={currentStep} />
      </div>
      <form className="mt-8 space-y-4 mx-auto">
        {token === "Medical Treatment" && (
          <MedicalForm stepsData={stepsData} setStepsData={setStepsData} />
        )}

        {token === "NGO/Charity" && (
          <>
            <div className="relative">
              <div class="text-sm font-medium text-slate-500 mb-1">
                Supported Photography
              </div>
              <MultiTypesFileUploads
                files={stepsData?.gallery}
                fileType={stepsData?.gallery?.map((item) => item?.type)}
                onChange={(e) => {
                  setGallery({
                    _id: crypto.randomUUID(),
                    type: e.target.files[0]?.type.split("/")[1],
                    file: e.target.files[0],
                    name: e.target.files[0]?.name,
                  });
                }}
                removeFile={(e) => {
                  setStepsData((preState) => ({
                    ...preState,
                    gallery: preState?.gallery?.filter(
                      (item) => item?._id !== e._id
                    ),
                  }));
                }}
                accept={["jpg", "jpeg", "png"]}
                errorText={"Supported photography is required!"}
                errorType={stepsData?.gallery.length > 0 ? "" : "danger"}
              />
            </div>
            <div className="relative">
              <Input
                //inputClasses={"!border-r-0 !rounded-r-none"}
                label={"Name your fundraiser"}
                inputPlaceholder={"Enter title"}
                inputName={"title"}
                inputValue={stepsData?.title}
                errorText={"Fundraiser name is required!"}
                errorType={stepsData?.title ? "" : "danger"}
                onChange={(e) => onHandleChange(e)}
              />
            </div>
            <div className="relative">
              <QuilEditor
                label="Describe here"
                placeholder="Write your story simple & specific"
                name="description"
                value={stepsData?.description}
                onChange={(e) => {
                  if (removeHtmlTags(e.target.value)) {
                    onHandleChange(e);
                  } else {
                    delete stepsData?.description;
                    setStepsData({ ...stepsData });
                  }
                }}
                errorText={"Description is required!"}
                errorType={stepsData?.description ? "" : "danger"}
              />
            </div>
          </>
        )}

        {token === "Other Cause" && (
          <>
            <div className="relative">
              <Input
                label={"Name"}
                inputPlaceholder={"Beneficiary Name"}
                isDisabled={
                  relationList?.find(
                    (item) => item?._id === stepsData?.relationWithFundraiser
                  )?.name === "Myself" ||
                  relationList?.find(
                    (item) => item?._id === stepsData?.relationWithFundraiser
                  )?.name === "self"
                }
                inputValue={stepsData?.[`beneficiary[name]`]}
                inputName={"beneficiary[name]"}
                errorText={"Beneficiary name is required!"}
                errorType={stepsData?.[`beneficiary[name]`] ? "" : "danger"}
                onChange={(e) => {
                  if (
                    relationList?.find(
                      (item) => item?._id === stepsData?.relationWithFundraiser
                    )?.name === "Myself" ||
                    relationList?.find(
                      (item) => item?._id === stepsData?.relationWithFundraiser
                    )?.name === "self"
                  ) {
                    return;
                  } else {
                    onHandleChange(e);
                  }
                }}
              />
            </div>
            <div className="relative mt-2 w-full">
              <SearchWithDropdown
                label={"Country"}
                placeholder={"Type three letter and find country"}
                data={
                  countryList?.find(
                    (item) =>
                      item?.name === stepsData?.[`beneficiary[phoneCode]`]
                  )?.country_name
                }
                dropdownData={[
                  { name: "Select country" },
                  ...countryList?.map((item) => ({
                    name: item?.country_name,
                    country_code: item?.name,
                    _id: item?._id,
                  })),
                ]}
                setCustomSelecedvalue={(value) => {
                  setCountry(value?.name);
                  onHandleChange({
                    target: {
                      name: "beneficiary[phoneCode]",
                      value: value?.country_code,
                    },
                  });
                  onHandleChange({
                    target: {
                      name: "beneficiary[address][country]",
                      value: value?.name,
                    },
                  });
                }}
                isError={
                  stepsData?.["beneficiary[address][country]"] ? false : true
                }
                errorText={"Country name is required!"}
              />
            </div>
            <div className="relative">
              <SearchWithDropdown
                label={"City"}
                placeholder={"Type your city"}
                dropdownData={cityList}
                setCustomSelecedvalue={(value) =>
                  onHandleChange({
                    target: {
                      name: "beneficiary[address][city]",
                      value: value?.name,
                    },
                  })
                }
                data={stepsData?.[`beneficiary[address][city]`]}
                isError={
                  stepsData?.[`beneficiary[address][city]`] ? false : true
                }
                errorText={"City name is required!"}
              />
            </div>
            <div className="relative">
              <div class="text-sm font-medium text-slate-500 mb-1">
                {stepsData?.[`beneficiary[age]`] === "Below 5 years" ||
                stepsData?.[`beneficiary[age]`] === "6 -10 years" ||
                stepsData?.[`beneficiary[age]`] === "11 - 17 years"
                  ? "Gardian Contact No."
                  : "Contact No."}
              </div>
              <div className="relative flex">
                <div className="w-20 min-w-[80px]">
                  <Select
                    xPlacement={"left"}
                    dropdownData={countryList}
                    dropdownClass={"max-h-40"}
                    dropdownButtonClass={"!px-2 !border-r-none rounded-r-none"}
                    selectedValue={
                      countryList?.find(
                        (item) =>
                          item?.name === stepsData?.[`beneficiary[phoneCode]`]
                      )?._id
                    }
                    setCustomSelecedvalue={(val) =>
                      onHandleChange({
                        target: {
                          name: "beneficiary[phoneCode]",
                          value: val?.name,
                        },
                      })
                    }
                  />
                </div>
                <div className="w-full">
                  <Input
                    inputPlaceholder={"Enter your phone number"}
                    inputClasses={"!border-l-none rounded-l-none -ml-[1px]"}
                    inputName={"beneficiary[phone]"}
                    inputValue={stepsData?.[`beneficiary[phone]`]}
                    errorText={"Phone number is required!"}
                    errorType={
                      stepsData?.[`beneficiary[phone]`] ? "" : "danger"
                    }
                    onChange={(e) => {
                      if (numReg.test(e.target.value)) {
                        if (e.target.value?.length > 10) {
                          return;
                        } else {
                          onHandleChange(e);
                        }
                      } else {
                        return;
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        <div className="relative flex justify-center gap-3">
          <Button
            buttonClasses={"w-full"}
            buttonType={"button"}
            buttonLabel={"Back"}
            buttonLabelClasses={""}
            buttonEffect={"filled"}
            buttonFunction={handleBack}
          />
          <Button
            buttonClasses={"w-full"}
            buttonType={"button"}
            buttonLabel={"Save & Continue"}
            buttonLabelClasses={""}
            buttonEffect={"filled"}
            buttonFunction={handleContinue}
          />
        </div>
      </form>
      <div className="text-xs text-slate-500 mt-4 text-center">
        By continuing you agree to
        <Button
          buttonClasses={
            "!px-0 !h-auto !bg-transparent !rounded-none !inline-flex mx-1"
          }
          buttonHasLink={true}
          buttonLink={"/"}
          buttonLabel={"Terms of Service"}
          buttonLabelClasses={
            "font-semibold text-carnation-500 !text-xs hover:!underline"
          }
        />
        and
        <Button
          buttonClasses={
            "!px-0 !h-auto !bg-transparent !rounded-none !inline-flex mx-1"
          }
          buttonHasLink={true}
          buttonLink={"/"}
          buttonLabel={"Privacy Policy"}
          buttonLabelClasses={
            "font-semibold text-carnation-500 !text-xs hover:!underline"
          }
        />
      </div>
    </>
  );
};

export default StepThree;
