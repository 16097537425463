import { apiRequest } from "../utils/async/apiUtils";

export const loadJobList = async (
    setJobs = () => {},
    setLoaded = () => {},
  
  ) => {
    setLoaded(false);
  
    try {
      const res = await apiRequest("get", "job");
      const data = res.data;
      setJobs(data.docs);
      setLoaded(true);
      return res
    } catch (err) {
      setLoaded(true);
    }
  };

  export const loadJobDetails = async (
    id,
    setLoaded = () => {}
  ) => {
    setLoaded(false);
    try {
      const res = await apiRequest("get", `job/${id}`, {});
  
      const data = res;
      if (data.status === 200) {
        setLoaded(true);
      }
      return res
    } catch (err) {
      setLoaded(true);
    }
  };

  export const JobApplyService = async (
    params,
    setLoaded = () => {}
  ) => {
  
    setLoaded(false);
    try {
      const res = await apiRequest("patch", `job/apply-for-this-job/${params?.id}`, {
        body: {
        ...params?.formData
        },
      }, "multipart/form-data");
      const data = res.data;
      if (data.status === 201 || 200) {
        setLoaded(true);
      }
      return res;
    } catch (err) {
      setLoaded(true);
      return err;
    }
  };