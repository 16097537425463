import React from 'react';
import SingleApplyJobs from '../items/SingleApplyJobs';

const ListApplyJob = ({ data }) => {
    return (
        <>
            <section className="relative overflow-hidden w-full py-10 bg-gray-100">
                <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
                    <div className="space-y-10">
                        <div className="space-y-4 text-center">
                            <h2 className="font-Lexend text-3xl sm:text-4xl xl:text-5xl font-bold text-black">{data.title}</h2>
                            <div className='flex justify-center gap-4'>
                                <div className="text-sm xl:text-base text-slate-600 max-w-xl flex items-center gap-1"><i class="fa-light fa-clock"></i>{data.time}</div>
                                <div className="text-sm xl:text-base text-slate-600 max-w-xl flex items-center gap-1"><i class="fa-regular fa-location-dot"></i>{data.location}</div>
                            </div>
                        </div>
                        {data?.subdata?.length > 0 &&
                            <div className="{relative w-full}">
                                {data?.subdata?.map((item) => (
                                    <SingleApplyJobs key={item._id} data={item} />
                                ))}
                            </div>
                        }
                    </div>
                </div>
            </section>
        </>
    );
};

export default ListApplyJob;