import React from "react";
import { Route, Routes, BrowserRouter, Switch } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import AuthLayout from "./layouts/AuthLayout";
import DefaultLayout from "./layouts/DefaultLayout";
import InnerLayout from "./layouts/InnerLayout";

import Home from "./pages/Home";
import Signin from "./pages/Signin";
import NewFundraising from "./pages/NewFundraising";
import HowItWorks from "./pages/HowItWorks";
import FundraiserDetails from "./pages/FundraiserDetails";
import ForgotPassword from "./pages/ForgotPassword";
import Teams from "./pages/Teams";
import VerifyOTP from "./pages/VerifyOTP";
import Dashboard from "./pages/account/Dashboard";
import Fundraised from "./pages/account/Fundraised";
import Completed from "./pages/account/Completed";
import Supported from "./pages/account/Supported";
import Supporters from "./pages/account/Supporters";
import Transactions from "./pages/account/Transactions";
import Profile from "./pages/account/Profile";
import MedicalCrowdfunding from "./pages/MedicalCrowdfunding";
import Blog from "./pages/Blog";
import News from "./pages/News";
import BlogDetails from "./pages/BlogDetails";
import Careers from "./pages/Careers";
import JobDetails from "./pages/JobDetails";
import ApplyJobs from "./pages/ApplyJobs";
import SuccessStories from "./pages/SuccessStories";
import SuccessStoryDetails from "./pages/SuccessStoryDetails";
import WhatIsCrowdfunding from "./pages/WhatIsCrowdfunding";
import FundraisingForNgo from "./pages/FundraisingForNgo";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CancerCrowdFunding from "./pages/CancerCrowdFunding";
import EducationCrowdfunding from "./pages/EducationCrowdfunding";
import ChildWelFare from "./pages/ChildWelFare";
import TransplantCrowdfunding from "./pages/TransplantCrowdfunding";
import Register from "./pages/Register";
import Fundraiser from "./pages/Fundraiser";
import FundraiserSupporters from "./pages/FundraiserSupporters";
import FreeAuthRoute from "./routes/FreeAuthRoute";
import FundraisedEdit from "./components/partials/fundraised/FundraisedEdit";
import FundraisedDashboard from "./components/partials/fundraised/FundraisedDashboard";
import FundraisedWithdraw from "./components/partials/fundraised/FundraisedWithdraw";
import FundraisedPromotion from "./components/partials/fundraised/FundraisedPromotion";
import FundraisedSupporters from "./components/partials/fundraised/FundraisedSupporters";
import { ProtectedRoute } from "./routes/ProtectedRoute";
import Update from "./components/partials/fundraised/Update";
import Help from "./components/partials/fundraised/Help";
import PageNotFound from "./components/elements/extra/PageNotFound";
import Withdraw from "./pages/Withdraw";
import DocsUpload from "./pages/DocsUpload";
import AccountDetails from "./pages/AccountDetails";
import BankDetailsForm from "./pages/BankDetailsForm";
import { GoogleOAuthProvider } from "@react-oauth/google";
// import { Router } from "react-router-dom/dist/umd/react-router-dom.development";

const CreateRoutes = () => {
  return (
    <GoogleOAuthProvider clientId="107666679092-6m38j0e1qfage5jgksnugnaegah3rkc1.apps.googleusercontent.com">
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<DefaultLayout />}>
              <Route path="/" exact element={<Home />} />
            </Route>
            <Route path="/signin">
              <Route
                path="/signin"
                exact
                element={
                  <FreeAuthRoute title={"Sign In"}>
                    <Signin />
                  </FreeAuthRoute>
                }
              />
            </Route>
            <Route path="/register" element={<AuthLayout />}>
              <Route
                path="/register"
                exact
                element={
                  <FreeAuthRoute title={"Register"}>
                    <Register />
                  </FreeAuthRoute>
                }
              />
            </Route>
            <Route path="/new-fundraising" element={<AuthLayout />}>
              <Route
                path="/new-fundraising"
                exact
                element={
                  <ProtectedRoute>
                    <NewFundraising />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="/forgot-password" element={<AuthLayout />}>
              <Route
                path="/forgot-password"
                exact
                element={<ForgotPassword />}
              />
            </Route>
            <Route path="/verify-otp" element={<AuthLayout />}>
              <Route path="/verify-otp" exact element={<VerifyOTP />} />
            </Route>
            <Route path="/how-it-works" element={<DefaultLayout />}>
              <Route path="/how-it-works" exact element={<HowItWorks />} />
            </Route>
            <Route path="/fundraiser" element={<DefaultLayout />}>
              <Route path="/fundraiser" exact element={<Fundraiser />} />
            </Route>

            <Route path="/fundraised-edit/:id" element={<DefaultLayout />}>
              <Route
                path="/fundraised-edit/:id"
                exact
                element={
                  <ProtectedRoute>
                    <FundraisedEdit />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="/dashboard/:id" element={<DefaultLayout />}>
              <Route
                path="/dashboard/:id"
                exact
                element={
                  <ProtectedRoute>
                    <FundraisedDashboard />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="/fundraised-update/:id" element={<DefaultLayout />}>
              <Route
                path="/fundraised-update/:id"
                exact
                element={
                  <ProtectedRoute>
                    <Update />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="/fundraised-help/:id" element={<DefaultLayout />}>
              <Route
                path="/fundraised-help/:id"
                exact
                element={
                  <ProtectedRoute>
                    <Help />
                  </ProtectedRoute>
                }
              />
            </Route>
            {/* <Route path="/fundraised-withdraw/:id" element={<DefaultLayout />}>
            <Route
              path="/fundraised-withdraw/:id"
              exact
              element={
                <ProtectedRoute>
                  <FundraisedWithdraw />
                </ProtectedRoute>
              }
            />
          </Route> */}
            <Route path="/fundraised-promotion/:id" element={<DefaultLayout />}>
              <Route
                path="/fundraised-promotion/:id"
                exact
                element={
                  <ProtectedRoute>
                    <FundraisedPromotion />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route
              path="/fundraised-supporters/:id"
              element={<DefaultLayout />}
            >
              <Route
                path="/fundraised-supporters/:id"
                exact
                element={
                  <ProtectedRoute>
                    <FundraisedSupporters />
                  </ProtectedRoute>
                }
              />
            </Route>

            <Route
              path="/fundraiser-supporters/:id"
              element={<DefaultLayout />}
            >
              <Route
                path="/fundraiser-supporters/:id"
                exact
                element={
                  <ProtectedRoute>
                    <FundraiserSupporters />
                  </ProtectedRoute>
                }
              />
            </Route>

            {/* Page not found */}
            {/* <Route path="/404" element={<PageNotFound />} /> */}
            {/* <Route path="*" element={<Navigate to="/404" />} /> */}
            <Route path="*" element={<DefaultLayout />}>
              <Route path="*" element={<PageNotFound />} />
            </Route>
            {/* <Route path='/fundraiser' element={<DefaultLayout/>}>
            <Route path='/fundraiser' exact element={<FundraiserDetails/>} />
          </Route> */}
            <Route path="/fundraiser/:id" element={<DefaultLayout />}>
              <Route
                path="/fundraiser/:id"
                exact
                element={<FundraiserDetails />}
              />
            </Route>
            <Route path="/teams" element={<DefaultLayout />}>
              <Route path="/teams" exact element={<Teams />} />
            </Route>
            <Route path="/user/dashboard" element={<InnerLayout />}>
              <Route
                path="/user/dashboard"
                exact
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="/user/fundraised" element={<InnerLayout />}>
              <Route
                path="/user/fundraised"
                exact
                element={
                  <ProtectedRoute>
                    <Fundraised />
                  </ProtectedRoute>
                }
              />
            </Route>

            {/* ======================================== */}

            <Route path="/fundraised-withdraw/:id" element={<DefaultLayout />}>
              <Route
                path="/fundraised-withdraw/:id"
                exact
                element={<Withdraw />}
              />
            </Route>
            <Route path="/upload/:id" element={<DefaultLayout />}>
              <Route path="/upload/:id" exact element={<DocsUpload />} />
            </Route>
            <Route path="/bank-details/:id" element={<InnerLayout />}>
              <Route
                path="/bank-details/:id"
                exact
                element={<AccountDetails />}
              />
            </Route>
            <Route path="/bank-details-form/:id" element={<InnerLayout />}>
              <Route
                path="/bank-details-form/:id"
                exact
                element={<BankDetailsForm />}
              />
            </Route>

            {/* ======================================== */}

            {/* <Route path="/withdraw" element={<InnerLayout />}>
            <Route path="/withdraw" exact element={<Withdraw />} />
          </Route> */}
            <Route path="/user/completed-fundraised" element={<InnerLayout />}>
              <Route
                path="/user/completed-fundraised"
                exact
                element={
                  <ProtectedRoute>
                    <Completed />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="/user/supported-fundraiser" element={<InnerLayout />}>
              <Route
                path="/user/supported-fundraiser"
                exact
                element={
                  <ProtectedRoute>
                    <Supported />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="/user/supporters" element={<InnerLayout />}>
              <Route
                path="/user/supporters"
                exact
                element={
                  <ProtectedRoute>
                    <Supporters />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="/user/transactions" element={<InnerLayout />}>
              <Route
                path="/user/transactions"
                exact
                element={
                  <ProtectedRoute>
                    <Transactions />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="/user/profile" element={<InnerLayout />}>
              <Route
                path="/user/profile"
                exact
                element={
                  <ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="/medical-crowd-funding" element={<DefaultLayout />}>
              <Route
                path="/medical-crowd-funding"
                exact
                element={<MedicalCrowdfunding />}
              />
            </Route>
            <Route path="/blogs" element={<DefaultLayout />}>
              <Route path="/blogs" exact element={<Blog />} />
            </Route>
            <Route path="/blogs-details/:id" element={<DefaultLayout />}>
              <Route
                path="/blogs-details/:id"
                exact
                element={
                  <ProtectedRoute>
                    <BlogDetails />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="/news" element={<DefaultLayout />}>
              <Route path="/news" exact element={<News />} />
            </Route>
            <Route path="/careers" element={<DefaultLayout />}>
              <Route path="/careers" exact element={<Careers />} />
            </Route>
            <Route path="/news-details/:id" element={<DefaultLayout />}>
              <Route path="/news-details/:id" exact element={<BlogDetails />} />
            </Route>
            <Route path="/job-details/:id" element={<DefaultLayout />}>
              <Route path="/job-details/:id" exact element={<JobDetails />} />
            </Route>
            <Route path="/apply-jobs/:id" element={<DefaultLayout />}>
              <Route path="/apply-jobs/:id" exact element={<ApplyJobs />} />
            </Route>
            <Route path="/success-stories" element={<DefaultLayout />}>
              <Route
                path="/success-stories"
                exact
                element={<SuccessStories />}
              />
            </Route>
            <Route
              path="/success-story-details/:id"
              element={<DefaultLayout />}
            >
              <Route
                path="/success-story-details/:id"
                exact
                element={<SuccessStoryDetails />}
              />
            </Route>
            <Route path="/what-is-crowdfunding" element={<DefaultLayout />}>
              <Route
                path="/what-is-crowdfunding"
                exact
                element={<WhatIsCrowdfunding />}
              />
            </Route>
            <Route path="/fundraising-for-ngo" element={<DefaultLayout />}>
              <Route
                path="/fundraising-for-ngo"
                exact
                element={<FundraisingForNgo />}
              />
            </Route>
            <Route path="/terms-conditions" element={<DefaultLayout />}>
              <Route
                path="/terms-conditions"
                exact
                element={<TermsConditions />}
              />
            </Route>
            <Route path="/privacy-policy" element={<DefaultLayout />}>
              <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
            </Route>
            <Route path="/cancer-crowd-funding" element={<DefaultLayout />}>
              <Route
                path="/cancer-crowd-funding"
                exact
                element={<CancerCrowdFunding />}
              />
            </Route>
            <Route path="/education-crowdfunding" element={<DefaultLayout />}>
              <Route
                path="/education-crowdfunding"
                exact
                element={<EducationCrowdfunding />}
              />
            </Route>
            <Route path="/child-wel-fare" element={<DefaultLayout />}>
              <Route path="/child-wel-fare" exact element={<ChildWelFare />} />
            </Route>
            <Route path="/transplant-crowdfunding" element={<DefaultLayout />}>
              <Route
                path="/transplant-crowdfunding"
                exact
                element={<TransplantCrowdfunding />}
              />
            </Route>
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
};

export default CreateRoutes;
