// import React from 'react';

// const ApplyJobs = () => {
//     return (
//         <div>
            
//         </div>
//     );
// };

// export default ApplyJobs;



import React from 'react';
import {setTitle} from '../helpers/MetaTag';
import MainBannerPage from "../components/sections/MainBannerPage";
import ListFundraiser from "../components/sections/ListFundraiser";

import Banner from "../assets/images/banner-page.jpg";
import Patient01 from "../assets/images/patient-01.png";
import Patient02 from "../assets/images/patient-02.png";
import Patient03 from "../assets/images/patient-03.png";
import ListApplyJob from '../components/sections/ListApplyJob';

const ApplyJobs = () => {
  setTitle('Crowd Funding | Browse Fundraiser');

  // const bannerData = {
  //   image: Banner,
  //   title: "Stand Up for the Future",
  //   description: "Join the Movement to Protect Young Lives and Make a Lasting Impact",
  //   actionLink:"/",
  //   actionLabel:"Start giving monthly"
  // }

  // const fundRaiserData = {
  //   title:"Software Developer",
  //   time:"Full time",
  //   location:"West bengal",
  //   subdata: [
  //     {
  //       _id:1,
  //       image:Patient01,
  //       name:"Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  //       description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  //       raised:70000,
  //       goal: 100000,
  //       shareLink:"",
  //       shareText:"Share",
  //       donateLink:"",
  //       donateText:"Donate",
  //       smallImage:false,
  //     },

  //   ]
  // }
  const fundRaiserData = {
    title:"Software Developer",
    time:"Full time",
    location:"West bengal",
    subdata: [
      {
        // _id:1,
        // image:Patient01,
        // name:"Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        // description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        // raised:70000,
        // goal: 100000,
        // shareLink:"",
        // shareText:"Share",
        // donateLink:"",
        // donateText:"Donate",
        // smallImage:false,
      },

    ]
  }

  return (
    <>
      {/* <MainBannerPage data={bannerData}/> */}
      <ListApplyJob data={fundRaiserData}/>
      {/* <ListApplyJob/> */}
    </>
  );
};

export default ApplyJobs;










