// import React from 'react';
// import MainBannerAlt from '../components/sections/MainBannerAlt';

// const SuccessStories = () => {
//     return (
//         <>
//             <div></div>
//             <MainBannerAlt data={bannerData}/>
//         </>

//     );
// };

// export default SuccessStories;




import React, { useEffect, useState } from 'react';
import {setTitle} from '../helpers/MetaTag';
import MainBannerPage from "../components/sections/MainBannerPage";
import ListFundraiser from "../components/sections/ListFundraiser";

import Banner from "../assets/images/banner-page.jpg";
import SuccessStory01 from "../assets/images/SuccessStory01.png";
import SuccessStory02 from "../assets/images/SuccessStory02.png";
import SuccessStory03 from "../assets/images/SuccessStory03.png";
import SuccessStory04 from "../assets/images/SuccessStory04.png";
import SuccessStory05 from "../assets/images/SuccessStory05.png";
import SuccessStory06 from "../assets/images/SuccessStory06.png";
import ListSuccessStory from '../components/sections/ListSuccessStory';
import { loadStoryList } from '../services/StoriesService';
import Loader from '../components/elements/loader/Loader';

const SuccessStories = () => {
  setTitle('Crowd Funding | Browse Fundraiser');
  const [data,setData]=useState([])
  const [loaded,setLoaded]=useState(true)

  useEffect(()=>{
    loadStoryList(()=>{},setData,setLoaded,()=>{})
  },[])


  const bannerData = {
    image: Banner,
    title: "Success Stories",
    description: "It is a long established fact that a reader will be distractedwhen  layout. The point of using Lorem Ipsum is that it has a more-or-less normal as opposed to using 'Content here,",
    // actionLink:"/",
    // actionLabel:"Start giving monthly"
  }

  const SuccessStoryData = {
    title:"Our Top Fundraisers",
    subtitle:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form",
    subdata: [
      {
        _id:1,
        image:SuccessStory01,
        name:"Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit  Lorem ipsum dolor sit amet, consectetur adipiscing",
        raised:70000,
        goal: 100000,
        shareLink:"",
        shareText:"Share",
        donateLink:"",
        donateText:"Donate",
        smallImage:false,
      },
      {
        _id:2,
        image:SuccessStory02,
        name:"Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit  Lorem ipsum dolor sit amet, consectetur adipiscing",
        raised:70000,
        goal: 100000,
        shareLink:"",
        shareText:"Share",
        donateLink:"",
        donateText:"Donate",
        smallImage:false,
      },
      {
        _id:3,
        image:SuccessStory03,
        name:"Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit  Lorem ipsum dolor sit amet, consectetur adipiscing",
        raised:70000,
        goal: 100000,
        shareLink:"",
        shareText:"Share",
        donateLink:"",
        donateText:"Donate",
        smallImage:false,
      },
      {
        _id:4,
        image:SuccessStory04,
        name:"Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit  Lorem ipsum dolor sit amet, consectetur adipiscing",
        raised:70000,
        goal: 100000,
        shareLink:"",
        shareText:"Share",
        donateLink:"",
        donateText:"Donate",
        smallImage:false,
      },
      {
        _id:5,
        image:SuccessStory05,
        name:"Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit  Lorem ipsum dolor sit amet, consectetur adipiscing",
        raised:70000,
        goal: 100000,
        shareLink:"",
        shareText:"Share",
        donateLink:"",
        donateText:"Donate",
        smallImage:false,
      },
      {
        _id:6,
        image:SuccessStory06,
        name:"Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit  Lorem ipsum dolor sit amet, consectetur adipiscing",
        raised:70000,
        goal: 100000,
        shareLink:"",
        shareText:"Share",
        donateLink:"",
        donateText:"Donate",
        smallImage:false,
      },
    //   {
    //     _id:7,
    //     image:Patient03,
    //     name:"Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    //     description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    //     raised:70000,
    //     goal: 100000,
    //     shareLink:"",
    //     shareText:"Share",
    //     donateLink:"",
    //     donateText:"Donate",
    //     smallImage:false,
    //   },
    //   {
    //     _id:8,
    //     image:Patient01,
    //     name:"Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    //     description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    //     raised:70000,
    //     goal: 100000,
    //     shareLink:"",
    //     shareText:"Share",
    //     donateLink:"",
    //     donateText:"Donate",
    //     smallImage:false,
    //   },
    //   {
    //     _id:9,
    //     image:Patient02,
    //     name:"Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    //     description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    //     raised:70000,
    //     goal: 100000,
    //     shareLink:"",
    //     shareText:"Share",
    //     donateLink:"",
    //     donateText:"Donate",
    //     smallImage:false,
    //   },
    ]
  }

  return (
    <>
      <MainBannerPage data={bannerData}/>
      {
        loaded ?  <ListSuccessStory data={data}/>:<Loader/>
      }
     
    </>
  );
};

export default SuccessStories;