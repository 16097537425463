import { apiRequest } from "../utils/async/apiUtils";

export const loadStoryList = async (
  params,
  setList = () => {},
  setLoaded = () => {},
  setCount = () => {}
) => {
  setLoaded(false);

  try {
    const res = await apiRequest("get", "posts", {
      queries: {
        ...params,
        selectValue: "title,slug,image,shortDescription,author,description,type,status,ordering,createdAt,publishDate",
        type:"stories"
      },
    });
    const data = res.data;
    setList(data.docs?.filter((item)=>item?.status==1));
    setCount(data.totalDocs);
    setLoaded(true);
    return res
  } catch (err) {
    setLoaded(true);
  }
};

export const storyDetails = async (
    id,
    setDetails = () => {},
    setLoaded = () => {}
  ) => {
    setLoaded(false);
    try {
      const res = await apiRequest("get", `posts/${id}`, {});
  
      const data = res.data;
      if (data.status === 200) {
        setDetails(data.data);
        setLoaded(true);
      }
    } catch (err) {
      setLoaded(true);
    }
  };
