import React from "react";
import Tabs from "../../components/elements/Tabs";
import Team01 from "../../assets/images/team/team-01.jpg";
import Team02 from "../../assets/images/team/team-02.jpg";
import Team03 from "../../assets/images/team/team-03.jpg";
import Team04 from "../../assets/images/team/team-04.jpg";
import Team05 from "../../assets/images/team/team-05.jpg";
import Team06 from "../../assets/images/team/team-06.jpg";
import Team07 from "../../assets/images/team/team-07.jpg";
import Team08 from "../../assets/images/team/team-08.jpg";
import FundraisedList from "../../components/partials/fundraised/FundraisedList";
import { useState } from "react";
import { loadFundraiserList } from "../../services/FundrisersService";
import Loader from "../../components/elements/loader/Loader";
import { useEffect } from "react";
import { calculateRemainingDays } from "../../helpers";
import dayjs from "dayjs";
import NoDataFound from "../../components/elements/extra/NoDataFound";
import { useSelector } from "react-redux";
import Pagination from "../../components/elements/Pagination";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import BannerSkeletonLoader from "../../components/elements/loader/BannerSkeletonLoader";
import LongCardSkeletonLoader from "../../components/elements/loader/LongCardSkeletonLoader";

const Fundraised = () => {
  const [loadedFundrisedList, setLoadedFundrisedList] = useState([]);
  const [fundraisedListData, setFundraisedListData] = useState({
    loaded: true,
    data: [],
    count: [],
    hasNextPage: false,
    currentPage: 0,
  });
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const profileData = useSelector((state) => state.profile.profileData);

  // loaded list ==========>

  useEffect(() => {
    setLoadedFundrisedList((pre) => [
      ...pre,
      ...fundraisedListData?.data?.filter(
        (item) => !pre.map((elm) => elm?._id).includes(item?._id)
      ),
    ]);
  }, [fundraisedListData?.data]);

  // load loadFundraiserList ========>

  useEffect(() => {
    if (profileData?._id) {
      setFundraisedListData((pre) => ({ ...pre, loaded: false }));
      loadFundraiserList({
        status: "[1,2,4]",
        limit,
        offset,
        userId: profileData?._id,
        sortQuery: "-createdAt",
      }).then((res) => {
        if (res.status === 200) {
          setFundraisedListData({
            loaded: true,
            data:
              (Array.isArray(res.docs) &&
                res.docs?.map((elm) => ({
                  _id: elm?._id,
                  title: elm?.title,
                  image:
                    elm.gallery &&
                    elm?.gallery.length > 0 &&
                    elm?.gallery[0].url,
                  description: elm?.description,
                  status: elm?.status,
                  remainingDays:
                    calculateRemainingDays(
                      dayjs(elm?.endDate).format("YYYY-MM-DD")
                    ) || "",
                  raised: elm?.raised,
                  goal: elm?.amount,
                  commission: elm?.adminPercentage,
                  editLink: `/fundraised-edit/${elm?.slug}`,
                  dashboardLink: `/dashboard/${elm?.slug}`,
                  withdrawLink: `/fundraised-withdraw/${elm?._id}`,
                  promotionLink: `/fundraised-promotion/${elm?.slug}`,
                  viewLink: `/fundraiser/${elm?.slug}`,
                  supporters: supporters,
                }))) ||
              [],
            currentPage: res.page - 1,
            count: res.totalPages,
            hasNextPage: res.hasNextPage,
          });
        } else {
          setFundraisedListData((pre) => ({ ...pre, loaded: true }));
        }
      });
    }
  }, [limit, offset, profileData]);

  const supporters = [
    { _id: 1, name: "Janet R. Hoffman", image: Team02 },
    { _id: 2, name: "Jeff C. Casey", image: Team01 },
    { _id: 3, name: "Jeremy A. Burrell", image: Team04 },
    { _id: 4, name: "Stephanie F. Sanfilippo", image: Team03 },
    { _id: 5, name: "Carl V. Powers", image: Team05 },
    { _id: 6, name: "Ray N. Hand", image: Team06 },
    { _id: 7, name: "Richard S. Bryden", image: Team07 },
    { _id: 8, name: "Lester B. Heath", image: Team08 },
  ];

  // const loadOnScrollHandler = (e) => {
  //   const isBottom =
  //     Math.ceil(e.target.scrollTop + e.target.clientHeight) >=
  //     e.target.scrollHeight - 2;

  //   if (isBottom && fundraisedListData?.hasNextPage) {
  //     setOffset(limit + offset);
  //   }
  // };

  const handlePageChange = (e) => {
    setOffset(e.selected * limit);
  };

  return (
    <>
      {!fundraisedListData?.loaded ? (
        <>
          <LongCardSkeletonLoader />
          <LongCardSkeletonLoader />
          <LongCardSkeletonLoader />
          <LongCardSkeletonLoader />
          <LongCardSkeletonLoader />
        </>
      ) : (
        <>
          <div
            className={`relative w-full`}
            // ${
            //   loadedFundrisedList?.length > 0
            //     ? "overflow-y-scroll h-screen fundraised-list"
            //     : ""
            // }
            // onScroll={(e) => loadOnScrollHandler(e)}
          >
            {/* <Tabs tabs={tabData} design={"style"} /> */}

            {/* ================================================== */}

            {fundraisedListData?.loaded &&
              fundraisedListData?.data?.length == 0 && <NoDataFound />}
            {fundraisedListData?.loaded &&
              Array.isArray(fundraisedListData?.data) &&
              fundraisedListData?.data?.length > 0 && (
                <FundraisedList data={fundraisedListData?.data} />
              )}
          </div>
          {/* {fundraisedListData?.currentPage > 0 &&
      fundraisedListData?.hasNextPage && ( */}
          <div className="py-5 w-full flex justify-center items-center">
            <Pagination
              onPageChange={handlePageChange}
              pageCount={fundraisedListData?.count}
              limit={limit}
              currentPage={fundraisedListData?.currentPage}
              isLoading={fundraisedListData?.loaded}
            />
          </div>
          {/* )} */}
        </>
      )}
    </>
  );
};

export default Fundraised;
