import React from "react";

const TextArea = ({
  register = () => {},
  onChange = () => {},
  placeholder = "Placeholder",
  name = "",
  rules = {
    required: false,
    message: "",
  },
  errors,
  textareaClasses="",
  label="",
  labelClass=""
}) => {
  return (
    <div>
      {label && (
        <div className={"text-sm font-medium text-slate-500 mb-1 "+labelClass}>{label}</div>
      )}
      <div className="relative">
        <textarea
          className={`${errors && Object.keys(errors)?.length > 0 && errors[name]?"border-carnation-400":"border-slate-200 "} w-full h-28 rounded-md bg-slate-50 border px-3 text-sm text-slate-600 !ring-0 !outline-0 focus:border-carnation-200 focus:bg-carnation-50 transition-all duration-200 `+textareaClasses}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          {...register(name, { required: rules?.required })}
        />
      </div>
      <div className="mt-2">
        {errors && Object.keys(errors)?.length > 0 && errors[name] && (
          <span className="text-xs text-carnation-500 flex gap-2">
            <span>
              <i class="fa-light fa-circle-info"></i>
            </span>
            <span>{rules?.message}</span>
          </span>
        )}
      </div>
    </div>
  );
};

export default TextArea;
