import React from "react";
import Select from "../../../../form/Select";
import { loadCauseList } from "../../../../../services/FundrisersService";
import { useState } from "react";
import { useEffect } from "react";
import Input from "../../../../form/Input";
import NgoContact from "../../fundraisingSteps/NgoContact";
import NgoMedical from "../../fundraisingSteps/NgoMedical";

const Ngo = ({
  data,
  setData = () => {},
  countryList = [],
  currentCountry,
  editData,
}) => {
  const [causeData, setCauseData] = useState([]); // for dropdown list

  // load cause list ============>

  useEffect(() => {
    loadCauseList({ limit: 100, offset: 0, type: 4 }).then((res) => {
      if (res.data.status === 200) {
        setCauseData(res?.data.docs);
      }
    });
  }, []);

  const onHandleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if(e.target.value){
      setData((pre) => ({ ...pre, [name]: value }));
    }else{
      delete data[name]
      setData({...data})
    }
  };


  return (
    <>
      <div className="relative w-full mb-3">
        <Input
          // inputClasses={"!border-r-0 !rounded-r-none"}
          label={"Name of the organisation"}
          inputPlaceholder={"Enter name"}
          inputName={"beneficiary[name]"}
          inputValue={data?.[`beneficiary[name]`]}
          onChange={(e) => onHandleChange(e)}
        />
      </div>
      <div className="flex gap-3 mb-3">
        <div className="relative w-full">
          <Input
            // inputClasses={"!border-r-0 !rounded-r-none"}
            label={"Funds raised will help"}
            inputPlaceholder={"Enter name"}
            inputName={"beneficiary[toHelp]"}
            inputValue={data?.[`beneficiary[toHelp]`]}
            onChange={(e) => onHandleChange(e)}
          />
        </div>
        <div className="relative w-full">
          <Input
            // inputClasses={"!border-r-0 !rounded-r-none"}
            label={"Based out of"}
            inputPlaceholder={"Enter city name"}
            inputName={"beneficiary[address][address1]"}
            inputValue={data?.[`beneficiary[address][address1]`]}
            onChange={(e) => onHandleChange(e)}
          />
        </div>
      </div>
      <div className="flex gap-3 mb-3">
        <div className="relative w-full">
          <Select
            xPlacement={"left"}
            label={"Please Choose a Cause"}
            dropdownData={[{ name: "Select a cause" }, ...causeData]}
            dropdownClass={"max-h-60 w-full"}
            dropdownButtonClass={""}
            selectedValueType={"name"}
            selectedValue={data?.[`cause[name]`]}
            setCustomSelecedvalue={(value) => {
              if (value?.name === "Select a cause") {
                delete data[`cause[_id]`];
                delete data[`cause[name]`];
                setData({...data})
              } else {
                onHandleChange({
                  target: { name: "cause[name]", value: value?.name },
                });
                onHandleChange({
                  target: { name: "cause[_id]", value: value?._id },
                });
              }
            }}
          />
        </div>
      </div>
      {data?.["cause[name]"] === "Medical" && (
        <NgoMedical stepsData={data} setStepsData={setData} />
      )}
      <NgoContact stepsData={data} setStepsData={setData} editData={editData} />
    </>
  );
};

export default Ngo;
