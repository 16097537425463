import { apiRequest } from "../utils/async/apiUtils";

export const loadHowItWorksList = async (
  setHowItWorks = () => {},
  setLoaded = () => {}
) => {
  setLoaded(false);

  try {
    const res = await apiRequest("get", "topics", {
      queries: {
        selectValue:
          "name,icon,image,type,description,status,ordering,createdAt",
        type: 3,
      },
    });
    const data = res.data;
    setHowItWorks(data.docs?.filter((item)=>item?.status==1));
    setLoaded(true);
    return res;
  } catch (err) {
    setLoaded(true);
  }
};

export const addHowItWorks = async (params, setLoaded = () => {}) => {
  setLoaded(false);

  try {
    const res = await apiRequest("post", "enquiry/add", {
      body: { ...params },
    });
    setLoaded(true);
    return res;
  } catch (error) {
    setLoaded(false);
    return error.response;
  }
};
