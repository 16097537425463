import React from "react";
import TailwindModal from "../components/elements/modal/TailwindModal";
import Button from "../components/form/Button";
import { useState } from "react";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";
import { useParams } from "react-router-dom";

const AccountDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [modal, setModal] = useState({
    isModalOpen: false,
    details: "",
  });
  const details_list = [
    {
      _id: crypto.randomUUID(),
      heading: "Provide recipient details",
      sub_heading: "You’ll need recipient’s ID for KYC verification",
      icon: require("../assets/images/withdraw-icon-3.png"),
      info: [
        {
          _id: crypto.randomUUID(),
          label: "See valid recipient ID proofs",
          info: {
            heading: "Valid recipient ID proofs",
            description:
              "List of all valid recipient’s ID proof for KYC verification include PAN card, Aadhaar, Driving license, Passport, Voter ID",
          },
        },
      ],
    },
    {
      _id: crypto.randomUUID(),
      heading: "Provide account details",
      sub_heading:
        "Based on the location of your bank account, you’ll need one or more of the following",
      icon: require("../assets/images/accoun-details.png"),
      info: [
        {
          _id: crypto.randomUUID(),
          label: "IFSC code",
          info: {
            heading: "IFSC code",
            description:
              "It is an 11-digit alpha-numeric code assigned by the RBI to identify every bank branch uniquely. IFSC Code is mandatory to transfer any funds to a Bank Account in India.",
          },
        },
        {
          _id: crypto.randomUUID(),
          label: "SWIFT code",
          info: {
            heading: "Swift code",
            description:
              "It may be eight to eleven digits code to identify every bank uniquely SWIFT Code is mandatory to transfer USD donations to any bank account located outside USA.",
          },
        },
        {
          _id: crypto.randomUUID(),
          label: "Routing no.",
          info: {
            heading: "Routing Number (Applicable for only US Bank Accounts)",
            description:
              "Your bank routing number is a nine-digit code that’s based on the U.S. Bank location where your account was opened. It’s the first set of numbers printed on the bottom of your checks, on the left side.",
          },
        },
      ],
    },
    {
      _id: crypto.randomUUID(),
      heading: "Provide relationship details",
      sub_heading:
        "You’ll need a relationship proof that displays both beneficiary & recipient’s name",
      icon: require("../assets/images/relation-icon.png"),
      info: [
        {
          _id: crypto.randomUUID(),
          label: "See all valid relationship proof docs.",
          info: {
            heading: "Valid relationship proof documents",
            description: (
              <ul className="pl-4">
                <li className="list-disc">
                  <div className="text-sm text-carnation-500 font-semibold">
                    What is a relationship proof document?
                  </div>
                  <div className="my-4 text-slate-500 text-[14px]">
                    A Valid legal document that establishes relationship and
                    displays both beneficiary & recipient's name. Eg:
                    PAN/Aadhaar/Driving License/Passport/Voter ID
                  </div>
                </li>
                <li className="list-disc">
                  <div className="text-sm text-carnation-500 font-semibold">
                    What if I don’t have any relationship proof document?
                  </div>
                  <div className="my-4 text-slate-500 text-[14px]">
                    Simply upload a valid ID proof of the beneficiary,
                    self-attested (i.e signed) by them. These include
                    beneficiary's PAN/Aadhaar/Driving License/Passport/Voter ID
                    Note: Transfer requests in this case will undergo additional
                    operational checks and might take longer than usual.
                  </div>
                </li>
                <li className="list-disc">
                  <div className="text-sm text-carnation-500 font-semibold">
                    What if the beneficiary is not an individual, but a group or
                    a project?
                  </div>
                  <div className="my-4 text-slate-500 text-[14px]">
                    Simply upload a valid ID proof of the campaign organizer,
                    self-attested (signed). These include campaign organizer's
                    PAN/Aadhaar/Driving License/Passport/Voter ID
                  </div>
                </li>
              </ul>
            ),
          },
        },
      ],
    },
  ];
  return (
    <section>
      <div className="py-10">
        <div className="text-center text-sm text-slate-500">
          Add a recipient account in easy steps
          <br /> & transfer funds when you wish
        </div>
        <div className="w-1/2 p-8 m-auto mt-5 rounded-md shadow bg-carnation-50">
          {Array.isArray(details_list) &&
            details_list?.map((item, i) => (
              <div
                key={item?._id}
                className={`sec ${details_list.length - 1 === i ? "" : "mb-6"}`}
              >
                <div className="flex gap-3 items-center">
                  <div className="icon w-12 h-12 min-w-[3rem] min-h-[3rem] flex items-center justify-center text-2xl bg-carnation-100 rounded-full overflow-hidden">
                    <img
                      src={item?.icon}
                      alt="icon"
                      className="w-2/3 h-2/3 object-contain object-center"
                    />
                  </div>
                  <div className="text-md font-semibold">{item?.heading}</div>
                </div>
                <div className="text-slate-500 font-normal text-sm my-2">
                  {item?.sub_heading}
                </div>
                <div className="flex flex-wrap gap-2">
                  {Array.isArray(item?.info) &&
                    item?.info?.map((info) => (
                      <div
                        className="font-semibold bg-carnation-500 p-1 px-3 rounded-md text-white text-[12px] cursor-pointer flex gap-2 items-center"
                        onClick={() =>
                          setModal({ isModalOpen: true, details: info?.info })
                        }
                      >
                        <span>
                          <i className="fa-regular fa-circle-info"></i>
                        </span>
                        <span>{info?.label}</span>
                      </div>
                    ))}
                </div>
              </div>
            ))}
          <div className="mt-5">
            <Button
              buttonClasses={"w-full"}
              buttonLabel={"Let’s begin"}
              buttonHasLink={true}
              buttonLink={`/bank-details-form/${id}`}
            />
          </div>
        </div>
      </div>
      <TailwindModal
        isOpen={modal?.isModalOpen}
        setOpen={() => setModal({ isModalOpen: false, details: "" })}
      >
        <div className="text-md text-carnation-500 font-semibold">
          {modal?.details?.heading}
        </div>
        <div className="mt-4 text-slate-500 text-[14px]">
          {modal?.details?.description}
        </div>
      </TailwindModal>
    </section>
  );
};

export default AccountDetails;
