import React from "react";
import SingleCause from "../items/SingleCause";

const Cause = ({ data, loaded }) => {
  return (
    <>
      <section className="relative overflow-hidden w-full py-20 bg-white">
        <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
          <div className="space-y-10">
            <div className="space-y-4 text-center">
              <h2 className="font-Lexend text-3xl sm:text-4xl xl:text-5xl font-bold text-black">
                How It Works
              </h2>
              {/* <div className="text-sm xl:text-base text-slate-600 max-w-xl mx-auto">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium</div> */}
            </div>
            {data?.length > 0 && (
              <div className="relative grid grid-cols-2 lg:grid-cols-4 gap-5 lg:gap-3 xl:gap-5">
                {data?.map((item) => (
                  <SingleCause key={item._id} data={item} />
                ))}
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Cause;
