import React, { Fragment, useEffect, useState } from "react";
import Image from "../components/elements/Image";
import Button from "../components/form/Button";
import { Link, NavLink } from "react-router-dom";
import { classNames } from "../helpers/classNames";
import { UpdateImage } from "../services/ProfileService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { fetchProfile } from "../store/Profile/profileData";

const Sidebar = ({ user, data, setActive, isActive }) => {
  const [menuToggle, setMenuToggle] = useState(false);
  const [imagee, setimage] = useState(null);
  const [loaded, setloaded] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    imagee &&
      UpdateImage(imagee, setloaded).then((res) => {
        if (res?.data?.status === 200) {
          toast.success(res?.data?.message);
          setTimeout(() => {
            dispatch(fetchProfile());
          }, 1000);

          // setimage(null);
        } else {
          toast.error(res?.data?.message);
        }
      });
  }, [imagee]);

  const onInputClick = (event) => {
    event.target.value = "";
  };

  const imageType = ["jpg", "jpeg", "png", "webp", "ico"];
  return (
    <>
      <div className="relative border-r border-slate-200 w-full bg-white divide-y xl:rounded-l-lg  divide-slate-100">
        <div className="relative w-full py-6 px-6 space-y-4 rounded-lg  flex z-10">
          <div className="w-full">
            <div className="w-32 h-32 relative">
              <div className="w-full h-full overflow-hidden rounded-full border-2 border-carnation-500">
                {/* {imagee !== user?.image ? (
                  <Image
                    src={user && user?.image}
                    alt={"Logo"}
                    width={"100%"}
                    height={"100%"}
                    effect={"blur"}
                    classes={"object-contain object-center"}
                  />
                ) : (
                  <Image
                    src={URL.createObjectURL(imagee)}
                    alt={"Logo"}
                    width={"100%"}
                    height={"100%"}
                    effect={"blur"}
                    classes={"object-contain object-center"}
                  />
                )} */}
                <Image
                  src={
                    imagee === null ? user?.image : URL.createObjectURL(imagee)
                  }
                  alt={"Logo"}
                  width={"100%"}
                  height={"100%"}
                  effect={"blur"}
                  classes={"object-contain object-center"}
                />
              </div>
              <div className="absolute bottom-1 right-1 w-8 h-8 overflow-hidden border-2 border-carnation-500 text-slate-800 bg-white rounded-full flex items-center justify-center text-base">
                <input
                  type="file"
                  className="absolute top-0 right-0 opacity-0 w-[1000px] h-full z-[1] cursor-pointer"
                  onChange={(e) => {
                    imageType.includes(e?.target?.files[0]?.type?.split("/")[1])
                      ? setimage(e.target.files[0])
                      : toast.info(
                          "Please select only jpg, jpeg, png or webp type image."
                        );
                  }}
                  onClick={onInputClick}
                />
                <i className="fa-regular fa-fw fa-camera"></i>
              </div>
            </div>
            <div className="space-y-4">
              <h2 className="text-2xl font-bold font-Lexend text-black">
                {user && user?.name}
              </h2>
              <div className="space-y-2">
                <a
                  href={"tel:" + user && user?.phone}
                  className="flex items-center gap-2 text-sm"
                >
                  <i className="fa-solid fa-fw fa-phone text-carnation-500"></i>
                  <span className="text-sm text-slate-700 font-medium">
                    {user && user?.phoneCode + "-" + user?.phone}
                  </span>
                </a>
                <a
                  href={"mailto:" + user && user?.email}
                  className="flex items-center gap-2 text-sm"
                >
                  <i className="fa-solid fa-fw fa-envelope text-carnation-500"></i>
                  <span className="text-sm text-slate-700 font-medium">
                    {user && user?.email}
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="ml-auto md:hidden">
            <Button
              buttonClasses={
                "!bg-white border border-none !text-slate-500 !px-0 !text-2xl"
              }
              buttonHasLink={false}
              buttonFunction={() => setMenuToggle(!menuToggle)}
              buttonIcon={"fa-regular fa-bars"}
              buttonIconPosition={"left"}
            />
          </div>
          <div
            className={classNames(
              "fixed z-[9] w-full h-full top-0 left-0 bg-carnation-500/75 transition-all duration-300",
              menuToggle ? "opacity-100 visible" : "opacity-0 invisible"
            )}
            onClick={() => setMenuToggle(!menuToggle)}
          ></div>
        </div>
        {data && data.length > 0 && (
          <div
            className={classNames(
              "fixed z-10 xl:relative top-24 xl:top-auto left-0 xl:left-auto w-80 xl:w-auto h-[calc(100vh-6rem)] xl:h-auto bg-white xl:bg-transparent flex flex-col xl:flex-row xl:items-center xl:justify-end gap-8 py-5 shadow-2xl xl:shadow-none xl:!translate-x-0 transition:all duration-300",
              menuToggle ? "translate-x-0" : "-translate-x-full"
            )}
          >
            <div className="relative px-2 space-y-1 w-full">
              {data.map((item) => (
                <Fragment key={item._id}>
                  {item.separator ? (
                    <div className="border-t border-slate-200"></div>
                  ) : item.link ? (
                    <NavLink
                      to={item.link}
                      className={({ isActive }) =>
                        "group flex items-center py-2 px-4 gap-3 rounded-md text-slate-900 font-medium transition-all duration-200 hover:text-white hover:bg-carnation-500 " +
                        (isActive
                          ? "text-white bg-carnation-500"
                          : "text-slate-800 bg-white")
                      }
                    >
                      {({ isActive }) => (
                        <>
                          <div
                            className={
                              "w-5 h-5 flex items-center justify-center text-lg group-hover:text-carnation-100 transition-all duration-200 " +
                              (isActive
                                ? "text-carnation-100"
                                : "text-carnation-500")
                            }
                          >
                            <i className={"fa-regular fa-fw " + item.icon}></i>
                          </div>
                          <div className="text-xs uppercase">{item.label}</div>
                        </>
                      )}
                    </NavLink>
                  ) : (
                    <button
                      type="button"
                      className={classNames(
                        "group w-full flex items-center py-2 px-4 gap-3 rounded-md text-slate-900 font-medium transition-all duration-200 hover:text-white hover:bg-carnation-500"
                      )}
                      onClick={item.onclick}
                    >
                      <div className="w-5 h-5 flex items-center justify-center text-lg text-carnation-500 group-hover:text-carnation-100 transition-all duration-200">
                        <i className={"fa-regular fa-fw " + item.icon}></i>
                      </div>
                      <div className="text-xs uppercase">{item.label}</div>
                    </button>
                  )}
                </Fragment>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default Sidebar;
