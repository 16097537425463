import React, { useEffect } from "react";
import InputFile from "../form/InputFile";
import { Link } from "react-router-dom";
import Input from "../../components/form/Input";
import Select from "../form/Select";
import { useState } from "react";
import ImageUploader from "../form/ImageUploader";
import {
  useNavigate,
  useParams,
} from "react-router-dom/dist/umd/react-router-dom.development";
import { JobApplyService, loadJobDetails } from "../../services/CareerService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../elements/loader/Loader";

const SingleApplyJobs = (data) => {
  const [loaded, setloaded] = useState(true);
  const [details, setDetails] = useState({});
  const dropdownData = [
    { name: "Choose Youre Gender", value: "" },
    { name: "Male", value: "male" },
    { name: "Female", value: "female" },
  ];
  const dropdownDataList = [
    { name: "+91" },
    { name: "+1" },
    { name: "+44" },
    { name: "+93" },
    { name: "+355" },
    { name: "+213" },
    { name: "+1684" },
    { name: "+376" },
    { name: "+244" },
    { name: "+1264" },
    { name: "+1268" },
    { name: "+54" },
  ];
  const dropdownDataMonth = [
    { name: "0" },
    { name: "1" },
    { name: "2" },
    { name: "3" },
    { name: "4" },
    { name: "5" },
    { name: "6" },
    { name: "7" },
    { name: "8" },
    { name: "9" },
    { name: "10" },
    { name: "11" },
    { name: "12" },
  ];
  const dropdownDataCurrency = [
    { name: "INR" },
    { name: "USD" },
    { name: "EURO" },
    { name: "ANG" },
    { name: "ERN" },
    { name: "GEL" },
    { name: "GIP" },
    { name: "GNF" },
    { name: "HKD" },
    { name: "IDR" },
    { name: "ILS" },
    { name: "JPY" },
    { name: "KRW" },
  ];

  useEffect(() => {
    loadJobDetails(id, setloaded).then((res) => {
      setDetails(res?.data.data);
    });
  }, []);

  //All data container
  const [formData, setformData] = useState({});
  const handleFormData = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setformData((pre) => ({ ...pre, [name]: value }));
  };

  useEffect(() => {
    handleFormData({
      target: { name: "currentSalary[currency]", value: "INR" },
    });
    handleFormData({
      target: { name: "expectedSalary[currency]", value: "INR" },
    });
    handleFormData({ target: { name: "phoneCode", value: "+91" } });
  }, []);
  const { id } = useParams();
  const navigate = useNavigate();
  const jobId = details && details?._id;

  const handleSubmit = () => {
    jobId &&
      JobApplyService({ formData: formData, id: jobId }, setloaded).then(
        (res) => {
          if (res.data.status === 200 || res.data.status === 201) {
            toast.success(res.data.message);
            setTimeout(function () {
              navigate(`/job-details/${id}`);
            }, 2000);
          } else {
            toast.error(res.data.message);
          }
        }
      );
  };

  return (
    <>
      <div className="relative bg-white w-8/12 p-4 flex flex-col ml-auto mr-auto rounded-xl">
        <form className="p-3">
          <div className="relative w-full ">
            <div className="flex justify-between">
              <div className="relative w-full">
                <div className="text-slate-500 hover:text-carnation-500 transition-all mb-8">
                  <Link to={"/careers"}>
                    <i class="fa-light fa-arrow-left mr-2"></i>Back to all job
                    openings
                  </Link>
                </div>
                <div className="w-full ">
                  <div className="relative">
                    <ImageUploader
                      label={"Applicant's Image"}
                      files={formData?.image}
                      onChange={(e) =>
                        handleFormData({
                          target: { name: "image", value: e.target.files[0] },
                        })
                      }
                      removeFile={() => {
                        delete formData?.image;
                        setformData({ ...formData });
                      }}
                      labelClasses={"!text-xs pb-1"}
                    />
                  </div>
                </div>
                <div className="relative mb-4">
                  <div className="relative w-full">
                    <div className="relative w-full mt-6 flex gap-4">
                      <div className="relative w-full">
                        <Input
                          label={"Full Name"}
                          labelClasses={"!text-xs pb-1"}
                          inputType={"text"}
                          inputPlaceholder={"Enter your Full Name"}
                          errorType={""}
                          inputName={"name"}
                          onChange={(e) => handleFormData(e)}
                          className={
                            "capitalize text-sm placeholder:text-slate-300 rounded-md outline-none border border-solid border-slate-200 w-full "
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="relative w-full">
                    <div className="relative w-full mt-6 flex gap-4">
                      <div className="relative w-full">
                        <div className="capitalize text-xs text-slate-500 pb-1">
                          Gender
                        </div>
                        <div className="relative w-full">
                          <Select
                            xPlacement={"left"}
                            dropdownData={dropdownData}
                            dropdownClass={"max-h-40"}
                            dropdownButtonClass={"!px-2 !border-r-none rounded"}
                            selectedValue={""}
                            setCustomSelecedvalue={(value) =>
                              handleFormData({
                                target: { name: "gender", value: value?.value },
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="relative w-full">
                        <Input
                          label={"Email"}
                          labelClasses={"!text-xs "}
                          inputType={"text"}
                          inputPlaceholder={"Enter your Email"}
                          errorType={""}
                          inputName={"email"}
                          onChange={(e) => handleFormData(e)}
                          className={
                            "capitalize text-sm placeholder:text-slate-300 rounded-md outline-none border border-solid border-slate-200 w-full   "
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <div className="relative mt-6 w-6/12">
                      <div className="capitalize text-xs text-slate-500 pb-1">
                        Experience
                      </div>
                      <div className="flex gap-2">
                        <div className="flex gap">
                          <div className="w-18 min-w-[80px]">
                            <Select
                              xPlacement={"left"}
                              dropdownData={dropdownDataMonth}
                              dropdownClass={"max-h-40"}
                              dropdownButtonClass={
                                "!px-2 !border-r-none rounded-r-none"
                              }
                              selectedValue={""}
                              setCustomSelecedvalue={(value) =>
                                handleFormData({
                                  target: {
                                    name: "experience[month]",
                                    value: value?.name,
                                  },
                                })
                              }
                            />
                          </div>
                          <div className="w-full">
                            <Input
                              inputType={"tel"}
                              inputPlaceholder={"Month"}
                              inputClasses={
                                "!border-l-none rounded-l-none -ml-[1px]"
                              }
                              isDisabled={true}
                            />
                          </div>
                        </div>
                        <div className="flex">
                          <div className="w-18 min-w-[80px]">
                            <Input
                              xPlacement={"left"}
                              inputPlaceholder={"eg: 1,2,3.."}
                              inputClasses={
                                "!border-l-none rounded rounded-r-none -ml-[1px]"
                              }
                              inputName={"experience[year]"}
                              onChange={(e) => handleFormData(e)}
                            />
                          </div>
                          <div className="w-full">
                            <Input
                              inputType={"Year"}
                              inputPlaceholder={"Year"}
                              inputClasses={
                                "!border-l-none rounded-l-none -ml-[1px]"
                              }
                              isDisabled={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="relative w-6/12 mt-6">
                      <div className="capitalize text-xs text-slate-500 pb-1">
                        Current salary
                      </div>
                      <div className="relative flex w-full">
                        <div className="w-20 min-w-[80px]">
                          <Select
                            xPlacement={"left"}
                            dropdownData={dropdownDataCurrency}
                            dropdownClass={"max-h-40"}
                            dropdownButtonClass={
                              "!px-2 !border-r-none rounded-r-none"
                            }
                            selectedValue={""}
                            setCustomSelecedvalue={(value) =>
                              handleFormData({
                                target: {
                                  name: "currentSalary[currency]",
                                  value: value?.name,
                                },
                              })
                            }
                          />
                        </div>
                        <div className="w-full">
                          <Input
                            inputType={"tel"}
                            inputPlaceholder={"Enter amount"}
                            inputClasses={
                              "!border-l-none rounded-l-none -ml-[1px]"
                            }
                            inputName={"currentSalary[amount]"}
                            onChange={(e) => handleFormData(e)}
                          />
                        </div>
                      </div>
                      <div className="relative w-full"></div>
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <div className="relative mt-6 w-6/12">
                      <div className="capitalize text-xs text-slate-500 pb-1">
                        Expected Salary
                      </div>
                      <div className="flex gap-2">
                        <div className="flex gap w-full">
                          <div className="w-20 min-w-[80px]">
                            <Select
                              xPlacement={"left"}
                              dropdownData={dropdownDataCurrency}
                              dropdownClass={"max-h-40"}
                              dropdownButtonClass={
                                "!px-2 !border-r-none rounded-r-none"
                              }
                              selectedValue={""}
                              setCustomSelecedvalue={(value) =>
                                handleFormData({
                                  target: {
                                    name: "expectedSalary[currency]",
                                    value: value?.name,
                                  },
                                })
                              }
                            />
                          </div>
                          <div className="w-full">
                            <Input
                              inputType={"tel"}
                              inputPlaceholder={"Enter amount"}
                              inputClasses={
                                "!border-l-none rounded-l-none -ml-[1px]"
                              }
                              inputName={"expectedSalary[amount]"}
                              onChange={(e) => handleFormData(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="relative w-6/12 mt-6">
                      <div className="capitalize text-xs text-slate-500 pb-1">
                        Available To Join (in days)
                      </div>
                      <div className="relative flex w-full">
                        <div className="w-full">
                          <Input
                            inputType={"tel"}
                            inputPlaceholder={"Enter number of days.."}
                            inputClasses={"!border-l-none rounded -ml-[1px]"}
                            inputName={"availability"}
                            onChange={(e) => handleFormData(e)}
                          />
                        </div>
                      </div>
                      <div className="relative w-full"></div>
                    </div>
                  </div>
                  <div className="relative w-full">
                    <div className="relative w-full mt-6 flex gap-4">
                      <div className="relative w-6/12">
                        <div className="capitalize text-xs text-slate-500 pb-1">
                          Phone No
                        </div>
                        <div className="relative flex w-full">
                          <div className="w-20 min-w-[80px]">
                            <Select
                              xPlacement={"left"}
                              dropdownData={dropdownDataList}
                              dropdownClass={"max-h-40"}
                              dropdownButtonClass={
                                "!px-2 !border-r-none rounded-r-none"
                              }
                              selectedValue={""}
                              setCustomSelecedvalue={(value) =>
                                handleFormData({
                                  target: {
                                    name: "phoneCode",
                                    value: value?.name,
                                  },
                                })
                              }
                            />
                          </div>
                          <div className="w-full">
                            <Input
                              inputType={"tel"}
                              inputPlaceholder={"Enter your phone number"}
                              inputClasses={
                                "!border-l-none rounded-l-none -ml-[1px]"
                              }
                              inputName={"phone"}
                              onChange={(e) => handleFormData(e)}
                            />
                          </div>
                        </div>
                        <div className="relative w-full"></div>
                      </div>
                    </div>
                    <div className="relative mt-6 w-6/12">
                      <div className="relative flex">
                        <div className="relative w-full">
                          <div className="capitalize text-xs text-slate-500 pb-1">
                            Additional Documents
                          </div>
                          <div className="relative ">
                            <InputFile
                              label={""}
                              inputPlaceholder={"Upload CV"}
                              inputValue={formData?.resume}
                              handleFile={(e) =>
                                handleFormData({
                                  target: {
                                    name: "resume",
                                    value: e.target.files[0],
                                  },
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="relative w-3/12 pl-3 pb-2 flex justify-center">
          <button
            onClick={handleSubmit}
            // disabled={loaded===true?false:true}
            type="button"
            class="relative overflow-hidden flex justify-center items-center gap-2 bg-carnation-500 text-white rounded-xl rounded-tl-none text-lg h-12 py-0 px-3  transition-all duration-200 w-full z-0 before:content-[''] before:z-[-1] before:absolute before:top-0 before:left-0 before:w-full  before:h-full before:bg-carnation-600/50  before:scale-x-0 before:transition-all before:duration-200 before:origin-left   hover:before:scale-x-100"
          >
            {loaded ? (
              <span class="text-base transition-all duration-200">
                Apply this job
              </span>
            ) : (
              <Loader />
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default SingleApplyJobs;
