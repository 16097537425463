import React, { useEffect, useRef } from "react";
import { register } from "swiper/element/bundle";
import Image from "../../elements/Image";
register();

const DetailsImage = ({ data, setCurrentImage }) => {
  const swiperElRef = useRef(null);

  useEffect(() => {
    const swiperEl = document.querySelector("swiper-container.details-image");
    const swiperParams = {
      spaceBetween: 10,
      slidesPerView: "auto",
      // pagination: {
      //   clickable: true,
      //   dynamicBullets: true
      // },
      navigation: true,
      on: {
        init() {},
      },
    };
    Object.assign(swiperEl, swiperParams);
    swiperEl.initialize();
  }, []);

  return (
    <>
      <swiper-container
        ref={swiperElRef}
        class="py-5 details-image"
        init="true"
      >
        {data?.map((item, index) => (
          <swiper-slide className="w-20 h-16 flex" lazy="true" key={index}>
            <button
              type="button"
              className="w-full h-full overflow-hidden rounded-md shadow hover:shadow-lg transition-all duration-200"
              onClick={() => setCurrentImage(item.url)}
            >
              <Image
                src={item.url}
                alt={""}
                width={"100%"}
                height={"100%"}
                effect={"blur"}
                classes={"object-cover"}
              />
            </button>
          </swiper-slide>
        ))}
      </swiper-container>
    </>
  );
};

export default DetailsImage;
