import React, { useState } from "react";
import { setTitle } from "../helpers/MetaTag";
import { Link } from "react-router-dom";
import Image from "../components/elements/Image";
import Input from "../components/form/Input";
import Checkbox from "../components/form/Checkbox";
import Button from "../components/form/Button";
import Logo from "../assets/images/logo.png";
import Google from "../assets/images/google.svg";
import Facebook from "../assets/images/facebook.svg";
import {
  SendOtpService,
  SignInService,
  SignInWithGoogleService,
  resendOtp,
} from "../services/FundrisersService";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { fetchProfile } from "../store/Profile/profileData";
import VerifyOTP from "./VerifyOTP";
import { getCountryDetails, loadCountries } from "../services/CountryService";
import Select from "../components/form/Select";
import { numReg } from "../helpers";
import SearchWithDropdown from "../components/form/SearchWithDropdown";
import { useGoogleLogin } from "@react-oauth/google";
import { hasGrantedAnyScopeGoogle } from "@react-oauth/google";
import axios from "axios";
import FacebookLogin from "react-facebook-login";
import { FaFacebookF } from "react-icons/fa";

const Signin = () => {
  setTitle("Crowd Funding | Signin");
  const [otpVia, setOtpVia] = useState("email");
  const [fillUp, setFillUp] = useState(false);
  const [signInData, setSignInData] = useState({});
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [signinType, setSigninType] = useState(true);
  const [loaded, setLoaded] = useState(true);
  const [resData, setResData] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [coords, setCoords] = useState({
    lat: undefined,
    lon: undefined,
  });
  const [googleResponse, setGoogleResponse] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [currentCountry, setCurrentCountry] = useState({});

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setCoords({
        lat: position?.coords?.latitude,
        lon: position?.coords?.longitude,
      });
    });
  }, []);

  // get country list ===========>

  useEffect(() => {
    loadCountries().then((res) => {
      if (res?.status === 200) {
        setCountryList(
          res?.docs?.map((country) => ({
            name: "+" + country?.phone_code?.replace("+", ""),
            country_name: country?.name,
            _id: country?._id,
          }))
        );
      }
    });
  }, []);

  // get current country details ==========>

  useEffect(() => {
    if (coords?.lon && coords?.lat && countryList) {
      getCountryDetails({ lat: coords?.lat, lon: coords?.lon }).then((res) => {
        if (!signInData?.phoneCode) {
          setCurrentCountry({
            name: countryList?.find(
              (item) => item?.country_name === res?.address?.country
            )?.name,
          });
          setSignInData((pre) => ({
            ...pre,
            phoneCode: countryList?.find(
              (item) => item?.country_name === res?.address?.country
            )?.name,
          }));
        }
      });
    }
  }, [coords, countryList]);

  useEffect(() => {
    if (signInData?.phoneCode) {
      setCurrentCountry({
        name: countryList?.find((item) => item?.name === signInData?.phoneCode)
          ?.name,
      });
    }
  }, [signInData?.phoneCode]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email && password) {
      SignInService({ email, password }, setLoaded, setResData)
        .then((res) => {
          if (res?.data?.status === 200) {
            localStorage.setItem("auth_token", res?.data?.accessToken);
            localStorage.setItem("refreshToken", res?.data?.refreshToken);
            if (localStorage.getItem("auth_token")) {
              dispatch(fetchProfile({ status: 1 }));
            }

            toast.success(res?.data?.message);
            setTimeout(function () {
              navigate("/user/dashboard");
            }, 2000);
          } else {
            toast.error(res?.data?.message);
          }
          // navigate("/new-fundraising")
        })
        .catch((err) => {});
    } else if (!email) {
      toast.error("Email is required!");
    } else if (!password) {
      toast.error("Password is required!");
    } else {
      toast.error("Email & Password are required!");
    }
  };

  const authToken = localStorage.getItem("auth_token");

  const handleSendOtp = () => {
    const requiredFields = ["email"];
    const missingFields = requiredFields.filter((field) => !signInData[field]);

    if (missingFields.length > 0) {
      // Display toast message for missing fields
      toast.error(`Please provide ${missingFields.join(", ")}`);
      return;
    } else {
      resendOtp(signInData).then((res) => {
        if (res.status === 200) {
          setFillUp(true);
          toast.success(res.message);
        } else {
          toast.info(res.message);
        }
      });
    }
  };

  const onChangeHandler = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setSignInData((pre) => ({ ...pre, [name]: value }));
  };

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => setGoogleResponse(tokenResponse),
  });

  googleResponse?.access_token &&
    axios
      .get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleResponse?.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${googleResponse?.access_token}`,
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        SignInWithGoogleService({
          email: res?.data?.email,
          verified_email: res?.data?.verified_email,
          name: res?.data?.name,
          given_name: res?.data?.given_name,
          family_name: res?.data?.family_name,
          picture: res?.data?.picture,
          locale: res?.data?.locale,
          social_login_id: res?.data?.id,
          social_login_type: "google",
        }).then((response) => {
          if (response?.data?.status === 200) {
            localStorage.setItem(
              "auth_token",
              response?.data?.data?.accessToken
            );
            localStorage.setItem(
              "refreshToken",
              response?.data?.data?.refreshToken
            );
            if (localStorage.getItem("auth_token")) {
              dispatch(fetchProfile({ status: 1 }));
            }

            toast.success(res?.data?.message);
            setTimeout(function () {
              navigate("/user/dashboard");
            }, 2000);
          } else {
            toast.error(res?.data?.message);
          }
        });
      })
      .catch((err) => console.log(err));

  const responseFacebook = (res) => {
    res &&
      SignInWithGoogleService({
        email: res?.email,
        name: res?.name,
        picture: res?.picture?.data?.url,
        social_login_id: res?.id,
        social_login_type: "facebook",
      }).then((response) => {
        if (response?.data?.status === 200) {
          localStorage.setItem("auth_token", response?.data?.data?.accessToken);
          localStorage.setItem(
            "refreshToken",
            response?.data?.data?.refreshToken
          );
          if (localStorage.getItem("auth_token")) {
            dispatch(fetchProfile({ status: 1 }));
          }

          toast.success(res?.data?.message);
          setTimeout(function () {
            navigate("/user/dashboard");
          }, 2000);
        } else {
          toast.error(res?.data?.message);
        }
      });
  };

  return (
    <>
      {!fillUp ? (
        <div className="w-full min-h-screen bg-gray-100 flex items-center justify-center py-5 px-5">
          <div className="w-full max-w-md xl:max-w-lg bg-white py-10 px-4 sm:px-10 rounded-3xl">
            <div className="mx-auto max-w-sm">
              <div className="w-full h-16 mx-auto mb-8">
                <Link to={"/"}>
                  <Image
                    src={Logo}
                    alt={"Logo"}
                    width={"100%"}
                    height={"100%"}
                    effect={"blur"}
                    classes={"object-contain"}
                  />
                </Link>
              </div>
              <div className="text-center space-y-2">
                <h1 className="text-2xl xl:text-3xl font-Lexend font-semibold">
                  Welcome back
                </h1>
                <div className="text-sm text-slate-600">
                  Signin to your account using your credentials.
                </div>
              </div>
              <form className="mt-8 space-y-4 mx-auto" onSubmit={handleSubmit}>
                <>
                  {signinType ? (
                    <>
                      <div className="relative">
                        <Input
                          label={""}
                          labelClasses={"!text-xs"}
                          inputType={"text"}
                          inputPlaceholder={"Enter your email"}
                          errorType={email ? "" : "danger"}
                          errorText={"Email is required!"}
                          inputName={"email"}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="relative">
                        <Input
                          label={""}
                          labelClasses={"!text-xs"}
                          inputType={"password"}
                          inputPlaceholder={"Enter your password"}
                          errorType={password ? "" : "danger"}
                          errorText={"Password is required!"}
                          inputName={"password"}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="relative">
                        <Input
                          label={""}
                          labelClasses={"!text-xs"}
                          inputType={"email"}
                          inputPlaceholder={"Enter your email"}
                          errorType={signInData?.email ? "" : "danger"}
                          errorText={"Email is required!"}
                          inputName={"email"}
                          inputValue={signInData?.email}
                          onChange={(e) => {
                            onChangeHandler(e);
                          }}
                        />
                      </div>
                      {/* {otpVia == "email" ? (
                        <div className="relative">
                          <Input
                            label={""}
                            labelClasses={"!text-xs"}
                            inputType={"text"}
                            inputPlaceholder={"Enter your email"}
                            errorType={""}
                            inputName={"email"}
                            inputValue={signInData?.email}
                            onChange={(e) => {
                              onChangeHandler(e);
                            }}
                          />
                        </div>
                      ) : (
                        <div>
                          <div className="relative mt-2">
                            <SearchWithDropdown
                              placeholder={"Type three letter and find country"}
                              data={
                                !fillUp &&
                                countryList?.find(
                                  (item) => item?.name === currentCountry?.name
                                )?.country_name
                              }
                              dropdownData={[
                                { name: "Select country" },
                                ...countryList?.map((item) => ({
                                  name: item?.country_name,
                                  country_code: item?.name,
                                  _id: item?._id,
                                })),
                              ]}
                              setCustomSelecedvalue={(value) => {
                                onChangeHandler({
                                  target: {
                                    name: "phoneCode",
                                    value: value?.country_code,
                                  },
                                });
                              }}
                            />
                          </div>
                          <div className="relative flex mt-2">
                            <div className="w-20 min-w-[80px]">
                              <Select
                                xPlacement={"left"}
                                dropdownData={countryList}
                                dropdownClass={"max-h-40"}
                                dropdownButtonClass={
                                  "!px-2 !border-r-none rounded-r-none"
                                }
                                selectedValue={
                                  countryList?.find(
                                    (item) =>
                                      item?.name === currentCountry?.name
                                  )?._id
                                }
                                setCustomSelecedvalue={(val) => {
                                  onChangeHandler({
                                    target: {
                                      name: "phoneCode",
                                      value: val?.name,
                                    },
                                  });
                                }}
                              />
                            </div>
                            <div className="w-full">
                              <Input
                                inputPlaceholder={"Enter your phone number"}
                                inputClasses={
                                  "!border-l-none rounded-l-none -ml-[1px]"
                                }
                                inputValue={signInData?.phone}
                                inputName={"phone"}
                                onChange={(e) => {
                                  if (numReg.test(e.target.value)) {
                                    if (e.target.value?.length > 10) {
                                      return;
                                    } else {
                                      onChangeHandler(e);
                                    }
                                  } else {
                                    return;
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )} */}
                      {/* <div
                        onClick={() =>
                          setOtpVia(otpVia == "phone" ? "email" : "phone")
                        }
                        class="text-center cursor-pointer transition-all duration-200 font-semibold text-carnation-500 !text-sm hover:!underline !px-0"
                      >
                        Using via {otpVia == "email" ? "phone" : "email"}
                      </div> */}
                    </>
                  )}
                  <div className="relative flex items-center gap-4">
                    <div className="w-1/2">
                      <Button
                        buttonClasses={
                          "!px-0 !h-auto !bg-transparent !rounded-none"
                        }
                        buttonHasLink={false}
                        buttonType={"button"}
                        buttonLabel={
                          signinType ? "Signin via OTP" : "Signin via Password"
                        }
                        buttonLabelClasses={
                          "font-semibold text-carnation-500 !text-xs hover:!underline !px-0"
                        }
                        buttonFunction={() => setSigninType(!signinType)}
                      />
                    </div>
                    <div className="w-1/2 flex justify-end">
                      {!signinType && (
                        <Button
                          buttonClasses={
                            "!px-0 !h-auto !bg-transparent !rounded-none !text-carnation-500 !text-sm"
                          }
                          buttonIcon={"fa-regular fa-circle-question"}
                          buttonIconPosition={"left"}
                          buttonHasLink={true}
                          buttonLink={"/forgot-password"}
                          buttonLabel={"Forgot Password"}
                          buttonLabelClasses={
                            "font-semibold text-carnation-500 !text-xs hover:!underline"
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div className="relative flex justify-center">
                    {signinType ? (
                      <Button
                        buttonClasses={"w-full"}
                        buttonType={"submit"}
                        buttonLabel={"Signin"}
                        buttonLabelClasses={""}
                        buttonEffect={"filled"}
                        isDisable={loaded ? false : true}
                      />
                    ) : (
                      <Button
                        buttonClasses={"w-full"}
                        buttonHasLink={false}
                        buttonLabel={"Get OTP"}
                        isDisable={loaded ? false : true}
                        buttonType={"button"}
                        buttonLabelClasses={""}
                        buttonEffect={"filled"}
                        buttonFunction={handleSendOtp}
                      />
                    )}
                  </div>
                </>
              </form>

              <div className="relative w-full my-4 mx-auto flex items-center justify-center before:content-[''] before:w-full before:absolute before:border-t before:border-slate-300 before:top-1/2 before:-translate-y-1/2 before:left-0">
                <div className="bg-white relative z-10 px-5 text-sm text-slate-600">
                  Or continue with
                </div>
              </div>
              <div className="flex items-center gap-4">
                {/* <button
                  type="button"
                  className="flex items-center justify-center bg-white border-1 border-slate-200 shadow py-0 px-4 h-10 w-full rounded-md gap-4"
                >
                  <div className="w-6 h-6">
                    <Image
                      src={Facebook}
                      alt={"Facebook"}
                      width={"100%"}
                      height={"100%"}
                      effect={"blur"}
                      classes={"object-contain"}
                    />
                  </div>
                  <div className="text-sm">Facebook</div>
                </button> */}
                <FacebookLogin
                  appId="902195771452199"
                  autoLoad={false}
                  fields="name,email,picture"
                  // onClick={componentClicked}
                  callback={responseFacebook}
                  textButton="Facebook"
                  cssClass="flex text-sm items-center justify-center bg-white border-1 border-slate-200 shadow py-0 px-4 h-10 w-[182px] rounded-md gap-4"
                  icon={<FaFacebookF className="w-6 h-6" />}
                />

                <button
                  type="button"
                  className="flex items-center justify-center bg-white border-1 border-slate-200 shadow py-0 px-4 h-10 w-full rounded-md gap-4"
                  onClick={() => login()}
                >
                  <div className="w-6 h-6">
                    <Image
                      src={Google}
                      alt={"Google"}
                      width={"100%"}
                      height={"100%"}
                      effect={"blur"}
                      classes={"object-contain"}
                    />
                  </div>
                  <div className="text-sm">Google</div>
                </button>
              </div>

              <div className="text-xs text-slate-500 mt-4 text-center">
                By continuing you agree to
                <Button
                  buttonClasses={
                    "!px-0 !h-auto !bg-transparent !rounded-none !inline-flex mx-1"
                  }
                  buttonHasLink={true}
                  buttonLink={"/"}
                  buttonLabel={"Terms of Service"}
                  buttonLabelClasses={
                    "font-semibold text-carnation-500 !text-xs hover:!underline"
                  }
                />
                and
                <Button
                  buttonClasses={
                    "!px-0 !h-auto !bg-transparent !rounded-none !inline-flex mx-1"
                  }
                  buttonHasLink={true}
                  buttonLink={"/"}
                  buttonLabel={"Privacy Policy"}
                  buttonLabelClasses={
                    "font-semibold text-carnation-500 !text-xs hover:!underline"
                  }
                />
              </div>
              <div className="flex items-center justify-center mt-2 gap-2 text-sm text-slate-600">
                <span>Don't have any account?</span>
                <Button
                  buttonClasses={"!px-0 !h-auto !bg-transparent !rounded-none"}
                  buttonHasLink={true}
                  buttonLink={"/register"}
                  buttonLabel={"Click here"}
                  buttonLabelClasses={
                    "font-semibold text-carnation-500 !text-sm hover:!underline !px-0"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <VerifyOTP setFillUp={setFillUp} data={signInData} type={"signin"} />
      )}
    </>
  );
};

export default Signin;
