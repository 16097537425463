import React, { useEffect, useState } from "react";
import { setTitle } from "../helpers/MetaTag";
import { Link, useLocation } from "react-router-dom";
import OtpInput from "react-otp-input";
import Image from "../components/elements/Image";
import Input from "../components/form/Input";
import Button from "../components/form/Button";
import Logo from "../assets/images/logo.png";
import { VerifyOtpService, resendOtp } from "../services/FundrisersService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";

const VerifyOTP = ({ setFillUp = () => {}, data = {}, type = "" }) => {
  setTitle("Crowd Funding | Verify OTP");
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);
  const [loaded, setLoaded] = useState(true);

  useEffect(() => {
    if (minutes == "0" && seconds == "0") {
      setLoaded(true);
    }
  }, [minutes, seconds]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const handleVerify = () => {
    const signUpData = {};
    if (type == "signup") {
      signUpData[`phone`] = data?.phone;
      signUpData[`phoneCode`] = data?.phoneCode;
    }
    setLoaded(false);
    VerifyOtpService({
      email: data?.email,
      ...signUpData,
      // email: data?.phoneCode === "+91" ? "" : data?.email,
      // phone: data?.phoneCode === "+91" ? data?.phone : "",
      // phoneCode: data?.phoneCode,
      otp: otp,
      type,
    }).then((res) => {
      if (res.status === 200) {
        setLoaded(true);
        toast.success(res?.message);
        localStorage.setItem("auth_token", res?.accessToken);
        localStorage.setItem("accessToken", res?.accessToken);
        localStorage.setItem("refreshToken", res?.refreshToken);
        navigate("/");
      } else {
        setLoaded(true);
        toast.error(res?.message);
      }
    });
  };

  useEffect(() => {
    if (otp.length > 3) {
      handleVerify();
    }
  }, [otp]);

  const resendOtpHandler = () => {
    setMinutes(1);
    setSeconds(30);
    resendOtp({
      // email: data?.phoneCode === "+91" && !data?.phone ? "" : data?.email,
      email: data?.email,
      phone: data?.phoneCode === "+91" && data?.phone ? data?.phone : "",
      phoneCode:
        data?.phoneCode === "+91" && data?.phone ? data?.phoneCode : "",
    }).then((res) => {
      if (res.status === 200) {
        toast.success(res?.message);
      } else {
        setLoaded(true);
        toast.error(res?.message);
      }
    });
  };

  return (
    <>
      <div className="w-full min-h-screen bg-gray-100 flex items-center justify-center py-5 px-5">
        <div className="w-full max-w-md xl:max-w-lg bg-white py-10 px-4 sm:px-10 rounded-3xl">
          <div className="mx-auto max-w-sm">
            <div className="w-full h-16 mx-auto mb-8">
              <Link to={"/"}>
                <Image
                  src={Logo}
                  alt={"Logo"}
                  width={"100%"}
                  height={"100%"}
                  effect={"blur"}
                  classes={"object-contain"}
                />
              </Link>
            </div>
            <div className="text-center space-y-2">
              <h1 className="text-2xl xl:text-3xl font-Lexend font-semibold">
                Verify OTP
              </h1>
              <div className="text-sm text-slate-600">
                Enter the code send to your email
                {/* {data?.phoneCode == "+91" && data?.phone
                  ? "mobile number"
                  : "email"}
                . */}
              </div>
              <div className="flex items-center justify-center gap-2">
                <div className="text-sm text-carnation-500 font-semibold">
                  {/* {data?.phoneCode === "+91" && data?.phone ? (
                    <span>
                      {data?.phoneCode}&nbsp;{data?.phone}
                    </span>
                  ) : (
                    <span>{data?.email}</span>
                  )} */}
                  <span>{data?.email}</span>
                </div>
                <Button
                  buttonClasses={"!px-0 !h-auto !bg-transparent !rounded-none"}
                  buttonHasLink={false}
                  buttonFunction={() => setFillUp(false)}
                  buttonLabel={"Edit"}
                  buttonLabelClasses={
                    "text-slate-500 !text-xs font-semibold !underline"
                  }
                />
              </div>
            </div>
            <form className="py-8 space-y-4 mx-auto">
              <div className="relative">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={4}
                  renderSeparator={""}
                  containerStyle={"pb-4 flex justify-center gap-2"}
                  inputType={"tel"}
                  inputStyle="w-12 h-12 min-w-[48px] p-0 bg-slate-100 border-none rounded-md focus:ring-carnation-500 focus:ring-2 focus:ring-offset-1 focus:bg-carnation-50 text-2xl text-slate-900 font-semibold"
                  renderInput={(props) => <input {...props} />}
                />
              </div>
              <div className="relative flex justify-center">
                <Button
                  buttonClasses={"!px-10"}
                  buttonType={"button"}
                  buttonLabel={`Verify & ${
                    type == "signup" ? "Signup" : "Signin"
                  }`}
                  buttonLabelClasses={""}
                  buttonEffect={"filled"}
                  isDisable={otp.length < 4 || loaded === false}
                  buttonFunction={handleVerify}
                />
              </div>
            </form>
            <div className="flex items-center justify-center text-sm text-slate-600">
              <Button
                buttonClasses={"!px-0 !h-auto !bg-transparent !rounded-none"}
                buttonType={"button"}
                buttonLabel={"Resend OTP"}
                buttonFunction={resendOtpHandler}
                buttonLabelClasses={
                  "font-semibold text-carnation-500 !text-sm hover:!underline"
                }
                isDisable={seconds > 0 || minutes > 0}
              />
              {seconds > 0 || minutes > 0 ? (
                <div className="font-medium text-slate-600 text-xs w-12 text-right">
                  ({minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds})
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyOTP;
