// import React from 'react';

// const ListSuccessStory = () => {
//     return (
//         <div>
            
//         </div>
//     );
// };

// export default ListSuccessStory;



import React from 'react';
import SingleFundRaiser from "../items/SingleFundRaiser";
import SingleSuccessStory from '../items/SingleSuccessStory';

const ListSuccessStory = ({ data }) => {
  return (
    <>
      <section className="relative overflow-hidden w-full py-20 bg-gray-100">
        <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
          <div className="space-y-10">
            {/* <div className="space-y-4 text-center">
              <h2 className="font-Lexend text-3xl sm:text-4xl xl:text-5xl font-bold text-black">{data.title}</h2>
              <div className="text-sm xl:text-base text-slate-600 max-w-xl mx-auto">{data.subtitle}</div>
            </div> */}
            {data?.length > 0 &&
              <div className="{relative grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-5 2xl:gap-x-10 gap-y-8 sm:gap-y-14}">
                {data?.map((item) => (
                  <SingleSuccessStory key={item._id} data={item} />
                ))}
              </div>
            }
          </div>
        </div>
      </section>
    </>
  );
};

export default ListSuccessStory;