import React from 'react';
import Image from "../elements/Image";
import Button from "../form/Button";

const CallToAction = ({data}) => {
  return (
    <>
      <section className="relative overflow-hidden w-full z-0 h-[400px] xl:h-[460px] flex items-center bg-white">
        <div className="absolute top-0 left-0 w-full h-full z-[-1] after:absolute after:content-[''] after:w-full after:h-full after:top-0 after:left-0 after:bg-gradient-to-r after:from-white/95 after:from-10% after:via-white/80 after:via-50%">
          <Image src={data.image} alt={''} width={'100%'} height={'100%'} effect={'blur'} classes={'object-cover saturate-200'} />
        </div>
        <div className="relative z-[1] w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto flex items-center">
          <div className="w-1/2 xl:w-3/5 space-y-8">
            <h2 className="font-Lexend text-3xl sm:text-4xl xl:text-5xl font-bold text-black leading-tight">{data.title}</h2>
            <div className="flex items-center !mt-10">
              <Button
                buttonClasses={'!px-5'}
                buttonHasLink={true}
                buttonLink={data.actionLink}
                buttonLabel={data.actionLabel}
                buttonLabelClasses={'text-base'}
                buttonEffect={"filled"}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CallToAction;