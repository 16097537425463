import React, { useEffect, useState } from "react";
import SingleSupporterSm from "../../items/SingleSupporterSm";
import Button from "../../form/Button";
import Loader from "../../elements/loader/Loader";
import { loadSupporterList } from "../../../services/SupportersService";
import CardSkeletonLoader from "../../elements/loader/CardSkeletonLoader";

const Suppoters = ({ id, desc = "" }) => {
  const [limit, setLimit] = useState(5);
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [limitTwo, setlimitTwo] = useState(5);
  const [offset, setOffset] = useState(0);
  const [initialPage, setInitialPage] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);
  const [hasNextPage, sethasNextPage] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    desc == "topDonors" &&
      id &&
      loadSupporterList({ fundRaiserId: id, limit: limit }, setData, setLoaded);
  }, [id, limit]);

  useEffect(() => {
    setInitialPage(0);
    setTotalDocs(0);
    desc == "supporters" &&
      id &&
      loadSupporterList(
        { fundRaiserId: id, limit: limitTwo, offset: offset },
        setData,
        setLoaded
      ).then((res) => {
        setInitialPage(
          // res?.data?.pagingCounter === 1 ? 0 : res?.data?.pagingCounter
          res?.data?.pagingCounter
        );
        setCurrentPage(res?.data?.page);
        setTotalDocs(res?.data?.totalDocs);
        sethasNextPage(res?.data?.hasNextPage);
      });
  }, [id, limitTwo, offset]);

  return (
    <>
      {loaded ? (
        Object.keys(data)?.length > 0 && (
          <div className="relative bg-white rounded-3xl">
            <div className="flex items-center justify-between py-4 px-6">
              <h4 className="font-semibold sm:font-medium text-black text-lg 2xl:text-2xl">
                {desc == "topDonors" ? "Top Donors" : "Supporters"}
              </h4>
              <div className="text-sm 2xl:text-base text-slate-600 font-normal ml-2">
                {/* {"(" + data.count + ")"} */}
              </div>
            </div>
            {data.length > 0 && (
              <>
                <div className="relative border-t border-slate-200 divide-y divide-slate-100 px-4 xl:px-6 lg:max-h-80 overflow-auto scroll-smooth scrollbar">
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <SingleSupporterSm key={item._id} data={item} />
                    ))}
                </div>
                {desc == "topDonors" && limit <= data.length && (
                  <div className="flex items-center justify-center border-y border-slate-100 -mx-8 py-4">
                    <Button
                      buttonClasses={
                        "!px-0 !h-auto !bg-transparent !rounded-none"
                      }
                      buttonHasLink={false}
                      // buttonFunction={() => setLimit(limit + 2)}
                      // buttonLabel={"View More"}
                      buttonLabelClasses={
                        "font-semibold text-carnation-500 !text-sm hover:!underline"
                      }
                    />
                  </div>
                )}
                {desc == "supporters" && (
                  <>
                    <hr />
                    <div className="flex p-3 justify-center items-center gap-4 text- ">
                      <div
                        className="cursor-pointer hover:text-red-600"
                        onClick={() => {
                          offset >= 1 &&
                            setOffset((prevOffset) => prevOffset - 1);
                          // setlimitTwo((prevLimit) => (offset + 1) * prevLimit);
                        }}
                      >
                        <i class="fa-solid fa-angle-left"></i>
                      </div>
                      <div className="text-slate-500">
                        <p>
                          {`${initialPage} - ${
                            initialPage + limitTwo >= totalDocs
                              ? totalDocs
                              : initialPage == 1
                              ? limitTwo
                              : limitTwo + initialPage
                          } of ${totalDocs} donations`}
                        </p>
                      </div>
                      <div
                        className="cursor-pointer hover:text-red-600"
                        onClick={() => {
                          hasNextPage &&
                            setOffset((prevOffset) => prevOffset + 1);
                          // hasNextPage &&
                          //   setlimitTwo(
                          //     (prevLimit) => (offset + 1) * prevLimit
                          //   );
                        }}
                      >
                        <i class="fa-solid fa-angle-right"></i>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )
      ) : (
        <CardSkeletonLoader />
      )}
    </>
  );
};

export default Suppoters;
