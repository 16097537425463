import { useState } from "react";
import Input from "../../../../components/form/Input";
import Select from "../../../../components/form/Select";
import { numReg } from "../../../../helpers";
import SearchWithDropdown from "../../../form/SearchWithDropdown";
import { useEffect } from "react";
import TaxSection from "./TaxSection";
import ForeignFunds from "./ForeignFunds";

const PanSection = ({
  countryList,
  panData,
  setPanData = () => {},
  stepsData = {},
  setStepsData = () => {},
  setTaxData,
  taxData,
  foreignFundsData,
  setForeignFundsData,
  currentCountry,
}) => {
  const [isPanProvideList, setPanProvideList] = useState([
    { _id: 0, name: "Yes", value: true, checked: false },
    { _id: 1, name: "No", value: false, checked: false },
  ]);

  const [isProvidePan, setIsProvidePan] = useState(false); // initial value false

  // console.log(isPanProvideList, "isPanProvideListisPanProvideList");
  // console.log(isProvidePan,'isProvidePanisProvidePan')

  const onChangeHandler = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    if (value !== undefined) {
      setPanData((pre) => ({ ...pre, [name]: value }));
    } else {
      if (name === "isContactPerson") {
        setPanData((pre) => ({ ...pre, [name]: false }));
      } else {
        delete panData[name];
        delete stepsData[name];
        setPanData({ ...panData });
        setStepsData({ ...stepsData });
      }
    }
  };
  // useEffect(() => {
  //   setIsProvidePan(panData?.isContactPerson);
  //   setPanProvideList((pre) =>
  //     pre.map((item) =>
  //       item.value == panData?.isContactPerson
  //         ? { ...item, checked: true }
  //         : { ...item, checked: false }
  //     )
  //   );
  // }, [panData?.isContactPerson]);

  // const handleDataChange = (e) => {
  //   const data = isPanProvideList?.map((item) => ({
  //     ...item,
  //     checked: item._id == e?.id ? true : false,
  //   }));

  //   // console.log(data, "datadatadatadatadatadata");
  //   setPanProvideList(data);
  // };

  useEffect(() => {
    if (panData?.isContactPerson !== undefined) {
      setIsProvidePan(panData.isContactPerson);
      setPanProvideList((prev) =>
        prev.map((item) => ({
          ...item,
          checked: item.value === panData.isContactPerson,
        }))
      );
    }
  }, [panData?.isContactPerson]);

  const handleDataChange = (value) => {
    setIsProvidePan(value);
    setPanProvideList((prev) =>
      prev.map((item) => ({
        ...item,
        checked: item.value === value,
      }))
    );
    onChangeHandler({ target: { name: "isContactPerson", value } });
    // console.log(value, "valuueseertftstfstfstf");
  };

  return (
    <>
      <div className="relative flex justify-between">
        <h4 className="text-sm font-semibold text-carnation-500 mt-3 mb-2">
          I’m the contact person for this organisation
        </h4>

        {/* <div className="grid grid-cols-2 gap-2 mt-2">
          {isPanProvideList?.map((item) => (
            <div
              key={item?._id}
              className="relative w-full overflow-hidden z-0 flex"
            >
              <input
                checked={item?.checked}
                value={item?.value}
                type="radio"
                name="isContactPerson"
                id={item._id}
                onChange={(e) => {
                  // console.log(e.target, "target");
                  setPanData((pre) => ({ phoneCode: pre?.phoneCode }));
                  handleDataChange(e.target);
                  setIsProvidePan(e.target.value ? true : false);
                  onChangeHandler(e);
                }}
                required
                className="peer absolute z-[1] cursor-pointer top-0 left-0 w-full h-full opacity-0"
              />
              <div className="w-full relative bg-slate-50 border border-slate-200 text-xs font-semibold px-2 py-2 flex items-center justify-center leading-tight text-center rounded-md text-slate-600 transition-all duration-200 peer-checked:bg-carnation-500 peer-checked:border-carnation-500 peer-checked:text-white">
                {item?.name}
              </div>
            </div>
          ))}
        </div> */}

        <div className="grid grid-cols-2 gap-2 mt-2">
          {isPanProvideList.map((item) => (
            <div
              key={item._id}
              className="relative w-full overflow-hidden z-0 flex"
            >
              <input
                checked={item.checked}
                value={item.value}
                type="radio"
                name="isContactPerson"
                id={item._id}
                onChange={() => handleDataChange(item.value)}
                required
                className="peer absolute z-[1] cursor-pointer top-0 left-0 w-full h-full opacity-0"
              />
              <div
                className={`w-full relative border ${
                  item.checked
                    ? "bg-carnation-500 border-carnation-500 text-white"
                    : "bg-slate-50 border-slate-200 text-slate-600"
                } text-xs font-semibold px-2 py-2 flex items-center justify-center leading-tight text-center rounded-md transition-all duration-200`}
              >
                {item.name}
              </div>
            </div>
          ))}
        </div>
      </div>
      {!isProvidePan ? (
        <>
          <h4 className="text-sm font-semibold text-carnation-500 mt-3 mb-2">
            Organisation contact person's details
          </h4>
          <div className="relative mt-2">
            <Input
              label={"Full Name"}
              labelClasses={"!text-xs"}
              inputType={"text"}
              inputPlaceholder={"Enter full name"}
              errorType={panData?.[`contactPerson[name]`] ? "" : "danger"}
              errorText={"Contact person name is required!"}
              inputValue={
                panData?.[`contactPerson[name]`]
                  ? panData?.[`contactPerson[name]`]
                  : ""
              }
              inputName={"contactPerson[name]"}
              onChange={(e) => onChangeHandler(e)}
            />
          </div>
          <div className="relative mt-2">
            <Input
              label={"Email"}
              labelClasses={"!text-xs"}
              inputType={"text"}
              inputPlaceholder={"Enter email"}
              inputName={"contactPerson[email]"}
              inputValue={panData?.[`contactPerson[email]`]}
              errorType={panData?.[`contactPerson[email]`] ? "" : "danger"}
              errorText={"Contact person email id is required!"}
              onChange={(e) => onChangeHandler(e)}
            />
          </div>
          <div className="relative mt-2">
            <SearchWithDropdown
              label={"Country"}
              placeholder={"Type three letter and find country"}
              data={
                countryList?.find(
                  (item) => item?.name === panData?.[`contactPerson[phoneCode]`]
                )?.country_name
              }
              dropdownData={[
                { name: "Select country" },
                ...countryList?.map((item) => ({
                  name: item?.country_name,
                  country_code: item?.name,
                  _id: item?._id,
                })),
              ]}
              setCustomSelecedvalue={(value) => {
                onChangeHandler({
                  target: {
                    name: "contactPerson[phoneCode]",
                    value: value?.country_code,
                  },
                });
              }}
            />
          </div>
          <div className="mt-2">
            <div class="text-sm font-medium text-slate-500 mb-1">
              Enter Mobile Number
            </div>
            <div className="relative flex">
              <div className="w-20 min-w-[80px]">
                <Select
                  xPlacement={"left"}
                  dropdownData={countryList}
                  dropdownClass={"max-h-40"}
                  dropdownButtonClass={"!px-2 !border-r-none rounded-r-none"}
                  selectedValue={
                    countryList?.find(
                      (item) =>
                        item?.name === panData?.[`contactPerson[phoneCode]`]
                    )?._id
                  }
                  setCustomSelecedvalue={(e) => {
                    onChangeHandler({
                      target: {
                        name: "contactPerson[phoneCode]",
                        value: e.name,
                      },
                    });
                  }}
                  errorText={"Contact person country name is required!"}
                  errorType={
                    panData?.["contactPerson[phoneCode]"] ? "" : "danger"
                  }
                />
              </div>
              <div className="w-full">
                <Input
                  inputType={"number"}
                  inputPlaceholder={"Enter your phone number"}
                  inputClasses={"!border-l-none rounded-l-none -ml-[1px]"}
                  inputName={"contactPerson[phone]"}
                  inputValue={panData?.["contactPerson[phone]"]}
                  onChange={(e) => {
                    if (numReg.test(e.target.value)) {
                      if (e.target.value?.length > 10) {
                        return;
                      } else {
                        onChangeHandler(e);
                      }
                    } else {
                      return;
                    }
                  }}
                  errorText={"Contact person phone number is required!"}
                  errorType={panData?.["contactPerson[phone]"] ? "" : "danger"}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="relative mt-2">
            <Input
              label={"Organisation’s name as in pan"}
              labelClasses={"!text-xs"}
              inputType={"text"}
              inputPlaceholder={"Enter organisation’s name as in pan"}
              errorType={panData?.[`detailsOfPan[nameInPan]`] ? "" : "danger"}
              inputValue={panData?.[`detailsOfPan[nameInPan]`]}
              inputName={"detailsOfPan[nameInPan]"}
              onChange={(e) => onChangeHandler(e)}
              errorText={"Organization name is required!"}
            />
          </div>
          <div className="relative mt-2">
            <Input
              label={"Organisation’s PAN number"}
              labelClasses={"!text-xs"}
              inputType={"text"}
              inputPlaceholder={"Enter Organisation’s PAN number"}
              inputValue={panData?.[`detailsOfPan[panNumber]`] || ""}
              errorType={panData?.[`detailsOfPan[panNumber]`] ? "" : "danger"}
              errorText={"Organization PAN number is required!"}
              inputName={"detailsOfPan[panNumber]"}
              onChange={(e) => onChangeHandler(e)}
            />
          </div>
        </>
      )}
    </>
  );
};

export default PanSection;
