import React from "react";
import { useState } from "react";
import Steps from "./Steps";
import Input from "../../form/Input";
import { toast, ToastContainer } from "react-toastify";
import Button from "../../form/Button";
import { hasArrayData, nameHasArrayData } from "../../../helpers";

const StepZero = ({
  steps,
  currentStep,
  setCurrentStep,
  setStepsData = () => {},
  stepsData,
  fundraiserData,
  setFundraiserData,
  formData,
  setFormData = () => {},
}) => {
  const [loaded, setloaded] = useState(true);
  const token = stepsData?.topic?.name;

  const handleContinue = () => {
    if (
      hasArrayData(Object.keys(stepsData), [
        "beneficiary[name]",
        "beneficiary[toHelp]",
        "beneficiary[address][address1]",
      ])
    ) {
      setCurrentStep((Number(currentStep) + 1).toString());
    } else {
      toast.info(
        `Please select ${nameHasArrayData(
          Object.keys(stepsData),
          [
            "beneficiary[name]",
            "beneficiary[toHelp]",
            "beneficiary[address][address1]",
          ],
          ["Name of the organisation", "Funds raised will help", "Address"]
        )}`
      );
    }
  };

  const handleBack = () => {
    setCurrentStep((Number(currentStep) - 1).toString());
  };

  const onHandleChange = (e) => {
    let notEmptyFields = {};
    const name = e?.target?.name;
    const value = e?.target?.value;
    if (value.length > 0) {
      setStepsData((preState) => ({ ...preState, [name]: value }));
    } else {
      setStepsData((preState) => ({ ...preState, [name]: value }));
      Object.keys(stepsData)?.forEach((fields) => {
        if (fields !== name) notEmptyFields[`${fields}`] = stepsData[fields];
      });
      setStepsData(notEmptyFields);
    }
  };

  return (
    <>
      <div className="text-center space-y-2">
        <h1 className="text-base xl:text-lg font-Lexend font-semibold">
          Raising funds for {token} purpose
        </h1>
        <Steps data={steps} currentStep={currentStep} />
      </div>
      <form className="mt-8 space-y-4 mx-auto">
        <div className="relative">
          <Input
            label={"Name of the organisation"}
            inputPlaceholder={"Enter name"}
            inputName={"beneficiary[name]"}
            inputValue={stepsData?.[`beneficiary[name]`]}
            onChange={(e) => onHandleChange(e)}
            errorText={"Organization name is required!"}
            errorType={stepsData?.[`beneficiary[name]`] ? "" : "danger"}
          />
        </div>
        <div className="relative">
          <Input
            // inputClasses={"!border-r-0 !rounded-r-none"}
            label={"Funds raised will help"}
            inputPlaceholder={"Enter name"}
            inputName={"beneficiary[toHelp]"}
            inputValue={stepsData?.[`beneficiary[toHelp]`]}
            onChange={(e) => onHandleChange(e)}
            errorText={"Beneficiary name is required!"}
            errorType={stepsData?.[`beneficiary[toHelp]`] ? "" : "danger"}
          />
        </div>
        <div className="relative">
          <Input
            // inputClasses={"!border-r-0 !rounded-r-none"}
            label={"Based out of"}
            inputPlaceholder={"Enter city name"}
            inputName={"beneficiary[address][address1]"}
            inputValue={stepsData?.[`beneficiary[address][address1]`]}
            onChange={(e) => onHandleChange(e)}
            errorText={"City name is required!"}
            errorType={
              stepsData?.[`beneficiary[address][address1]`] ? "" : "danger"
            }
          />
        </div>
        <div className="relative flex justify-center gap-3">
          <Button
            buttonClasses={"w-full"}
            buttonType={"button"}
            buttonLabel={"Back"}
            buttonLabelClasses={""}
            buttonEffect={"filled"}
            buttonFunction={handleBack}
          />
          <Button
            buttonClasses={"w-full"}
            buttonType={"button"}
            buttonLabel={"Save & Continue"}
            buttonLabelClasses={""}
            buttonEffect={"filled"}
            buttonFunction={handleContinue}
          />
        </div>
      </form>
      <div className="text-xs text-slate-500 mt-4 text-center">
        By continuing you agree to
        <Button
          buttonClasses={
            "!px-0 !h-auto !bg-transparent !rounded-none !inline-flex mx-1"
          }
          buttonHasLink={true}
          buttonLink={"/"}
          buttonLabel={"Terms of Service"}
          buttonLabelClasses={
            "font-semibold text-carnation-500 !text-xs hover:!underline"
          }
        />
        and
        <Button
          buttonClasses={
            "!px-0 !h-auto !bg-transparent !rounded-none !inline-flex mx-1"
          }
          buttonHasLink={true}
          buttonLink={"/"}
          buttonLabel={"Privacy Policy"}
          buttonLabelClasses={
            "font-semibold text-carnation-500 !text-xs hover:!underline"
          }
        />
      </div>
    </>
  );
};

export default StepZero;
