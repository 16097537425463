import React from "react";
import Select from "../../../../form/Select";
import Input from "../../../../form/Input";
import SearchWithDropdown from "../../../../form/SearchWithDropdown";
import { numReg } from "../../../../../helpers";
import SelectOptionHeader from "../../../../form/SelectOptionHeader";
import { useState } from "react";
import { useEffect } from "react";
import { loadFundraiserRelationList } from "../../../../../services/FundrisersService";

const Others = ({
  data,
  setData = () => {},
  countryList = [],
  currentCountry,
  cityList = [],
}) => {
  const [relationList, setrelationList] = useState([]);
  const [relationListData, setrelationListData] = useState([]);
  const [loaded, setloaded] = useState(true);

  const onHandleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if(e.target.value){
      setData((pre) => ({ ...pre, [name]: value }));
    }else{
      delete data[name]
      setData({...data})
    }
  };

  useEffect(() => {
    setloaded(false);
    loadFundraiserRelationList().then((res) => {
      if (res?.data?.status === 200) {
        setrelationList(res.data?.docs);
        setloaded(true);
      } else {
        setloaded(true);
      }
    });
  }, []);

  useEffect(() => {
    setrelationListData(
      (Array.isArray(relationList) &&
        relationList.length > 0 &&
        relationList.map((elm) => ({
          optionHeader: elm?.name,
          options:
            Array.isArray(elm?.childs) && elm?.childs.length > 0 && elm?.childs,
        }))) ||
        []
    );
  }, [relationList]);

  return (
    <>
      <div className="flex gap-3 mb-3">
        <div className="relative w-full">
          <SelectOptionHeader
            xPlacement={"left"}
            name={"relationWithFundraiser"}
            label={"Whom are you raising funds for?"}
            dropdownData={[
              { name: "Select raising funds for" },
              ...relationListData,
            ]}
            dropdownClass={"max-h-60 w-full"}
            dropdownButtonClass={""}
            selectedValueType={"_id"}
            selectedValue={data?.relationWithFundraiser}
            setCustomSelecedvalue={(value) =>
              onHandleChange({
                target: { name: "relationWithFundraiser", value: value?._id },
              })
            }
          />
        </div>
        <div className="relative w-full">
          <Input
            label={"Beneficiary Name"}
            inputPlaceholder={"Beneficiary Name"}
            inputValue={data?.[`beneficiary[name]`]}
            isDisabled={
              relationList
                ?.map((item) => item.childs)
                .flat(Infinity)
                ?.find((item) => item?._id === data?.relationWithFundraiser)
                ?.name === "Myself" ||
              relationList
                ?.map((item) => item.childs)
                .flat(Infinity)
                ?.find((item) => item?._id === data?.relationWithFundraiser)
                ?.name === "self"
            }
            inputName={"beneficiary[name]"}
            onChange={(e) => {
              if (
                relationList
                  ?.map((item) => item.childs)
                  .flat(Infinity)
                  ?.find((item) => item?._id === data?.relationWithFundraiser)
                  ?.name === "Myself" ||
                relationList
                  ?.map((item) => item.childs)
                  .flat(Infinity)
                  ?.find((item) => item?._id === data?.relationWithFundraiser)
                  ?.name === "self"
              ) {
                return;
              } else {
                onHandleChange(e);
              }
            }}
          />
        </div>
      </div>
      <div className="flex gap-3 mb-3">
        <div className="relative w-full">
          <SearchWithDropdown
            label={"City"}
            placeholder={"Type your city"}
            dropdownData={cityList}
            setCustomSelecedvalue={(value) =>
              onHandleChange({
                target: {
                  name: "beneficiary[address][city]",
                  value: value?.name,
                },
              })
            }
            data={data?.[`beneficiary[address][city]`]}
          />
        </div>
        <div className="relative w-full flex">
          <div className="relative">
            <div class="text-sm font-medium text-slate-500 mb-1">
              {data?.[`beneficiary[age]`] === "Below 5 years" ||
              data?.[`beneficiary[age]`] === "6 -10 years" ||
              data?.[`beneficiary[age]`] === "11 - 17 years"
                ? "Gardian Contact No."
                : "Contact No."}
            </div>
            <div className="relative flex">
              <div className="w-20 min-w-[80px]">
                <Select
                  xPlacement={"left"}
                  dropdownData={countryList}
                  dropdownClass={"max-h-40"}
                  dropdownButtonClass={"!px-2 !border-r-none rounded-r-none"}
                  selectedValue={
                    countryList?.find(
                      (item) => item?.name === data?.[`beneficiary[phoneCode]`]
                    )?._id
                  }
                  setCustomSelecedvalue={(val) =>
                    onHandleChange({
                      target: {
                        name: "beneficiary[phoneCode]",
                        value: val?.name,
                      },
                    })
                  }
                />
              </div>
              <div className="w-full">
                <Input
                  inputPlaceholder={"Enter your phone number"}
                  inputClasses={"!border-l-none rounded-l-none -ml-[1px]"}
                  inputName={"beneficiary[phone]"}
                  inputValue={data?.[`beneficiary[phone]`]}
                  onChange={(e) => {
                    if (numReg.test(e.target.value)) {
                      if (e.target.value?.length > 10) {
                        return;
                      } else {
                        onHandleChange(e);
                      }
                    } else {
                      return;
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Others;
