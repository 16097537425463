import React from "react";
import SingleFundraised from "../../items/SingleFundraised";

const FundraisedList = ({ data = [] }) => {
  return (
    <>
      <div className="relative space-y-5 px-0 py-4  xl:px-4 xl:py-4">
        {data.length > 0 &&
          data.map((item) => <SingleFundraised key={item._id} data={item} />)}
      </div>
    </>
  );
};

export default FundraisedList;
