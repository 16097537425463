import React from "react";

const InactivateModal = ({ isOpen, onCancel, onConfirm, loaded }) => {
  return (
    <div
      className={`fixed inset-0 flex items-center justify-center ${
        isOpen ? "block" : "hidden"
      } bg-black bg-opacity-50 z-50`}
    >
      <div className="modal-overlay" onClick={onCancel}></div>
      <div className="modal-container bg-slate-100 w-1/3 mx-auto rounded shadow-lg z-50">
        <div className="modal-content py-4 text-center px-6">
          <div className="text-amber-500 text-4xl mb-4">
            <i className="fas fa-exclamation-triangle"></i>
          </div>
          <h1 className="text-xl font-semibold mb-4">
            Are you sure you want to Inactivate the fundraiser?
          </h1>
          <div className="mt-4 flex items-center justify-center">
            <button
              className="btn-primary mr-2 w-[85px] bg-carnation-500 text-white p-1 rounded-md "
              onClick={() => {
                onConfirm();
                onCancel();
              }}
              disabled={!loaded}
            >
              {loaded ? (
                "Inactive"
              ) : (
                <div
                  class=" h-6 w-6 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] flex items-center justify-between m-auto"
                  role="status"
                >
                  <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                    Loading...
                  </span>
                </div>
              )}
            </button>
            <button
              className="btn-secondary bg-blue-500 text-white p-1 rounded-md  w-[80px]"
              onClick={onCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InactivateModal;
