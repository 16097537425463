import React from 'react';
import { useSelector } from "react-redux";
import noImage from "../../assets/images/no-data-found.png"
import NoDataFound from "../../components/elements/extra/NoDataFound";
const Dashboard = () => {
  // const profileData = useSelector((state) => state.profile.profileData);
  // console.log(profileData,'profileData')

  return (
    <>
      {/* <img src={noImage}/> */}
      <NoDataFound/>
    </>
  );
};

export default Dashboard;