import React from "react";
import Image from "../elements/Image";
import Button from "../form/Button";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import BannerSkeletonLoader from "../elements/loader/BannerSkeletonLoader";

const MainBanner = ({ data }) => {
  const token = localStorage.getItem("auth_token");

  return (
    <>
      {data && data?.loaded ? (
        <section className="relative overflow-hidden w-full z-0 h-[500px] xl:h-[680px] flex items-center">
          <>
            <div className="absolute top-0 left-0 w-full h-full z-[-1] after:absolute after:content-[''] after:w-full after:h-full after:top-0 after:left-0 after:bg-gradient-to-r after:from-white/95 after:from-10% after:via-white/80 after:via-50%">
              <Image
                src={data.image}
                alt={""}
                width={"100%"}
                height={"100%"}
                effect={"blur"}
                classes={
                  "object-cover saturate-200 object-right sm:object-center"
                }
              />
            </div>
            <div className="relative z-[1] w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto flex items-center">
              <div className="w-4/5 sm:w-2/3 md:w-2/3 lg:w-3/5 xl:w-3/5 2xl:w-1/2 3xl:w-2/5 space-y-8">
                <div className="space-y-4">
                  {/* <div className="font-Lexend text-base uppercase font-semibold text-black">{data.subtitle}</div> */}
                  <h1
                    className="font-Lexend text-3xl sm:text-4xl xl:text-5xl uppercase font-bold text-black"
                    dangerouslySetInnerHTML={{ __html: data.title }}
                  ></h1>
                </div>
                <div className="text-sm xl:text-base text-slate-600">
                  {data.description}
                </div>

                <div className="flex items-center !mt-10">
                  <Button
                    buttonClasses={"!px-5"}
                    buttonHasLink={true}
                    buttonLink={token ? data.actionLink : "/register"}
                    buttonLabel={data.actionLabel}
                    buttonEffect={"filled"}
                  />
                </div>
              </div>
            </div>
          </>
        </section>
      ) : (
        <BannerSkeletonLoader />
      )}
    </>
  );
};

export default MainBanner;
