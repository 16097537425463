import React from "react";
import { Link } from "react-router-dom";
import Image from "../elements/Image";
import Button from "../form/Button";
import dayjs from "dayjs";

const SingleCareer = (data) => {

  return (
    <>
      <div
        className={
          "w-full flex flex-col bg-white rounded-4xl rounded-tl-none overflow-hidden transition-all duration-200 hover:shadow-lg"
        }
      >
        {/* <div
          className={
            data?.smallImage
              ? "w-full data.smallImage?'70%': h-52 xl:h-80 3xl:h-96 overflow-hidden rounded-4xl rounded-tl-none"
              : "h-52 xl:h-80 3xl:h-96 overflow-hidden rounded-4xl rounded-tl-none"
          }
        >
          <Link to={`/job-details/${data?.data?._id}`}>
          
            <Image
              src={data?.data?.image?.url}
              alt={""}
              width={"100%"}
              height={"100%"}
              effect={"blur"}
              classes={"object-cover"}
            />
          </Link>
        </div> */}
        <div className="py-6 px-4">
          <Link to={`/job-details/${data?.data?.slug}`}>
            <h4 className="text-base font-Lexend text-black font-medium leading-tight">
              {data?.data?.title}
            </h4>
            {/* <div className="text-xs xl:text-sm text-slate-600 my-2">
              {data.description}
            </div> */}
            <div
              className="text-xs xl:text-sm text-slate-600 my-2 text-ellips-desc"
              dangerouslySetInnerHTML={{ __html: data?.data?.description }}
            />
            <div className="relative mt-4">
              <div
                className="flex items-center"
                style={{ justifyContent: "space-between" }}
              >
                {/* <div className="w-4 h-100% overflow-hidden flex justify-center text-black relative z-10">
                  <Image
                    src={data.Image1}
                    alt={""}
                    width={"100%"}
                    height={"100%"}
                    effect={"blur"}
                    classes={"object-cover"}
                  />
                </div> */}
                <div className="text-late-900 text-sm items-center flex gap-2">
                  <i class="fa-solid fa-calendar-days"></i>
                  {dayjs(data?.data?.createdAt).format("MMMM D, YYYY")}
                </div>
                <div className="text-late-900 text-sm items-center flex gap-2">
                  <i class="fa-solid fa-location-dot"></i>
                  {data?.data?.location?.city}
                </div>
              </div>
              {/* <div className="flex items-center gap-2">
                {data.image2}
                <div className="w-4 h-100% overflow-hidden flex justify-center text-black relative z-10">
                  <Image
                    src={data.Image2}
                    alt={""}
                    width={"100%"}
                    height={"100%"}
                    effect={"blur"}
                    classes={"object-cover"}
                  />
                </div>
                <div className="text-late-900 text-sm">{data?.data?.location?.city}</div>
              </div> */}
            </div>
          </Link>
          {/* <div className="flex items-center justify-start mt-6">
            {data.applyLink !== undefined && <Button
              buttonClasses={'!px-8 !h-10 !ml-auto'}
              buttonHasLink={true}
              buttonLink={""}
              buttonLabel={'Apply'}
              buttonLabelClasses={'text-sm'}
              buttonEffect={"filled"}
            />}
            {data.shareText !== undefined && <Button
              buttonClasses={'!px-8 !h-10 !mr-auto !bg-transparent !text-carnation-500 border border-carnation-500'}
              buttonHasLink={true}
              buttonLink={""}
              buttonLabel={'Share'}
              buttonLabelClasses={'text-sm'}
              buttonEffect={"hollow"}
            />}
          </div> */}
        </div>
      </div>
    </>
  );
};

export default SingleCareer;
