import React, { useState } from "react";
import noDataImg from "../../../assets/images/extra/No-data.svg";
import Image from "../../elements/Image";
import PreviewModal from "../../elements/modal/PreviewModal";

const Documents = ({ data = [] }) => {
  const [previewDocument, setPreviewDocument] = useState(null);


  const openPreview = (document, index) => {
    setPreviewDocument(document);
    
  };

  const closePreview = () => {
    setPreviewDocument(null);
  };

  return (
    <div className="flex flex-wrap gap-5">
      {Array.isArray(data) && data.length > 0 ? (
        data.map((document, index) => (
          <div key={document?._id} style={{ width: "130px", height: "100px", overflow: "hidden", borderRadius: "12px" }}>
            {document?.extension !== "pdf" ? (
              <img
                src={document?.url}
                className="w-full h-full object-cover cursor-pointer"
                alt="Document Preview"
                onClick={() => openPreview(document, index)}
              />
            ) : (
              <i
                className="fa-duotone fa-file-pdf text-9xl"
                style={{ color: '#ef4f5e', fontSize: '100px', cursor: 'pointer' }}
                onClick={() => openPreview(document, index)}
              ></i>
            )}
          </div>
        ))
      ) : (
        <div className="flex items-center justify-center">
          <div className="w-1/2">
            <Image
              src={noDataImg}
              classes={"w-100 h-100 object-contain object-center"}
            />
          </div>
        </div>
      )}

      {previewDocument && (
        <PreviewModal
          url={previewDocument.url}
          extension={previewDocument.extension}
          onClose={closePreview}
        />
      )}
    </div>
  );
};

export default Documents;
