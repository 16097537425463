import PostSidebar from "../../../shared/PostSidebar";
import { useParams } from "react-router-dom/dist/umd/react-router-dom.development";
import NoDataFound from "../../elements/extra/NoDataFound";
import Input from "../../form/Input";
import QuilEditor from "../../elements/quilEditor/QuilEditor";
import { useState } from "react";
import Button from "../../form/Button";
import {
  addFundraiser,
  addUpdateService,
  loadFundraiserDetails,
} from "../../../services/FundrisersService";
import { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const Update = () => {
  const navigate = useNavigate();
  const authData = useSelector((state) => state?.profile?.profileData);
  const [submitComplete, setSubmitComplete] = useState(true);
  const { id } = useParams();
  const location = useLocation();

  const [formData, setFormData] = useState({});
  const [details, setDetails] = useState({
    loaded: true,
    data: {},
  });

  useEffect(() => {
    if (location?.state?._id) {
      setFormData({
        "updates[title]": details?.data?.updates?.find(
          (item) => item?._id === location?.state?._id
        )?.title,
        "updates[description]": details?.data?.updates?.find(
          (item) => item?._id === location?.state?._id
        )?.description,
      });
    }
  }, [location?.state?._id, details?.data]);

  // load details ===========>

  useEffect(() => {
    if (id) {
      setDetails({ loaded: false, data: {} });
      loadFundraiserDetails(id).then((res) => {
        if (res.status === 200) {
          setDetails({ loaded: true, data: res.data });
        } else {
          setDetails({ loaded: true, data: {} });
        }
      });
    }
  }, [id]);

  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData((pre) => ({ ...pre, [name]: value }));
  };

  const onSubmit = () => {
    const requiredFields = ["title", "description"];
    const missingFields = requiredFields.filter((field) => !formData[field]);

    if (missingFields.length > 0) {
      // Display toast message for missing fields
      toast.error(
        `Please provide information for: ${missingFields.join(", ")}`
      );
      return;
    } else {
      setSubmitComplete(false);
      addUpdateService({ id: details?.data?._id, ...formData }).then((res) => {
        if (res?.data?.status === 201) {
          navigate(`/dashboard/${id}`);
          toast.success(res?.data?.message);
          setSubmitComplete(true);
        } else {
          setSubmitComplete(true);
        }
      });
    }
  };

  return (
    <section className="relative  overflow-hidden w-full py-5 bg-gray-100">
      <div className="relative z-[1] w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
        <div className="w-full flex md:flex-nowrap flex-wrap gap-4 md:gap-0">
          <div className="w-full md:w-80 min-w-[320px] ">
            <PostSidebar id={id} />
          </div>

          <div className="p-5 relative bg-white rounded-r-lg w-full max-w-[calc(100%-320px)])] overflow-hidden">
            <div className="my-3">
              <h4 className="text-xl font-semibold">Add Update</h4>
            </div>
            <div className="relative mb-3">
              <Input
                label={"Title"}
                inputPlaceholder={"Enter Title"}
                inputName={"title"}
                inputValue={formData?.title}
                onChange={onChangeHandler}
                errorText={"Title is required!"}
                errorType={formData?.title ? "" : "danger"}
              />
            </div>
            <div className="relative">
              <QuilEditor
                // label="Description"
                placeholder="Write your current update..."
                name={"description"}
                onChange={(val) => onChangeHandler(val)}
                value={formData?.description}
              />
            </div>
            <div className="mt-2 flex justify-end">
              <Button
                buttonLabel={"Submit"}
                buttonType={"submit"}
                buttonFunction={onSubmit}
                isDisable={!submitComplete}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Update;
