import { useState } from "react";
import img from "../../../../../../assets/images/icons/image.svg";
import pdf from "../../../../../../assets/images/icons/pdf.svg";
import audio from "../../../../../../assets/images/icons/audio.svg";
import video from "../../../../../../assets/images/icons/video.svg";
import doc from "../../../../../../assets/images/icons/doc.svg";
import { formatBytes } from "../../../../../../helpers";
import TailwindModal from "../../../../modal/TailwindModal";
import Delete from "../../../../extra/Delete";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const StorageItem = ({ data = {}, setData = () => {}, fundraiserId }) => {
  const { id } = useParams();
  const [option, setOption] = useState(false);
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    target: {
      data: {},
      api_end: "fund-raiser/delete-image",
    },
  });
  const [modalOpen, setModalOpen] = useState({
    isOpen: false,
    details: {},
  });
  const doc_type = {
    image: ["jpg", "png", "jpeg", "hello"],
  };

  const deleteResponseHandler = (res) => {
    if (res?.data?.status === 200) {
      setData((pre) => ({
        ...pre,
        data: pre.data.filter(
          (item) => item._id !== deleteModal?.target?.data?.imageId
        ),
      }));
      setDeleteModal({
        target: { data: {}, api_end: undefined },
        isOpen: false,
      });
      toast.success(res?.data?.message);
    } else {
      toast.info(res?.data?.message);
    }
  };

  return (
    <div
      onMouseEnter={() => setOption(true)}
      onMouseLeave={() => setOption(false)}
      className={`storage-items relative w-28 shadow-sm bg-gray-100 rounded-md overflow-hidden h-32 min-h-32 max-h-32 flex flex-col justify-between cursor-pointer`}
    >
      <div
        className={`flex justify-center items-center p-2 ${
          doc_type.image.includes(data?.extension) ||
          data?.mediaType === "image"
            ? " h-full"
            : " h-20 min-h-20 max-h-20"
        }`}
      >
        <div
          className={`icon ${
            doc_type.image.includes(data?.extension) ||
            data?.mediaType === "image"
              ? "w-full"
              : "w-2/3"
          } h-full`}
        >
          <img
            src={
              doc_type.image.includes(data?.extension) ||
              data?.mediaType === "image"
                ? data.url
                : data?.extension === "pdf"
                ? pdf
                : data?.extension === "doc"
                ? doc
                : data?.mediaType === "audio"
                ? audio
                : data?.mediaType === "video" && video
            }
            alt="icon"
            className={`w-full h-full ${
              doc_type.image.includes(data?.extension) ||
              data?.mediaType === "image"
                ? "object-cover"
                : "object-contain"
            } object-center`}
          />
        </div>
      </div>
      {(!doc_type.image.includes(data?.extension) ||
        (data?.mediaType && data?.mediaType != "image")) && (
        <div className="file-details px-2 py-1 bg-carnation-200 h-12 min-h-12 max-h-12 flex flex-col justify-center">
          <div className="title font-semibold text-xs whitespace-nowrap text-ellipsis w-full overflow-hidden">
            {data?.filename}
          </div>
          <div className="size font-semibold text-slate-500 text-[12px]">
            {formatBytes(data?.size)}
          </div>
        </div>
      )}
      {option && (
        <div className="bg-carnation-100/60 absolute top-0 left-0 w-full h-full flex justify-center items-center">
          <div className="flex justify-center items-center gap-3">
            <span
              onClick={() => setModalOpen({ isOpen: true, details: data })}
              className="flex justify-center items-center text-sm bg-white p-2 rounded-md w-8 h-8 hover:scale-110 transition-all"
            >
              <i className="fa-regular fa-circle-info"></i>
            </span>
            <span
              onClick={() =>
                setDeleteModal((pre) => ({
                  isOpen: true,
                  target: {
                    data: {
                      fundraiserId: fundraiserId,
                      imageId: data?._id,
                      path: "bankDocs",
                    },
                    api_end: "fund-raiser/delete-image",
                  },
                }))
              }
              className="flex justify-center items-center text-sm bg-carnation-600 p-2 text-white rounded-md w-8 h-8 hover:scale-110 transition-all"
            >
              <i className="fa-regular fa-trash"></i>
            </span>
          </div>
        </div>
      )}
      {/* ============D E T A I L S   M O D A L========== */}
      <TailwindModal
        size="xl3"
        title={
          <div className="text-center">{modalOpen?.details?.filename}</div>
        }
        isOpen={modalOpen?.isOpen}
        setOpen={() => {
          setModalOpen({ isOpen: false, details: {} });
          setOption(false);
        }}
      >
        {modalOpen?.details?.mediaType === "image" && (
          <img
            src={modalOpen?.details?.url}
            alt={modalOpen?.details?.filename}
            className="mx-auto"
          />
        )}
        {modalOpen?.details?.mediaType === "docs" && (
          <embed
            src={modalOpen?.details?.url}
            className="w-full mx-auto h-screen"
          />
        )}
        {modalOpen?.details?.mediaType === "audio" && (
          <audio controls className="w-full">
            <source
              src={modalOpen?.details?.url}
              type={`audio/${modalOpen?.details?.url
                ?.split("/")
                .at(-1)
                .split(".")
                .at(-1)}`}
            />
          </audio>
        )}
        {modalOpen?.details?.mediaType === "video" && (
          <>
            <video className="w-full h-[300px]" controls>
              <source
                src={modalOpen?.details?.url}
                type={`video/${modalOpen?.details?.url
                  ?.split("/")
                  .at(-1)
                  .split(".")
                  .at(-1)}`}
              />
            </video>
          </>
        )}
      </TailwindModal>
      {/* ============D E L E T E   M O D A L========== */}
      <TailwindModal
        title={<div className="text-center">Delete Item</div>}
        secondaryTitle={
          <div className="etxt-center text-center px-4">
            You are trying to delete the record, click "Delete" below.
          </div>
        }
        isOpen={deleteModal?.isOpen}
        setOpen={() => {
          setDeleteModal({ isOpen: false, target: {} });
        }}
      >
        <Delete
          onCancel={(val) =>
            setDeleteModal({
              target: { data: {}, api_end: undefined },
              isOpen: val,
            })
          }
          data={deleteModal?.target}
          response={(res) => deleteResponseHandler(res)}
        />
      </TailwindModal>
    </div>
  );
};

export default StorageItem;
