import React, { useState, useEffect } from "react";
import { setTitle } from "../helpers/MetaTag";
import { Link } from "react-router-dom";
import Image from "../components/elements/Image";
import Logo from "../assets/images/logo.png";
import StepOne from "../components/partials/fundraising/StepOne";
import StepZero from "../components/partials/fundraising/StepZero";
import StepTwo from "../components/partials/fundraising/StepTwo";
import StepThree from "../components/partials/fundraising/StepThree";
import StepFour from "../components/partials/fundraising/StepFour";
import { addFundraiser } from "../services/FundrisersService";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { fetchProfile } from "../store/Profile/profileData";

const NewFundraising = () => {
  setTitle("Crowd Funding | NewFundraising");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [addComplete, setAddComplete] = useState(true);
  const [currentStep, setCurrentStep] = useState("1");
  const [stepsData, setStepsData] = useState({
    documents: [],
    gallery: [],
  });

  const [flag, setFlag] = useState(false);
  const [formData, setFormData] = useState([]);
  const [steps, setSteps] = useState([
    {
      _id: 1,
      name: "Step",
      number: "1",
      function: () => {
        setCurrentStep("1");
      },
      component: StepOne,
    },
    {
      _id: 2,
      name: "Step",
      number: "2",
      function: () => {
        setCurrentStep("2");
      },
      component: StepTwo,
    },
    {
      _id: 3,
      name: "Step",
      number: "3",
      function: () => {
        setCurrentStep("3");
      },
      component: StepThree,
    },
    {
      _id: 4,
      name: "Step",
      number: "4",
      function: () => {
        setCurrentStep("4");
      },
      component: StepFour,
    },
  ]);

  useEffect(() => {
    dispatch(fetchProfile());
  }, []);

  useEffect(() => {
    setFormData(Object.keys(stepsData));
    if (stepsData?.topic?.name) {
      if (stepsData?.topic?.name === "NGO/Charity") {
        setSteps([
          {
            _id: 1,
            name: "Step",
            number: "1",
            function: () => {
              setCurrentStep("1");
            },
            component: StepOne,
          },
          {
            _id: 2,
            name: "Step",
            number: "2",
            function: () => {
              setCurrentStep("2");
            },
            component: StepZero,
          },
          {
            _id: 3,
            name: "Step",
            number: "3",
            function: () => {
              setCurrentStep("3");
            },
            component: StepTwo,
          },
          {
            _id: 4,
            name: "Step",
            number: "4",
            function: () => {
              setCurrentStep("4");
            },
            component: StepThree,
          },
          {
            _id: 5,
            name: "Step",
            number: "5",
            function: () => {
              setCurrentStep("5");
            },
            component: StepFour,
          },
        ]);
      } else {
        setSteps([
          {
            _id: 1,
            name: "Step",
            number: "1",
            function: () => {
              setCurrentStep("1");
            },
            component: StepOne,
          },
          {
            _id: 2,
            name: "Step",
            number: "2",
            function: () => {
              setCurrentStep("2");
            },
            component: StepTwo,
          },
          {
            _id: 3,
            name: "Step",
            number: "3",
            function: () => {
              setCurrentStep("3");
            },
            component: StepThree,
          },
          {
            _id: 4,
            name: "Step",
            number: "4",
            function: () => {
              setCurrentStep("4");
            },
            component: StepFour,
          },
        ]);
      }
    }
  }, [stepsData]);

  const onSubmit = () => {
    if (stepsData?.title) {
      setAddComplete(false);
      addFundraiser(stepsData).then((res) => {
        if (res?.data?.status === 201) {
          setFlag(false);
          toast.success(res?.data?.message);
          setStepsData(null);
          setAddComplete(true);
          navigate(`/fundraiser`);
        } else {
          setAddComplete(true);
          toast.error(res?.data?.message);
        }
      });
    } else {
      toast.info("Please enter title");
    }
  };

  return (
    <>
      <div className="w-full min-h-screen bg-gray-100 flex items-center justify-center py-5 px-5">
        <div className="w-full max-w-lg xl:max-w-xl bg-white py-10 px-4 sm:px-10 rounded-3xl">
          <div className="mx-auto w-full">
            <div className="w-full h-16 mx-auto mb-8">
              <Link to={"/"}>
                <Image
                  src={Logo}
                  alt={"Logo"}
                  width={"100%"}
                  height={"100%"}
                  effect={"blur"}
                  classes={"object-contain"}
                />
              </Link>
            </div>
            {Array.isArray(steps) &&
              steps?.length > 0 &&
              steps.map((item, index) =>
                item.number === currentStep ? (
                  <item.component
                    key={index}
                    steps={steps}
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    stepsData={stepsData}
                    setStepsData={setStepsData}
                    flag={flag}
                    setFlag={setFlag}
                    onSubmit={onSubmit}
                    formData={formData}
                    setFormData={setFormData}
                    isSubmit={addComplete}
                  />
                ) : (
                  ""
                )
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewFundraising;
