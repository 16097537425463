import React from "react";
import Tabs from "../../components/elements/Tabs";
import SupporterList from "../../components/partials/supporter/SupporterList";
import Team01 from "../../assets/images/team/team-01.jpg";
import Team02 from "../../assets/images/team/team-02.jpg";
import Team03 from "../../assets/images/team/team-03.jpg";
import Team04 from "../../assets/images/team/team-04.jpg";
import Team05 from "../../assets/images/team/team-05.jpg";
import Team06 from "../../assets/images/team/team-06.jpg";
import Team07 from "../../assets/images/team/team-07.jpg";
import Team08 from "../../assets/images/team/team-08.jpg";
import { useEffect, useState } from "react";
import { loadSupporterList } from "../../services/SupportersService";
import dayjs from "dayjs";
import Loader from "../../components/elements/loader/Loader";
import noData from "../../assets/images/no-data-found.png";

const Supporters = () => {
  const [loaded, setloaded] = useState(true);
  const [supporters, setsupporters] = useState([]);

  useEffect(() => {
    loadSupporterList({}, setsupporters, setloaded).then((res) => {});
  }, []);

  const donationListData = [
    {
      _id: 1,
      title:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.",
      donation: 2000,
      date: "10 Jul 2023",
      transactionId: "TXN0428636",
      method: "UPI",
      status: "success",
    },
    {
      _id: 2,
      title:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.",
      donation: 6000,
      date: "10 Jul 2023",
      transactionId: "TXN5462025",
      method: "Credit Card",
      status: "pending",
    },
    {
      _id: 3,
      title:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.",
      donation: 3000,
      date: "10 Jul 2023",
      transactionId: "TXN9856822",
      method: "Bank Transfer",
      status: "failed",
    },
    {
      _id: 4,
      title:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.",
      donation: 5000,
      date: "10 Jul 2023",
      transactionId: "TXN2132487",
      method: "Debit Card",
      status: "success",
    },
    {
      _id: 5,
      title:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.",
      donation: 4000,
      date: "10 Jul 2023",
      transactionId: "TXN3325740",
      method: "PAYTM",
      status: "pending",
    },
  ];

  const supporterListDataa =
    Array.isArray(supporters) &&
    supporters.length > 0 &&
    supporters.map((elm, index) => ({
      _id: elm?._id,
      name: elm?.supporter?.name,
      image: elm?.supporter?.image?.url,
      donation: elm?.totalDonation,
      donationList:
        Array.isArray(elm.donations) &&
        elm.donations.length > 0 &&
        elm.donations.map((ele, index) => ({
          _id: index,
          title: ele?.transactionInfo,
          donation: ele?.donation,
          date: dayjs(ele?.createdAt).format("MMM D, YYYY"),
          transactionId: ele?.transactionId,
          method: ele?.paymentMethod,
          status: ele?.paymentStatus,
        })),
    }));

  const supporterListData = [
    {
      _id: 1,
      name: "Janet R. Hoffman",
      image: Team02,
      donation: 10000,
      donationList: donationListData,
    },
    {
      _id: 2,
      name: "Jeff C. Casey",
      image: Team01,
      donation: 10000,
      donationList: donationListData,
    },
    {
      _id: 3,
      name: "Jeremy A. Burrell",
      image: Team04,
      donation: 10000,
      donationList: donationListData,
    },
    {
      _id: 4,
      name: "Stephanie F. Sanfilippo",
      image: Team03,
      donation: 10000,
      donationList: donationListData,
    },
    {
      _id: 5,
      name: "Carl V. Powers",
      image: Team05,
      donation: 10000,
      donationList: donationListData,
    },
    {
      _id: 6,
      name: "Ray N. Hand",
      image: Team06,
      donation: 10000,
      donationList: donationListData,
    },
    {
      _id: 7,
      name: "Richard S. Bryden",
      image: Team07,
      donation: 10000,
      donationList: donationListData,
    },
    {
      _id: 8,
      name: "Lester B. Heath",
      image: Team08,
      donation: 10000,
      donationList: donationListData,
    },
  ];

  const tabData = [
    {
      _id: 1,
      name: "Supporters",
      component: <SupporterList data={supporterListData} />,
    },
  ];
  return Array.isArray(supporters) && supporters.length > 0 ? (
    loaded ? (
      <>
        {" "}
        <SupporterList data={supporterListDataa} />
      </>
    ) : (
      <Loader />
    )
  ) : (
    <img src={noData} />
  );
  // <>
  //   <div className="relative w-full">
  //     {/* <Tabs section={"supporter"} tabs={tabData} design={"style"} /> */}

  //   </div>
  // </>
};

export default Supporters;
