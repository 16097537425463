import React, { useEffect, useState } from "react";
import blogIamge from "../assets/images/patient-05.png";
import Image from "../components/elements/Image";
import { loadPostDetails } from "../services/PostService";
import { useParams } from "react-router";
import dayjs from "dayjs";
import Loader from "../components/elements/loader/Loader";

const BlogDetails = () => {
  const [details, setDetails] = useState({});
  const [loaded, setLoaded] = useState(true);

  const { id } = useParams();
  useEffect(() => {
    loadPostDetails(id, setLoaded).then((res) => {
      setDetails(res?.data.data);
    });
  }, []);

  return (
    <>
      {loaded === false ? (
        <Loader />
      ) : (
        <section className="relative w-full py-10 lg:py-20 bg-gray-100">
          <div className="relative flex justify-center mb-4">
            {/* <div className='text-3xl font-bold text-slte-800'>Blog Details</div> */}
            {details && details?.type === "news" ? (
              <div className="text-3xl font-bold text-slte-800">
                News Details
              </div>
            ) : (
              <div className="text-3xl font-bold text-slte-800">
                Blog Details
              </div>
            )}
          </div>
          <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
            <div className="realative">
              <div className="w-full  h-[500px] rounded-xl overflow-hidden">
                <Image
                  src={details?.image?.url}
                  alt={""}
                  width={"100%"}
                  height={"100%"}
                  effect={"blur"}
                  classes={"object-cover"}
                />
              </div>
              <div className="relative flex justify-center">
                <div className="relative w-10/12">
                  <h2 className="text-2xl font-medium text-slate-900 leading-8 mt-6">
                    {details?.title}
                  </h2>
                  <div className="flex justify-between items-center mt-3">
                    <div style={{display:"flex"}} className="text-sm text-slate-500 font-normal">
                    <img
                  src={details.author?.image?.url}
                 style={{height:"20px",width:"20px",borderRadius:"50%",marginRight:"5px"}}
                />{details?.author && "By " + details.author?.fullname?.firstName +" "+details.author?.fullname?.lastName}
                    </div>
                    <div className="text-sm text-slate-500 font-normal">
                      {dayjs(details?.publishDate).format("MMM D, YYYY")}
                    </div>
                  </div>
                  <div
                    className="text-sm font-normal text-slate-500 mt-4"
                    dangerouslySetInnerHTML={{ __html: details?.description }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default BlogDetails;
