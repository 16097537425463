import React from "react";
import Input from "../../components/form/Input";
import Select from "../../components/form/Select";
import InputFile from "../../components/form/InputFile";
import { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  UpdateProfile,
  deleteImage,
  loadCityList,
  loadCountryList,
  loadProfileDetails,
} from "../../services/ProfileService";
import { useEffect } from "react";
import dayjs from "dayjs";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchWithDropdown from "../../components/form/SearchWithDropdown";
import { fetchProfile } from "../../store/Profile/profileData";
import { useDispatch, useSelector } from "react-redux";
import {
  getCountryDetails,
  loadCountries,
} from "../../services/CountryService";
import { numReg } from "../../helpers";
import TextAreaAutoSize from "../../components/form/TextareaAutoSize";
import Button from "../../components/form/Button";
import DeleteConfirmationModal from "../../components/elements/modal/DeleteModal";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function Profile() {
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.profile.profileData);
  const [deletePopupOpen, setDeletePopupOpen] = useState({
    isOpen: false,
    loading: false,
  });

  const [image, setimage] = useState("");
  const [loaded, setLoaded] = useState(true);
  const [photo, setphoto] = useState(null);
  const [cityList, setcityList] = useState([]);
  const [coords, setCoords] = useState({
    lat: undefined,
    lon: undefined,
  });
  const [countryListData, setCountryListData] = useState([]);
  const [currentCountry, setCurrentCountry] = useState({});
  const [updateComplete, setUpdateComplete] = useState(true);

  //  new address state by buddhadev ==================>
  const [formData, setFormData] = useState({});
  const [address, setAddress] = useState({});

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setCoords({
        lat: position?.coords?.latitude,
        lon: position?.coords?.longitude,
      });
    });
  }, []);

  // get country list ===========>

  useEffect(() => {
    loadCountries().then((res) => {
      if (res?.status === 200) {
        setCountryListData(
          res?.docs?.map((country) => ({
            name: "+" + country?.phone_code?.replace("+", ""),
            country_name: country?.name,
            _id: country?._id,
          }))
        );
      }
    });
  }, []);

  // get current country details ==========>

  useEffect(() => {
    if (coords?.lon && coords?.lat && countryListData) {
      getCountryDetails({ lat: coords?.lat, lon: coords?.lon }).then((res) => {
        setCurrentCountry({
          name: countryListData?.find(
            (item) => item?.country_name === res?.address?.country
          )?.name,
        });
      });
    }
  }, [coords, countryListData]);

  useEffect(() => {
    if (profileData?._id) {
      setFormData({
        photo: profileData?.image?.url,
        chequePhoto: profileData?.cheque?.url,
        name: profileData?.name,
        phone: profileData?.phone,
        phoneCode: profileData?.phoneCode,
        email: profileData?.email,
        pan: profileData?.detailsOfPan?.panNumber,
        nameInPan: profileData?.detailsOfPan?.nameInPan,
        accountHolderName: profileData?.bankDetails?.name,
        accountNumber: profileData?.bankDetails?.accountNo,
        ifsc: profileData?.bankDetails?.ifscCode,
        bankName: profileData?.bankDetails?.bankName,
        aadharNumber: profileData?.aadharNo,
        facebook: profileData?.social[0]?.url,
        twitter: profileData?.social[1]?.url,
        dateOfBirth: profileData?.dateOfBirth
          ? new Date(profileData?.dateOfBirth).toISOString().split("T")[0]
          : false,
      });
    }
    setimage(profileData?.image?.url);
    setAddress(profileData?.address);
  }, [profileData]);

  // load city list =======>

  useEffect(() => {
    if (address?.country) {
      loadCityList({ name: address?.country }).then((res) => {
        if (res?.data?.status === 200) {
          setcityList(res.data.docs[0]?.cities.map((city) => ({ name: city })));
        }
      });
    }
  }, [address?.country]);

  const handleUpdate = (e) => {
    e.preventDefault();
    const requiredFields = [
      "name",
      "email",
      "dateOfBirth",
      "phone",
      "city",
      "state",
      "country",
      "zipcode",
      "phoneCode",
      "nameInPan",
      "pan",
    ];
    // const missingFields = requiredFields.filter((field) => !formData[field]);
    const missingFields = requiredFields.filter((field) => {
      // Check if the field is missing in either formData or address
      return !formData[field] && !address[field];
    });

    // const displayNames = {
    //   "address[city]": "city",
    //   "address[state]": "state",
    //   "address[country]": "country",
    //   "address[zipcode]": "zipcode",
    // };
    if (missingFields.length > 0) {
      // const displayMissingFields = missingFields.map(
      //   (field) => displayNames[field] || field
      // );
      toast.error(
        `Please provide information for: ${missingFields.join(", ")}`
      );

      return;
    } else {
      const addressData = {};
      Object.keys(address).length > 0 &&
        Object.keys(address).map(
          (item) =>
            address[item] != "null" &&
            address[item] &&
            item !== "type" &&
            (addressData[`address[${item}]`] = address[item])
        );

      setUpdateComplete(false);
      UpdateProfile(
        {
          ...formData,
          image: image,
          address: addressData,
        },
        setLoaded
      ).then((res) => {
        if (res.data.status === 200) {
          toast.success(res.data.message);
          setUpdateComplete(true);
          setTimeout(() => {
            dispatch(fetchProfile());
          }, 1000);
        } else {
          setUpdateComplete(true);
          toast.error(res.data.message);
        }
      });
    }
  };

  const onChangeAddressHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setAddress((pre) => ({ ...pre, [name]: value }));
  };
  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData((pre) => ({ ...pre, [name]: value }));
  };
  useEffect(() => {
    image === undefined &&
      profileData?.image?.url &&
      setimage(profileData?.image?.url);
  }, [image]);

  const imageType = ["jpg", "jpeg", "png", "webp", "ico"];

  return (
    <>
      <nav class="flex overflow-auto border-b border-slate-200 px-4 mb-4 xl:px-8 gap-5 xl:gap-6">
        <button
          type="button"
          class="relative py-1 xl:py-4 rounded-t-2xl transition-all duration-200 border-b-2 border-transparent px-0 !border-carnation-500"
        >
          <div class="whitespace-nowrap text-sm font-semibold !text-carnation-500">
            Personal Details
          </div>
        </button>
      </nav>

      {loaded ? (
        <div className="px-4">
          <form className="p-3 flex items-start gap-3">
            <div className="relative  w-8/12">
              <div className="p-4 border border-solid border-slate-200 rounded mb-4">
                <div className="flex flex-col gap-3">
                  <div className="relative w-full">
                    <div className="relative">
                      <InputFile
                        label={"Profile Image"}
                        inputPlaceholder={"Upload Image"}
                        inputValue={
                          image
                            ? {
                                image:
                                  image instanceof File
                                    ? URL.createObjectURL(image)
                                    : image,
                                name: image?.name,
                              }
                            : { image: photo && photo, name: photo && "Avater" }
                        }
                        handleFile={(e) => {
                          e &&
                          imageType.includes(
                            e.target?.files[0]?.type?.split("/")[1]
                          ) !== undefined
                            ? setimage(e.target.files[0])
                            : toast.info(
                                "Please select only jpg, jpeg, png or webp type image."
                              );
                        }}
                        clearImage={() =>
                          image && profileData?.image?.url === image
                            ? setDeletePopupOpen({
                                isOpen: true,
                                loading: false,
                              })
                            : setimage(profileData?.image?.url)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-4 border border-solid border-slate-200 rounded  mb-4">
                <div className="flex justify-between items-center overflow-auto gap-5 xl:gap-6">
                  <div className="relative mb-2 rounded-t-2xl transition-all duration-200 border-b-2 border-transparent px-0">
                    <div className="whitespace-nowrap text-sm font-medium text-slate-900 capitalize">
                      Contact Details
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-between gap-3 mb-3">
                  <div className="relative w-full">
                    <div className="relative">
                      <Input
                        label={"Full name"}
                        labelClasses={"!capitalize text-xs text-slate-500"}
                        inputType={"text"}
                        inputPlaceholder={"Enter your full name"}
                        className={
                          "capitalize text-sm placeholder:text-slate-300 rounded-md outline-none border border-solid border-slate-300 w-full "
                        }
                        inputName={"name"}
                        onChange={(e) => onChangeHandler(e)}
                        inputValue={formData?.name}
                        errorText={"Full name is required!"}
                        errorType={formData?.name ? "" : "danger"}
                      />
                    </div>
                  </div>
                </div>

                <div className="relative w-full mb-3">
                  <div className="relative">
                    <Input
                      label={"Email"}
                      labelClasses={"!text-xs"}
                      inputType={"email"}
                      errorType={formData?.email ? "" : "danger"}
                      errorText={"Email is required!"}
                      className={
                        "capitalize text-sm placeholder:text-slate-300 rounded-md outline-none border border-solid border-slate-300 w-full "
                      }
                      inputName={"email"}
                      onChange={(e) => onChangeHandler(e)}
                      inputValue={formData?.email}
                    />
                  </div>
                </div>

                <div className="flex items-center justify-between gap-3 mb-3">
                  <div className="relative w-full">
                    <div className="capitalize text-xs text-slate-500 pb-1">
                      Date Of Birth
                    </div>
                    <div className="relative">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          format={"DD-MM-YYYY"}
                          onChange={(e) =>
                            onChangeHandler({
                              target: {
                                name: "dateOfBirth",
                                value: dayjs(e?.$d).format("YYYY-MM-DD"),
                              },
                            })
                          }
                          value={
                            formData?.dateOfBirth
                              ? dayjs(formData?.dateOfBirth)
                              : ""
                          }
                          className="pt-0 pb-0 w-full h-10 rounded-md bg-slate-50 border border-slate-200 px-3 text-sm text-slate-600 !ring-0 !outline-0 focus:border-carnation-200 focus:bg-carnation-50 transition-all duration-200 "
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="relative w-full">
                    <div className="relative">
                      <div className="capitalize text-xs text-slate-500 pb-1">
                        Mobile No.
                      </div>
                      <div className="relative flex ">
                        <div className="w-20 min-w-[70px]">
                          <Select
                            xPlacement={"left"}
                            dropdownData={countryListData}
                            dropdownClass={"max-h-40"}
                            dropdownButtonClass={
                              "!px-2 !border-r-none rounded-r-none"
                            }
                            selectedValue={
                              Object.keys(currentCountry).length > 0
                                ? countryListData?.find(
                                    (item) =>
                                      item?.name === currentCountry?.name
                                  )?._id
                                : "6408455b56c702cdfce36f96"
                            }
                            setCustomSelecedvalue={(val) =>
                              onChangeHandler({
                                target: { name: "phoneCode", value: val?.name },
                              })
                            }
                            disabled={true}
                          />
                        </div>
                        <div className="w-full">
                          <Input
                            inputType={"number"}
                            inputPlaceholder={"Enter your phone number"}
                            inputClasses={
                              "!border-l-none rounded-l-none -ml-[1px]"
                            }
                            inputName={"phone"}
                            onChange={(e) => onChangeHandler(e)}
                            inputValue={formData?.phone}
                            errorText={"Phone number is required!"}
                            errorType={formData?.phone ? "" : "danger"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* ============ B A N K   D E T A I L S =============> */}

              <div className="p-4 border border-solid border-slate-200 rounded  mb-4">
                <div className="flex justify-between items-center overflow-auto gap-5 xl:gap-6">
                  <div className="relative mb-2 rounded-t-2xl transition-all duration-200 border-b-2 border-transparent px-0">
                    <div className="whitespace-nowrap text-sm font-medium text-slate-900 capitalize">
                      {/* Beneficiary Bank details */}
                      Pan details
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-between gap-3 mb-3 mt-3">
                  <div className="relative w-full">
                    <div className="relative">
                      <Input
                        label={"PAN no."}
                        labelClasses={"!text-xs"}
                        inputType={"text"}
                        inputPlaceholder={"Enter your PAN card number"}
                        errorType={formData?.pan ? "" : "danger"}
                        errorText={"PAN number is required!"}
                        className={
                          " capitalize text-sm placeholder:text-slate-300 rounded-md outline-none border border-solid border-slate-300 w-full !pl-20"
                        }
                        inputName={"pan"}
                        onChange={(e) => onChangeHandler(e)}
                        inputValue={formData?.pan}
                      />
                    </div>
                  </div>

                  <div className="relative w-full">
                    <div className="relative">
                      <Input
                        label={"Name in PAN card"}
                        labelClasses={"!text-xs"}
                        inputType={"text"}
                        inputPlaceholder={"Enter the name"}
                        errorType={formData?.nameInPan ? "" : "danger"}
                        errorText={"Name in PAN is required!"}
                        className={
                          "capitalize text-sm placeholder:text-slate-300 rounded-md outline-none border border-solid border-slate-300 w-full "
                        }
                        inputName={"nameInPan"}
                        onChange={(e) => onChangeHandler(e)}
                        inputValue={formData?.nameInPan}
                      />
                    </div>
                  </div>
                </div>

                {/* address for pan */}

                <div className="relative w-full">
                  <TextAreaAutoSize
                    label={"Address"}
                    inputPlaceholder={"Enter address"}
                    inputName={"address1"}
                    inputValue={address?.address1}
                    onChange={(e) => onChangeAddressHandler(e)}
                    errorType={address?.address1 ? "" : "danger"}
                    errorText={"Address is required!"}
                  />
                </div>

                <div className="flex items-center justify-between gap-3 mb-3 mt-3">
                  <div className="relative w-full">
                    <SearchWithDropdown
                      label={"Select Country"}
                      placeholder={"Type your country name"}
                      dropdownData={
                        Array.isArray(countryListData) &&
                        countryListData?.length > 0 &&
                        countryListData?.map((country) => ({
                          _id: country?._id,
                          name: country?.country_name,
                        }))
                      }
                      setCustomSelecedvalue={(value) =>
                        onChangeAddressHandler({
                          target: { name: "country", value: value?.name },
                        })
                      }
                      data={address?.country}
                    />
                  </div>
                  <div className="relative w-full">
                    <Input
                      label={"State"}
                      labelClasses={"!text-xs"}
                      inputType={"text"}
                      inputPlaceholder={"Enter your state"}
                      className={
                        " capitalize text-sm placeholder:text-slate-300 rounded-md outline-none border border-solid border-slate-300 w-full !pl-20"
                      }
                      inputName={"state"}
                      onChange={(e) => onChangeAddressHandler(e)}
                      inputValue={address?.state}
                      errorText={"State name is required!"}
                      errorType={address?.state ? "" : "danger"}
                    />
                  </div>
                </div>
                <div className="flex items-center justify-between gap-3 mb-3 mt-3">
                  <div className="relative w-full">
                    <SearchWithDropdown
                      label={"Select City"}
                      placeholder={"Type your city"}
                      dropdownData={cityList}
                      setCustomSelecedvalue={(value) =>
                        onChangeAddressHandler({
                          target: { name: "city", value: value?.name },
                        })
                      }
                      data={address?.city}
                      disabled={!address?.country}
                    />
                    {!address?.country && (
                      <span className="text-carnation-500 text-sm absolute -bottom-5 left-0">
                        <i class="fa-regular fa-fw fa-circle-info text-sm mr-1"></i>
                        <span>Please select country first</span>
                      </span>
                    )}
                  </div>
                  <div className="relative w-full">
                    <Input
                      label={"Zip code"}
                      labelClasses={"!text-xs"}
                      inputType={"text"}
                      inputPlaceholder={"Enter your zipcode"}
                      errorType={address?.zipcode ? "" : "danger"}
                      errorText={"ZIP code is required!"}
                      className={
                        " capitalize text-sm placeholder:text-slate-300 rounded-md outline-none border border-solid border-slate-300 w-full !pl-20"
                      }
                      inputName={"zipcode"}
                      onChange={(e) => {
                        if (numReg.test(e.target.value)) {
                          onChangeAddressHandler(e);
                        } else {
                          return;
                        }
                      }}
                      inputValue={address?.zipcode}
                    />
                  </div>
                </div>
              </div>

              <Button
                buttonClasses={"w-full"}
                buttonType={"button"}
                buttonLabel={"Save"}
                buttonLabelClasses={""}
                buttonEffect={"filled"}
                buttonFunction={handleUpdate}
                isDisable={!updateComplete}
              />
            </div>
            <div className="relative  w-1/2">
              <div className="p-4 border border-solid border-slate-200 rounded mb-3">
                <div className="flex justify-between items-center overflow-auto gap-5 xl:gap-6">
                  <div className="relative mb-2 rounded-t-2xl transition-all duration-200 border-b-2 border-transparent px-0">
                    <div className="whitespace-nowrap text-sm font-medium text-slate-900 capitalize">
                      Socail media
                    </div>
                  </div>
                </div>
                <div className="relative mb-4">
                  <div className="relative w-full">
                    <div className="relative w-full">
                      <div className="relative">
                        <Input
                          label={"Facebook link"}
                          labelClasses={"!text-xs pb-1"}
                          inputType={"text"}
                          inputPlaceholder={"Enter your Facebook"}
                          errorType={""}
                          className={
                            "capitalize text-sm placeholder:text-slate-300 rounded-md outline-none border border-solid border-slate-200 w-full "
                          }
                          inputName={"facebook"}
                          onChange={(e) => onChangeHandler(e)}
                          inputValue={formData?.facebook}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="relative w-full mb-4">
                  <div className="relative">
                    <Input
                      label={"Twitter link"}
                      labelClasses={"!text-xs pb-1"}
                      inputType={"text"}
                      inputPlaceholder={"Enter your Twitter"}
                      errorType={""}
                      className={
                        "capitalize text-sm placeholder:text-slate-300 rounded-md outline-none border border-solid border-slate-200 w-full "
                      }
                      inputName={"twitter"}
                      onChange={(e) => onChangeHandler(e)}
                      inputValue={formData?.twitter}
                    />
                  </div>
                </div>
                <div className="relative">
                  <div className="relative w-full"></div>
                </div>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <Skeleton count={20} />
      )}

      <DeleteConfirmationModal
        isOpen={deletePopupOpen?.isOpen}
        loaded={!deletePopupOpen?.loading}
        onCancel={() => setDeletePopupOpen({ isOpen: false, loading: false })}
        onConfirm={() => {
          setDeletePopupOpen((pre) => ({ ...pre, loading: true }));
          setimage("");
          setphoto(null);
          deleteImage().then((res) => {
            if (res?.data.status === 200) {
              toast.success(res.data.message);
              setDeletePopupOpen({ isOpen: false, loading: false });
              setTimeout(() => {
                dispatch(fetchProfile());
              }, 1000);
            } else {
              setDeletePopupOpen({ isOpen: false, loading: false });
              toast.error(res.message);
            }
          });
        }}
      />
    </>
  );
}
