import { apiRequest } from "../utils/async/apiUtils";

export const loadTransectionsList = async (
    params,
    setTransections = () => {},
    setLoaded = () => {}
  ) => {
    setLoaded(false);
   
    try {
      const res = await apiRequest("get", "transactions",{
        queries: {
          ...params
       }
      });
     
      const data = res.data;
      setTransections(data.docs);
      setLoaded(true);
      return res;
    } catch (err) {
      setLoaded(true);
    }
  };