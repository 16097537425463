// import React from 'react';

// const BannerFormAlt = () => {
//     return (
//         <div>
            
//         </div>
//     );
// };

// export default BannerFormAlt;


import React from 'react';
import Input from "../form/Input";
import Select from "../form/Select";
import Button from "../form/Button";

const BannerFormAlt = () => {
  const dropdownData = [
    { name: '+91' },
    { name: '+1' },
    { name: '+44' },
    { name: '+93' },
    { name: '+355' },
    { name: '+213' },
    { name: '+1684' },
    { name: '+376' },
    { name: '+244' },
    { name: '+1264' },
    { name: '+1268' },
    { name: '+54' },
  ]
  
  const dropdownData2 = [
    { name: 'What will be the funds used for?' },
    { name: 'Medical crowdfunding' },
    { name: 'Memorial crowdfunding' },
    { name: 'Cancer crowdfunding' },
    { name: 'Education crowdfunding' },
    { name: 'Child welfare' },
    { name: 'Transplant crowdfunding' },
  ]
  
  return (
    <>
      <div className="relative w-full max-w-sm 3xl:max-w-md bg-white rounded-2xl shadow-lg ml-auto my-auto">
        <h2 className="font-Lexend text-xl md:text-2xl text-black font-semibold py-4 px-6 text-center">We know you are in urgent need of funds.</h2>
        <div className="relative border-t border-slate-200 py-6 px-6">
          <div className="space-y-4">
            <div className="relative">
              <Input
                inputType={"text"}
                inputPlaceholder={"Enter your name"}
              />
            </div>
            <div className="relative flex">
              <div className="w-20 min-w-[80px]">
                <Select
                  xPlacement={"left"}
                  dropdownData={dropdownData}
                  dropdownClass={"max-h-40"}
                  dropdownButtonClass={"!px-2 !border-r-none rounded-r-none"}
                  selectedValue={""}
                />
              </div>
              <div className="w-full">
                <Input
                  inputType={"tel"}
                  inputPlaceholder={"Mobile no"}
                  inputClasses={"!border-l-none rounded-l-none -ml-[1px]"}
                />
              </div>
            </div>
            <div className="relative">
              <Select
                xPlacement={"left"}
                dropdownData={dropdownData2}
                dropdownClass={"max-h-40 w-full"}
                dropdownButtonClass={""}
                selectedValue={"What will be the funds used for?"}
              />
            </div>
            <div className="relative">
              <Button
                buttonClasses={'w-full'}
                buttonHasLink={false}
                buttonLabel={"Continue"}
                buttonLabelClasses={'text-base'}
                buttonEffect={"filled"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerFormAlt;