import React, { useEffect, useState } from 'react';
import {setTitle} from '../helpers/MetaTag'; 

import Banner from "../assets/images/banner-page.jpg";
import Patient01 from "../assets/images/patient-01.png";
import Patient02 from "../assets/images/patient-02.png";
import Patient03 from "../assets/images/patient-03.png";
import MainBannerPage from '../components/sections/MainBannerPage';
import ListCareer from '../components/sections/ListCareer';

import Image1 from "../assets/images/clock.png";
import Image2 from "../assets/images/location.png";
import Loader from '../components/elements/loader/Loader';
import { loadJobList } from '../services/CareerService';


const Careers = () => {
  setTitle('Crowd Funding | Browse Fundraiser');
const [jobData,setJobData]=useState([])
const [loaded,setLoaded]=useState(true)


useEffect(()=>{
 loadJobList(setJobData,setLoaded)
},[])

  const  bannerData = {
    image: Banner,
    title: "Careers",
    description: "Join the Movement to Protect Young Lives and Make a Lasting Impact",
    actionLink:"/job-details",
    actionLabel:"Start giving monthly",
    image:"",
  }

  const CareerData = {
    title:"Career Opportunity",
    subtitle:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form",
    subdata: [
      {
        _id:1,
        Image1:Image1,
        Image2:Image2,
        name:"Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        time:"Full time",
        location:"Location",
        raised:70000,
        goal: 100000,
        shareLink:"",
        shareText:"Share",
        donateLink:"",
        applyLink:"Apply",
        smallImage:false,
      },
      {
        _id:2,
        Image1:Image1,
        Image2:Image2,
        name:"Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        time:"Full time",
        location:"Location",
        raised:70000,
        goal: 100000,
        shareLink:"",
        shareText:"Share",
        donateLink:"",
        applyLink:"Apply",
        smallImage:false,
      },
      {
        _id:3,
        Image1:Image1,
        Image2:Image2,
        name:"Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        time:"Full time",
        location:"Location",
        raised:70000,
        goal: 100000,
        shareLink:"",
        shareText:"Share",
        donateLink:"",
        applyLink:"Apply",
        smallImage:false,
      },
      {
        _id:4,
        Image1:Image1,
        Image2:Image2,
        name:"Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        time:"Full time",
        location:"Location",
        raised:70000,
        goal: 100000,
        shareLink:"",
        shareText:"Share",
        donateLink:"",
        applyLink:"Apply",
        smallImage:false,

      },
      {
        _id:5,
        Image1:Image1,
        Image2:Image2,
        name:"Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        time:"Full time",
        location:"Location",
        raised:70000,
        goal: 100000,
        shareLink:"",
        shareText:"Share",
        donateLink:"",
        applyLink:"Apply",
        smallImage:false,
      },
      {
        _id:6,
        Image1:Image1,
        Image2:Image2,
        name:"Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        time:"Full time",
        location:"Location",
        raised:70000,
        goal: 100000,
        shareLink:"",
        shareText:"Share",
        donateLink:"",
        applyLink:"Apply",
      },
      {
        _id:7,
        Image1:Image1,
        Image2:Image2,
        name:"Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        time:"Full time",
        location:"Location",
        raised:70000,
        goal: 100000,
        shareLink:"",
        shareText:"Share",
        donateLink:"",
        applyLink:"Apply",
        smallImage:false,
      },
      {
        _id:8,
        Image1:Image1,
        Image2:Image2,
        name:"Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        time:"Full time",
        location:"Location",
        raised:70000,
        goal: 100000,
        shareLink:"",
        shareText:"Share",
        donateLink:"",
        applyLink:"Apply",
        smallImage:false,
      },
      {
        _id:9,
        Image1:Image1,
        Image2:Image2,
        name:"Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        time:"Full time",
        location:"Location",
        raised:70000,
        goal: 100000,
        shareLink:"",
        // shareText:"Share",
        donateLink:"",
        applyLink:"Apply",
        smallImage:false,
      },
    ]
  }

  return (
    <>
      <MainBannerPage data={bannerData} />
    <ListCareer data={jobData} loaded={loaded}/>
      
    </>
  );
};

export default Careers;