
import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";
import { API_BASE_URL } from "../../utils/constant/ApiConstant";

export const profileSlice = createSlice({
    name: 'profile',
    loading: false,
    initialState: {
        profileData: {}
    },
    reducers:{
        listRequested: (state, action)=>{
            state.loading = true;
        },
        listRequestFailed: (state, action)=>{
            state.loading = false;
            state.profileData ='';
            //console.log(action.payload);
        },
        listReceived: (state, action)=>{
            state.loading = false;
           state.profileData = action.payload.data?action.payload.data:{};
        }
    }
});


export const {listReceived,listRequested ,listRequestFailed } = profileSlice.actions;
//console.log(body);
export const fetchProfile = (body)=> apiCallBegan({
    url: '/profile',
    method: 'get',
    data: body,
    baseUrl: API_BASE_URL,
    onSuccess: listReceived.type,
    onStart: listRequested.type,
    onError: listRequestFailed.type
});














