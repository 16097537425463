import React from "react";
import PanSection from "../reg_for_ngo/PanSection";
import TaxSection from "../reg_for_ngo/TaxSection";
import ForeignFunds from "../reg_for_ngo/ForeignFunds";
import { useState } from "react";
import {
  getCountryDetails,
  loadCountries,
} from "../../../../services/CountryService";
import { useEffect } from "react";

const NgoContact = ({
  stepsData = {},
  setStepsData = () => {},
  editData = {},
}) => {
  const [coords, setCoords] = useState({
    lat: undefined,
    lon: undefined,
  });
  const [countryList, setCountryList] = useState([]);
  const [currentCountry, setCurrentCountry] = useState({});
  const [taxData, setTaxData] = useState({
    is80G: false,
  });
  const [panData, setPanData] = useState({
    isContactPerson: false,
  });
  const [foreignFundsData, setForeignFundsData] = useState({
    isForeignFund: false,
  });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setCoords({
        lat: position?.coords?.latitude,
        lon: position?.coords?.longitude,
      });
    });
  }, []);

  // get country list ===========>

  useEffect(() => {
    loadCountries().then((res) => {
      if (res?.status === 200) {
        setCountryList(
          res?.docs?.map((country) => ({
            name: "+" + country?.phone_code?.replace("+", ""),
            country_name: country?.name,
            _id: country?._id,
          }))
        );
      }
    });
  }, []);

  // get current country details ==========>

  useEffect(() => {
    if (coords?.lon && coords?.lat && countryList) {
      getCountryDetails({ lat: coords?.lat, lon: coords?.lon }).then((res) => {
        setCurrentCountry({
          name: countryList?.find(
            (item) => item?.country_name === res?.address?.country
          )?.name,
        });
        setTaxData((pre) => ({
          ...pre,
          phoneCode: countryList?.find(
            (item) => item?.country_name === res?.address?.country
          )?.name,
        }));
        setPanData((pre) => ({
          ...pre,
          phoneCode: countryList?.find(
            (item) => item?.country_name === res?.address?.country
          )?.name,
        }));
      });
    }
  }, [coords, countryList]);

  useEffect(() => {
    if (!panData?.isContactPerson) {
      setTaxData({ is80G: false });
      setForeignFundsData({ isForeignFund: false });
    }
  }, [panData]);

  useEffect(() => {
    if (editData?._id) {
      setPanData((pre) => ({
        ...pre,
        isContactPerson: editData?.isContactPerson,
        "detailsOfPan[nameInPan]": editData?.detailsOfPan?.nameInPan,
        "detailsOfPan[panNumber]": editData?.detailsOfPan?.panNumber,
        "contactPerson[name]": editData?.contactPerson?.name,
        "contactPerson[email]": editData?.contactPerson?.email,
        "contactPerson[phoneCode]": editData?.contactPerson?.phoneCode,
        "contactPerson[phone]": editData?.contactPerson?.phone,
      }));
      setTaxData((pre) => ({
        ...pre,
        is80G: editData?.is80G,
        certificate80G: editData?.detailsOf80G?.certificate80G?.map((file) => ({
          _id: file?._id,
          file: file?.url,
          type: file?.extension,
          name: file?.filename,
        })),
        "detailsOf80G[email]": editData?.detailsOf80G?.email,
        "detailsOf80G[phone]": editData?.detailsOf80G?.phone,
        "detailsOf80G[phoneCode]": editData?.detailsOf80G?.phoneCode,
        "detailsOf80G[organisationType]":
          editData?.detailsOf80G?.organisationType,
        "detailsOf80G[registrationSection]":
          editData?.detailsOf80G?.registrationSection,
        "detailsOf80G[registrationNumber]":
          editData?.detailsOf80G?.registrationNumber,
        "detailsOf80G[registrationAddress]":
          editData?.detailsOf80G?.registrationAddress,
      }));
      setForeignFundsData((pre) => ({
        ...pre,
        isForeignFund: editData?.isForeignFund,
        "detailsOfForeignFund[isFcra]": editData?.detailsOfForeignFund?.isFcra,
        "detailsOfForeignFund[is501c3]":
          editData?.detailsOfForeignFund?.is501c3,
        "detailsOfForeignFund[fcraRegistrationNumber]":
          editData?.detailsOfForeignFund?.fcraRegistrationNumber,
        "detailsOfForeignFund[name501c3]":
          editData?.detailsOfForeignFund?.name501c3,
        "detailsOfForeignFund[employeeId]":
          editData?.detailsOfForeignFund?.employeeId,
        "detailsOfForeignFund[address][address1]":
          editData?.detailsOfForeignFund?.address?.address1,
        "detailsOfForeignFund[authorisedRepresentative]":
          editData?.detailsOfForeignFund?.authorisedRepresentative,
        fcraCertificate: editData?.detailsOfForeignFund?.fcraCertificate?.map(
          (file) => ({
            file: file?.url,
            name: file?.filename,
            type: file?.extension,
            _id: file?._id,
          })
        ),
      }));
    } else {
      // this extra object using for keep data in input fields if the back from steps ===========================>
      const pan = {
        isContactPerson: stepsData?.isContactPerson || panData?.isContactPerson,
        "contactPerson[email]": stepsData?.[`contactPerson[email]`],
        "contactPerson[name]": stepsData?.[`contactPerson[name]`],
        "contactPerson[phoneCode]":
          stepsData?.[`contactPerson[phoneCode]`] || "+91",
        "contactPerson[phone]": stepsData?.[`contactPerson[phone]`],
        // ---------------------->
        "detailsOfPan[nameInPan]": stepsData?.[`detailsOfPan[nameInPan]`],
        "detailsOfPan[panNumber]": stepsData?.[`detailsOfPan[panNumber]`],
      };
      Object.keys(pan)?.forEach((item) => {
        if (item != "isContactPerson") {
          pan[item] == undefined && delete pan[item];
        }
      });
      const tax = {
        is80G: stepsData?.is80G || taxData?.is80G,
        "detailsOf80G[email]": stepsData?.[`detailsOf80G[email]`],
        "detailsOf80G[organisationType]":
          stepsData?.[`detailsOf80G[organisationType]`],
        "detailsOf80G[phone]": stepsData?.[`detailsOf80G[phone]`],
        "detailsOf80G[phoneCode]":
          stepsData?.[`detailsOf80G[phoneCode]`] || "+91",
        "detailsOf80G[registrationAddress]":
          stepsData?.[`detailsOf80G[registrationAddress]`],
        "detailsOf80G[registrationNumber]":
          stepsData?.[`detailsOf80G[registrationNumber]`],
        // ------------------>
        "detailsOf80G[registrationSection]":
          stepsData?.[`detailsOf80G[registrationSection]`],
      };
      Object.keys(tax)?.forEach((item) => {
        if (item != "is80G") {
          tax[item] == undefined && delete tax[item];
        }
      });
      const foreign = {
        isForeignFund:
          stepsData?.isForeignFund || foreignFundsData?.isForeignFund,
        "detailsOfForeignFund[fcraRegistrationNumber]":
          stepsData?.[`detailsOfForeignFund[fcraRegistrationNumber]`],
        "detailsOfForeignFund[is501c3]":
          stepsData?.[`detailsOfForeignFund[is501c3]`],
        "detailsOfForeignFund[isFcra]":
          stepsData?.[`detailsOfForeignFund[isFcra]`],
        // --------- for enable 501c3------------>
        "detailsOfForeignFund[address][address1]":
          stepsData?.[`detailsOfForeignFund[address][address1]`],
        "detailsOfForeignFund[authorisedRepresentative]":
          stepsData?.[`detailsOfForeignFund[authorisedRepresentative]`],
        "detailsOfForeignFund[employeeId]":
          stepsData?.[`detailsOfForeignFund[employeeId]`],
        "detailsOfForeignFund[name501c3]":
          stepsData?.[`detailsOfForeignFund[name501c3]`],
      };
      Object.keys(foreign)?.forEach((item) => {
        if (item != "isForeignFund") {
          foreign[item] == undefined && delete foreign[item];
        }
      });

      setPanData({ ...pan });
      setTaxData({ ...tax });
      setForeignFundsData({ ...foreign });
    }
  }, [editData?._id]);

  // this section use for remove data dynamically from steps by true false options    =================>

  useEffect(() => {
    if (panData?.isContactPerson) {
      delete stepsData?.[`contactPerson[email]`];
      delete stepsData?.[`contactPerson[name]`];
      delete stepsData?.[`contactPerson[phoneCode]`];
      delete stepsData?.[`contactPerson[phone]`];
      setStepsData({ ...stepsData });
    }
    if (!panData?.isContactPerson) {
      delete stepsData?.[`detailsOfPan[nameInPan]`];
      delete stepsData?.[`detailsOfPan[panNumber]`];
      // tax data remove ==========>
      delete stepsData?.[`detailsOf80G[email]`];
      delete stepsData?.[`detailsOf80G[organisationType]`];
      delete stepsData?.[`detailsOf80G[phone]`];
      delete stepsData?.[`detailsOf80G[registrationAddress]`];
      delete stepsData?.[`detailsOf80G[registrationNumber]`];
      delete stepsData?.[`detailsOf80G[registrationSection]`];
      delete stepsData?.[`detailsOf80G[phoneCode]`];
      delete stepsData?.certificate80G;
      // foreign data remove ==========>
      delete stepsData?.[`detailsOfForeignFund[fcraRegistrationNumber]`];
      delete stepsData?.[`detailsOfForeignFund[is501c3]`];
      delete stepsData?.[`detailsOfForeignFund[isFcra]`];
      delete stepsData?.[`detailsOfForeignFund[address][address1]`];
      delete stepsData?.[`detailsOfForeignFund[authorisedRepresentative]`];
      delete stepsData?.[`detailsOfForeignFund[employeeId]`];
      delete stepsData?.[`detailsOfForeignFund[name501c3]`];
      delete stepsData?.fcraCertificate;
      setStepsData({ ...stepsData });
    }
  }, [panData?.isContactPerson]);

  useEffect(() => {
    if (taxData?.is80G === false) {
      // Create a copy of stepsData
      const updatedStepsData = { ...stepsData };

      // Delete properties using dynamic property names
      delete updatedStepsData["detailsOf80G[email]"];
      delete updatedStepsData["detailsOf80G[organisationType]"];
      delete updatedStepsData["detailsOf80G[phone]"];
      delete updatedStepsData["detailsOf80G[registrationAddress]"];
      delete updatedStepsData["detailsOf80G[registrationNumber]"];
      delete updatedStepsData["detailsOf80G[registrationSection]"];
      delete updatedStepsData["detailsOf80G[phoneCode]"];
      delete updatedStepsData.certificate80G;
      // Set the updated state
      setStepsData(updatedStepsData);
    }
  }, [taxData?.is80G]);

  useEffect(() => {
    if (taxData?.is80G === false) {
      setStepsData((prevStepsData) => {
        const updatedStepsData = { ...prevStepsData };
        delete updatedStepsData["detailsOf80G[email]"];
        delete updatedStepsData["detailsOf80G[organisationType]"];
        // ... (delete other properties)
        delete updatedStepsData.certificate80G;
        return updatedStepsData;
      });
    }
  }, [taxData?.is80G]);

  useEffect(() => {
    if (!foreignFundsData?.isForeignFund) {
      delete stepsData?.[`detailsOfForeignFund[fcraRegistrationNumber]`];
      delete stepsData?.[`detailsOfForeignFund[is501c3]`];
      delete stepsData?.[`detailsOfForeignFund[isFcra]`];
      delete stepsData?.[`detailsOfForeignFund[address][address1]`];
      delete stepsData?.[`detailsOfForeignFund[authorisedRepresentative]`];
      delete stepsData?.[`detailsOfForeignFund[employeeId]`];
      delete stepsData?.[`detailsOfForeignFund[name501c3]`];
      delete stepsData?.fcraCertificate;
      setStepsData({ ...stepsData });
    }
  }, [foreignFundsData?.isForeignFund]);

  useEffect(() => {
    if (!foreignFundsData?.[`detailsOfForeignFund[isFcra]`]) {
      delete stepsData?.fcraCertificate;
      delete stepsData?.[`detailsOfForeignFund[fcraRegistrationNumber]`];
      setStepsData({ ...stepsData });
    }
  }, [foreignFundsData?.[`detailsOfForeignFund[isFcra]`]]);

  useEffect(() => {
    if (!foreignFundsData?.[`detailsOfForeignFund[is501c3]`]) {
      delete stepsData?.[`detailsOfForeignFund[address][address1]`];
      delete stepsData?.[`detailsOfForeignFund[authorisedRepresentative]`];
      delete stepsData?.[`detailsOfForeignFund[employeeId]`];
      delete stepsData?.[`detailsOfForeignFund[name501c3]`];
      setStepsData({ ...stepsData });
    }
  }, [foreignFundsData?.[`detailsOfForeignFund[is501c3]`]]);

  useEffect(() => {
    if (taxData[`detailsOf80G[organisationType]`] !== "Trust") {
      delete stepsData[`detailsOf80G[registrationSection]`];
      setStepsData({ ...stepsData });
    }
    setStepsData((pre) => ({
      ...pre,
      ...taxData,
      ...panData,
      ...foreignFundsData,
    }));
  }, [taxData, panData, foreignFundsData]);

  // ===========================================================================>

  return (
    <>
      <PanSection
        countryList={countryList}
        currentCountry={currentCountry}
        panData={panData}
        setPanData={setPanData}
        stepsData={stepsData}
        setStepsData={setStepsData}
      />

      {panData.isContactPerson && (
        <>
          <TaxSection
            countryList={countryList}
            currentCountry={currentCountry}
            setTaxData={setTaxData}
            taxData={taxData}
            stepsData={stepsData}
            setStepsData={setStepsData}
          />

          <ForeignFunds
            foreignFundsData={foreignFundsData}
            setForeignFundsData={setForeignFundsData}
            stepsData={stepsData}
            setStepsData={setStepsData}
          />
        </>
      )}
    </>
  );
};

export default NgoContact;
