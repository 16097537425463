import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import reducer from "./reducer";
import api from './middleware/api';
const ConfigureStore = () => configureStore({
    reducer,
    middleware: [
        ...getDefaultMiddleware({ serializableCheck: false}),
        api
    ]
});

export default ConfigureStore