import React from "react";
import noDataImg from "../../../assets/images/extra/No-data.svg";
import Image from "../../elements/Image";

const About = ({ data = {} }) => {
  return (
    <>
      {Object.keys(data)?.length > 0 ? (
        <div className="relative space-y-2 xl:space-y-4">
          <h4 className="text-lg font-semibold text-black">{data.title}</h4>
          <div
            className="text-[13px] xl:text-sm text-slate-600 space-y-6"
            dangerouslySetInnerHTML={{ __html: data.content }}
          ></div>
        </div>
      ) : (
        <div className="flex items-center justify-center">
          <div className="w-1/2">
            <Image
              src={noDataImg}
              classes={"w-100 h-100 object-contain object-center"}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default About;
