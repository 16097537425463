import React from 'react';
import { classNames } from "../../helpers/classNames";
import Image from "../elements/Image";
import Image2 from "../../assets/images/crowd-funding2.jpg";

const DescriptiveWithImageAlt = ({ data, imagePosition }) => {
  return (
    <>
      <section className="relative overflow-hidden w-full py-16 bg-white">
        <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
          <div className={classNames("w-full flex flex-col justify-between md:flex-row xl:items-center sm:gap-5 lg:gap-4", imagePosition !== 'left' ? "flex-row-reverse" : "")}>
            {/* <div className="w-full sm:w-5/12"> */}
            <div className="w-full md:w-6/12">
              <div className="lg:w-full h-[430px] overflow-hidden rounded-5xl rounded-tl-none">
                <Image src={Image2} alt={''} width={'100%'} height={'100%'} effect={'blur'} classes={'object-cover'} />
              </div>
            </div>
            <div className="w-full md:w-6/12">
              <div className="space-y-8">
                <h2 className="font-Lexend text-3xl sm:text-4xl xl:text-5xl font-bold text-black">{data.title}</h2>
                <div className="text-sm xl:text-base text-slate-600 space-y-4" dangerouslySetInnerHTML={{ __html: data.description }}></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DescriptiveWithImageAlt;