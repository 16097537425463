import React from "react";

const FileUpload = ({
  onChange = () => {},
  label = "Upload",
  className = "",
  labelClass = "",
}) => {
  const fileSelectHandler = (e) => {
    onChange(e.target.files[0]);
  };

  return (
    <div
      className={`relative bg-carnation-500 h-10 rounded-md flex items-center justify-center cursor-pointer ${className}`}
    >
      <span
        className={`absolute text-center text-white font-normal ${labelClass}`}
      >
        {label}
      </span>
      <input
        type="file"
        className="w-full h-full top-0 left-0 absolute opacity-0"
        onChange={(e) => fileSelectHandler(e)}
      />
    </div>
  );
};

export default FileUpload;
