import React, { useEffect, useState } from "react";
import { setTitle } from "../helpers/MetaTag";
import MainBannerPage from "../components/sections/MainBannerPage";

import Banner from "../assets/images/banner-page.jpg";
import Patient01 from "../assets/images/patient-01.png";
import Patient02 from "../assets/images/patient-02.png";
import Patient03 from "../assets/images/patient-03.png";
import { loadPostsList } from "../services/PostService";
import ListArticle from "../components/sections/ListArticle";
import Loader from "../components/elements/loader/Loader";

const Blog = () => {
  setTitle("Crowd Funding | Browse Fundraiser");
  const [pageAction, setPageAction] = useState({
    limit: 10,
    offset: 0,
    sortQuery: "-createdAt",
    type: "blogs",
    selectValue:
      "title,slug,shortDescription,author,publishDate,image,description,type,status,featured,ordering,createdAt",
  });
  const [blogsData, setBlogsData] = useState({
    loaded: true,
    data: [],
    count: 0,
  });

  // list fetch of blog =========>
  useEffect(() => {
    setBlogsData({ loaded: false, data: [], count: 0 });
    loadPostsList(pageAction).then((res) => {
      if (res?.status === 200) {
        setBlogsData({
          loaded: true,
          data: res?.docs?.filter((item) => item?.status == 1),
          count: res?.totalDocs,
        });
      } else {
        setBlogsData({ loaded: true, data: [], count: 0 });
      }
    });
  }, [pageAction]);

  const bannerData = {
    image: Banner,
    title: "BLOG",
    description:
      "It is a long established fact that a reader will be distractedwhen  layout. The point of using Lorem Ipsum is that it has a more-or-less normal as opposed to using 'Content here,Join the Movement to Protect Young Lives and Make a Lasting Impact",
    actionLink: "/",
    actionLabel: "Start giving monthly",
  };

  // const blogData = {
  //   title: "Our Top Fundraisers",
  //   headline: "Recent Post",
  //   subtitle:
  //     "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form",
  //   subdata: [
  //     {
  //       _id: 1,
  //       image: Patient01,
  //       name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  //       description:
  //         "Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  //       // raised:70000,
  //       // goal: 100000,
  //       // shareLink:"",
  //       // shareText:"Share",
  //       // donateLink:"",
  //       // donateText:"Donate"
  //       link:"/blogs-details",
  //       dateText:"06 May 2023",
  //       monthAgo:"2Month",
  //       smallImage:false,
  //       boxRow:false,
  //       fontSize:false,

  //     },
  //     {
  //       _id: 2,
  //       image: Patient02,
  //       name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  //       description:
  //         "Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  //       // raised:70000,
  //       // goal: 100000,
  //       // shareLink:"",
  //       // shareText:"Share",
  //       // donateLink:"",
  //       // donateText:"Donate"
  //       dateText: "06 May 2023",
  //       monthAgo: "2Month",
  //       smallImage: false,
  //       boxRow: false,
  //       fontSize: false,
  //     },
  //     {
  //       _id: 3,
  //       image: Patient03,
  //       name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  //       description:
  //         "Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  //       // raised:70000,
  //       // goal: 100000,
  //       // shareLink:"",
  //       // shareText:"Share",
  //       // donateLink:"",
  //       // donateText:"Donate"
  //       dateText: "06 May 2023",
  //       monthAgo: "2Month",
  //       smallImage: false,
  //       fontSize: false,
  //     },

  //     {
  //       _id: 4,
  //       image: Patient02,
  //       name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  //       description:
  //         "Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  //       // raised:70000,
  //       // goal: 100000,
  //       // shareLink:"",
  //       // shareText:"Share",
  //       // donateLink:"",
  //       // donateText:"Donate"
  //       Link:"/src/blogs-details",
  //       dateText:"06 May 2023",
  //       monthAgo:"2Month",
  //       smallImage:false,
  //       boxRow:false,
  //       fontSize:false,

  //       type:"date",

  //     },
  //     {
  //       _id: 5,
  //       image: Patient03,
  //       name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  //       description:
  //         "Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  //       // raised:70000,
  //       // goal: 100000,
  //       // shareLink:"",
  //       // shareText:"Share",
  //       // donateLink:"",
  //       // donateText:"Donate"
  //       dateText: "06 May 2023",
  //       monthAgo: "2Month",
  //       smallImage: false,
  //       boxRow: false,
  //       fontSize: false,
  //     },
  //     {
  //       _id: 6,
  //       image: Patient01,
  //       name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  //       description:
  //         "Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  //       // raised:70000,
  //       // goal: 100000,
  //       // shareLink:"",
  //       // shareText:"Share",
  //       // donateLink:"",
  //       // donateText:"Donate"
  //       dateText: "06 May 2023",
  //       monthAgo: "2Month",
  //       smallImage: false,
  //       boxRow: false,
  //       fontSize: false,
  //     },
  //     {
  //       _id: 7,
  //       image: Patient03,
  //       name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  //       description:
  //         "Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  //       // raised:70000,
  //       // goal: 100000,
  //       // shareLink:"",
  //       // shareText:"Share",
  //       // donateLink:"",
  //       // donateText:"Donate"
  //       dateText: "06 May 2023",
  //       monthAgo: "2Month",
  //       smallImage: false,
  //       boxRow: false,
  //       fontSize: false,
  //     },
  //     {
  //       _id: 8,
  //       image: Patient01,
  //       name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  //       description:
  //         "Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  //       // raised:70000,
  //       // goal: 100000,
  //       // shareLink:"",
  //       // shareText:"Share",
  //       // donateLink:"",
  //       // donateText:"Donate"
  //       dateText: "06 May 2023",
  //       monthAgo: "2Month",
  //       smallImage: false,
  //       boxRow: false,
  //       fontSize: false,
  //     },
  //     {
  //       _id: 9,
  //       image: Patient02,
  //       name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  //       description:
  //         "Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  //       // raised:70000,
  //       // goal: 100000,
  //       // shareLink:"",
  //       // shareText:"Share",
  //       // donateLink:"",
  //       // donateText:"Donate"
  //       dateText: "06 May 2023",
  //       monthAgo: "2Month",
  //       smallImage: false,
  //       boxRow: false,
  //       fontSize: false,
  //     },
  //   ],
  // };

  return (
    <>
      <MainBannerPage data={bannerData} />
      {blogsData?.loaded === false ? (
        <Loader />
      ) : (
        <ListArticle
          data={blogsData?.data}
          loaded={blogsData?.loaded}
          setPageAction={setPageAction}
        />
      )}
    </>
  );
};

export default Blog;
