import React from "react";
import Image from "../../Image";
import userImg from "../../../../assets/images/user.png";

const UploadBeneficiaryImage = ({ image = "", setImage = () => {} }) => {
  return (
    <div className="flex justify-center w-full">
      <div className="w-32 h-32 relative">
        <div className="w-full h-full overflow-hidden rounded-full border-2 border-carnation-500">
          <Image
            src={
              image instanceof File
                ? URL.createObjectURL(image)
                : image || userImg
            }
            alt={"Logo"}
            width={"100%"}
            height={"100%"}
            effect={"blur"}
            classes={"object-contain object-center"}
          />
        </div>
        <div className="absolute bottom-1 right-1 w-8 h-8 overflow-hidden border-2 border-carnation-500 text-slate-800 bg-white rounded-full flex items-center justify-center text-base">
          <input
            type="file"
            className="absolute top-0 right-0 opacity-0 w-[1000px] h-full z-[1] cursor-pointer"
            onChange={(e) => setImage(e.target.files[0])}
          />
          <i className="fa-regular fa-fw fa-camera"></i>
        </div>
      </div>
    </div>
  );
};

export default UploadBeneficiaryImage;
