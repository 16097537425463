import React from "react";
import TextAreaAutoSize from "../../form/TextareaAutoSize";
import Button from "../../form/Button";
import { useState } from "react";

const ReplyInput = ({ onChange, handleSubmit, loaded, inputValue="" }) => {

  return (
    <>
      <div className="relative space-y-4">
        <h4 className="text-sm font-semibold text-black">Reply for this comment</h4>
        <TextAreaAutoSize
          inputClasses={"!min-h-[80px]"}
          inputPlaceholder={"Reply.."}
          inputValue={inputValue}
          onChange={onChange}
        />
        <div className="flex justify-end">
          <Button
            buttonClasses={"!px-5"}
            buttonType={"submit"}
            buttonLabel={"Submit Reply"}
            buttonLabelClasses={""}
            buttonEffect={"filled"}
            buttonFunction={handleSubmit}
            isDisable={loaded === false ? true : false}
          />
        </div>
      </div>
    </>
  );
};

export default ReplyInput;
