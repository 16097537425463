import React from "react";
import Button from "../components/form/Button";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";
import { useParams } from "react-router-dom";
import PostSidebar from "../shared/PostSidebar";

const Withdraw = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const withdraw_list = [
    {
      _id: crypto.randomUUID(),
      title: "All Transfers",
      description:
        "You have not requested any funds transfer yet Add a bank account and provide necessary documents for KYC verification to request fund transfer",
      image: require("../assets/images/withdraw-icon-1.png"),
      button_label: "Request Transfer",
      link: "",
    },
    {
      _id: crypto.randomUUID(),
      title: "Supporting documents",
      description: "Upload your supporting documents here",
      image: require("../assets/images/withdraw-icon-2.png"),
      button_label: "Upload",
      link: `/upload/${id}`,
    },
    {
      _id: crypto.randomUUID(),
      title: "Recipient Accounts",
      description: "You haven’t added any account to transfer funds",
      image: require("../assets/images/withdraw-icon-3.png"),
      button_label: "Add recipient account now",
      link: `/bank-details/${id}`,
    },
  ];

  return (
    <section className="h-full flex flex-col justify-center items-center">
      <div className="relative z-[1] w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
        <div className="w-full flex md:flex-nowrap flex-wrap gap-4 md:gap-0">
          <div className="w-full md:w-80 min-w-[320px] ">
            <PostSidebar id={id} />
          </div>
          <div className="flex gap-3 p-3">
            {withdraw_list?.map((item) => (
              <div
                key={item?._id}
                className="w-1/3 flex flex-col bg-carnation-50 rounded-md shadow p-5 cursor-pointer hover:shadow-md"
              >
                <div className="icon w-16 h-16 min-w-[4rem] min-h-[4rem] flex items-center justify-center text-2xl bg-carnation-100 rounded-full overflow-hidden">
                  <img
                    src={item?.image}
                    alt="icon"
                    className="w-2/3 h-2/3 object-contain object-center"
                  />
                </div>
                <div className="mt-3">
                  <div className="title text-lg font-semibold">
                    {item?.title}
                  </div>
                  <div className="description my-3 text-sm">
                    {item?.description}
                  </div>
                </div>
                <div className="button mt-auto">
                  <Button
                    buttonClasses={"!px-5 !h-10 w-full"}
                    buttonHasLink={false}
                    buttonLabel={item?.button_label}
                    buttonEffect={"filled"}
                    buttonFunction={() => navigate(item?.link)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Withdraw;
