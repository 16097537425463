import React, { useEffect, useState } from 'react';
import { setTitle } from '../helpers/MetaTag';
import MainBannerPage from "../components/sections/MainBannerPage";
import ListTeam from "../components/sections/ListTeam";

import Banner from "../assets/images/banner-teams.jpg";
import Team01 from "../assets/images/team/team-01.jpg";
import Team02 from "../assets/images/team/team-02.jpg";
import Team03 from "../assets/images/team/team-03.jpg";
import Team04 from "../assets/images/team/team-04.jpg";
import Team05 from "../assets/images/team/team-05.jpg";
import Team06 from "../assets/images/team/team-06.jpg";
import Team07 from "../assets/images/team/team-07.jpg";
import Team08 from "../assets/images/team/team-08.jpg";
import { loadTeamsList } from '../services/TeamsService';
import Loader from "../components/elements/loader/Loader";


const Teams = () => {
  setTitle('Crowd Funding | Teams');
  const [loaded,setLoaded]=useState(true)
  const [teams,setTeams]=useState([])


  useEffect(()=>{
    loadTeamsList(setTeams,setLoaded)
  },[])


  const bannerData = {
    image: Banner,
    title: "our Team",
    description: "It is a long established fact that a reader will be distracted when layout. The point of using Lorem Ipsum.",
    actionLink:"/",
    actionLabel:"Start giving monthly"
  }

  const teamData = [
    {
      _id:1,
      image:Team01,
      name:"Jeff C. Casey",
      designation:"Vice President - Marketing and Growth",
      social:[
        { name:"Facebook", icon:"fa-facebook-f", link:"https://www.facebook.com/"},
        { name:"Instagram", icon:"fa-instagram", link:"https://www.instagram.com/"},
        { name:"Twitter", icon:"fa-twitter", link:"https://twitter.com/"},
      ]
    },
    {
      _id:2,
      image:Team02,
      name:"Janet R. Hoffman",
      designation:"Vice President - Marketing and Growth",
      social:[
        { name:"Facebook", icon:"fa-facebook-f", link:"https://www.facebook.com/"},
        { name:"Instagram", icon:"fa-instagram", link:"https://www.instagram.com/"},
        { name:"Twitter", icon:"fa-twitter", link:"https://twitter.com/"},
      ]
    },
    {
      _id:3,
      image:Team03,
      name:"Stephanie F. Sanfilippo",
      designation:"Vice President - Marketing and Growth",
      social:[
        { name:"Facebook", icon:"fa-facebook-f", link:"https://www.facebook.com/"},
        { name:"Instagram", icon:"fa-instagram", link:"https://www.instagram.com/"},
        { name:"Twitter", icon:"fa-twitter", link:"https://twitter.com/"},
      ]
    },
    {
      _id:4,
      image:Team04,
      name:"Jeremy A. Burrell",
      designation:"Vice President - Marketing and Growth",
      social:[
        { name:"Facebook", icon:"fa-facebook-f", link:"https://www.facebook.com/"},
        { name:"Instagram", icon:"fa-instagram", link:"https://www.instagram.com/"},
        { name:"Twitter", icon:"fa-twitter", link:"https://twitter.com/"},
      ]
    },
    {
      _id:5,
      image:Team05,
      name:"Carl V. Powers",
      designation:"Vice President - Marketing and Growth",
      social:[
        { name:"Facebook", icon:"fa-facebook-f", link:"https://www.facebook.com/"},
        { name:"Instagram", icon:"fa-instagram", link:"https://www.instagram.com/"},
        { name:"Twitter", icon:"fa-twitter", link:"https://twitter.com/"},
      ]
    },
    {
      _id:6,
      image:Team06,
      name:"Ray N. Hand",
      designation:"Vice President - Marketing and Growth",
      social:[
        { name:"Facebook", icon:"fa-facebook-f", link:"https://www.facebook.com/"},
        { name:"Instagram", icon:"fa-instagram", link:"https://www.instagram.com/"},
        { name:"Twitter", icon:"fa-twitter", link:"https://twitter.com/"},
      ]
    },
    {
      _id:7,
      image:Team07,
      name:"Richard S. Bryden",
      designation:"Vice President - Marketing and Growth",
      social:[
        { name:"Facebook", icon:"fa-facebook-f", link:"https://www.facebook.com/"},
        { name:"Instagram", icon:"fa-instagram", link:"https://www.instagram.com/"},
        { name:"Twitter", icon:"fa-twitter", link:"https://twitter.com/"},
      ]
    },
    {
      _id:8,
      image:Team08,
      name:"Lester B. Heath",
      designation:"Vice President - Marketing and Growth",
      social:[
        { name:"Facebook", icon:"fa-facebook-f", link:"https://www.facebook.com/"},
        { name:"Instagram", icon:"fa-instagram", link:"https://www.instagram.com/"},
        { name:"Twitter", icon:"fa-twitter", link:"https://twitter.com/"},
      ]
    },
  ]

  return (
    <>
      <MainBannerPage data={bannerData}/>
      {loaded === false ? <Loader/> : <ListTeam data={teams}/>}
    </>
  );
};

export default Teams;