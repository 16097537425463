import React from 'react';
import SingleMedicalCrowdfunding from '../items/SingleMedicalCrowdfunding';

const MedicalCrowdfundingList = ({ data }) => {
  return (
    <>
      <section className="relative overflow-hidden  border-l border-0 w-full xl:w-2/3">
        <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
          <div className="space-y-10">
            {data?.subdata?.length > 0 &&
              <div className="{relative grid}">
                {data?.subdata?.map((item) => (
                  <SingleMedicalCrowdfunding key={item._id} data={item} />
                ))}
              </div>
            }
          </div>
        </div>
      </section>
    </>
  );
};

export default MedicalCrowdfundingList;