import React from "react";
import Image from "../Image";
import noFoundImg from "../../../assets/images/extra/no-data-cover.svg";

const NoDataFound = () => {
  return (
    <div className="flex items-center justify-center">
      <div className="w-2/3">
        <Image src={noFoundImg} />
      </div>
    </div>
  );
};

export default NoDataFound;
