import React from 'react';
import SingleFundRaiser from "../items/SingleFundRaiser";
import SingleCareer from '../items/SingleCareer';
import Loader from '../elements/loader/Loader';

const ListCareer = ({ data,loaded }) => {

  return (
    <>
      <section className="relative overflow-hidden w-full py-20 bg-gray-100">
        <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
          <div className="space-y-10">
            <div className="space-y-4 text-center">
              <h2 className="font-Lexend text-3xl sm:text-4xl xl:text-5xl font-bold text-black">Career Opportunity</h2>
              <div className="text-sm xl:text-base text-slate-600 max-w-xl mx-auto">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form</div>
            </div>
            {loaded ? <>{data?.length > 0 &&
              <div className="{relative grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-5 2xl:gap-x-10 gap-y-8 sm:gap-y-14}">
                {data?.map((item) => (
                  <SingleCareer key={item._id} data={item} />
                ))}
              </div>
            }</>:<Loader/> }
            
          </div>
        </div>
      </section>
    </>
  );
};

export default ListCareer;