import React from "react";
import Image from "../elements/Image";
import { Link } from "react-router-dom/dist/umd/react-router-dom.development";

const SingleStory = ({ data }) => {
  return (
    <>
      {data && (
        <Link to={`/success-story-details/${data?._id}`}>
          <div className="w-full flex flex-col bg-white rounded-2xl py-6 px-8 xl:py-10 xl:px-12 overflow-hidden transition-all duration-200 hover:shadow-lg">
            <div className="flex items-center gap-5">
              <div className="w-16 h-16 xl:w-20 xl:h-20 flex-grow-0 flex-shrink-0 rounded-full overflow-hidden">
                <Image
                  src={data?.image?.url}
                  alt={""}
                  width={"100%"}
                  height={"100%"}
                  effect={"blur"}
                  classes={"object-cover"}
                />
              </div>
              <div className="w-full flex-grow flex-shrink">
                <h4 className="text-base xl:text-lg font-Lexend text-black font-semibold leading-tight text-ellips-title">
                  {data?.title}
                </h4>
                {/* <div className="text-xs xl:text-sm text-slate-600">
            {data?.designation}
          </div> */}
              </div>
            </div>
            {/* <div className="text-xs xl:text-sm text-slate-600 mt-4">{data?.description}</div> */}
            <div
              className="text-xs xl:text-sm text-slate-600 mt-4 text-ellips-desc"
              dangerouslySetInnerHTML={{ __html: data?.shortDescription }}
            />
          </div>
        </Link>
      )}
    </>
  );
};

export default SingleStory;
