import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import DefaultHeader from "../shared/DefaultHeader";
import DefaultFooter from "../shared/DefaultFooter";
import Sidebar from "../shared/Sidebar";

import Logo from "../assets/images/logo.png";
import User from "../assets/images/team/team-04.jpg";
import Modal from "../components/elements/modal/modal";
import { useEffect } from "react";
import { loadProfileDetails } from "../services/ProfileService";
import { loadFundsForList } from "../services/fundsForService";
import { loadSiteSettingsDetails } from "../services/SiteSettingsService";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfile } from "../store/Profile/profileData";

const InnerLayout = () => {
  const [isOpen, setOpen] = useState(false);
  const [isActive, setActive] = useState(false);
  const [profileData, setprofileData] = useState(null);
  const [loaded, setloaded] = useState(true);
  const [topics, settopics] = useState([]);
  const [siteData, setsiteData] = useState(null);
  const settings = useSelector((state) => state?.settings?.data);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchProfile());
  }, []);

  useEffect(() => {
    loadProfileDetails(setprofileData, setloaded).then((res) => {});
  }, []);
  useEffect(() => {
    loadFundsForList(
      () => {},
      settopics,
      () => {}
    ).then((res) => {});
  }, []);
  useEffect(() => {
    loadSiteSettingsDetails(setsiteData, () => {}).then((res) => {});
  }, []);
  const profileDataa = useSelector((state) => state.profile.profileData);

  const loggedInUser = {
    name: profileDataa && profileDataa?.name,
    image: profileDataa && profileDataa?.image?.url,
    phone: profileDataa && profileDataa?.phone,
    email: profileDataa && profileDataa?.email,
    phoneCode: profileDataa && profileDataa?.phoneCode,
    // link:"/user/dashboard",
  };

  const dropdownData = [
    {
      _id: 1,
      label: "Dashboard",
      icon: "fa-gauge",
      link: "/user/dashboard",
      standout: false,
    },
    {
      _id: 7,
      label: "Profile",
      icon: "fa-circle-user",
      link: "/user/profile",
      standout: false,
    },
    {
      _id: 2,
      label: "My Fundraisers",
      icon: "fa-hands-holding-dollar",
      link: "/user/fundraised",
      standout: false,
    },
    {
      _id: 3,
      label: "Completed",
      icon: "fa-heart-circle-check",
      link: "/user/completed-fundraised",
      standout: false,
    },
    {
      _id: 5,
      label: "My Donations",
      icon: "fa-circle-dollar-to-slot",
      link: "/user/supported-fundraiser",
      standout: false,
    },
    // {_id:4, label: 'Supported', icon:'fa-hand-holding-dollar', link:'/user/supported-fundraiser', standout:false},
    // {_id:5, label: 'Transactions', icon:'fa-circle-dollar-to-slot', link:'/user/transactions', standout:false},
    // {_id:6, label: 'Supporters', icon:'fa-people-group', link:'/user/supporters', standout:false},
    {
      _id: 8,
      label: "Settings",
      icon: "fa-sliders",
      link: "/user/settings",
      standout: false,
    },
    { _id: 9, separator: true },
    {
      _id: 10,
      label: "Logout",
      icon: "fa-arrow-right-from-arc",
      onclick: () => {
        setOpen(true);
      },
      standout: true,
    },
  ];

  const menuData = {
    logo: settings && settings?.logo?.url ? settings?.logo?.url : Logo,
    menu: [
      {
        _id: 1,
        name: "Browse Fundraise",
        link: "/fundraiser",
        isParent: false,
      },
      {
        _id: 2,
        name: "Fundraise For",
        link: "",
        isParent: true,
        subdata: topics,
        // subdata: [
        //   {
        //     _id:"2-1",
        //     name:"Medical Treatments",
        //     link:"/new-fundraising",
        //   },
        //   {
        //     _id:"2-2",
        //     name:"NGO / Charity",
        //     link:"/new-fundraising",
        //   },
        //   {
        //     _id:"2-3",
        //     name:"Other Cause",
        //     link:"/new-fundraising",
        //   },
        // ]
      },
      {
        _id: 3,
        name: "How It Work",
        link: "/how-it-works",
        isParent: false,
      },
    ],
    options: {
      signinLabel: "Signin",
      signinLink: "/signin",
      actionLabel: "Start a Fundraiser",
      actionLink: "/new-fundraising",
    },
  };

  const footerData = {
    logo: siteData && siteData?.logo?.url ? settings?.logo?.url : Logo,
    about:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has.",
    socialMenu: [
      {
        _id: 1,
        name: "Facebook",
        link: "https://www.facebook.com/",
        icon: "fa-facebook-f",
      },
      {
        _id: 2,
        name: "Instagram",
        link: "https://www.instagram.com/",
        icon: "fa-instagram",
      },
      {
        _id: 3,
        name: "Twitter",
        link: "https://twitter.com/",
        icon: "fa-twitter",
      },
    ],
    columnOne: {
      title: "Causes",
      menu: [
        { _id: 1, name: "Medical Crowdfunding", link: "/medicalcrowdfunding" },
        { _id: 2, name: "Cancer Crowdfunding", link: "/" },
        { _id: 3, name: "Education Crowdfunding", link: "/" },
        { _id: 4, name: "Child Welfare", link: "/" },
        { _id: 5, name: "Transplant Crowdfunding", link: "/" },
      ],
    },
    columnTwo: {
      title: "Resources",
      menu: [
        {
          _id: 1,
          name: "What is Crowdfunding?",
          link: "/what-is-crowdfunding",
        },
        { _id: 2, name: "Fundraising for NGOs", link: "/fundraising-for-ngo" },
        { _id: 3, name: "Terms & Conditions", link: "/" },
        { _id: 4, name: "Privacy Policy", link: "/" },
      ],
    },
    columnThree: {
      title: "About Us",
      menu: [
        { _id: 1, name: "Team Crowdfunds", link: "/teams" },
        { _id: 2, name: "News", link: "/" },
        { _id: 3, name: "Blog", link: "/" },
        { _id: 4, name: "Careers", link: "/" },
        { _id: 5, name: "Success Stories", link: "/" },
      ],
    },
    contact: {
      title: "Contact Us",
      subdata: [
        {
          _id: 1,
          label: "Email",
          data: "info@companyname.org",
          icon: "fa-envelope",
          type: "mailto",
        },
        {
          _id: 2,
          label: "Contact No.",
          data: "+91 9930088522",
          icon: "fa-phone-volume",
          type: "tel",
        },
        {
          _id: 3,
          label: "Address",
          data: "717 Maxey Rd, Houston, Texas, 77013, United States",
          icon: "fa-location-dot",
          type: "address",
        },
      ],
    },
    copyright: "Copyright © 2023 Company Name | All rights reserved",
  };

  return (
    <>
      <DefaultHeader data={menuData} user={loggedInUser} />
      <section className="relative  overflow-hidden w-full py-5 bg-gray-100">
        <div className="relative z-[1] w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
          <div className="w-full flex md:flex-nowrap flex-wrap gap-4 md:gap-0">
            <div className="w-full md:w-80 min-w-[320px] ">
              <Sidebar
                user={loggedInUser}
                data={dropdownData}
                setActive={setActive}
                isActive={isActive}
              />
            </div>

            <div className="relative bg-white rounded-r-lg w-full max-w-[calc(100%-320px)])] overflow-hidden">
              <Outlet />
            </div>
          </div>
        </div>
      </section>
      <Modal isOpen={isOpen} setOpen={setOpen} />
      <DefaultFooter data={footerData} />
    </>
  );
};

export default InnerLayout;
