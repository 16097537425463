import React, { useEffect, useState } from "react";
import Input from "../../form/Input";
import QuilEditor from "../quilEditor/QuilEditor";
import {
  EditUpdatService,
  loadFundraiserDetails,
} from "../../../services/FundrisersService";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom/dist/umd/react-router-dom.development";
import Spinner from "../extra/Sinner";

const UpdateModal = ({
  isOpen,
  onClose,
  onChangeHandler,
  handleSubmit,
  formData,
  loaded,
}) => {
  //   const [inputValue, setInputValue] = useState('');
  // const [formData, setFormData] = useState({});
  const [details, setdetails] = useState(null);
  // const [loaded, setloaded] = useState(false)
  const { id } = useParams();

  // useEffect(()=>{
  //     loadFundraiserDetails({id},setdetails,setloaded)
  // },[id])

  // useEffect(()=>{
  // setFormData({title:data && data?.title,description:data && data?.content})
  // },[data])
  // const onChangeHandler = (e) => {
  //     const name = e.target.name;
  //     const value = e.target.value;
  //     setFormData((pre) => ({ ...pre, [name]: value }));
  //   };

  //   const handleSubmit = () => {
  //     EditUpdatService({
  //       ...formData,
  //       id: details && details._id,
  //       updateId:data && data?._id
  //     },setloaded).then((res) => {
  //         console.log(res?.data?.data,"res")
  //       if (res?.data?.status === 200) {
  //        toast.success(res?.data?.message)
  //        onClose()
  //        setFormData({title:res?.data?.data?.title,description:res?.data?.data?.description})
  //       }else{
  //         toast.error(res?.data?.message)
  //       }
  //     });
  //   };
  //   const handleSubmit = () => {
  //     // onClose();
  //   console.log(formData,"formData")

  //   };

  return (
    <div
      className={`fixed inset-0 ${
        isOpen ? "" : "hidden"
      } flex items-center justify-center z-50`}
    >
      <div className="modal-overlay" onClick={onClose}></div>
      <div className="modal-container bg-white w-1/2 mx-auto rounded shadow-lg z-50">
        <div className="modal-content py-4 text-left px-6">
          <h1 className="text-2xl font-semibold mb-4">Edit Comment</h1>
          <div className="p-3 relative bg-white rounded-r-lg w-full max-w-[calc(100%-320px)])] overflow-hidden">
            <div className="relative mb-3">
              <Input
                label={"Title"}
                inputPlaceholder={"Enter Title"}
                inputName={"title"}
                inputValue={formData?.title}
                onChange={onChangeHandler}
              />
            </div>
            <div className="relative">
              <QuilEditor
                label="Description"
                placeholder="Write your story simple and specific"
                name={"description"}
                onChange={(val) => onChangeHandler(val)}
                value={formData?.description}
              />
            </div>
            {/* <div className="mt-2">
              <Button
                buttonLabel={"Submit"}
                buttonType={"submit"}
                buttonFunction={handleSubmit}
              />
            </div> */}
          </div>
          <div className="mt-4 flex items-center justify-center">
            <button
              className="btn-primary mr-2 min-w-[100px] m-auto rounded-lg bg-carnation-500 text-white cursor-pointer p-1"
              onClick={handleSubmit}
            >
              {loaded ? (
                "Submit"
              ) : (
                <div className="animate-spin m-auto rounded-full h-6 w-6 border-t-2 border-white border-solid"></div>
              )}
            </button>
            <button
              className="btn-secondary min-w-[100px] rounded-lg bg-carnation-500 text-white cursor-pointer p-1"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateModal;
