import React from "react";

const MiniLoader = () => {
  return (
    <div className="miniLoader-container">
      <div className="loader"></div>
    </div>
  );
};

export default MiniLoader;
