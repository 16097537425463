import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { LoadPageData } from "../services/pagesService";
import dayjs from "dayjs";
import Loader from "../components/elements/loader/Loader";

const PrivacyPolicy = () => {
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [info, setInfo] = useState({});

  useEffect(() => {
    LoadPageData(
      () => {},
      () => {},
      setLoaded
    ).then((res) => {
      let dataa = res?.data?.docs;
      let privecy = dataa.filter((elm) => elm.title == "Privacy Policy");
      setInfo(privecy[0]);
    });
  }, []);

  return loaded === false ? (
    <Loader />
  ) : (
    <section className="relative py-16 w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
      <div className="flex justify-start lg:justify-center py-4">
        <h2 className="text-3xl font-bold text-slte-800">
          {info && info?.title}
        </h2>
      </div>
      <div className="relative flex flex-col gap-4">
        <div className="text-xs text-slate-500 mt-4">
          Latest update january{" "}
          <span>{dayjs(info?.createdAt).format("DD/MM/YYYY")}</span>
          {/* <span>2023</span> */}
        </div>
        {/* <div className="mt-2">
      <h2 className="text-slate-800 text-xl font-medium">
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry?
      </h2>
      <div className="text-sm text-slate-500 font-normal mt-2 leading-6">
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. 
      </div>
    </div>
    <div className="mt-4">
      <h2 className="text-slate-800 text-xl font-medium">
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry?
      </h2>
      <div className="text-sm text-slate-500 font-normal mt-2 leading-6">
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. 
      </div>
    </div>
    <div className="mt-4">
      <h2 className="text-slate-800 text-xl font-medium">
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry?
      </h2>
      <div className="text-sm text-slate-500 font-normal mt-2 leading-6">
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. 
      </div>
    </div> */}
        <div
          className="mt-2"
          dangerouslySetInnerHTML={{ __html: info?.shortDescription }}
        />
        <div
          className="mt-2"
          dangerouslySetInnerHTML={{ __html: info?.description }}
        />
      </div>
    </section>
  );
};

export default PrivacyPolicy;
