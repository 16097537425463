import React, { useEffect } from "react";
import { useState } from "react";
import Input from "../../../../components/form/Input";
import Select from "../../../../components/form/Select";
import MultiTypesFileUploads from "../../../form/MultiTypesFileUploads";
import SearchWithDropdown from "../../../form/SearchWithDropdown";

const TaxSection = ({
  countryList,
  setTaxData = () => {},
  taxData,
  stepsData = {},
  setStepsData = () => {},
}) => {
  const [country, setCountry] = useState("");
  const [isProvideTaxList, setIsProvideTaxList] = useState([
    { _id: 0, name: "Yes", value: true, checked: false },
    { _id: 1, name: "No", value: false, checked: true },
  ]);
  const [isProvideTax, setIsProvideTax] = useState(false); // initial value false

  const organisationType = [
    { name: "Trust" },
    { name: "Society" },
    { name: "Section 25" },
    { name: "Section 8" },
  ];
  const regsec = [
    { name: "Indian Trusts Act, 1882" },
    { name: "SociBombay Public Trusts Act, 1950ety" },
    { name: "Gujarat Public Trusts Act, 1950" },
    { name: "RajasthanTrusts Act, 1959" },
    { name: "Madhya Pradesh Trusts Act, 1951" },
  ];

  // const onChangeHandler = (e) => {
  //   const name = e?.target?.name;
  //   const value = e?.target?.value;
  //   if (e.target.value) {
  //     setTaxData((pre) => ({ ...pre, [name]: value }));
  //   } else {
  //     delete taxData[name];
  //     delete stepsData[name];
  //     setTaxData({ ...taxData });
  //     setStepsData({ ...stepsData });
  //   }
  // };

  const onChangeHandler = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    if (value !== undefined) {
      setTaxData((pre) => ({ ...pre, [name]: value }));
    } else {
      if (name === "is80G") {
        setTaxData((pre) => ({ ...pre, [name]: false }));
      } else {
        delete taxData[name];
        delete stepsData[name];
        setTaxData({ ...taxData });
        setStepsData({ ...stepsData });
      }
    }
  };

  // useEffect(() => {
  //   setIsProvideTax(taxData?.is80G);
  //   setIsProvideTaxList((pre) =>
  //     pre.map((item) =>
  //       item.value == taxData?.is80G
  //         ? { ...item, checked: true }
  //         : { ...item, checked: false }
  //     )
  //   );
  // }, [taxData?.is80G]);

  useEffect(() => {
    if (isProvideTax) {
      setTaxData((pre) => ({
        ...pre,
        "detailsOf80G[phoneCode]": "+91",
        "detailsOf80G[address][country]": "India",
      }));
    } else {
      delete taxData[`detailsOf80G[phoneCode]`];
      delete taxData?.certificate80G;
      setTaxData({ ...taxData });
    }
  }, [isProvideTax]);

  useEffect(() => {
    if (taxData?.is80G !== undefined) {
      setIsProvideTax(taxData.is80G);
      setIsProvideTaxList((prev) =>
        prev.map((item) => ({
          ...item,
          checked: item.value === taxData.is80G,
        }))
      );
    }
  }, [taxData?.is80G]);

  const handleDataChange = (value) => {
    setIsProvideTax(value);
    setIsProvideTaxList((prev) =>
      prev.map((item) => ({
        ...item,
        checked: item.value === value,
      }))
    );
    onChangeHandler({ target: { name: "is80G", value } });
  };

  return (
    <>
      {/* {/ ============ provide 80g tax sec ============ /} */}
      <div className="relative flex justify-between">
        <h4 className="text-sm font-semibold text-carnation-500 mt-3 mb-2">
          Provide 80G tax exemption to donors?
        </h4>
        <div className="grid grid-cols-2 gap-2 mt-2">
          {Array.isArray(isProvideTaxList) &&
            isProvideTaxList?.length > 0 &&
            isProvideTaxList?.map((item) => (
              <div
                key={item?._id}
                className="relative w-full overflow-hidden z-0 flex"
              >
                <input
                  checked={item?.checked}
                  value={item?.value}
                  type="radio"
                  name="provideTax"
                  // name="is80G"
                  id={item?._id}
                  onChange={() => handleDataChange(item.value)}
                  // onChange={(e) => {
                  //   setTaxData((pre) => ({ phoneCode: pre?.phoneCode }));
                  //   setIsProvideTaxList((pre) =>
                  //     pre.map((item) => ({
                  //       ...item,
                  //       checked: item?.checked == true ? false : true,
                  //     }))
                  //   );
                  //   // handleDataChange(e.target);
                  //   setIsProvideTax(e.target.value ? true : false);
                  //   onChangeHandler({
                  //     target: {
                  //       name: "is80G",
                  //       value: e.target.value == "true" ? true : false,
                  //     },
                  //   });
                  // }}
                  required
                  className="peer absolute z-[1] cursor-pointer top-0 left-0 w-full h-full opacity-0"
                />
                <div className="w-full relative bg-slate-50 border border-slate-200 text-xs font-semibold px-2 py-2 flex items-center justify-center leading-tight text-center rounded-md text-slate-600 transition-all duration-200 peer-checked:bg-carnation-500 peer-checked:border-carnation-500 peer-checked:text-white">
                  {item.name}
                </div>
              </div>
            ))}
        </div>
      </div>
      {/* {/ =========== multiple file select =========== /} */}
      {isProvideTax && (
        <>
          <div className="border border-carnation-500 rounded-md p-2 mt-3">
            <div className="relative">
              <MultiTypesFileUploads
                label="80G Certificate Photo"
                files={taxData?.certificate80G}
                errorText={"80G certificate image is required!"}
                errorType={
                  taxData?.certificate80G && taxData?.certificate80G.length > 0
                    ? ""
                    : "danger"
                }
                fileType={taxData?.certificate80G?.map((item) => item?.type)}
                onChange={(e) => {
                  if (taxData?.certificate80G) {
                    setTaxData((pre) => ({
                      ...pre,
                      certificate80G: [
                        ...pre.certificate80G,
                        {
                          _id: crypto.randomUUID(),
                          type: e.target.files[0]?.type.split("/")[1],
                          file: e.target.files[0],
                          name: e.target.files[0]?.name,
                        },
                      ],
                    }));
                  } else {
                    setTaxData((pre) => ({
                      ...pre,
                      certificate80G: [
                        {
                          _id: crypto.randomUUID(),
                          type: e.target.files[0]?.type.split("/")[1],
                          file: e.target.files[0],
                          name: e.target.files[0]?.name,
                        },
                      ],
                    }));
                  }
                }}
                removeFile={(file) => {
                  setTaxData((pre) => ({
                    ...pre,
                    certificate80G: pre?.certificate80G?.filter(
                      (item) => item?._id != file?._id
                    ),
                  }));
                }}
                accept={["jpg", "jpeg", "png", "pdf"]}
              />
            </div>
          </div>
          <div className="relative mt-2">
            <Input
              label={""}
              labelClasses={"!text-xs"}
              inputType={"email"}
              inputPlaceholder={"Enter email"}
              errorType={taxData["detailsOf80G[email]"] ? "" : "danger"}
              inputValue={taxData["detailsOf80G[email]"]}
              inputName={"detailsOf80G[email]"}
              onChange={(e) => onChangeHandler(e)}
              errorText={"Email id is required!"}
            />
          </div>
          <div className="relative mt-2 w-full">
            <SearchWithDropdown
              label={"Country"}
              placeholder={"Type three letter and find country"}
              data={
                countryList?.find(
                  (item) => item?.name === taxData?.[`detailsOf80G[phoneCode]`]
                )?.country_name
              }
              dropdownData={[
                { name: "Select country" },
                ...countryList?.map((item) => ({
                  name: item?.country_name,
                  country_code: item?.name,
                  _id: item?._id,
                })),
              ]}
              isError={taxData?.[`detailsOf80G[phoneCode]`] ? false : true}
              errorText={"Country name is required!"}
              setCustomSelecedvalue={(value) => {
                setCountry(value?.name);
                onChangeHandler({
                  target: {
                    name: "detailsOf80G[phoneCode]",
                    value: value?.country_code,
                  },
                });
                onChangeHandler({
                  target: {
                    name: "detailsOf80G[address][country]",
                    value: value?.name,
                  },
                });
              }}
            />
          </div>
          <div className="relative flex mt-2">
            <div className="w-20 min-w-[80px]">
              <Select
                xPlacement={"left"}
                dropdownData={countryList}
                dropdownClass={"max-h-40"}
                dropdownButtonClass={"!px-2 !border-r-none rounded-r-none"}
                selectedValue={
                  countryList?.find(
                    (item) => item?.name === taxData[`detailsOf80G[phoneCode]`]
                  )?._id
                }
                setCustomSelecedvalue={(e) =>
                  onChangeHandler({
                    target: { name: `detailsOf80G[phoneCode]`, value: e.name },
                  })
                }
              />
            </div>
            <div className="w-full">
              <Input
                inputType={"number"}
                inputPlaceholder={"Enter phone number"}
                inputClasses={"!border-l-none rounded-l-none -ml-[1px]"}
                inputName={"detailsOf80G[phone]"}
                // inputValue={
                //   taxData["detailsOf80G[phone]"].split(" ").length > 1
                //     ? taxData["detailsOf80G[phone]"].split(" ")[1]
                //     : ""
                // }
                inputValue={taxData[`detailsOf80G[phone]`]}
                // onChange={(e) =>
                //   onChangeHandler({
                //     target: {
                //       name: e.target.name,
                //       value:
                //         taxData[`detailsOf80G[phoneCode]`] +
                //         " " +
                //         e.target.value,
                //     },
                //   })
                // }
                onChange={(e) => onChangeHandler(e)}
                errorText={"Phone number is required!"}
                errorType={taxData[`detailsOf80G[phone]`] ? "" : "danger"}
              />
            </div>
          </div>
          <div className="flex gap-2 w-full">
            <div
              className={`relative mt-2 ${
                taxData[`detailsOf80G[organisationType]`] == "Trust"
                  ? "w-1/2"
                  : "w-full"
              } `}
            >
              <Select
                xPlacement={"left"}
                dropdownData={[
                  { name: "Select organisation type" },
                  ...organisationType,
                ]}
                errorText={"Organization type is required!"}
                errorType={
                  taxData[`detailsOf80G[organisationType]`] ? "" : "error"
                }
                dropdownClass={"max-h-60 w-full"}
                dropdownButtonClass={""}
                selectedValueType={"name"}
                selectedValue={taxData[`detailsOf80G[organisationType]`]}
                setCustomSelecedvalue={(value) => {
                  if (value.name === "Select organisation type") {
                    delete taxData[`detailsOf80G[organisationType]`];
                    delete taxData[`detailsOf80G[registrationSection]`];
                    setTaxData({ ...taxData });
                    return;
                  } else {
                    if (value.name !== "Trust") {
                      delete taxData[`detailsOf80G[registrationSection]`];
                      setTaxData({ ...taxData });
                      onChangeHandler({
                        target: {
                          name: "detailsOf80G[organisationType]",
                          value: value.name,
                        },
                      });
                    } else {
                      onChangeHandler({
                        target: {
                          name: "detailsOf80G[organisationType]",
                          value: value.name,
                        },
                      });
                    }
                  }
                }}
              />
            </div>
            {taxData[`detailsOf80G[organisationType]`] == "Trust" && (
              <div className="relative mt-2 w-1/2">
                <Select
                  xPlacement={"left"}
                  dropdownData={[
                    { name: "Select registration section" },
                    ...regsec,
                  ]}
                  dropdownClass={"max-h-60 w-full"}
                  dropdownButtonClass={""}
                  selectedValueType={"name"}
                  errorText={"This field is required!"}
                  errorType={
                    taxData[`detailsOf80G[registrationSection]`] ? "" : "error"
                  }
                  selectedValue={taxData[`detailsOf80G[registrationSection]`]}
                  setCustomSelecedvalue={(value) => {
                    if (value.name === "Select registration section") {
                      return;
                    } else {
                      onChangeHandler({
                        target: {
                          name: "detailsOf80G[registrationSection]",
                          value: value.name,
                        },
                      });
                    }
                  }}
                />
              </div>
            )}
          </div>
          <div className="relative mt-2">
            <Input
              label={""}
              labelClasses={"!text-xs"}
              inputType={"text"}
              inputPlaceholder={"Enter Registration No"}
              inputValue={taxData[`detailsOf80G[registrationNumber]`]}
              inputName={"detailsOf80G[registrationNumber]"}
              onChange={(e) => onChangeHandler(e)}
              errorText={"Registration number is required!"}
              errorType={
                taxData[`detailsOf80G[registrationNumber]`] ? "" : "danger"
              }
            />
          </div>
          <div className="relative mt-2">
            <Input
              label={""}
              labelClasses={"!text-xs"}
              inputType={"text"}
              inputPlaceholder={"Enter Registration address"}
              inputValue={taxData[`detailsOf80G[registrationAddress]`]}
              inputName={"detailsOf80G[registrationAddress]"}
              onChange={(e) => onChangeHandler(e)}
              errorText={"Resigtration address is required!"}
              errorType={
                taxData[`detailsOf80G[registrationAddress]`] ? "" : "danger"
              }
            />
          </div>
        </>
      )}
    </>
  );
};

export default TaxSection;
