import React from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  WhatsappShareButton,
  InstapaperShareButton,
  EmailShareButton,
  FacebookShareCount,
  PinterestShareCount,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from "react-share";

const SocialShare = ({ url, title, description }) => {
  return (
    <div className=" relative overflow-hidden">
      <div className="flex gap-1 justify-end items-center">
        <span className="text-lg font-semibold">Share:</span>
        {/* Email */}
        <EmailShareButton url={url} quote={title}>
          {/* <div className="bg-red-800 text-white rounded px-4 py-2 flex gap-3 items-center justify-center space-x-1 w-[230px]"> */}
          {/* <i className="fa fa-envelope"></i> Share via Email */}
          <EmailIcon size={32} round />
          {/* </div> */}
        </EmailShareButton>
        {/* Facebook */}
        <FacebookShareButton url={url} quote={title}>
          {/* <div className="bg-blue-600 text-white rounded px-4 py-2 flex gap-3 items-center justify-center space-x-1 w-[230px]"> */}
          {/* <i className="fab fa-facebook"></i> Share on Facebook */}
          <FacebookIcon size={32} round />
          {/* </div> */}
          {/* <div>
            <FacebookShareCount
              url={url}
              className="Demo__some-network__share-count"
            >
              {(count) => console.log(count, "count")}
            </FacebookShareCount>
          </div> */}
        </FacebookShareButton>

        {/* Twitter */}
        <TwitterShareButton url={url} title={title}>
          {/* <div className="bg-blue-400 text-white rounded px-4 py-2 flex gap-3 items-center justify-center space-x-1 w-[230px]"> */}
          {/* <i className="fab fa-twitter"></i> Share on Twitter */}
          <TwitterIcon size={32} round />
          {/* </div> */}
        </TwitterShareButton>

        {/* LinkedIn */}
        {/* <LinkedinShareButton url={url} title={title} summary={description}> */}
        {/* <div className="bg-blue-800 text-white rounded px-4 py-2 flex gap-3 items-center justify-center space-x-1 w-[230px]"> */}
        {/* <i className="fab fa-linkedin"></i> Share on LinkedIn */}
        {/* <LinkedinIcon size={32} round /> */}
        {/* </div> */}
        {/* </LinkedinShareButton> */}

        {/* Pinterest */}
        {/* <PinterestShareButton url={url} title={title} summary={description}>
          <div className="bg-red-600 text-white rounded px-4 py-2 flex gap-3 items-center justify-center space-x-1 w-[230px]">
            
            <PinterestIcon size={32} round /> Share on Pinterest
          </div>
          <div>
            <PinterestShareCount
              url={url}
              className="Demo__some-network__share-count"
            >
              {(count) => console.log(count, "count")}
            </PinterestShareCount>
          </div>
        </PinterestShareButton> */}

        {/* Whatsapp */}
        <WhatsappShareButton url={url} title={title} summary={description}>
          {/* <div className="bg-green-800 text-white rounded px-4 py-2 flex gap-3 items-center justify-center space-x-1 w-[230px]"> */}
          {/* <i className="fab fa-whatsapp"></i> Share on Whatsapp */}
          <WhatsappIcon size={32} round />
          {/* </div> */}
        </WhatsappShareButton>
      </div>
    </div>
  );
};

export default SocialShare;
