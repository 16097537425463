import { apiRequest } from "../utils/async/apiUtils";


export const loadTeamsList = async (
    setTeams = () => {},
    setLoaded = () => {},
  
  ) => {
    setLoaded(false);
  
    try {
      const res = await apiRequest("get", "testimonials", {
        queries: {
          selectValue: "name,designation,image,socialMedia,description,type,status,ordering,createdAt",
          type:2
        },
      });
      const data = res.data;
      setTeams(data.docs?.filter((item)=>item?.status==1));
      setLoaded(true);
      return res
    } catch (err) {
      setLoaded(true);
    }
  };