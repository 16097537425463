import React from "react";
import { Link } from "react-router-dom";
import Image from "../elements/Image";
import Button from "../form/Button";
import dayjs from "dayjs";

const SideArticle = ({ data }) => {
  return (
    <>
      <div
        className={
          "w-full flex flex-row gap-2 bg-gray-100 rounded-4xl rounded-tl-none overflow-hidden transition-all duration-200 hover:shadow-lg"
        }
      >
        <div
          className={
            data?.smallImage
              ? " data.smallImage?'70%':h-20 xl:h-28 3xl:h-32 overflow-hidden rounded-4xl rounded-tl-none"
              : "!w-[150px]  h-full overflow-hidden rounded-4xl rounded-tl-none"
          }
        >
          <Link
            to={
              data?.postType && data?.postType === "news"
                ? `/news-details/${data?._id}`
                : `/blogs-details/${data?._id}`
            }
          >
            <></>
            <Image
              src={data?.image?.url}
              alt={""}
              width={"100%"}
              height={"100%"}
              effect={"blur"}
              classes={"object-cover"}
            />
          </Link>
        </div>
        <div className="py-6 px-4 w-[calc(100%_-_150px)]">
          <Link
            to={
              data?.postType && data?.postType === "news"
                ? `/news-details/${data?._id}`
                : `/blogs-details/${data?._id}`
            }
          >
            <h4 className="text-base font-Lexend text-black font-medium leading-tight">
              {data?.title}
            </h4>
            {/* <div className="text-xs xl:text-sm text-slate-600 my-2">{data.description}</div> */}
            <div dangerouslySetInnerHTML={{ __html: data.description }} className="text-xs xl:text-sm text-slate-600 my-2 ellips-text"/>
            {data.raised !== undefined && (
              <div className="relative bg-gray-100 pt-10 pb-4 px-6 my-4 rounded-lg">
                <div className="relative flex bg-gray-200 rounded-full w-full h-2">
                  <div
                    className="relative bg-carnation-500 rounded-full"
                    style={{ width: (data.raised / data.goal) * 100 + "%" }}
                  >
                    <div className="absolute w-4 h-4 rounded-full top-1/2 -translate-y-1/2 -right-2 bg-carnation-500"></div>
                    <div className="absolute w-8 h-5 rounded-full z-top-6 -right-4 text-xs text-center font-medium text-slate-600">
                      {(data.raised / data.goal) * 100 + "%"}
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-between mt-2">
                  {data.raised !== undefined && (
                    <div className="text-xs font-medium text-slate-600">
                      {data.raised.toLocaleString()}
                    </div>
                  )}
                  {data.goal !== undefined && (
                    <div className="text-xs font-medium text-slate-600">
                      {data.goal.toLocaleString()}
                    </div>
                  )}
                </div>
              </div>
            )}
          </Link>
          {/* <div className='relative mt-2'>
              <div className='relative flex'>
                <div className='text-slate-900'>{dateObject}</div>
              </div>
              <div className=''>
                <div></div>
              </div>
            </div> */}

          <div className="flex items-center justify-between mt-auto">
            {data.shareText !== undefined && (
              <Button
                buttonClasses={
                  "!px-8 !h-10 !mr-auto !bg-transparent !text-carnation-500 border border-carnation-500"
                }
                buttonHasLink={true}
                buttonLink={""}
                buttonLabel={"Share"}
                buttonLabelClasses={"text-sm"}
                buttonEffect={"hollow"}
              />
            )}
            {data.donateText !== undefined && (
              <Button
                buttonClasses={"!px-8 !h-10 !ml-auto"}
                buttonHasLink={true}
                buttonLink={""}
                buttonLabel={"Donate"}
                buttonLabelClasses={"text-sm"}
                buttonEffect={"filled"}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SideArticle;
