import React from "react";
import Image from "../elements/Image";
import { classNames } from "../../helpers/classNames";

const SingleSupporterSm = ({ data }) => {
  return (
    <>
      <div className="relative flex items-center gap-2 xl:gap-5 py-2">
        <div className="w-10 h-10 min-w-[40px] rounded-full overflow-hidden">
          <Image
            src={data?.supporter?.image?.url}
            alt={""}
            width={"100%"}
            height={"100%"}
            effect={"blur"}
            classes={"object-cover"}
          />
        </div>
        <div className="w-full flex-grow flex-shrink space-y-3">
          <div className="flex items-center justify-between">
            <h4 className="text-xs xl:text-sm font-semibold text-black">
              {data?.supporter?.name}
            </h4>
            <div className="flex items-center gap-1 text-slate-800">
              {/* <i class="fas fa-rupee-sign"></i> */}
              <i class="fa-solid fa-indian-rupee-sign"></i>
              <div className="text-sm font-medium">{data?.totalDonation}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleSupporterSm;
