import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import DefaultHeader from "../shared/DefaultHeader";
import DefaultFooter from "../shared/DefaultFooter";
// import Logo from "../assets/images/logo.png";
import Logo from "../assets/images/logo.png";
import User from "../assets/images/team/team-04.jpg";
import { loadProfileDetails } from "../services/ProfileService";
import { loadFundsForList } from "../services/fundsForService";
import { loadSiteSettingsDetails } from "../services/SiteSettingsService";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfile } from "../store/Profile/profileData";
import { getSettings } from "../store/siteSettings/settingsSlice";

const DefaultLayout = () => {
  const dispatch = useDispatch();
  const profileDataa = useSelector((state) => state.profile.profileData);
  const settings = useSelector((state) => state?.settings?.data);
  const token = localStorage.getItem("auth_token");
  const [profileData, setprofileData] = useState(null);
  const [loaded, setloaded] = useState(true);
  const [topics, settopics] = useState([]);

  useEffect(() => {
    dispatch(fetchProfile());
  }, []);

  useEffect(() => {
    loadProfileDetails(setprofileData, setloaded).then((res) => {});
  }, []);

  useEffect(() => {
    loadFundsForList(
      () => {},
      settopics,
      () => {}
    ).then((res) => {});
  }, []);

  function favIconChange() {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = settings?.favicon?.url;
  }
  favIconChange();

  useEffect(() => {
    loadSiteSettingsDetails().then((res) => {
      if (res?.data?.status === 200) {
        dispatch(getSettings(res.data.data));
      }
    });
  }, []);

  const loggedInUser = {
    name: profileDataa && profileDataa?.name,
    image: profileDataa && profileDataa?.image?.url,
    phone: profileDataa && profileDataa?.phone,
    email: profileDataa && profileDataa?.email,
    link: "/user/dashboard",
  };

  // const loggedInUser = {
  //   name:"Jeremy A. Burrell",
  //   image:User,
  //   phone:"089 654554154",
  //   email:"info123@gmail.com",
  //   link:"/user/dashboard",
  // dropdownData: [
  //   {_id:1, label: 'Dashboard', icon:'fa-gauge', link:'/user/dashboard', standout:false},
  //   {_id:2, label: 'Fundraise', icon:'fa-hands-holding-dollar', link:'/user/fundraised', standout:false},
  //   {_id:3, label: 'Completed', icon:'fa-heart-circle-check', link:'/user/completed-fundraised', standout:false},
  //   {_id:4, label: 'Transactions', icon:'fa-circle-dollar-to-slot', link:'/user/transactions', standout:false},
  //   {_id:5, label: 'Supporters', icon:'fa-people-group', link:'/user/supporters', standout:false},
  //   {_id:6, label: 'Profile', icon:'fa-circle-user', link:'/user/profile', standout:false},
  //   {_id:7, label: 'Settings', icon:'fa-sliders', link:'/user/settings', standout:false},
  //   {_id:8, label: 'Logout', icon:'fa-arrow-right-from-arc', onclick:() => {}, standout:true},
  // ]
  //}

  const menuData = {
    logo: settings && settings?.logo?.url ? settings?.logo?.url : Logo,
    menu: [
      {
        _id: 1,
        name: "Browse Fundraise",
        link: "/fundraiser",
        isParent: false,
      },
      {
        _id: 2,
        name: "Fundraise For",
        link: "",
        isParent: true,
        subdata: topics,
        // subdata: [
        //   {
        //     _id:"2-1",
        //     name:"Medical Treatments",
        //     link:"/new-fundraising",
        //   },
        //   {
        //     _id:"2-2",
        //     name:"NGO / Charity",
        //     link:"/new-fundraising",
        //   },
        //   {
        //     _id:"2-3",
        //     name:"Other Cause",
        //     link:"/new-fundraising",
        //   },
        // ]
      },
      {
        _id: 3,
        name: "How It Work",
        link: "/how-it-works",
        isParent: false,
      },
      // {
      //   _id:4,
      //   name:"Profile",
      //   link:"/user/dashboard",
      //   isParent: false,
      // },
    ],
    options: {
      signinLabel: "Signin",
      signinLink: "/signin",
      actionLabel: "Start a Fundraiser",
      actionLink: `/${token ? "new-fundraising" : "register"}`,
    },
  };

  const footerData = {
    logo: settings && settings?.logo?.url ? settings?.logo?.url : Logo,
    about:
      "We are India's most trusted crowdfunding platform, with a vision to create a social impact. Our online technology platform connects donors & donees. Our unique model allows people from across the globe to donate towards raising funds for products required by NGOs and charities and Individual in India, ",
    socialMenu: [
      {
        _id: 1,
        name: "Facebook",
        link: "https://www.facebook.com/",
        icon: "fa-facebook-f",
      },
      {
        _id: 2,
        name: "Instagram",
        link: "https://www.instagram.com/",
        icon: "fa-instagram",
      },
      {
        _id: 3,
        name: "Twitter",
        link: "https://twitter.com/",
        icon: "fa-twitter",
      },
    ],
    columnOne: {
      title: "Causes",
      menu: [
        {
          _id: 1,
          name: "Medical Crowdfunding",
          link: "/medical-crowd-funding",
        },
        { _id: 2, name: "Cancer Crowdfunding", link: "/cancer-crowd-funding" },
        {
          _id: 3,
          name: "Education Crowdfunding",
          link: "/education-crowdfunding",
        },
        { _id: 4, name: "Child Welfare", link: "/child-wel-fare" },
        {
          _id: 5,
          name: "Transplant Crowdfunding",
          link: "/transplant-crowdfunding",
        },
      ],
    },
    columnTwo: {
      title: "Resources",
      menu: [
        {
          _id: 1,
          name: "What is Crowdfunding?",
          link: "/what-is-crowdfunding",
        },
        { _id: 2, name: "Fundraising for NGOs", link: "/fundraising-for-ngo" },
        { _id: 3, name: "Terms & Conditions", link: "/terms-conditions" },
        { _id: 4, name: "Privacy Policy", link: "/privacy-policy" },
      ],
    },
    columnThree: {
      title: "About Us",
      menu: [
        { _id: 1, name: "Team Crowdfunds", link: "/teams" },

        { _id: 2, name: "News", link: "/news" },
        { _id: 3, name: "Blogs", link: "/blogs" },

        { _id: 4, name: "Careers", link: "/careers" },
        { _id: 5, name: "Success Stories", link: "/success-stories" },
      ],
    },
    contact: {
      title: "Contact Us",
      subdata: [
        {
          _id: 1,
          label: "Email",
          data: settings?.email,
          icon: "fa-envelope",
          type: "mailto",
        },
        {
          _id: 2,
          label: "Contact No.",
          data: settings?.phone,
          icon: "fa-phone-volume",
          type: "tel",
        },
        {
          _id: 3,
          label: "Address",
          data: (
            <>
              <p>{settings?.address?.address1}</p>
              <p>{settings?.address?.address2}</p>
              <p>{settings?.address?.country}</p>
              <p>{settings?.address?.state}</p>
              <p>{settings?.address?.city}</p>
              <p>{settings?.address?.zipcode}</p>
            </>
          ),
          icon: "fa-location-dot",
          type: "address",
        },
      ],
    },
    copyright:
      "Copyright ©  KYNREM Online Ventures Private Limited. All rights reserved",
  };

  return (
    <>
      <DefaultHeader data={menuData} user={loggedInUser} />
      <Outlet />
      <DefaultFooter data={footerData} />
    </>
  );
};

export default DefaultLayout;
