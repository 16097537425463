import React from "react";
import Image from "../elements/Image";
import Button from "../form/Button";
import BannerSkeletonLoader from "../elements/loader/BannerSkeletonLoader";

const MainBannerPage = ({ data, loaded }) => {
  return (
    <>
      {loaded ? (
        <section className="relative overflow-hidden w-full z-0 h-[300px] xl:h-[360px] flex items-center">
          <div className="absolute top-0 left-0 w-full h-full z-[-1] after:absolute after:content-[''] after:w-full after:h-full after:top-0 after:left-0 after:bg-gradient-to-r after:from-carnation-500 after:from-10% after:via-carnation-500/90 after:via-50%">
            <Image
              src={data.image}
              alt={""}
              width={"100%"}
              height={"100%"}
              effect={"blur"}
              classes={"object-cover saturate-200 object-center"}
            />
          </div>
          <div className="relative z-[1] w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto flex items-center">
            <div className="w-4/5 sm:w-2/3 md:w-4/5 lg:w-3/5 xl:w-2/3 2xl:w-3/5 3xl:w-1/2 space-y-8">
              <div className="space-y-4">
                <h1 className="font-Lexend text-3xl sm:text-4xl xl:text-5xl uppercase font-bold text-white">
                  {data.title}
                </h1>
                <div className="text-sm xl:text-xl text-white max-w-[80%]">
                  {data.description}
                </div>
              </div>
              {data.actionLink && data.actionLabel && (
                <div className="flex items-center !mt-10">
                  <Button
                    buttonClasses={
                      "!px-5 !bg-white before:!bg-carnation-500/10"
                    }
                    buttonHasLink={true}
                    buttonLink={data.actionLink}
                    buttonLabel={data.actionLabel}
                    buttonLabelClasses={"text-sm !text-black font-semibold"}
                    buttonEffect={"filled"}
                  />
                </div>
              )}
            </div>
          </div>
        </section>
      ) : (
        <BannerSkeletonLoader />
      )}
    </>
  );
};

export default MainBannerPage;
