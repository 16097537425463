import { queries } from "@testing-library/react";
import { apiRequest } from "../utils/async/apiUtils";
import dayjs from "dayjs";

export const SignUpService = async (
  params,
  resData = () => {},
  setLoaded = () => {}
) => {
  const bodyData = {
    name: params?.name,
    email: params?.email,
    password: params?.password,
    phone: params?.phone,
    phoneCode: params?.phoneCode,
    ...params,
  };
  params?.image && (bodyData[`image`] = params?.image);
  !bodyData?.image && delete bodyData?.image;

  setLoaded(false);
  try {
    const res = await apiRequest(
      "post",
      "signup",
      {
        body: {
          ...bodyData,
        },
      },
      "multipart/form-data"
    );
    const data = res.data;
    if (data.status === 201 || data.status === 200) {
      resData(data);
      setLoaded(true);
    }
    return res;
  } catch (err) {
    setLoaded(true);
    return err;
  }
};

export const SignInService = async (
  params,
  setLoaded = () => {},
  setResData = () => {}
) => {
  setLoaded(false);
  try {
    const res = await apiRequest("post", "user-login", {
      body: {
        email: params?.email,
        password: params?.password,
      },
    });
    const data = res.data;
    setResData(data);
    setLoaded(true);
    return res;
  } catch (error) {
    setResData(error);
    setLoaded(true);
    return error.response;
  }
};

export const SignInWithGoogleService = async (
  params,
  setLoaded = () => {},
  setResData = () => {}
) => {
  setLoaded(false);
  try {
    const res = await apiRequest("post", "google", {
      body: {
        ...params,
      },
    });
    const data = res.data;
    setResData(data);
    setLoaded(true);
    return res;
  } catch (error) {
    setResData(error);
    setLoaded(true);
    return error.response;
  }
};

export const addFundraiser = async (params) => {
  let bodyData = {
    ...params,
  };
  params?.topic && (bodyData[`topicId`] = params?.topic?._id);
  params?.topic && (bodyData[`topicCode`] = params?.topic?.code);
  params?.documents &&
    params?.documents?.forEach(
      (item, i) => (bodyData[`documents[${i}]`] = item?.file)
    );
  params?.gallery &&
    params?.gallery?.forEach(
      (item, i) => (bodyData[`gallery[${i}]`] = item?.file)
    );
  params?.image &&
    params?.image?.forEach((item, i) => (bodyData[`image[${i}]`] = item?.file));
  params?.city && (bodyData[`location[city]`] = params?.city);
  params?.certificate80G &&
    params?.certificate80G?.map(
      (item, i) => (bodyData[`certificate80G[${i}]`] = item?.file)
    );
  params?.fcraCertificate &&
    params?.fcraCertificate?.map(
      (item, i) => (bodyData[`fcraCertificate[${i}]`] = item?.file)
    );
  params?.endDate &&
    (bodyData[`endDate`] = dayjs(params?.endDate).format("YYYY-MM-DD"));

  delete bodyData.fcraCertificate;
  delete bodyData.image;
  delete bodyData.certificate80G;
  delete bodyData.topic;
  delete bodyData.city;
  delete bodyData.documents;
  delete bodyData.gallery;
  delete bodyData.beneficiaryImage;

  try {
    const res = await apiRequest(
      `${params?._id ? "put" : "post"}`,
      `fund-raiser${params?._id ? "/" + params?._id : ""}`,
      {
        body: {
          ...bodyData,
        },
      },
      "multipart/form-data"
    );

    return res;
  } catch (error) {
    return error?.response;
  }
};

export const loadFundraiserList = async (
  params,
  setDeta = () => {},
  setLoaded = () => {}
) => {
  setLoaded(false);
  try {
    const res = await apiRequest("get", "fund-raiser", {
      queries: {
        ...params,
      },
    });

    const data = res.data;
    if (data.status === 200) {
      setDeta(data.docs);
      setLoaded(true);
    }
    return data;
  } catch (err) {
    setLoaded(true);
    return err;
  }
};

export const loadFundraiserDetails = async (
  id,
  setDetails = () => {},
  setLoaded = () => {}
) => {
  setLoaded(false);

  try {
    const res = await apiRequest("get", `fund-raiser/${id}`, {});

    const data = res.data;
    if (data.status === 200) {
      setDetails(data.data);
      setLoaded(true);
    }
    return data;
  } catch (err) {
    setLoaded(true);
    return err;
  }
};

export const loadCommentDetails = async (
  params,
  setDetails = () => {},
  setLoaded = () => {}
) => {
  setLoaded(false);

  try {
    const res = await apiRequest("get", `fund-raiser/comments`, {
      queries: {
        ...params,
      },
    });

    const data = res.data.docs;
    if (res?.data?.status === 200) {
      setDetails(data);
      setLoaded(true);
    }
    return res;
  } catch (err) {
    setLoaded(true);
    return err;
  }
};

export const AddCommentsService = async (
  params,
  resData = () => {},
  setLoaded = () => {}
) => {
  setLoaded(false);

  try {
    const res = await apiRequest("put", "fund-raiser/add-comments", {
      body: {
        id: params?._id,
        comment: params?.comment,
      },
    });
    const data = res.data;
    if (data.status === 201 || data.status === 200) {
      resData(data?.data);
      setLoaded(true);
    }
    return res;
  } catch (err) {
    setLoaded(true);
    return err?.response;
  }
};

export const AddReplyService = async (params, setLoaded = () => {}) => {
  setLoaded(false);

  try {
    const res = await apiRequest("put", "fund-raiser/add-reply", {
      body: {
        ...params,
      },
    });
    const data = res.data;
    if (data.status === 201 || data.status === 200) {
      setLoaded(true);
    }
    return res;
  } catch (err) {
    setLoaded(true);
    return err.response;
  }
};

export const DeleteReplyService = async (params, setLoaded = () => {}) => {
  setLoaded(false);
  try {
    const res = await apiRequest("patch", "fund-raiser/delete-reply", {
      body: {
        ...params,
      },
    });
    const data = res.data;
    if (data.status === 201 || data.status === 200) {
      setLoaded(true);
    }
    return res;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};

export const DeleteCommentsService = async (params, setLoaded = () => {}) => {
  setLoaded(false);
  try {
    const res = await apiRequest("patch", "fund-raiser/delete-comments", {
      body: {
        ...params,
      },
    });
    const data = res.data;
    if (data.status === 201 || data.status === 200) {
      setLoaded(true);
    }
    return res;
  } catch (error) {
    setLoaded(true);
    return error;
  }
};

export const EditCommentsService = async (params, setLoaded = () => {}) => {
  setLoaded(false);
  try {
    const res = await apiRequest("put", "fund-raiser/edit-comments", {
      body: {
        commentId: params?.id,
        comment: params?.comment,
      },
    });
    const data = res.data;
    if (data.status === 201 || data.status === 200) {
      setLoaded(true);
    }
    return res;
  } catch (error) {
    setLoaded(true);
    return error;
  }
};

export const EditReplyService = async (params, setLoaded = () => {}) => {
  setLoaded(false);
  try {
    const res = await apiRequest("put", "fund-raiser/edit-reply", {
      body: {
        commentId: params?.commentId,
        replyId: params?.replyId,
        reply: params?.reply,
      },
    });
    const data = res.data;
    if (data.status === 201 || data.status === 200) {
      setLoaded(true);
    }
    return res;
  } catch (error) {
    setLoaded(true);
    return error;
  }
};

//-------------- U    P    D    A    T    E -------------------

export const EditUpdatService = async (params, setLoaded = () => {}) => {
  setLoaded(false);
  try {
    const res = await apiRequest("patch", "fund-raiser/add-edit-update", {
      body: {
        ...params,
      },
    });
    const data = res.data;
    if (data.status === 201 || data.status === 200) {
      setLoaded(true);
    }
    return res;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};

export const addUpdateService = async (params) => {
  try {
    const res = await apiRequest("patch", "fund-raiser/add-edit-update", {
      body: { ...params },
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const DeleteUpdatesService = async (params, setLoaded = () => {}) => {
  setLoaded(false);
  try {
    const res = await apiRequest("patch", "fund-raiser/delete-update", {
      body: {
        ...params,
      },
    });
    const data = res.data;
    if (data.status === 201 || data.status === 200) {
      setLoaded(true);
    }
    return res;
  } catch (error) {
    setLoaded(true);
    return error;
  }
};

// export const SendOtpService = async (params) => {
//   const bodyData = {};
//   params?.email && (bodyData[`email`] = params?.email);
//   params?.phone && (bodyData[`phone`] = params?.phone);
//   try {
//     const res = await apiRequest("post", "send-otp", {
//       body: {
//         ...bodyData,
//       },
//     });
//     const data = res.data;
//     return data;
//   } catch (err) {
//     return err.response;
//   }
// };

export const VerifyOtpService = async (params) => {
  const bodyData = {};
  params?.email && (bodyData[`email`] = params?.email);
  params?.phone && (bodyData[`phone`] = params?.phone);
  try {
    const res = await apiRequest("post", "verify-otp", {
      body: {
        ...bodyData,
        otp: params?.otp,
        type: params?.type,
        phoneCode: params?.phoneCode,
      },
    });
    const data = res.data;
    return data;
  } catch (err) {
    return err.response;
  }
};

export const resendOtp = async (params) => {
  const bodyData = {};
  params?.email && (bodyData[`email`] = params?.email);
  // params?.phone && (bodyData[`phone`] = params?.phone);
  try {
    const res = await apiRequest("post", "send-otp", {
      body: {
        ...bodyData,
        // phoneCode: params?.phoneCode,
      },
    });
    const data = res.data;
    return data;
  } catch (err) {
    return err.response;
  }
};

export const loadFundraiserRelationList = async (
  setRelationList = () => {},
  setLoaded = () => {},
  params
) => {
  setLoaded(false);
  try {
    const res = await apiRequest("get", `category/group-by-parent`, {
      queries: {
        type: 1,
        ...params,
      },
    });

    const data = res.data;
    if (data.status === 200) {
      setRelationList(data.docs);
      setLoaded(true);
    }
    return res;
  } catch (err) {
    setLoaded(true);
    return err;
  }
};

export const loadMedicalConditionList = async (
  setMedicalConditionList = () => {},
  setLoaded = () => {}
) => {
  setLoaded(false);

  try {
    const res = await apiRequest("get", `category`, {
      queries: {
        type: 3,
      },
    });

    const data = res.data;
    if (data.status === 200) {
      setMedicalConditionList(data.docs?.filter((item) => item?.status == 1));
      setLoaded(true);
    }
    return res;
  } catch (err) {
    setLoaded(true);
  }
};

export const loadHospitalList = async (
  setHospitalListData = () => {},
  setLoaded = () => {},
  params
) => {
  setLoaded(false);

  try {
    const res = await apiRequest("get", `category`, {
      queries: {
        type: 2,
        ...params,
      },
    });

    const data = res.data;
    if (data.status === 200) {
      setHospitalListData(data.docs?.filter((item) => item.status == 1));
      setLoaded(true);
    }
    return res;
  } catch (err) {
    setLoaded(true);
    return err;
  }
};

export const loadCauseList = async (params) => {
  try {
    const res = await apiRequest("get", `category`, { queries: { ...params } });
    return res;
  } catch (error) {
    return error.response;
  }
};

export const repostService = async (params, setLoaded = () => {}) => {
  setLoaded(false);

  try {
    const res = await apiRequest("patch", "fund-raiser/repost", {
      body: {
        ...params,
      },
    });
    const data = res.data;
    if (data.status === 201 || data.status === 200) {
      setLoaded(true);
    }
    return res;
  } catch (err) {
    setLoaded(true);
    return err.response;
  }
};

export const inactiveService = async (params, setLoaded = () => {}) => {
  setLoaded(false);
  try {
    const res = await apiRequest("patch", "fund-raiser/change-status", {
      body: { ...params },
    });
    setLoaded(true);
    return res;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};

export const removePhotoService = async (params, setLoaded = () => {}) => {
  setLoaded(false);
  try {
    const res = await apiRequest("patch", "fund-raiser/delete-image", {
      body: { ...params },
    });
    setLoaded(true);
    return res;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};
