import React from "react";
import Image from "../elements/Image";
import BannerForm from "../partials/BannerForm";

const MainBannerAlt = ({ data }) => {
  return (
    <>
      <section className="relative overflow-hidden w-full z-0 sm:h-[600px] flex items-center py-10 sm:py-0">
        <div className="absolute top-0 left-0 w-full h-full z-[-1] after:absolute after:content-[''] after:w-full after:h-full after:top-0 after:left-0 after:bg-gradient-to-r after:from-white/95 after:from-10% after:via-white/80 after:via-50%">
          <Image
            src={data.image}
            alt={""}
            width={"100%"}
            height={"100%"}
            effect={"blur"}
            classes={"object-cover saturate-200 object-center"}
          />
        </div>
        <div className="relative z-[1] w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto flex items-center">
          <div className="w-full flex flex-col sm:flex-row items-center justify-between sm:pb-20 gap-y-8 sm:gap-y-0">
            <div className="w-full sm:w-1/2 md:w-1/2 lg:w-3/5 xl:w-3/5 2xl:w-1/2 3xl:w-2/5 space-y-8">
              <div className="space-y-4">
                <h1
                  className="font-Lexend  text-3xl sm:text-4xl xl:text-5xl uppercase font-bold text-black"
                  dangerouslySetInnerHTML={{ __html: data.title }}
                ></h1>
              </div>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/2 lg:w-2/5 xl:w-2/5 2xl:w-1/2 3xl:w-2/5 flex">
              <BannerForm />
            </div>
          </div>
        </div>
      </section>
      {data.statistics?.length > 0 && (
        <section className="relative w-full flex items-center bg-white py-5 sm:py-0">
          <div className="relative flex justify-center w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
            <div className="w-full sm:w-auto flex flex-col sm:flex-row gap-6 py-4 px-5 bg-gray-100 rounded-xl sm:-translate-y-1/2">
              {data.statistics?.map((item) => (
                <div
                  className="flex flex-col items-center justify-center bg-white py-8 px-4 rounded-xl min-w-[200px]"
                  key={item._id}
                >
                  <div className="text-xl text-carnation-500 font-semibold">
                    {item.data}
                  </div>
                  <h4 className="text-base text-slate-600">{item.label}</h4>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default MainBannerAlt;
