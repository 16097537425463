import React from 'react';
import Image from "../components/elements/Image";
import { classNames } from "../helpers/classNames";
import { Link } from "react-router-dom";

const DefaultFooter = ({ data }) => {

  return (
    <>
      <footer className="relative bg-white py-8">
        <div className="relative py-16 w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
          <div className="flex flex-col lg:flex-row gap-10 lg:gap-5">
            <div className="w-full sm:w-4/5 mx-auto lg:mx-0 lg:w-3/12">
              <div className="max-w-[200px] h-16 mx-auto lg:mr-auto">
                <Image src={data.logo} alt={'Logo'} width={'100%'} height={'100%'} effect={'blur'} classes={'object-contain lg:object-left'} />
              </div>
              <div className="mt-8 text-xs xl:text-sm text-slate-600 text-center lg:text-left">{data.about}</div>
              {data.socialMenu?.length > 0 &&
                <div className="mt-8 flex items-center justify-center lg:justify-start gap-2">
                  {data.socialMenu?.map((item) => (
                    <a 
                      key={item._id} 
                      href={item.link} 
                      target="_blank" 
                      rel="noreferrer"
                      className="flex items-center justify-center w-8 h-8 bg-carnation-500 rounded-md text-white border border-carnation-500 transition-all duration-200 hover:text-carnation-500 hover:bg-white"
                    >
                      <i className={classNames("fa-brands fa-fw", item.icon)}></i>
                    </a>
                  ))}
                </div>
              }
            </div>
            <div className="w-full lg:w-9/12">
              <div className="grid grid-cols-2 sm:flex gap-y-10 sm:gap-y-0 gap-x-2 sm:gap-x-5">
                <div className="w-full sm:w-1/4">
                  {data.columnOne &&
                 
                    <>
                      <h4 className="text-base xl:text-lg font-semibold text-black uppercase mb-6">{data.columnOne?.title}</h4>
                      {data.columnOne?.menu?.length > 0 &&
                        <div className="relative space-y-3 sm:space-y-4">
                          {data.columnOne?.menu?.map((item) => (
                           
                            <div className="flex" key={item._id}>
                               
                              <Link to={item.link} className="text-slate-600 text-xs xl:text-sm transition-all duration-200 hover:text-carnation-500 hover:ml-2">{item.name}</Link>
                            </div>
                          ))}
                        </div>
                      }
                    </>
                  }
                </div>
                <div className="w-full sm:w-1/4">
                  {data.columnTwo &&
                    <>
                      <h4 className="text-base xl:text-lg font-semibold text-black uppercase mb-6">{data.columnTwo?.title}</h4>
                      {data.columnTwo?.menu?.length > 0 &&
                        <div className="relative space-y-3 sm:space-y-4">
                          {data.columnTwo?.menu?.map((item) => (
                            <div className="flex" key={item._id}>
                              <Link to={item.link} className="text-slate-600 text-xs xl:text-sm transition-all duration-200 hover:text-carnation-500 hover:ml-2">{item.name}</Link>
                            </div>
                          ))}
                        </div>
                      }
                    </>
                  }
                </div>
                <div className="w-full sm:w-1/4">
                  {data.columnThree &&
                    <>
                      <h4 className="text-base xl:text-lg font-semibold text-black uppercase mb-6">{data.columnThree?.title}</h4>
                      {data.columnThree?.menu?.length > 0 &&
                        <div className="relative space-y-3 sm:space-y-4">
                          {data.columnThree?.menu?.map((item) => (
                            <div className="flex" key={item._id}>
                              <Link to={item.link} className="text-slate-600 text-xs xl:text-sm transition-all duration-200 hover:text-carnation-500 hover:ml-2">{item.name}</Link>
                            </div>
                          ))}
                        </div>
                      }
                    </>
                  }
                </div>
                <div className="w-full sm:w-1/4">
                  {data.contact && 
                    <>
                      <h4 className="text-base xl:text-lg font-semibold text-black uppercase mb-6">{data.contact?.title}</h4>
                      {data.contact?.subdata?.length > 0 &&
                        <div className="relative space-y-3 sm:space-y-4">
                          {data.contact?.subdata?.map((item) => (
                            <div className="" key={item._id}>
                              <Link
                                to={(item.type !== 'address')?item.type+":"+item.data:''} 
                                className={classNames("inline-flex gap-4 group", item.type === 'address'?"pointer-events-none":"")}
                              >
                                <div className="text-base text-carnation-500"><i className={classNames("fa-regular fa-fw", item.icon)}></i></div>
                                <div className="text-slate-600 text-xs xl:text-sm transition-all duration-200 group-hover:text-carnation-500">{item.data}</div>
                              </Link>
                            </div>
                          ))}
                        </div>
                      }
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-t border-slate-200">
          <div className="relative py-5 w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
            <div className="text-center text-xs text`-slate-500">{data.copyright}</div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default DefaultFooter;