import React, { useEffect, useRef, useState } from "react";
import Input from "../../form/Input";
import Select from "../../form/Select";
import Button from "../../form/Button";
import SelectOptionHeader from "../../form/SelectOptionHeader";
import InputFile from "../../form/InputFile";
import Steps from "./Steps";
import Image from "../../elements/Image";
import ImageUploader from "../../form/ImageUploader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  loadCauseList,
  loadFundraiserRelationList,
} from "../../../services/FundrisersService";
import {
  findErrorField,
  hasArrayData,
  nameHasArrayData,
} from "../../../helpers";
import NgoMedical from "../../elements/pagesections/fundraisingSteps/NgoMedical";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import QuilEditor from "../../../components/elements/quilEditor/QuilEditor";

const StepTwo = ({
  steps,
  currentStep,
  setCurrentStep,
  setStepsData = () => {},
  stepsData,
  fundraiserData,
  setFundraiserData,
  formData,
  setFormData = () => {},
}) => {
  const [loaded, setloaded] = useState(true);
  const token = stepsData?.topic?.name;
  const [relationList, setrelationList] = useState([]);
  const [relationListData, setrelationListData] = useState([]);
  const [causeData, setCauseData] = useState([]);

  // load cause list ============>

  useEffect(() => {
    loadCauseList({ limit: 100, offset: 0, type: 4 }).then((res) => {
      if (res.data.status === 200) {
        setCauseData(res?.data.docs?.filter((item) => item?.status == 1));
      }
    });
  }, []);

  useEffect(() => {
    setloaded(false);
    loadFundraiserRelationList().then((res) => {
      if (res?.data?.status === 200) {
        setrelationList(res.data?.docs);
        setloaded(true);
      } else {
        setloaded(true);
      }
    });
  }, [token]);

  useEffect(() => {
    setrelationListData(
      (Array.isArray(relationList) &&
        relationList.length > 0 &&
        relationList.map((elm) => ({
          optionHeader: elm?.name,
          options:
            Array.isArray(elm?.childs) &&
            elm?.childs.length > 0 &&
            elm?.childs?.filter((item) => item?.status == 1),
        }))) ||
        []
    );
  }, [relationList]);

  const handleContinue = () => {
    let extraMedicalData = [];
    if (stepsData[`cause[name]`] === "Medical") {
      extraMedicalData = [
        "hospitalDetails[ailment]",
        "hospitalDetails[address][city]",
        "hospitalDetails[name]",
      ];
    }
    if (token === "Medical Treatment") {
      if (
        // check field has or not ============>
        hasArrayData(Object.keys(stepsData), [
          "image",
          "amount",
          "endDate",
          "relationWithFundraiser",
        ])
      ) {
        if (stepsData["amount"] > 2000) {
          setCurrentStep((Number(currentStep) + 1).toString());
        } else {
          toast.info(`Minimum amount should be 2000`);
        }
      } else {
        toast.info(
          `Please select ${nameHasArrayData(
            Object.keys(stepsData),
            ["image", "amount", "endDate", "relationWithFundraiser"],
            ["image", "amount", "End date", "relation with fundraiser"]
          )}`
        );
      }
    }
    if (token === "NGO/Charity") {
      if (
        hasArrayData(Object.keys(stepsData), [
          "image",
          "amount",
          "endDate",
          "cause[_id]",
          "cause[name]",
          ...extraMedicalData,
        ])
      ) {
        if (stepsData["amount"] > 2000) {
          setCurrentStep((Number(currentStep) + 1).toString());
        } else {
          toast.info(`Minimum amount should be 2000`);
        }
      } else {
        toast.info(
          `Please select ${nameHasArrayData(
            Object.keys(stepsData),
            [
              "image",
              "amount",
              "endDate",
              "cause[_id]",
              "cause[name]",
              ...extraMedicalData,
            ],
            [
              "image",
              "amount",
              "End date",
              "Please Choose a Cause",
              "enter cause name",
              "ailment",
              "Hospital address",
              "Hospital name",
            ]
          )}`
        );
      }
    }
    if (token === "Other Cause") {
      if (
        hasArrayData(Object.keys(stepsData), [
          "image",
          "amount",
          "endDate",
          "relationWithFundraiser",
        ])
      ) {
        if (stepsData["amount"] > 2000) {
          setCurrentStep((Number(currentStep) + 1).toString());
        } else {
          toast.info(`Minimum amount should be 2000`);
        }
      } else {
        toast.info(
          `Please select ${nameHasArrayData(
            Object.keys(stepsData),
            ["image", "amount", "endDate", "relationWithFundraiser"],
            ["image", "amount", "End date", "relation with fundraiser"]
          )}`
        );
      }
    }
    // setCurrentStep((Number(currentStep) + 1).toString());
  };

  const handleBack = () => {
    setCurrentStep((Number(currentStep) - 1).toString());
  };

  const onImageSelectHandler = (e) => {
    setStepsData((pre) => ({
      ...pre,
      image: [{ _id: crypto.randomUUID(), file: e.target.files[0] }],
    }));
  };

  const onHandleChange = (e) => {
    let notEmptyFields = {};
    const name = e?.target?.name;
    const value = e?.target?.value;
    if (value.length > 0) {
      setStepsData((preState) => ({ ...preState, [name]: value }));
    } else {
      setStepsData((preState) => ({ ...preState, [name]: value }));
      Object.keys(stepsData)?.forEach((fields) => {
        if (fields !== name) notEmptyFields[`${fields}`] = stepsData[fields];
      });
      setStepsData(notEmptyFields);
    }
  };

  return (
    <>
      <div className="text-center space-y-2">
        <h1 className="text-base xl:text-lg font-Lexend font-semibold">
          Raising funds for {token} purpose
        </h1>
        <Steps data={steps} currentStep={currentStep} />
      </div>

      <form className="mt-8 space-y-4 mx-auto">
        <div className="relative mb-5 flex flex-col items-center justify-center">
          <ImageUploader
            label={"Beneficiary's Photo"}
            onChange={(e) => onImageSelectHandler(e)}
            files={stepsData?.image}
            removeFile={(e) => {
              setStepsData((preState) => ({
                ...preState,
                image: preState?.image?.filter((item) => item?._id !== e._id),
              }));
              delete stepsData?.image;
              setStepsData({ ...stepsData });
            }}
          />
        </div>
        <div className="relative">
          <Input
            isInputGroup={true}
            inputGroupIcon={"fa-regular fa-indian-rupee-sign"}
            inputGroupPosition={"left"}
            label={"How much do you want to raise?"}
            inputValue={stepsData?.amount}
            errorType={
              stepsData?.amount < 2000
                ? "warning"
                : stepsData?.amount >= 2000
                ? ""
                : "danger"
            }
            errorText={
              stepsData?.amount && stepsData?.amount < 2000
                ? "The minimum goal amount is ₹2000"
                : "Amount is required!"
            }
            inputName={"amount"}
            onChange={(e) => onHandleChange(e)}
            inputType="number"
            minValue="0"
            inputPlaceholder="Enter the amount.."
          />
        </div>
        <div className="relative">
          <div class="text-sm font-medium text-slate-500 mb-1">End date</div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format={"DD-MM-YYYY"}
              onChange={(e) =>
                onHandleChange({
                  target: {
                    name: "endDate",
                    value: dayjs(e?.$d).format("YYYY-MM-DD"),
                  },
                })
              }
              value={
                stepsData?.endDate === undefined
                  ? ""
                  : dayjs(stepsData?.endDate)
              }
              className="pt-0 pb-0 w-full h-10 rounded-md bg-slate-50 border border-slate-200 px-3 text-sm text-slate-600 !ring-0 !outline-0 focus:border-carnation-200 focus:bg-carnation-50 transition-all duration-200 "
            />
          </LocalizationProvider>
          {!stepsData?.endDate && (
            <div className="text-xs text-red-600 mt-1">
              <i className="fa-regular fa-fw fa-square-exclamation text-sm mr-1"></i>
              {"End date is required!"}
            </div>
          )}
        </div>
        {token === "NGO/Charity" && (
          <>
            <div className="relative">
              <Select
                xPlacement={"left"}
                label={"Please Choose a Cause"}
                dropdownData={[{ name: "Select a cause" }, ...causeData]}
                dropdownClass={"max-h-60 w-full"}
                dropdownButtonClass={""}
                selectedValueType={"name"}
                errorText={"Cause selection is required!"}
                errorType={stepsData?.[`cause[name]`] ? "" : "error"}
                selectedValue={stepsData?.[`cause[name]`]}
                setCustomSelecedvalue={(value) => {
                  if (value?.name === "Select a cause") {
                    delete stepsData[`cause[_id]`];
                    delete stepsData[`cause[name]`];
                    setStepsData({ ...stepsData });
                  } else {
                    if (value?.name !== "Medical") {
                      delete stepsData[`hospitalDetails[address][city]`];
                      delete stepsData[`hospitalDetails[ailment]`];
                      delete stepsData[`hospitalDetails[name]`];
                      setStepsData({ ...stepsData });
                    }
                    onHandleChange({
                      target: { name: "cause[name]", value: value?.name },
                    });
                    onHandleChange({
                      target: { name: "cause[_id]", value: value?._id },
                    });
                  }
                }}
              />
            </div>
            {stepsData?.[`cause[name]`] == "Medical" && (
              <NgoMedical stepsData={stepsData} setStepsData={setStepsData} />
            )}
          </>
        )}
        {token === "Medical Treatment" && (
          <div className="relative">
            <SelectOptionHeader
              xPlacement={"left"}
              name={"relationWithFundraiser"}
              label={"Relation with fundraiser?"}
              dropdownData={relationListData}
              dropdownClass={"max-h-60 w-full"}
              dropdownButtonClass={""}
              selectedValueType={"_id"}
              errorText={"Please choose one relationship!"}
              errorType={stepsData?.relationWithFundraiser ? "" : "error"}
              selectedValue={stepsData?.relationWithFundraiser}
              setCustomSelecedvalue={(value) =>
                onHandleChange({
                  target: { name: "relationWithFundraiser", value: value?._id },
                })
              }
              placeholder={"Select one relation"}
            />
          </div>
        )}
        {token === "Other Cause" && (
          <div className="relative">
            <SelectOptionHeader
              xPlacement={"left"}
              name={"relationWithFundraiser"}
              label={"Whom are you raising funds for?"}
              dropdownData={[
                { name: "Select raising funds for" },
                ...relationListData,
              ]}
              errorText={"Relation with fundraiser is required!"}
              errorType={stepsData?.relationWithFundraiser ? "" : "error"}
              dropdownClass={"max-h-60 w-full"}
              dropdownButtonClass={""}
              selectedValueType={"_id"}
              selectedValue={stepsData?.relationWithFundraiser}
              setCustomSelecedvalue={(value) =>
                onHandleChange({
                  target: { name: "relationWithFundraiser", value: value?._id },
                })
              }
            />
          </div>
        )}
        {/* {token != "NGO/Charity" && (
          <div className="relative">
            <ImageUploader
              label={"Beneficiary's Photo"}
              onChange={(e) => onImageSelectHandler(e)}
              files={stepsData?.image}
              removeFile={(e) => {
                setStepsData((preState) => ({
                  ...preState,
                  image: preState?.image?.filter((item) => item?._id !== e._id),
                }));
                delete stepsData?.image
                setStepsData({...stepsData})
              }}
            />
          </div>
        )} */}

        <div className="relative flex justify-center gap-3">
          <Button
            buttonClasses={"w-full"}
            buttonType={"button"}
            buttonLabel={"Back"}
            buttonLabelClasses={""}
            buttonEffect={"filled"}
            buttonFunction={handleBack}
          />
          <Button
            buttonClasses={"w-full"}
            buttonType={"button"}
            buttonLabel={"Save & Continue"}
            buttonLabelClasses={""}
            buttonEffect={"filled"}
            buttonFunction={handleContinue}
          />
        </div>
      </form>
      <div className="text-xs text-slate-500 mt-4 text-center">
        By continuing you agree to
        <Button
          buttonClasses={
            "!px-0 !h-auto !bg-transparent !rounded-none !inline-flex mx-1"
          }
          buttonHasLink={true}
          buttonLink={"/"}
          buttonLabel={"Terms of Service"}
          buttonLabelClasses={
            "font-semibold text-carnation-500 !text-xs hover:!underline"
          }
        />
        and
        <Button
          buttonClasses={
            "!px-0 !h-auto !bg-transparent !rounded-none !inline-flex mx-1"
          }
          buttonHasLink={true}
          buttonLink={"/"}
          buttonLabel={"Privacy Policy"}
          buttonLabelClasses={
            "font-semibold text-carnation-500 !text-xs hover:!underline"
          }
        />
      </div>
    </>
  );
};

export default StepTwo;
