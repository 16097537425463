import { apiRequest } from "../utils/async/apiUtils";

export const addBankDetails = async (params) => {
  let data = {};
  params?.id && (data["id"] = params?.id);
  delete params?.id;
  try {
    const res = await apiRequest("patch", `add-bank-details/${data?.id}`, {
      body: { ...params },
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const uploadDocoments = async (params) => {
  let data = {};
  params?.id && (data["id"] = params?.id);
  delete params?.id;
  try {
    const res = await apiRequest(
      "patch",
      `upload-documents/${data?.id}`,
      { body: params },
      "multipart/form-data"
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const uploadDocomentsLists = async (params) => {
  try {
    const res = await apiRequest("get", `bank-docs/${params?.id}`);
    return res;
  } catch (error) {
    return error;
  }
};
