import React from "react";
import PostSidebar from "../../../shared/PostSidebar";
import { useParams } from "react-router-dom/dist/umd/react-router-dom.development";
import NoDataFound from "../../elements/extra/NoDataFound";

const Help = () => {
  const { id } = useParams();
  return (
    <section className="relative  overflow-hidden w-full py-5 bg-gray-100">
      <div className="relative z-[1] w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
        <div className="w-full flex md:flex-nowrap flex-wrap gap-4 md:gap-0">
          <div className="w-full md:w-80 min-w-[320px] ">
            <PostSidebar id={id} />
          </div>

          <div className="p-4 bg-white rounded-lg shadow-md w-full max-w-[calc(100%-320px)] overflow-hidden text-center">
            <h1 className="text-3xl font-semibold text-gray-800 mb-4">
              Need Help?
            </h1>

            <p className="text-base text-gray-600 mb-4">
              Contact your Fundraiser Manager:
            </p>

            <div className="flex flex-col items-center mb-4">
              <p className="text-lg text-gray-800">Phone: +91 9930088522</p>
              <p className="text-lg text-gray-800">
                Email:{" "}
                <a
                  href="mailto:help@ketto.org"
                  className="text-blue-500 hover:underline"
                >
                  help@ketto.org
                </a>
              </p>
            </div>

            <div className="mt-8">
              <p className="text-base text-gray-600 mb-2">
                Additional Information:
              </p>
              <ul className="pl-4 text-base text-gray-800">
                <li className="mb-2">
                  <a href="#" className="text-blue-500 hover:underline">
                    Terms of Use
                  </a>
                </li>
                <li className="mb-2">
                  <a href="#" className="text-blue-500 hover:underline">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="#" className="text-blue-500 hover:underline">
                    AML Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Help;
