import React, { useEffect, useState } from "react";
import Input from "../form/Input";
import Select from "../form/Select";
import Button from "../form/Button";
import Textarea from "../form/Textarea";
import { addHowItWorks } from "../../services/HowItWorksService";
import { loadFundsForList } from "../../services/fundsForService";
import { toast } from "react-toastify";
import { classNames } from "../../helpers/classNames";

const BannerForm = () => {
  const [name, setname] = useState("");
  const [mobile, setmobile] = useState("");
  const [phoneCode, setphoneCode] = useState("+91");
  const [purpose, setpurpose] = useState("");
  const [description, setdescription] = useState("");
  const [loaded, setloaded] = useState(true);
  const [topics, settopics] = useState([
    { name: "Select one topic..", _id: "" },
  ]);

  const dropdownData = [
    { name: "+91" },
    { name: "+1" },
    { name: "+44" },
    { name: "+93" },
    { name: "+355" },
    { name: "+213" },
    { name: "+1684" },
    { name: "+376" },
    { name: "+244" },
    { name: "+1264" },
    { name: "+1268" },
    { name: "+54" },
  ];

  const dropdownData2 = [
    { name: "What will be the funds used for?", value: "wasdfg454sdfgh" },
    { name: "Medical crowdfunding", value: "wasdfgfg454sdfgh" },
    { name: "Memorial crowdfunding", value: "wtysdfg454sdfgh" },
    { name: "Cancer crowdfunding", value: "wasd5fgh54sdfgh" },
    { name: "Education crowdfunding", value: "w7ghjdfg454sdfgh" },
    { name: "Child welfare", value: "was42fghdfg454sdfgh" },
    { name: "Transplant crowdfunding", value: "w5fghjhgg454sdfgh" },
  ];

  // const handleChange = (e) => {
  //   console.log(e.target.name + ":" + e.target.value);
  // };
  // console.log(topics, "topics");
  useEffect(() => {
    loadFundsForList().then((res) => {
      // console.log(res, "res");
      let data = res.data?.docs;
      // console.log(data, "data");
      data && settopics((pre) => [...pre, ...data]);
    });
  }, []);

  const handleSubmit = () => {
    // setname("");
    // setmobile("");
    // setphoneCode("+91");
    // setdescription("");
    // setpurpose("");
    addHowItWorks(
      { name, phone: mobile, phoneCode, description, purposeId: purpose },
      setloaded
    ).then((res) => {
      if (res.data.status >= 200 && res.data.status < 300) {
        toast.success(res.data.message);
        setname("");
        setmobile("");
        setphoneCode("+91");
        setdescription("");
        setpurpose("");
      } else {
        toast.error(res.data.message);
      }
    });
  };
  // console.log(name, mobile, description, purpose);

  return (
    <>
      <div className="relative w-full max-w-sm 3xl:max-w-md bg-white rounded-2xl shadow-lg ml-auto my-auto">
        <h2 className="font-Lexend text-2xl text-black font-semibold py-4 px-6 text-center">
          Raise your funds
        </h2>
        <div className="relative border-t border-slate-200 py-6 px-6">
          <div className="space-y-4">
            <div className="relative">
              <Input
                inputType={"text"}
                inputPlaceholder={"Enter your name"}
                onChange={(e) => setname(e.target.value)}
                // onChange={(e) => handleChange(e)}
                inputName={"name"}
                inputValue={name}
              />
            </div>
            <div className="relative flex">
              <div className="w-20 min-w-[80px]">
                <Select
                  xPlacement={"left"}
                  dropdownData={dropdownData}
                  dropdownClass={"max-h-40"}
                  dropdownButtonClass={"!px-2 !border-r-none rounded-r-none"}
                  selectedValue={phoneCode}
                  setCustomSelecedvalue={(value) => setphoneCode(value.name)}
                />
              </div>
              <div className="w-full">
                <Input
                  // inputType={"tel"}
                  inputPlaceholder={"Mobile no"}
                  inputClasses={"!border-l-none rounded-l-none -ml-[1px]"}
                  onChange={(e) => setmobile(e.target.value)}
                  inputName={"mobile"}
                  inputValue={mobile}
                  inputType="number"
                />
              </div>
            </div>
            <div className="relative">
              {/* <Select
                xPlacement={"left"}
                dropdownData={topics}
                dropdownClass={"max-h-40 w-full"}
                dropdownButtonClass={""}
                selectedValue={purpose}
                setCustomSelecedvalue={(value) => setpurpose(value)}
              /> */}
              <select
                className={classNames(
                  "w-full rounded bg-slate-50 border border-slate-200 px-3 text-sm text-slate-600 !ring-0 !outline-0 focus:border-gray-400 transition-all duration-200 resize-none overflow-auto scroll-smooth scrollbar"
                )}
                value={purpose}
                onChange={(e) => setpurpose(e.target.value)}
              >
                {topics &&
                  topics.map((elm) => (
                    <option value={elm?._id}>{elm.name}</option>
                  ))}
              </select>
            </div>
            <div className="relative">
              <textarea
                className={classNames(
                  "w-full h-20 rounded bg-slate-50 border border-slate-200 px-3 text-sm text-slate-600 !ring-0 !outline-0 focus:border-gray-400 transition-all duration-200 resize-none overflow-auto scroll-smooth scrollbar"
                )}
                value={description}
                placeholder={" Write a short description.."}
                name={"description"}
                defaultValue={description}
                onChange={(e) => setdescription(e.target.value)}
              ></textarea>
              {/* <Textarea
                inputPlaceholder="Write a short description..."
                inputName="description"
                inputValue={description}
                onChange={(e) => setdescription(e.target.value)}
              /> */}
            </div>
            <div className="relative">
              <Button
                buttonClasses={"w-full"}
                buttonHasLink={false}
                buttonLabel={"Continue"}
                buttonLabelClasses={"text-base"}
                buttonEffect={"filled"}
                buttonFunction={handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerForm;
