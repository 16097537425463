import React, { useEffect, useState } from "react";
import Input from "../../form/Input";
import Select from "../../form/Select";
import Button from "../../form/Button";
import Steps from "./Steps";
import { loadFundsForList } from "../../../services/fundsForService";
import {
  SignUpService,
  addFundraiser,
} from "../../../services/FundrisersService";
import Loader from "../../elements/loader/Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";
import {
  getCountryDetails,
  loadCountries,
} from "../../../services/CountryService";
import dayjs from "dayjs";
// import Loader from "../components/elements/loader/Loader";

const StepOne = ({
  steps,
  currentStep,
  setCurrentStep,
  setStepsData,
  stepsData,
}) => {
  const [fundsFor, setFundsFor] = useState([]);

  const [loaded, setLoaded] = useState(true);
  const [phoneCode, setPhoneCode] = useState("");
  const [customSelectedvalue, setCustomSelecedvalue] = useState({
    name: "+91",
  });
  const [purpose, setPurpose] = useState(null);
  const [coords, setCoords] = useState({
    lat: undefined,
    lon: undefined,
  });
  const [countryList, setCountryList] = useState([]);
  const [currentCountry, setCurrentCountry] = useState({});
  const [country, setCountry] = useState({
    limit: 10,
    offset: 0,
    hasNextPage: false,
  });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setCoords({
        lat: position?.coords?.latitude,
        lon: position?.coords?.longitude,
      });
    });
  }, []);

  // get country list ===========>

  useEffect(() => {
    loadCountries().then((res) => {
      if (res?.status === 200) {
        setCountry((preState) => ({
          ...preState,
          hasNextPage: res?.hasNextPage,
        }));
        setCountryList(
          res?.docs?.map((country) => ({
            name: "+" + country?.phone_code?.replace("+", ""),
            country_name: country?.name,
            _id: country?._id,
          }))
        );
      }
    });
  }, []);

  // get current country details ==========>

  useEffect(() => {
    if (coords?.lon && coords?.lat && countryList) {
      getCountryDetails({ lat: coords?.lat, lon: coords?.lon }).then((res) => {
        setCurrentCountry({
          name: countryList?.find(
            (item) => item?.country_name === res?.address?.country
          )?.name,
        });
      });
    }
  }, [coords, countryList]);

  useEffect(() => {
    loadFundsForList(() => {}, setFundsFor, setLoaded);
  }, []);

  useEffect(() => {
    setPhoneCode(customSelectedvalue?.name);
  }, [customSelectedvalue]);

  const handleContinue = () => {
    if (stepsData?.topic?._id) {
      setCurrentStep("2");
    } else {
      toast.info("Please select a purpose");
    }
  };

  const onHandleChange = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    setStepsData((preState) => ({ ...preState, [name]: value }));
  };

  return (
    <>
      <div className="text-center space-y-2">
        <h1 className="text-2xl xl:text-3xl font-Lexend font-semibold">
          New Fundraising
        </h1>
        <Steps data={steps} currentStep={currentStep} />
      </div>
      <form className="mt-8 space-y-4 mx-auto">
        <div className="relative">
          <h4 className="text-sm font-semibold text-black">
            Purpose of raising funds
          </h4>
          {fundsFor?.length === 0 && loaded === false ? (
            <Loader />
          ) : (
            <div className="grid grid-cols-3 gap-2 mt-2">
              {fundsFor?.map((item, index) => (
                <div
                  key={item._id}
                  className="relative w-full overflow-hidden z-0 flex"
                >
                  <input
                    checked={stepsData?.topic?._id === item?._id}
                    type="radio"
                    name="topic"
                    onChange={(e) => {
                      // setStepsData({
                      //   endDate: dayjs(new Date(new Date().setMonth(new Date().getMonth()+1))).format("YYYY-MM-DD"),
                      // });
                      onHandleChange({
                        target: { name: e.target.name, value: item },
                      });
                    }}
                    required
                    className="peer absolute z-[1] cursor-pointer top-0 left-0 w-full h-full opacity-0"
                  />
                  <div className="w-full relative bg-slate-50 border border-slate-200 text-xs font-semibold px-2 py-2 flex items-center justify-center leading-tight text-center rounded-md text-slate-600 transition-all duration-200 peer-checked:bg-carnation-500 peer-checked:border-carnation-500 peer-checked:text-white">
                    {item.name}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="relative flex justify-center">
          <Button
            buttonClasses={"w-full"}
            buttonType={"button"}
            buttonLabel={"Continue"}
            buttonLabelClasses={""}
            buttonEffect={"filled"}
            buttonFunction={handleContinue}
            isDisable={loaded === false ? true : false}
          />
        </div>
      </form>
      <div className="text-xs text-slate-500 mt-4 text-center">
        By continuing you agree to
        <Button
          buttonClasses={
            "!px-0 !h-auto !bg-transparent !rounded-none !inline-flex mx-1"
          }
          buttonHasLink={true}
          buttonLink={"/"}
          buttonLabel={"Terms of Service"}
          buttonLabelClasses={
            "font-semibold text-carnation-500 !text-xs hover:!underline"
          }
        />
        and
        <Button
          buttonClasses={
            "!px-0 !h-auto !bg-transparent !rounded-none !inline-flex mx-1"
          }
          buttonHasLink={true}
          buttonLink={"/"}
          buttonLabel={"Privacy Policy"}
          buttonLabelClasses={
            "font-semibold text-carnation-500 !text-xs hover:!underline"
          }
        />
      </div>
    </>
  );
};

export default StepOne;
