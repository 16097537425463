import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import LoaderFull from "../components/LoaderFull";
import { useDispatch, useSelector } from "react-redux";
import { apiRequest } from "../utils/async/apiUtils";
export const ProtectedRoute = (props) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(true);
  const [auth, setAuth] = useState(false);
  let token_ = localStorage.getItem("auth_token");
  useEffect(() => {
    const checkData = async () => {
      const res = await apiRequest("post", "verify-token", {});
      if (res.status === 401) {
        localStorage.removeItem("auth_token");
        navigate("/signin");
      } else {
        setAuth(true);
      }
    };

    if (!token_) {
      setIsLoader(false);
      navigate("/signin");
    } else {
      checkData();
    }
  }, [token_, dispatch]);

  return (
    <>
      {/* {isLoader && <LoaderFull />} */}

      {auth && props.children}
    </>
  );
};
