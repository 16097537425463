import React from "react";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

const PageNotFound = () => {
  return (
    <div className="min-h-[50vh] flex flex-col items-center justify-center">
      <CSSTransition in={true} appear={true} timeout={500} classNames="fade">
        <div className="text-center">
          <h1 className="text-4xl font-bold text-red-500 mb-4">404</h1>
          <p className="text-xl text-gray-700 mb-4">Page not found</p>
          <Link to="/" className="text-blue-500 hover:underline">
            Go Back to Home
          </Link>
        </div>
      </CSSTransition>
    </div>
  );
};

export default PageNotFound;
