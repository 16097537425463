import { apiRequest } from "../utils/async/apiUtils";

export const UpdateProfile = async (params, setLoaded = () => {}) => {
  setLoaded(false);
  try {
    const res = await apiRequest(
      "put",
      "profile",
      {
        body: {
          name: params?.name || "",
          ...params.address,
          phone: params?.phone || "",
          phoneCode: params?.phoneCode || "",
          dateOfBirth: params?.dateOfBirth || "",
          image: params?.image || "",
          "social[0][url]": params?.facebook || "",
          "social[1][url]": params?.twitter || "",
          "detailsOfPan[panNumber]": params?.pan || "",
          email: params?.email || "",
          "detailsOfPan[nameInPan]": params?.nameInPan || "",
          aadharNo: params?.aadharNumber || "",
          "bankDetails[name]": params?.accountHolderName || "",
          "bankDetails[accountNo]": params?.accountNumber || "",
          "bankDetails[ifscCode]": params?.ifsc || "",
          "bankDetails[bankName]": params?.bankName || "",
        },
      },
      "multipart/form-data"
    );
    const data = res.data;
    setLoaded(true);
    return res;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};

export const loadProfileDetails = async (
  setDetails = () => {},
  setLoaded = () => {}
) => {
  setLoaded(false);

  try {
    const res = await apiRequest("get", `profile`);

    const data = res.data;
    if (data.status === 200) {
      setDetails(data.data);
      setLoaded(true);
    }
    return res;
  } catch (err) {
    setLoaded(true);
    return err;
  }
};

export const loadCityList = async (
  params,
  setDetails = () => {},
  setLoaded = () => {}
) => {
  setLoaded(false);

  try {
    const res = await apiRequest("get", `countries`, {
      queries: {
        ...params,
        selectValue: "cities",
      },
    });

    const data = res.data;
    if (data.status === 200) {
      setDetails(data.docs);
      setLoaded(true);
    }
    return res;
  } catch (err) {
    setLoaded(true);
    return err;
  }
};
export const loadCountryList = async (
  setDetails = () => {},
  setLoaded = () => {}
) => {
  setLoaded(false);

  try {
    const res = await apiRequest("get", `countries`, {
      queries: {
        limit: 300,
      },
    });

    const data = res.data;
    if (data.status === 200) {
      setDetails(data.docs);
      setLoaded(true);
    }
    return res;
  } catch (err) {
    setLoaded(true);
    return err;
  }
};

export const UpdateImage = async (params, setLoaded = () => {}) => {
  setLoaded(false);
  try {
    const res = await apiRequest(
      "put",
      "profile/upload-image",
      {
        body: {
          image: params,
        },
      },
      "multipart/form-data"
    );
    const data = res.data;
    setLoaded(true);
    return res;
  } catch (error) {
    setLoaded(true);
    return error.response;
  }
};

export const deleteImage = async (setLoaded = () => {}) => {
  setLoaded(false);
  try {
    const res = await apiRequest("delete", "profile/delete-image", {
      body: undefined,
    });
    setLoaded(true);
    return res;
  } catch (error) {
    setLoaded(true);
    return error;
  }
};
