import React from "react";

const Steps = ({ data, currentStep }) => {
  const length = data.length;

  return (
    <>
      <div className="relative w-60 mx-auto">
        {data?.length > 0 && (
          <>
            <div className="absolute top-2 left-0 h-2 bg-slate-100 w-full overflow-hidden">
              <div
                className="bg-carnation-500 h-full rounded-full"
                style={{
                  width: ((currentStep - 1) / (length - 1)) * 100 + "%",
                }}
              ></div>
            </div>
            <div className="relative z-[1] flex items-center justify-between gap-8">
              {data.map((item) => (
                <button
                  key={item._id}
                  type="button"
                  className="flex items-center justify-center flex-col"
                  onClick={item.function}
                  disabled={(Number(item?.number)<=currentStep) ? false:true}
                >
                  <div className="w-6 h-6 bg-carnation-500 text-white flex items-center justify-center rounded-full text-base font-semibold">
                    {item.number}
                  </div>
                  <div className="text-[9px] uppercase text-slate-500 font-semibold">
                    {item.name}
                  </div>
                </button>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Steps;
