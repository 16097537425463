import React, { useState, useEffect } from "react";
import Tabs from "../../components/elements/Tabs";

import FundraiserImage01 from "../../assets/images/patient-01.png";
import FundraiserImage02 from "../../assets/images/patient-02.png";
import FundraiserImage03 from "../../assets/images/patient-03.png";
import FundraiserImage04 from "../../assets/images/patient-04.png";
import FundraiserImage05 from "../../assets/images/patient-05.png";
import FundraiserImage06 from "../../assets/images/patient-06.png";
import FundraiserImage07 from "../../assets/images/patient-07.png";
import FundraiserImage08 from "../../assets/images/patient-08.png";
import Team01 from "../../assets/images/team/team-01.jpg";
import Team02 from "../../assets/images/team/team-02.jpg";
import Team03 from "../../assets/images/team/team-03.jpg";
import Team04 from "../../assets/images/team/team-04.jpg";
import Team05 from "../../assets/images/team/team-05.jpg";
import Team06 from "../../assets/images/team/team-06.jpg";
import Team07 from "../../assets/images/team/team-07.jpg";
import Team08 from "../../assets/images/team/team-08.jpg";
import FundraisedList from "../../components/partials/fundraised/FundraisedList";
import { loadFundsForList } from "../../services/fundsForService";
import { loadSupportedList } from "../../services/SupportedService";
import dayjs from "dayjs";
import { loadSupporterList } from "../../services/SupportersService";
import DonationsList from "../../components/partials/mydonation/DonationsList";
import NoDataFound from "../../components/elements/extra/NoDataFound";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import LongCardSkeletonLoader from "../../components/elements/loader/LongCardSkeletonLoader";

const Supported = () => {
  const [loaded, setloaded] = useState(true);
  const [topics, settopics] = useState([]);
  const [totalFiles, setTotalFiles] = useState([]);
  const [topicId, settopicId] = useState("");
  const [offset, setoffset] = useState(0);
  const [supported, setsupported] = useState([]);
  const [supporter, setsupporter] = useState([]);

  useEffect(() => {
    loadSupporterList({}, setsupporter, setloaded).then((res) => {});
  }, []);
  const supporters =
    Array.isArray(supporter) &&
    supporter.length > 0 &&
    supporter.map((elm) => ({
      _id: elm?.supporter?._id,
      name: elm?.supporter?.name,
      image: elm?.supporter?.image?.url,
    }));

  useEffect(() => {
    loadSupportedList({ limit: 1000 }, setsupported, setloaded).then((res) => {
      // console.log(res,"res")
    });
  }, []);
  function calculateRemainingDays(targetDateString) {
    // Convert the target date string to a Date object
    const targetDate = new Date(targetDateString);

    // Get the current date
    const currentDate = new Date();

    // Calculate the time difference in milliseconds
    const timeDifferenceMillis = targetDate - currentDate;

    // Calculate the number of days remaining
    const daysRemaining = Math.ceil(
      timeDifferenceMillis / (1000 * 60 * 60 * 24)
    );

    return daysRemaining;
  }

  // const supporterss = [
  //   { _id: 1, name: "Janet R. Hoffman", image: Team02, },
  //   { _id: 2, name: "Jeff C. Casey", image: Team01, },
  //   { _id: 3, name: "Jeremy A. Burrell", image: Team04, },
  //   { _id: 4, name: "Stephanie F. Sanfilippo", image: Team03, },
  //   { _id: 5, name: "Carl V. Powers", image: Team05, },
  //   { _id: 6, name: "Ray N. Hand", image: Team06, },
  //   { _id: 7, name: "Richard S. Bryden", image: Team07, },
  //   { _id: 8, name: "Lester B. Heath", image: Team08, },
  // ]
  const fundraisedListData =
    Array.isArray(supported) &&
    supported.length > 0 &&
    supported.map((elm, index) => ({
      _id: elm?._id,
      title: elm?.title,
      image: elm?.user?.image?.url,
      description: elm?.description,
      remainingDays:
        calculateRemainingDays(dayjs(elm?.endDate).format("YYYY-MM-DD")) || "",
      raised: elm?.raised,
      goal: elm?.amount,
      donation: elm?.myDonation,
      supporters: supporters,
      link: "#",
      commission: elm?.adminPercentage,
      editLink: `/fundraised-edit/${elm?.slug}`,
      dashboardLink: `/dashboard/${elm?.slug}`,
      withdrawLink: `/fundraised-withdraw/${elm?.slug}`,
      // promotionLink: `/fundraised-promotion/${elm?.slug}`,
      // viewLink: `/fundraised-supporters/${elm?.slug}`,
    }));

  const fundraisedListDataa = [
    {
      _id: 1,
      title:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been... ",
      image: FundraiserImage01,
      description:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      status: "active",
      remainingDays: 26,
      raised: 70000,
      goal: 100000,
      link: "/fundraiser",
      supporters: supporters,
      createdby: {
        name: "Janet R. Hoffman",
        image: Team02,
        date: "14 Jul 2023",
      },
      donation: 10000,
    },
    {
      _id: 2,
      title:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been...",
      image: FundraiserImage02,
      description:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      status: "on-hold",
      remainingDays: 33,
      raised: 50000,
      goal: 100000,
      link: "/fundraiser",
      supporters: supporters,
      createdby: {
        name: "Jeff C. Casey",
        image: Team01,
        date: "14 Jul 2023",
      },
      donation: 8000,
    },
    {
      _id: 3,
      title:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been...",
      image: FundraiserImage03,
      description:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      status: "inactive",
      remainingDays: 15,
      raised: 50000,
      goal: 80000,
      link: "/fundraiser",
      supporters: supporters,
      createdby: {
        name: "Jeremy A. Burrell",
        image: Team04,
        date: "14 Jul 2023",
      },
      donation: 12000,
    },
    {
      _id: 4,
      title:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been...",
      image: FundraiserImage04,
      description:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      status: "active",
      remainingDays: 28,
      raised: 75000,
      goal: 120000,
      link: "/fundraiser",
      supporters: supporters,
      createdby: {
        name: "Stephanie F. Sanfilippo",
        image: Team03,
        date: "14 Jul 2023",
      },
      donation: 6000,
    },
    {
      _id: 5,
      title:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been...",
      image: FundraiserImage05,
      description:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      status: "on-hold",
      remainingDays: 45,
      raised: 80000,
      goal: 150000,
      link: "/fundraiser",
      supporters: supporters,
      createdby: {
        name: "Carl V. Powers",
        image: Team05,
        date: "14 Jul 2023",
      },
      donation: 11000,
    },
    {
      _id: 6,
      title:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been...",
      image: FundraiserImage06,
      description:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      status: "inactive",
      remainingDays: 10,
      raised: 80000,
      goal: 100000,
      link: "/fundraiser",
      supporters: supporters,
      createdby: {
        name: "Ray N. Hand",
        image: Team06,
        date: "14 Jul 2023",
      },
      donation: 15000,
    },
    {
      _id: 7,
      title:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been...",
      image: FundraiserImage07,
      description:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      status: "active",
      remainingDays: 5,
      raised: 160000,
      goal: 180000,
      link: "/fundraiser",
      supporters: supporters,
      createdby: {
        name: "Richard S. Bryden",
        image: Team07,
        date: "14 Jul 2023",
      },
      donation: 9000,
    },
    {
      _id: 8,
      title:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been...",
      image: FundraiserImage08,
      description:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      status: "on-hold",
      remainingDays: 2,
      raised: 38000,
      goal: 40000,
      link: "/fundraiser",
      supporters: supporters,
      createdby: {
        name: "Lester B. Heath",
        image: Team08,
        date: "14 Jul 2023",
      },
      donation: 13000,
    },
  ];

  // const tabData = [
  //   { _id: 1, name: "Medical Treatments", component: <FundraisedList data={fundraisedListData}/> },
  //   { _id: 2, name: "NGO Treatments", component: <FundraisedList data={fundraisedListData}/> },
  //   { _id: 3, name: "Other Cause", component: <FundraisedList data={fundraisedListData}/> },
  // ]
  // const tabData =
  // topics &&
  // Array.isArray(topics) &&
  // topics.length > 0 &&
  // topics.map((elm, index) => ({
  //   _id: elm?._id,
  //   name: elm?.name,
  // }));

  useEffect(() => {
    setTotalFiles([]);
    // topics && topics.length>0 && settopicId(topics[0]?._id)
  }, []);

  useEffect(() => {
    loadFundsForList(
      () => {},
      settopics,
      () => {}
    ).then((res) => {});
  }, []);

  return (
    <>
      {/* <div className="relative w-full"> */}
      {/* <Tabs tabs={tabData} design={"style"} /> */}
      {/* <Tabs
          tabs={Array.isArray(tabData) && tabData.length > 0 && tabData}
          design={"style"}
          settopicId={settopicId}
          topicId={topicId}
          setTotalFiles={setTotalFiles}
          setoffset={setoffset}
        /> */}
      {/* </div> */}
      {!loaded && supported.length < 1 ? (
        <>
          <LongCardSkeletonLoader />
          <LongCardSkeletonLoader />
          <LongCardSkeletonLoader />
          <LongCardSkeletonLoader />
          <LongCardSkeletonLoader />
        </>
      ) : supported && Array.isArray(supported) && supported.length > 0 ? (
        <DonationsList data={fundraisedListData} />
      ) : (
        <NoDataFound />
      )}

      {/* {
      supported &&
      Array.isArray(supported) &&
      supported.length > 0 ? (
        <DonationsList data={fundraisedListData} />
      ) : (
        <NoDataFound />
      )} */}
    </>
  );
};

export default Supported;
