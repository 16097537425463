import { apiRequest } from "../utils/async/apiUtils";

export const loadCategoryList = async (
  setCategories = () => {},
  setLoaded = () => {}
) => {
  setLoaded(false);

  try {
    const res = await apiRequest("get", "category", {
      queries: {
        type: 4,
      },
    });
    const data = res.data;
    setCategories(data.docs);
    setLoaded(true);
    return res;
  } catch (err) {
    setLoaded(true);
    return err.response;
  }
};
