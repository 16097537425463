import { apiRequest } from "../utils/async/apiUtils";

export const loadFundsForList = async (
  params,
  setFundsFor = () => {},
  setLoaded = () => {}
) => {
  setLoaded(false);

  try {
    const res = await apiRequest("get", "topics", {
      queries: {
        ...params,
        selectValue:
          "name,icon,image,code,type,description,status,ordering,createdAt",
        type: 2,
      },
    });
    const data = res.data;
    setFundsFor(data.docs?.filter((funds) => funds?.status == 1));
    setLoaded(true);
    return res;
  } catch (err) {
    setLoaded(true);
    return err;
  }
};

export const addFundsFor = async (
  params,
  resData = () => {},
  setLoaded = () => {}
) => {
  setLoaded(false);
  try {
    const res = await apiRequest(
      `${params?.id ? "put" : "post"}`,
      `${params?.id ? `topics/${params.id}` : "topics"}`,
      {
        body: {
          id: params?.id || "",
          name: params.name,
          icon: params.icon,
          description: params.description,
          type: 2,
        },
      },
      "multipart/form-data"
    );

    const data = res.data;
    if (data.status === 201 || 200) {
      resData(data);
      setLoaded(true);
    }
    return res;
  } catch (err) {
    setLoaded(true);
  }
};

export const fundsForDetails = async (
  id,
  setDetails = () => {},
  setLoaded = () => {}
) => {
  setLoaded(false);
  try {
    const res = await apiRequest("get", `topics/${id}`, {});

    const data = res;
    if (data.status === 200) {
      setDetails(data.data);
      setLoaded(true);
    }
  } catch (err) {
    setLoaded(true);
  }
};
export const changeStatus = async (ids = [], params) => {
  try {
    const res = await apiRequest("patch", "topics/change-status", {
      body: {
        id: [ids],
        ...params,
      },
    });
    return res;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const changeMultipleStatus = async (ids = [], params) => {
  try {
    const res = await apiRequest("patch", "topics/change-status", {
      body: {
        id: ids,
        ...params,
      },
    });
    return res;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const orderTopic = async (data = [], offset, type) => {
  try {
    const res = await apiRequest("patch", "topics/save-ordering", {
      body: data,
      type: type,
    });
    return res;
  } catch (err) {
    throw new Error(err.message);
  }
};
