import React from "react";
import { setTitle } from "../helpers/MetaTag";
import MainBannerPage from "../components/sections/MainBannerPage";

import Banner from "../assets/images/banner-alt-ngo.jpg";
import { Link } from "react-router-dom";
import { LoadPageData } from "../services/pagesService";
import { useState } from "react";
import { useEffect } from "react";

const FundraisingForNgo = () => {
  setTitle("Crowd Funding | Browse Fundraiser");

  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [info, setInfo] = useState({});

  useEffect(() => {
    LoadPageData(
      () => {},
      () => {},
      setLoaded
    ).then((res) => {
      let dataa = res?.data?.docs;
      let privecy = dataa.filter((elm) => elm.title == "Fundraising for ngo");
      setInfo(privecy[0]);
    });
  }, []);

  const bannerData = {
    image: info && info?.image?.url,
    title: "Fundraising for ngo",
    description:
      "Join the Movement to Protect Young Lives and Make a Lasting Impact",
    actionLink: "/new-fundraising",
    actionLabel: "Start a Fundraiser",
  };

  return (
    <>
      <MainBannerPage data={bannerData} />
      {/* <ListFundraiser data={fundRaiserData}/> */}
      <section className="relative py-16 w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
        {/* <div className='sticky w-[360px] top-[0px] max-h-[calc(100%_-_180px)] p-5 bg-white shadow-lg -shadow-slate-200 rounded-xl'>
                    <div className='relative subpixel-antialiased max-h-[100%] overflow-auto !h-full bg-slate-50 p-4 flex flex-col gap-3'>
                        <div className='subpixel-antialiased scroll-smooth' id='01'>
                            <Link href="javascript:void(0)"  className='text-sm text-slate-500 leading-8 py-[5px] font-normal'>Lorem ipsum ipsum</Link>
                        </div>
                        <div className='subpixel-antialiased scroll-smooth' id='02'>
                            <Link href="javascript:void(0)"  className='text-sm text-slate-500 leading-8 py-[5px] font-normal'>Lorem ipsum ipsum</Link>
                        </div>
                        <div className='subpixel-antialiased scroll-smooth' id='03'>
                            <Link href="javascript:void(0)"  className='text-sm text-slate-500 leading-8 py-[5px] font-normal'>Lorem ipsum ipsum</Link>
                        </div>
                        <div className='subpixel-antialiased scroll-smooth' id='04'>
                            <Link href="javascript:void(0)"  className='text-sm text-slate-500 leading-8 py-[5px] font-normal'>Lorem ipsum ipsum</Link>
                        </div>
                        <div className='subpixel-antialiased scroll-smooth' id='05'>
                            <Link href="javascript:void(0)"  className='text-sm text-slate-500 leading-8 py-[5px] font-normal'>Lorem ipsum ipsum</Link>
                        </div>
                        <div className='subpixel-antialiased scroll-smooth' id='06'>
                            <Link href="javascript:void(0)"  className='text-sm text-slate-500 leading-8 py-[5px] font-normal'>Lorem ipsum ipsum</Link>
                        </div>
                        <div className='subpixel-antialiased scroll-smooth' id='07'>
                            <Link href="javascript:void(0)"  className='text-sm text-slate-500 leading-8 py-[5px] font-normal'>Lorem ipsum ipsum</Link>
                        </div>
                        <div className='subpixel-antialiased scroll-smooth' id='08'>
                            <Link href="javascript:void(0)"  className='text-sm text-slate-500 leading-8 py-[5px] font-normal'>Lorem ipsum ipsum</Link>
                        </div>
                        <div className='subpixel-antialiased scroll-smooth' id='09'>
                            <Link href="javascript:void(0)"  className='text-sm text-slate-500 leading-8 py-[5px] font-normal'>Lorem ipsum ipsum</Link>
                        </div>
                    </div>
                </div> */}
        <div className="relative flex flex-col gap-4">
          {/* <div className='mt-2'>
                        <h2 className='text-slate-800 text-xl font-medium'>Lorem Ipsum is simply dummy text of the printing and typesetting industry?</h2>
                        <div className='text-sm text-slate-500 font-normal mt-2 leading-6'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</div>
                    </div>
                    <div className='mt-4'>
                        <h2 className='text-slate-800 text-xl font-medium'>Lorem Ipsum is simply dummy text of the printing and typesetting industry?</h2>
                        <div className='text-sm text-slate-500 font-normal mt-2 leading-6'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</div>
                    </div>
                    <div className='mt-4'>
                        <h2 className='text-slate-800 text-xl font-medium'>Lorem Ipsum is simply dummy text of the printing and typesetting industry?</h2>
                        <div className='text-sm text-slate-500 font-normal mt-2 leading-6'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</div>
                    </div> */}
          <div
            className="mt-2"
            dangerouslySetInnerHTML={{ __html: info?.shortDescription }}
          />
          <div
            className="mt-2"
            dangerouslySetInnerHTML={{ __html: info?.description }}
          />
        </div>
      </section>
    </>
  );
};

export default FundraisingForNgo;
