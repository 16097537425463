import React, { useEffect, useState } from "react";
import PostSidebar from "../../../shared/PostSidebar";
import { useParams } from "react-router-dom/dist/umd/react-router-dom.development";
import {
  loadCityList,
  loadCountryList,
} from "../../../services/ProfileService";
import {
  addFundraiser,
  loadFundraiserDetails,
  removePhotoService,
} from "../../../services/FundrisersService";
import UploadBeneficiaryImage from "../../elements/pagesections/fundriserEdit/UploadBeneficiaryImage";
import MedicalForm from "../../elements/pagesections/fundriserEdit/medical/MedicalForm";
import Input from "../../form/Input";
import QuilEditor from "../../elements/quilEditor/QuilEditor";
import MultiTypesFileUploads from "../../form/MultiTypesFileUploads";
import { getCountryDetails } from "../../../services/CountryService";
import Others from "../../elements/pagesections/fundriserEdit/other/Others";
import Ngo from "../../elements/pagesections/fundriserEdit/ngo/Ngo";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Button from "../../form/Button";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../elements/loader/Loader";
import {
  hasArrayData,
  nameHasArrayData,
  removeHtmlTags,
} from "../../../helpers";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import CardSkeletonLoader from "../../elements/loader/CardSkeletonLoader";
import FormSkeletonLoader from "../../elements/loader/FormSkeletonLoader";

const FundraisedEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [documents, setDocuments] = useState();
  const [gallery, setGallery] = useState();
  const [formData, setFormData] = useState({});

  // const [details, setDetails] = useState({
  //   loaded: true,
  //   data: {},
  // });
  const [details, setDetails] = useState(null);
  const [loaded, setLoaded] = useState(true);
  const [coords, setCoords] = useState({
    lat: null,
    lon: null,
  });
  const [currentCountry, setCurrentCountry] = useState({});
  const [cityList, setcityList] = useState([]);
  const [countryList, setCountryList] = useState({
    loaded: true,
    data: [],
  });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setCoords({
        lat: position?.coords?.latitude,
        lon: position?.coords?.longitude,
      });
    });
  }, []);

  // get current country details ==========>

  useEffect(() => {
    if (coords?.lon && coords?.lat && Array.isArray(countryList)) {
      getCountryDetails({ lat: coords?.lat, lon: coords?.lon }).then((res) => {
        setCurrentCountry({
          name: countryList?.find(
            (item) => item?.country_name === res?.address?.country
          )?.name,
        });
      });
    }
  }, [coords, countryList]);

  // Load country list =============>

  useEffect(() => {
    setCountryList({ loaded: false, data: [] });
    loadCountryList().then((res) => {
      if (res.data?.status === 200) {
        setCountryList({ loaded: true, data: res.data.docs });
      } else {
        setCountryList({ loaded: true, data: [] });
      }
    });
  }, []);

  // load city list =======>

  useEffect(() => {
    if (
      formData?.[`beneficiary[address][country]`] &&
      formData?.[`beneficiary[address][country]`]?.toLowerCase() === "india"
    ) {
      loadCityList({ name: formData?.[`beneficiary[address][country]`] }).then(
        (res) => {
          if (res?.data?.status === 200) {
            setcityList(
              res.data.docs[0]?.cities.map((city) => ({ name: city }))
            );
          }
        }
      );
    }
  }, [formData?.[`beneficiary[address][country]`]]);

  // load details ===========>

  useEffect(() => {
    // if (id) {
    // setDetails({ loaded: false, data: {} });
    loadFundraiserDetails(id, setDetails, setLoaded).then((res) => {
      // if (res.status === 200) {
      //   setDetails({ loaded: true, data: res.data });
      // } else {
      //   setDetails({ loaded: true, data: {} });
      // }
    });
    // }
  }, [id]);

  // useEffect(() => {
  //   const image = {};
  //   details?.data?.beneficiary?.image &&
  //     details?.data?.beneficiary?.image?.forEach(
  //       (img, i) => (image[`image[${i}]`] = img?.url)
  //     );
  //   const data_with_condition = {};
  //   if (
  //     details?.data?.beneficiary?.age != "6 -10 years" ||
  //     details?.data?.beneficiary?.age != "11 - 17 years" ||
  //     details?.data?.beneficiary?.age != "Below 5 years"
  //   ) {
  //     data_with_condition[`parentName`] =
  //       details?.data?.beneficiary?.parentName;
  //   }

  //   if (details) {
  //     setFormData({
  //       ...image,
  //       amount: details?.data?.amount,
  //       purpose: details?.data?.purpose,
  //       "beneficiary[phoneCode]": details?.data?.beneficiary?.phoneCode,
  //       "beneficiary[name]": details?.data?.beneficiary?.name,
  //       "beneficiary[gender]": details?.data?.beneficiary?.gender,
  //       "beneficiary[age]": details?.data?.beneficiary?.age,
  //       "beneficiary[phone]": details?.data?.beneficiary?.phone,
  //       "beneficiary[toHelp]": details?.data?.beneficiary?.toHelp,
  //       "beneficiary[address][address1]":
  //         details?.data?.beneficiary?.address?.address1,
  //       "beneficiary[address][city]": details?.data?.beneficiary?.address?.city,
  //       "beneficiary[address][country]":
  //         details?.data?.beneficiary?.address?.country,
  //       relationWithFundraiser: details?.data?.relationWithFundraiser?._id,
  //       documents: details?.data?.documents?.map((item) => ({
  //         _id: item?._id,
  //         type: item?.extension,
  //         file: item?.url,
  //         name: item?.filename,
  //       })),
  //       gallery: details?.data?.gallery?.map((item) => ({
  //         _id: item?._id,
  //         type: item?.extension,
  //         file: item?.url,
  //         name: item?.filename,
  //       })),
  //       "cause[name]": details?.data?.cause?.name,
  //       "cause[_id]": details?.data?.cause?._id,
  //       "detailsOfPan[nameInPan]": details?.data?.detailsOfPan?.nameInPan,
  //       "detailsOfPan[panNumber]": details?.data?.detailsOfPan?.panNumber,
  //       endDate: details?.data?.endDate,
  //       certificate80G: details?.data?.detailsOf80G?.certificate80G?.map(
  //         (file) => ({
  //           file: file?.url,
  //           name: file?.filename,
  //           type: file?.extension,
  //           _id: file?._id,
  //         })
  //       ),
  //       fcraCertificate:
  //         details?.data?.detailsOfForeignFund?.fcraCertificate?.map((file) => ({
  //           file: file?.url,
  //           name: file?.filename,
  //           type: file?.extension,
  //           _id: file?._id,
  //         })),
  //       title: details?.data?.title,
  //       description: details?.data?.description,
  //       "hospitalDetails[ailment]":
  //         details?.data?.hospitalDetails?.ailment?._id,
  //       "hospitalDetails[address][city]":
  //         details?.data?.hospitalDetails?.address?.city,
  //       "hospitalDetails[name]": details?.data?.hospitalDetails?.hospital?._id,
  //       "hospitalDetails[hospitalisation]":
  //         details?.data?.hospitalDetails?.hospitalisation,
  //       ...data_with_condition,
  //     });
  //   }
  //   // note : ngo steps 4 ngo contact data was added in NgoContact.jsx file for edit data ==============>
  // }, [details?.data?._id]);

  useEffect(() => {
    const image = {};
    details?.beneficiary?.image &&
      details?.beneficiary?.image?.forEach(
        (img, i) => (image[`image[${i}]`] = img?.url)
      );
    const data_with_condition = {};
    if (
      details?.beneficiary?.age != "6 -10 years" ||
      details?.beneficiary?.age != "11 - 17 years" ||
      details?.beneficiary?.age != "Below 5 years"
    ) {
      data_with_condition[`beneficiary[parentName]`] =
        details?.beneficiary?.parentName;
    }

    if (details) {
      setFormData({
        ...image,
        amount: details?.amount,
        purpose: details?.purpose,
        "beneficiary[phoneCode]": details?.beneficiary?.phoneCode,
        "beneficiary[name]": details?.beneficiary?.name,
        "beneficiary[gender]": details?.beneficiary?.gender,
        "beneficiary[age]": details?.beneficiary?.age,
        "beneficiary[phone]": details?.beneficiary?.phone,
        // "beneficiary[parentName]": details?.beneficiary?.parentName,
        "beneficiary[toHelp]": details?.beneficiary?.toHelp,
        "beneficiary[address][address1]":
          details?.beneficiary?.address?.address1,
        "beneficiary[address][city]": details?.beneficiary?.address?.city,
        "beneficiary[address][country]": details?.beneficiary?.address?.country,
        relationWithFundraiser: details?.relationWithFundraiser?._id,
        documents: details?.documents?.map((item) => ({
          _id: item?._id,
          type: item?.extension,
          file: item?.url,
          name: item?.filename,
        })),
        gallery: details?.gallery?.map((item) => ({
          _id: item?._id,
          type: item?.extension,
          file: item?.url,
          name: item?.filename,
        })),
        "cause[name]": details?.cause?.name,
        "cause[_id]": details?.cause?._id,
        "detailsOfPan[nameInPan]": details?.detailsOfPan?.nameInPan,
        "detailsOfPan[panNumber]": details?.detailsOfPan?.panNumber,
        endDate: details?.endDate,
        certificate80G: details?.detailsOf80G?.certificate80G?.map((file) => ({
          file: file?.url,
          name: file?.filename,
          type: file?.extension,
          _id: file?._id,
        })),
        fcraCertificate: details?.detailsOfForeignFund?.fcraCertificate?.map(
          (file) => ({
            file: file?.url,
            name: file?.filename,
            type: file?.extension,
            _id: file?._id,
          })
        ),
        title: details?.title,
        description: details?.description,
        "hospitalDetails[ailment]": details?.hospitalDetails?.ailment?._id,
        "hospitalDetails[address][city]":
          details?.hospitalDetails?.address?.city,
        "hospitalDetails[name]": details?.hospitalDetails?.hospital?._id,
        "hospitalDetails[hospitalisation]":
          details?.hospitalDetails?.hospitalisation,
        ...data_with_condition,
      });
    }
    // note : ngo steps 4 ngo contact data was added in NgoContact.jsx file for edit data ==============>
  }, [details]);

  // console.log(details, "details");
  useEffect(() => {
    if (documents) {
      if (formData?.documents) {
        setFormData((pre) => ({
          ...pre,
          documents: [...pre?.documents, { ...documents }],
        }));
      } else {
        setFormData((pre) => ({
          ...pre,
          documents: [{ ...documents }],
        }));
      }
    }
  }, [documents]);

  useEffect(() => {
    if (gallery) {
      if (formData?.gallery) {
        setFormData((pre) => ({
          ...pre,
          gallery: [...pre?.gallery, { ...gallery }],
        }));
      } else {
        setFormData((pre) => ({
          ...pre,
          gallery: [{ ...gallery }],
        }));
      }
    }
  }, [gallery]);

  const onHandleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (e.target.value) {
      setFormData((pre) => ({ ...pre, [name]: value }));
    } else {
      delete formData[name];
      setFormData({ ...formData });
    }
  };

  const addFormSubmit = () => {
    const data = { _id: details?._id };
    Object.keys(formData).forEach((item) => {
      if (item === "purpose") {
        formData[item] && (data[`topicId`] = formData[item]?._id);
      } else {
        formData[item] && (data[`${item}`] = formData[item]);
      }
    });
    addFundraiser({ ...data }).then((res) => {
      if (res?.data?.status === 200) {
        navigate("/user/fundraised");
        toast.success(res.data?.message);
      } else {
        toast.info(res.data?.message);
      }
    });
  };

  const handleSubmit = () => {
    const medicalForm = [];
    const medicalFormMessage = [];
    if (
      formData[`beneficiary[age]`] === "Below 5 years" ||
      formData[`beneficiary[age]`] === "6 -10 years" ||
      formData[`beneficiary[age]`] === "11 - 17 years"
    ) {
      medicalForm.push("beneficiary[parentName]");
      medicalFormMessage.push("Gardian name");
    } else {
      medicalForm.filter((item) => item !== "beneficiary[parentName]");
      medicalFormMessage.filter((item) => item !== "Gardian name");
    }

    if (formData?.purpose?.name === "NGO/Charity") {
      if (
        hasArrayData(Object.keys(formData), [
          "amount",
          "endDate",
          "beneficiary[name]",
          "beneficiary[toHelp]",
          "beneficiary[address][address1]",
          "cause[_id]",
          "cause[name]",
          "hospitalDetails[ailment]",
          "hospitalDetails[address][city]",
        ])
      ) {
        if (formData?.isContactPerson) {
          if (!formData[`detailsOfPan[nameInPan]`]) {
            toast.info("Enter name acording to pan");
          } else {
            if (!formData[`detailsOfPan[panNumber]`]) {
              toast.info("Enter pan number");
            } else {
              if (formData?.is80G) {
                if (formData?.certificate80G) {
                  if (
                    Array.isArray(formData?.certificate80G) &&
                    formData?.certificate80G?.length > 0
                  ) {
                    if (!formData[`detailsOf80G[email]`]) {
                      toast.info("please enter email");
                    } else {
                      if (!formData[`detailsOf80G[phoneCode]`]) {
                        toast.info("please select country and phone code");
                      } else {
                        if (!formData[`detailsOf80G[phone]`]) {
                          toast.info("Please enter phone number");
                        } else {
                          if (!formData[`detailsOf80G[organisationType]`]) {
                            toast.info("Select organization type");
                          } else {
                            if (
                              formData[`detailsOf80G[organisationType]`] ===
                              "Trust"
                            ) {
                              if (
                                formData[`detailsOf80G[registrationSection]`]
                              ) {
                                if (
                                  !formData[`detailsOf80G[registrationNumber]`]
                                ) {
                                  toast.info("Please enter reg no");
                                } else {
                                  if (
                                    !formData[
                                      `detailsOf80G[registrationAddress]`
                                    ]
                                  ) {
                                    toast.info("Please enter address");
                                  } else {
                                    // ======================================================================
                                    // if isForeignFund select or the value is true ==============>
                                    // ======================================================================
                                    if (formData?.isForeignFund) {
                                      if (
                                        !formData[
                                          `detailsOfForeignFund[is501c3]`
                                        ]
                                      ) {
                                        if (
                                          !formData[
                                            `detailsOfForeignFund[isFcra]`
                                          ]
                                        ) {
                                          toast.info(
                                            "Please select Foreign Fund Type"
                                          );
                                        } else {
                                          if (!formData?.fcraCertificate) {
                                            toast.info(
                                              "Please select fcra certificate"
                                            );
                                          } else {
                                            if (
                                              Array.isArray(
                                                formData?.fcraCertificate
                                              ) &&
                                              formData?.fcraCertificate
                                                ?.length > 0
                                            ) {
                                              if (
                                                !formData[
                                                  `detailsOfForeignFund[fcraRegistrationNumber]`
                                                ]
                                              ) {
                                                toast.info(
                                                  "Please enter FCRA Registration number"
                                                );
                                              } else {
                                                addFormSubmit();
                                              }
                                            } else {
                                              toast.info(
                                                "Please select fcra certificate"
                                              );
                                            }
                                          }
                                        }
                                      } else {
                                        // console.log("501c3 selected");
                                        addFormSubmit();
                                      }
                                    }
                                  }
                                }
                              } else {
                                toast.info(
                                  "Please select registration section"
                                );
                              }
                            } else {
                              if (
                                !formData[`detailsOf80G[registrationNumber]`]
                              ) {
                                toast.info("Please enter reg no");
                              } else {
                                if (
                                  !formData[`detailsOf80G[registrationAddress]`]
                                ) {
                                  toast.info("Please enter address");
                                } else {
                                  addFormSubmit();
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  } else {
                    toast.info("Add 80g certificate");
                  }
                } else {
                  toast.info("Add 80g certificate");
                }
              } else {
                // ======================================================================
                // if isForeignFund select or the value is true ==============>
                // ======================================================================
                if (formData?.isForeignFund) {
                  if (!formData[`detailsOfForeignFund[is501c3]`]) {
                    if (!formData[`detailsOfForeignFund[isFcra]`]) {
                      toast.info("Please select Foreign Fund Type");
                    } else {
                      if (!formData?.fcraCertificate) {
                        toast.info("Please select fcra certificate");
                      } else {
                        if (
                          Array.isArray(formData?.fcraCertificate) &&
                          formData?.fcraCertificate?.length > 0
                        ) {
                          if (
                            !formData[
                              `detailsOfForeignFund[fcraRegistrationNumber]`
                            ]
                          ) {
                            toast.info("Please enter FCRA Registration number");
                          } else {
                            addFormSubmit();
                          }
                        } else {
                          toast.info("Please select fcra certificate");
                        }
                      }
                    }
                  } else {
                    if (!formData[`detailsOfForeignFund[name501c3]`]) {
                      toast.info("Please enter name for 501c3");
                    } else {
                      if (!formData[`detailsOfForeignFund[employeeId]`]) {
                        toast.info("Please enter name for employee id");
                      } else {
                        if (
                          !formData[`detailsOfForeignFund[address][address1]`]
                        ) {
                          toast.info("Please enter address for 501c3");
                        } else {
                          if (
                            !formData[
                              `detailsOfForeignFund[authorisedRepresentative]`
                            ]
                          ) {
                            toast.info(
                              "please enter authorised representative of the organisation"
                            );
                          } else {
                            addFormSubmit();
                          }
                        }
                      }
                    }
                  }
                  // ======================================================================
                  // end section ==============>
                  // ======================================================================
                } else {
                  addFormSubmit();
                }
              }
            }
          }
        } else {
          if (!formData[`contactPerson[name]`]) {
            toast.info("Enter contact person name");
          } else {
            if (!formData[`contactPerson[email]`]) {
              toast.info("Enter contact person email");
            } else {
              if (!formData[`contactPerson[phoneCode]`]) {
                toast.info("Enter contact person phone code");
              } else {
                if (!formData[`contactPerson[phone]`]) {
                  toast.info("Enter contact person phone");
                } else {
                  addFormSubmit();
                }
              }
            }
          }
        }
      } else {
        toast.info(
          `Please add ${nameHasArrayData(
            Object.keys(formData),
            [
              "amount",
              "endDate",
              "beneficiary[name]",
              "beneficiary[toHelp]",
              "beneficiary[address][address1]",
              "cause[_id]",
              "cause[name]",
              "hospitalDetails[ailment]",
              "hospitalDetails[address][city]",
            ],
            [
              "amount",
              "End date",
              "Name of the organisation",
              "beneficiary will help",
              "Based out of",
              "Please Choose a Cause",
              "enter cause name",
              "ailment",
              "Hospital address",
            ]
          )}`
        );
      }
    }
    if (formData?.purpose?.name === "Other Cause") {
      if (
        hasArrayData(Object.keys(formData), [
          "amount",
          "endDate",
          "relationWithFundraiser",
          "beneficiary[name]",
          "beneficiary[address][city]",
          "beneficiary[phoneCode]",
          "beneficiary[phone]",
          "title",
          "description",
        ])
      ) {
        if (formData?.documents) {
          if (
            Array.isArray(formData?.documents) &&
            formData?.documents?.length > 0
          ) {
            if (
              Array.isArray(formData?.gallery) &&
              formData?.gallery?.length > 0
            ) {
              addFormSubmit();
            } else {
              toast.info("Please select Supporting Photography");
            }
          } else {
            toast.info("Please select Supporting Documents");
          }
        }
      } else {
        toast.info(
          `Please select ${nameHasArrayData(
            Object.keys(formData),
            [
              "amount",
              "endDate",
              "relationWithFundraiser",
              "beneficiary[name]",
              "beneficiary[address][city]",
              "beneficiary[phoneCode]",
              "beneficiary[phone]",
              "title",
              "description",
            ],
            [
              "amount",
              "End date",
              "Whom are you raising funds for?",
              "Beneficiary Name",
              "City",
              "phone code",
              "Phone",
              "title",
              "description",
            ]
          )}`
        );
      }
    }
    if (formData?.purpose?.name === "Medical Treatment") {
      if (
        // check field has or not ============>
        hasArrayData(Object.keys(formData), [
          "amount",
          "endDate",
          "beneficiary[name]",
          "relationWithFundraiser",
          "beneficiary[age]",
          "beneficiary[phone]",
          ...medicalForm,
          "beneficiary[gender]",
          "beneficiary[address][city]",
          "hospitalDetails[ailment]",
          "hospitalDetails[hospitalisation]",
          "hospitalDetails[name]",
          "title",
          "description",
          "image[0]",
        ])
      ) {
        if (formData?.documents) {
          if (
            Array.isArray(formData?.documents) &&
            formData?.documents?.length > 0
          ) {
            if (
              Array.isArray(formData?.gallery) &&
              formData?.gallery?.length > 0
            ) {
              addFormSubmit();
            } else {
              toast.info("Please select Supporting Photography");
            }
          } else {
            toast.info("Please select Supporting Documents");
          }
        }
      } else {
        toast.info(
          `Please select or enter ${nameHasArrayData(
            Object.keys(formData),
            [
              "amount",
              "endDate",
              "beneficiary[name]",
              "relationWithFundraiser",
              "beneficiary[age]",
              "beneficiary[phone]",
              ...medicalForm,
              "beneficiary[gender]",
              "beneficiary[address][city]",
              "hospitalDetails[ailment]",
              "hospitalDetails[hospitalisation]",
              "hospitalDetails[name]",
              "title",
              "description",
              "image[0]",
            ],
            [
              "amount",
              "End date",
              "Patient's full name",
              "relation with fundraiser",
              "Patient's age",
              "Contact No.",
              ...medicalFormMessage,
              "patient's Gender",
              "patient's city",
              "Ailment / Medical Condition",
              "Hospitalisation status",
              "hospital name",
              "title",
              "description",
              "image",
            ]
          )}`
        );
      }
    }
  };

  return (
    <>
      <section className="relative  overflow-hidden w-full py-5 bg-gray-100">
        <div className="relative z-[1] w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
          <div className="w-full flex md:flex-nowrap flex-wrap gap-4 md:gap-0">
            <div className="w-full md:w-80 min-w-[320px] ">
              <PostSidebar id={id} />
            </div>
            <div className="relative bg-white rounded-r-lg w-full max-w-[calc(100%-320px)])] p-4">
              {/* {!loaded && <Skeleton count={50} />} */}

              <div className="flex h-full">
                {loaded ? (
                  <div className="w-2/3 border-r border-slate-200 pr-3">
                    {/* =============== F O R   A L L  T O P I C S ============== */}

                    <div className="flex gap-3 mb-2">
                      <div className="relative w-full">
                        <Input
                          isInputGroup={true}
                          inputGroupIcon={"fa-regular fa-indian-rupee-sign"}
                          inputGroupPosition={"left"}
                          label={"How much do you want to raise?"}
                          inputValue={formData?.amount}
                          errorType={
                            formData?.amount &&
                            formData?.amount < 2000 &&
                            "danger"
                          }
                          errorText={
                            formData?.amount &&
                            formData?.amount < 2000 &&
                            "The minimum goal amount is ₹2000"
                          }
                          inputName={"amount"}
                          onChange={(e) => onHandleChange(e)}
                          inputType="number"
                          minValue="0"
                          inputPlaceholder="Enter the amount.."
                        />
                      </div>
                      <div className="relative w-full">
                        <div class="text-sm font-medium text-slate-500 mb-1">
                          End date
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            format={"DD-MM-YYYY"}
                            onChange={(e) =>
                              onHandleChange({
                                target: {
                                  name: "endDate",
                                  value: dayjs(e?.$d).format("YYYY-MM-DD"),
                                },
                              })
                            }
                            value={dayjs(formData?.endDate)}
                            className="pt-0 pb-0 w-full h-10 rounded-md bg-slate-50 border border-slate-200 px-3 text-sm text-slate-600 !ring-0 !outline-0 focus:border-carnation-200 focus:bg-carnation-50 transition-all duration-200 "
                          />
                        </LocalizationProvider>
                      </div>
                    </div>

                    {/* ===============E N D================= */}

                    {details?.purpose?.name == "NGO/Charity" && (
                      <Ngo
                        data={formData}
                        setData={setFormData}
                        countryList={countryList?.data?.map((item) => ({
                          name: "+" + item?.phone_code?.replace("+", ""),
                        }))}
                        currentCountry={currentCountry}
                        editData={details}
                      />
                    )}
                    {details?.purpose?.name == "Medical Treatment" && (
                      <MedicalForm
                        data={formData}
                        setData={setFormData}
                        countryList={countryList?.data?.map((item) => ({
                          _id: item?._id,
                          name: "+" + item?.phone_code?.replace("+", ""),
                        }))}
                        currentCountry={currentCountry}
                      />
                    )}
                    {details?.purpose?.name == "Other Cause" && (
                      <Others
                        data={formData}
                        setData={setFormData}
                        countryList={countryList?.data?.map((item) => ({
                          name: "+" + item?.phone_code?.replace("+", ""),
                          _id: item?._id,
                        }))}
                        cityList={cityList}
                        currentCountry={currentCountry}
                      />
                    )}

                    {/* =============== F O R   A L L  T O P I C S ============== */}
                    <div>
                      <div className="relative">
                        <Input
                          inputPlaceholder={"Enter title."}
                          label={"Title"}
                          inputName={"title"}
                          inputValue={formData?.title}
                          onChange={(e) => onHandleChange(e)}
                        />
                      </div>
                      <div className="relative my-3">
                        <QuilEditor
                          label="Description"
                          placeholder="Write your story simple & specific"
                          name="description"
                          value={formData?.description}
                          onChange={(e) => {
                            if (removeHtmlTags(e.target.value)) {
                              onHandleChange(e);
                            } else {
                              delete formData?.description;
                              setFormData({ ...formData });
                            }
                          }}
                        />
                      </div>
                    </div>
                    {/* ===============E N D================= */}

                    <div className="relative flex justify-center mt-5">
                      <Button
                        buttonClasses={"w-full"}
                        buttonType={"button"}
                        buttonLabel={"Submit"}
                        buttonLabelClasses={""}
                        buttonEffect={"filled"}
                        buttonFunction={handleSubmit}
                      />
                    </div>
                  </div>
                ) : (
                  <FormSkeletonLoader />
                )}

                {/* =============== F O R   R I G H T   S I D E   C O N T E N T ============== */}

                <div className="w-1/3">
                  {/* {details?.data?.purpose?.name != "NGO/Charity" && ( */}
                  {loaded ? (
                    <>
                      <div className="px-3">
                        <div class="text-sm font-medium text-center text-slate-500 mb-1">
                          Beneficiary Image
                        </div>
                        <UploadBeneficiaryImage
                          image={formData?.[`image[0]`]}
                          setImage={(file) =>
                            setFormData((pre) => ({ ...pre, "image[0]": file }))
                          }
                        />
                      </div>

                      <div className="relative m-3">
                        <div class="text-sm font-medium text-slate-500 mb-1">
                          Supporting Documents
                        </div>
                        <div className="px-2 py-4 border rounded-md border-carnation-500">
                          <MultiTypesFileUploads
                            files={formData?.documents}
                            fileType={formData?.documents?.map(
                              (item) => item?.type
                            )}
                            onChange={(e) => {
                              setDocuments({
                                _id: crypto.randomUUID(),
                                type: e.target.files[0]?.type.split("/")[1],
                                file: e.target.files[0],
                                name: e.target.files[0]?.name,
                              });
                            }}
                            removeFile={(e) => {
                              const check =
                                details &&
                                details?.documents &&
                                Array.isArray(details?.documents) &&
                                details?.documents?.length > 0 &&
                                details?.documents.some(
                                  (obj) => obj._id === e._id
                                );

                              if (check) {
                                removePhotoService({
                                  fundraiserId: details && details?._id,
                                  path: "documents",
                                  imageId: e?._id,
                                }).then((res) => {
                                  if (res.data.status === 200) {
                                    toast.success(res.data?.message);
                                  } else {
                                    toast.error(res.data?.message);
                                  }
                                });
                              }

                              setFormData((preState) => ({
                                ...preState,
                                documents: preState?.documents?.filter(
                                  (item) => item?._id !== e._id
                                ),
                              }));
                            }}
                            accept={["jpg", "jpeg", "png", "pdf"]}
                          />
                        </div>
                      </div>
                      <div className="relative m-3">
                        <div class="text-sm font-medium text-slate-500 mb-1">
                          Supporting Photography
                        </div>
                        <div className="px-2 py-4 border rounded-md border-carnation-500">
                          <MultiTypesFileUploads
                            files={formData?.gallery}
                            fileType={formData?.gallery?.map(
                              (item) => item?.type
                            )}
                            onChange={(e) => {
                              setGallery({
                                _id: crypto.randomUUID(),
                                type: e.target.files[0]?.type.split("/")[1],
                                file: e.target.files[0],
                                name: e.target.files[0]?.name,
                              });
                            }}
                            removeFile={(e) => {
                              const check = details && details;
                              details?.gallery &&
                                Array.isArray(details?.gallery) &&
                                details?.gallery?.length > 0 &&
                                details?.gallery.some(
                                  (obj) => obj._id === e._id
                                );

                              if (check) {
                                removePhotoService({
                                  fundraiserId: details && details?._id,
                                  path: "gallery",
                                  imageId: e?._id,
                                }).then((res) => {
                                  if (res.data.status === 200) {
                                    toast.success(res.data?.message);
                                  } else {
                                    toast.error(res.data?.message);
                                  }
                                });
                              }

                              setFormData((preState) => ({
                                ...preState,
                                gallery: preState?.gallery?.filter(
                                  (item) => item?._id !== e._id
                                ),
                              }));
                            }}
                            accept={["jpg", "jpeg", "png"]}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <CardSkeletonLoader />
                  )}

                  {/* )} */}
                  {/* {details?.purpose?.name != "NGO/Charity" && (
                      <div className="relative m-3">
                        <div class="text-sm font-medium text-slate-500 mb-1">
                          Supporting Documents
                        </div>
                        <div className="px-2 py-4 border rounded-md border-carnation-500">
                          <MultiTypesFileUploads
                            files={formData?.documents}
                            fileType={formData?.documents?.map(
                              (item) => item?.type
                            )}
                            onChange={(e) => {
                              setDocuments({
                                _id: crypto.randomUUID(),
                                type: e.target.files[0]?.type.split("/")[1],
                                file: e.target.files[0],
                                name: e.target.files[0]?.name,
                              });
                            }}
                            removeFile={(e) => {
                              const check =
                                details &&
                                details?.documents &&
                                Array.isArray(details?.documents) &&
                                details?.documents?.length > 0 &&
                                details?.documents.some(
                                  (obj) => obj._id === e._id
                                );

                              if (check) {
                                removePhotoService({
                                  fundraiserId: details && details?._id,
                                  path: "documents",
                                  imageId: e?._id,
                                }).then((res) => {
                                  if (res.data.status === 200) {
                                    toast.success(res.data?.message);
                                  } else {
                                    toast.error(res.data?.message);
                                  }
                                });
                              }

                              setFormData((preState) => ({
                                ...preState,
                                documents: preState?.documents?.filter(
                                  (item) => item?._id !== e._id
                                ),
                              }));
                            }}
                            accept={["jpg", "jpeg", "png", "pdf"]}
                          />
                        </div>
                      </div>
                    )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FundraisedEdit;
