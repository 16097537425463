import React, { useState } from 'react';
import Image from "../elements/Image";
import { classNames } from "../../helpers/classNames";
import SingleSupporterDonation from "./SingleSupporterDonation";

const SingleSupporter = ({data}) => {
  console.log(data,"data")
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className={classNames(
        "relative shadow rounded-lg overflow-hidden hover:shadow-lg transition-all duration-200",
        isOpen?"bg-slate-50":"bg-white"
      )}>
        <div className="relative w-full flex items-center py-2 px-4">
          <div className="flex items-center space-x-4 mr-auto">
            <div className="w-10 h-10 rounded-full overflow-hidden">
              <Image src={data.image} alt={''} width={'100%'} height={'100%'} effect={'blur'} classes={'object-cover'} />
            </div>
            <div className="font-Lexend text-base font-semibold leading-tight text-slate-900">{data.name}</div>
          </div>

          <div className="relative flex items-center space-x-2 mx-4">
            <div className="text-xs text-slate-500">Total Donation:</div>
            <div className="flex items-center text-base">
              <i className="text-sm fa-solid fa-fw fa-indian-rupee-sign"></i>
              <span className="text-slate-900 font-semibold">{data.donation.toLocaleString()}</span>
            </div>
          </div>
          <button 
            type="button" 
            onClick={() => setIsOpen(!isOpen)}
            className="w-8 h-8 hover:bg-slate-800/5 transition-all duration-200 flex items-center justify-center p-0 rounded-md text-slate-400">
            <i className={classNames("fa-light fa-fw fa-chevron-right transition-all duration-200", isOpen?"rotate-90":"")}></i>
          </button>
        </div>
        
        {data.donationList?.length > 0 &&
        <div className={classNames(
          "relative w-full border-t border-slate-200 transition-all duration-200", 
          isOpen?"max-h-[2000px] opacity-100 visible py-2":"max-h-0 opacity-0 invisible"
        )}>
          <div className="overflow-auto scroll-smooth scrollbar space-y-4 px-4 py-2 pb-4">
          {data.donationList?.map((item) => (
            <SingleSupporterDonation key={item._id} data={item}/>
          ))}
          </div>
        </div>
        }
      </div>
    </>
  );
};

export default SingleSupporter;