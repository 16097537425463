import React from 'react';
import Image from "../elements/Image";
import { classNames } from "../../helpers/classNames";

const SingleTeam = ({ data }) => {
  return (
    <>
      <div className="w-full group flex flex-col rounded-4xl rounded-tl-none">
        <div className="relative w-full h-52 md:h-60 lg:h-72 2xl:h-80 3xl:h-96 overflow-hidden rounded-4xl rounded-tl-none group-hover:shadow-lg transition-all duration-200">
          <div className="w-full h-full overflow-hidden rounded-4xl rounded-tl-none">
            <Image src={data?.image?.url} alt={''} width={'100%'} height={'100%'} effect={'blur'} classes={'object-cover'} />
          </div>
          {data.socialMedia?.length > 0 &&
            <div className="absolute bottom-0 left-0 w-full flex items-center justify-center gap-x-2 bg-carnation-500/50 py-2 sm:py-4 translate-y-full transition-all duration-200 group-hover:translate-y-0">
              {data.socialMedia?.map((item, index) => (
                <div key={index}>
                  <a href={item.url} className="flex items-center justify-center w-8 h-8 bg-white rounded-full shadow text-carnation-500 transition-all duration-200 hover:shadow-lg hover:text-white hover:bg-carnation-500" target="_blank" rel="noreferrer" title={item.name}>
                    <i className={classNames("fa-brands fa-fw", item.icon)}></i>
                  </a>
                </div>
              ))}
            </div>
          }
        </div>
        <div className="py-6 px-4 text-center">
          <h4 className="text-sm sm:text-lg text-black font-bold leading-tight">{data.name}</h4>
          <div className="text-xs xl:text-sm text-slate-600 mt-1">{data.designation}</div>
        </div>
      </div>
    </>
  );
};

export default SingleTeam;