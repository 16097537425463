import React, { useEffect, useState } from "react";
import Input from "../../../form/Input";
import Select from "../../../form/Select";
import SearchWithDropdown from "../../../form/SearchWithDropdown";
import "react-toastify/dist/ReactToastify.css";

import { numReg } from "../../../../helpers";
import {
  getCountryDetails,
  loadCountries,
} from "../../../../services/CountryService";
import {
  loadHospitalList,
  loadMedicalConditionList,
} from "../../../../services/FundrisersService";

const NgoMedical = ({ stepsData, setStepsData = () => {} }) => {
  const [loaded, setloaded] = useState(true);
  const [hospitalListData, sethospitalListData] = useState([]);
  const [medicalConditionData, setmedicalConditionData] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [currentCountry, setCurrentCountry] = useState({});
  const [coords, setCoords] = useState({
    lat: undefined,
    lon: undefined,
  });

  // const ageData = [
  //   { name: "Below 5 years" },
  //   { name: "6 -10 years" },
  //   { name: "11 - 17 years" },
  //   { name: "18 - 30 years" },
  //   { name: "30 - 40 years" },
  //   { name: "Above 40 years" },
  // ];

  // const hospitalStatusData = [
  //   { name: "Currently hospitalised" },
  //   { name: "Does not require hospitalisation" },
  //   { name: "Recently discharged from the hospital" },
  //   { name: "Will be hospitalised soon" },
  // ];

  // get country list ===========>

  useEffect(() => {
    loadCountries().then((res) => {
      if (res?.status === 200) {
        setCountryList(
          res?.docs?.map((country) => ({
            name: "+" + country?.phone_code?.replace("+", ""),
            country_name: country?.name,
            _id: country?._id,
          }))
        );
      }
    });
  }, []);

  // get current country details ==========>

  useEffect(() => {
    if (coords?.lon && coords?.lat && countryList) {
      getCountryDetails({ lat: coords?.lat, lon: coords?.lon }).then((res) => {
        setCurrentCountry({
          name: countryList?.find(
            (item) => item?.country_name === res?.address?.country
          )?.name,
        });
      });
    }
  }, [coords, countryList]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setCoords({
        lat: position?.coords?.latitude,
        lon: position?.coords?.longitude,
      });
    });
  }, []);

  useEffect(() => {
    loadMedicalConditionList(setmedicalConditionData, setloaded).then(
      (res) => {}
    );
  }, []);

  useEffect(() => {
    loadHospitalList(sethospitalListData, setloaded);
  }, []);

  const onHandleChange = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    if (e.target.value) {
      setStepsData((preState) => ({ ...preState, [name]: value }));
    } else {
      delete stepsData[name];
      setStepsData({ ...stepsData });
    }
  };

  return (
    <>
      <div className="relative mb-2">
        <SearchWithDropdown
          label={"Ailment / Medical Condition"}
          placeholder={"Type your Medical Condition here"}
          data={
            medicalConditionData?.find(
              (item) => item?._id === stepsData?.[`hospitalDetails[ailment]`]
            )?.name
          }
          dropdownData={[
            { name: "Select medical condition" },
            ...medicalConditionData,
          ]}
          isError={stepsData?.[`hospitalDetails[ailment]`] ? false : true}
          errorText={"Medical condition details is required!"}
          setCustomSelecedvalue={(value) => {
            if (value?.name === "Select medical condition") {
              delete stepsData[`hospitalDetails[ailment]`];
              setStepsData({ ...stepsData });
              return;
            } else {
              onHandleChange({
                target: { name: "hospitalDetails[ailment]", value: value?._id },
              });
            }
          }}
        />
      </div>
      <div className="relative mb-2">
        <Input
          label={"Location (City) of the hospital"}
          inputPlaceholder={"Enter city"}
          inputValue={stepsData?.[`hospitalDetails[address][city]`]}
          inputName={"hospitalDetails[address][city]"}
          onChange={(e) => onHandleChange(e)}
          errorText={"Hospital location is required!"}
          errorType={
            stepsData?.[`hospitalDetails[address][city]`] ? "" : "danger"
          }
        />
      </div>
      <div className="relative mb-2">
        <SearchWithDropdown
          label={"Hospital"}
          placeholder={"Type your Hospital Name here"}
          data={
            hospitalListData?.find(
              (item) => item?._id === stepsData?.["hospitalDetails[name]"]
            )?.name
          }
          dropdownData={hospitalListData}
          isError={stepsData?.["hospitalDetails[name]"] ? false : true}
          errorText={"Hospital name is required!"}
          setCustomSelecedvalue={(value) => {
            onHandleChange({
              target: { name: "hospitalDetails[name]", value: value?._id },
            });
          }}
        />
      </div>
    </>
  );
};

export default NgoMedical;
