import React from "react";
import BlogImage from "../../assets/images/patient-08.png";
import Patient01 from "../../assets/images/patient-01.png";

import { Link } from "react-router-dom";
import Image from "../elements/Image";
import SingleArticle from "../items/SingleArticle";
import SideArticle from "../items/SideArticle";
import Loader from "../elements/loader/Loader";

const ListArticle = ({ data,loaded, type = "", setPageAction = () => {} }) => {


  return (
    <>
   <section className="relative overflow-hidden w-full py-20 bg-gray-100">
        <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
          <div className="space-y-10">
            {/* <div className="space-y-4 text-center">
                            <h2 className="font-Lexend text-3xl sm:text-4xl xl:text-5xl font-bold text-black">{data.title}</h2>
                            <div className="text-sm xl:text-base text-slate-600 max-w-xl mx-auto">{data.subtitle}</div>
                        </div> */}

            <div className="flex flex-col md:flex-row items-start gap-6">
              <div className="md:w-8/12 w-full bg-white p-4 rounded-lg">
                {Array?.isArray(data) && data?.length > 0 && (
                  <div
                    className={
                      type === "grid"
                        ? "relative grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-x-5 2xl:gap-x-10 gap-y-8 sm:gap-y-6"
                        : "relative grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-x-5 2xl:gap-x-10 gap-y-8 sm:gap-y-14"
                    }
                  >
                    {data?.map((item) => (
                      <SingleArticle key={item._id} data={item} />
                    ))}
                  </div>
                )}
              </div>
              <div className="md:w-4/12 w-full bg-white p-4 rounded-lg">
                <h2 className="font-Lexend !text-2xl pb-3 mb-2 border-b border-solid border-slate-200  sm:text-4xl xl:text-5xl font-bold text-slate-900">
                  {data.headline}
                </h2>
                {Array.isArray(data) && data?.length > 0 && (
                  <div
                    className={
                      type === "grid"
                        ? "relative grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-x-5 2xl:gap-x-10 gap-y-8 sm:gap-y-6 "
                        : "relative grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-x-5 2xl:gap-x-10 gap-y-8 sm:gap-y-6 mt-2"
                    }
                  >
                    {data?.slice(0, 5)?.map((item) => (
                      <SideArticle key={item._id} data={item} />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
   </section>
    </>
  );
};

export default ListArticle;
